import React, { useState, useEffect } from 'react';
import './ContaPagarReceber.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/TableV2'
import Loading from './../../components/templates/Loading'
import { Grid, Button, TextField } from '@mui/material'
import {Pagination, Autocomplete} from '@mui/material';
import { saveAs } from 'file-saver';
import axios from 'axios'
import moment from 'moment';
import Alert from './../../components/templates/Alert'
import real from '../../services/real'
import ModalExibeBoleto from './../../components/modals/ExibeBoleto'
import ModalInformarConta from './../../components/modals/InformarConta'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import CreateIcon from '@mui/icons-material/Create';
import PrintIcon from '@mui/icons-material/Print';
import DescriptionIcon from '@mui/icons-material/Description'
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ModalAjuda from './../../components/modals/Ajuda'
import { format } from 'cnpj'
import { useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

function renderClassFinanceiro(param) {
  if (param === 'VENCIDO') {
    return {
      backgroundColor: '#FFB6C1	'
    }
  } else if (param === 'A VENCER') {
    return {
      backgroundColor: '#FFFFE0'
    }
  } else if (param === 'BAIXADO') {
    return {
      backgroundColor: '#98FB98'
    }
  } else if (param === 'CANCELADO' || param === 'ESTORNADO') {
    return {
      backgroundColor: '#FFFAFA'
    }
  }
}

const initialState = {
  conta: {

  },
  contas: {
    list: []
  },
  contasFiltrado: {
    list: []
  },
  planosConta: {
    list: []
  },
  planosContaFiltrado: {
    list: []
  },
  filtro: {
    tipo: "",
    descricao: "",
    data_inicial: "",
    data_final: "",
    data_por: "VENCIMENTO",
    situacoes: [{
      id: 'A_VENCER',
      value: 'A VENCER',
      descricao: 'A Vencer'
    },
    {
      id: 'BAIXADO',
      value: 'BAIXADO',
      descricao: 'Baixado'
    },
    {
      id: 'VENCIDO',
      value: 'VENCIDO',
      descricao: 'Vencido'
    }],
    formas_pagto: [],
    pessoa_id: "",
    nome: "",
    limit: 10,
    offset: 0
  },
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Uni.' },
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição Conta' },
    // { id: 'planocontas_descricao', numeric: false, disablePadding: true, label: 'Plano' },
    { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Data Venc.', orderBy: 'datavencimentoOrder' },
    { id: 'datapagamento', numeric: false, disablePadding: false, label: 'Data Pag.', orderBy: 'datapagamentoOrder' },
    { id: 'data_documento', numeric: false, disablePadding: true, label: 'Data Emissão', orderBy: 'data_documentoOrder' },
    { id: 'valorparcela', numeric: true, disablePadding: false, label: 'Vl. Parc.' },
    { id: 'multajuros', numeric: true, disablePadding: false, label: 'Multa/Juros ou Desc.' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma Pagto.' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' }
  ],
  acoesTabela: ['imprimirBoleto'],
  tipo: "",
  loading: true,
  mensagemLoading: 'Carregando Contas Bancárias...',
  loadingTable: false,
  permissoesContasPR: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCaixa: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  perfilLogado: '',
  formasPagamento:{
    list: []
  },
  totais:{
    totalParcelaPagar: 0,
    totalParcelaReceber: 0,
    totalPagoPagar: 0,
    totalPagoReceber: 0
  },
  loadingImprimir: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalExibeBoleto: false,
  boletoSelecionado:{
    tipo_transaction: 'BOLETO',
    bank_slip:{
      url_slip_pdf: ''
    }
  },
  liberaBaixaMultipla: false,
  pessoas:{
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  parcelasBaixa: [],
  parcelasBaixaValor: [],
  primeiraParcelaSelecionada: {
    formapagto_slug: '',
    habilita: '',
    unidade_id: ''
  }, 
  modalInformarConta: false,
  baixaConta: {
    contfin_id: '',
    datapagamento: ''
  },
  contasFinanceira: {
    list: []
  },
  loadingBaixaConta: false,
  planoContaSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: '',
    tipo: ''
  },
  modal: {
    mensagem: '',
    row: null
  },
  rows: 0,
  formasSelecionadas: [],
  situacoes:{
    list: [
      {
        id: 'A_VENCER',
        value: 'A VENCER',
        descricao: 'A Vencer'
      },
      {
        id: 'BAIXADO',
        value: 'BAIXADO',
        descricao: 'Baixado'
      },
      {
        id: 'VENCIDO',
        value: 'VENCIDO',
        descricao: 'Vencido'
      },
      {
        id: 'ESTORNADO',
        value: 'ESTORNADO',
        descricao: 'Estornado'
      },
      {
        id: 'CANCELADO',
        value: 'CANCELADO',
        descricao: 'Cancelado'
      }
    ]
  },
  situacoesSelecionadas: [
    {
      id: 'A_VENCER',
      value: 'A VENCER',
      descricao: 'A Vencer'
    },
    {
      id: 'BAIXADO',
      value: 'BAIXADO',
      descricao: 'Baixado'
    },
    {
      id: 'VENCIDO',
      value: 'VENCIDO',
      descricao: 'Vencido'
    },
  ],
  ocultarFiltros: false,
  modalConfirmacaoOpen: false,
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ContaPagarReceber = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: `${pessoa.nome} ${pessoa.sobrenome || ''}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFiltro = (event) => {
    const {filtro, planosConta, formasPagamento} = state

    filtro[event.target.name] = event.target.value

    if(event.target.name === 'tipo'){
      setState(prevState => ({...prevState,
        planoContaSelecionado:{
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: '',
          tipo: ''
        }
      }))

      filtro.planocontas_id = ''
      
      if(event.target.value !== ''){
        setState(prevState => ({...prevState,
          planosContaFiltrado: {
            list: planosConta.list.filter(param => param.tipo === (event.target.value === 'PAGAR' ? "DESPESA" : "RECEITA"))
          }
        }))
      }else{
        setState(prevState => ({...prevState,
          planosContaFiltrado: {
            list: []
          }
        }))
      }

    }
    
    if(event.target.name === 'planocontas_id'){
      if(planosConta.list.filter(param => param.id === parseInt(event.target.value))[0]){
        filtro.planocontas_descricao = planosConta.list.filter(param => param.id === parseInt(event.target.value))[0].descricao
      }else{
        filtro.planocontas_descricao = ''
      }
    }

    if(event.target.name === 'formapagto_id'){
      if(formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]){
        filtro.formapagto_descricao = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0].descricao
      }else{
        filtro.formapagto_descricao = ''
      }
    }

    setState(prevState => ({...prevState,
      filtro
    }))

  }

  const filtrar = async () => {
    const {filtro, formasSelecionadas, situacoesSelecionadas, cliente} = state

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    filtro.unidadesnegocio = dados.unidadesnegocio

    filtro.situacoes = situacoesSelecionadas
    filtro.formas_pagto = formasSelecionadas
    filtro.limit = 10
    filtro.offset = 0

    setState(prevState => ({...prevState,
      filtro
    }))

    // criaUrl(filtro)
    localStorage.setItem('filtro_contaspr', JSON.stringify(filtro));
    localStorage.setItem('filtro_cliente_contaspr', JSON.stringify(cliente));

    await filtrarContas(filtro)
  }

  const filtrarContas = async (filtro) => {
    if (filtro.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data final!'
        }
      }))
      return
    }

    if (filtro.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial!'
        }
      }))
      return
    }

    setState(prevState => ({...prevState,
      loadingTable: true
    }))

    try {

      setState(prevState => ({...prevState,
        totais:{
          totalParcelaPagar: 0,
          totalParcelaReceber: 0,
          totalPagoPagar: 0,
          totalPagoReceber: 0
        }
      }))

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      filtro.unidadesnegocio = dados.unidadesnegocio
      filtro.situacoes = filtro.situacoes
      filtro.formas_pagto = filtro.formas_pagto
      
      localStorage.setItem('filtro_contaspr', JSON.stringify(filtro));      

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/listPrincipal`, filtro, getToken())

      const contas = []

      if(filtro.pessoa_id !== ''){
        setState(prevState => ({...prevState,
          liberaBaixaMultipla: true
        }))
      }else{
        setState(prevState => ({...prevState,
          liberaBaixaMultipla: false
        }))
      }

      for (const conta of data.list) {
        if(conta.descricao){

          let aberto = false

          if(conta.situacao === 'A VENCER' || conta.situacao === 'VENCIDO'){
            aberto = true
          }

          let configuracao = await getConfiguracaoUnidade(conta.unidade_id)

          
          contas.push({
            _id: conta.parcela_id,
            contapr_id: conta.contapr_id,
            recorrencia_id: conta.recorrencia_id,
            unidade_id: conta.unidade_id,
            unidade: `${conta.numero_unidade} - ${conta.unidade}`,
            contpr_descricao: conta.descricao + (conta.numparcela > 1 ? ` - Parc: ${conta.num}/${conta.numparcela}` : ''),
            num: conta.num,
            numparcela: conta.numparcela,
            boleto_digitable_line: conta.boleto_digitable_line,
            boleto_transaction_id: conta.boleto_transaction_id,
            boleto_link: conta.boleto_link,
            tipo_transaction: conta.tipo_transaction,
            documento: conta.documento,
            tipo: conta.valorparcela < 0 ? 'PAGAR' : 'RECEBER',
            datavencimento: moment(conta.datavencimento).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(conta.datavencimento).format('YYYYMMDD'),
            datapagamento:conta.datapagamento ? moment(conta.datapagamento).format('DD/MM/YYYY') : '',
            datapagamentoOrder: conta.datapagamento ? moment(conta.datapagamento).format('YYYYMMDD') : '',
            datavencimento_sem_format: conta.datavencimento,
            data_documento: conta.data_documento ? moment(conta.data_documento).format('DD/MM/YYYY') : '',
            data_documentoOrder: conta.data_documento ? moment(conta.data_documento).format('YYYYMMDD') : '',
            situacao: conta.situacao,
            valorparcela: real(conta.valorparcela),
            valor_pagamento: conta.situacao === 'BAIXADO' ? real(conta.valor_pagamento) : real(0),
            valor_pagamento_real: real(conta.valor_pagamento),
            formapagto_descricao: conta.forma_pagto,
            formapagto_slug: conta.formapagto_slug,
            multajuros: conta.multajuros ? real(conta.multajuros) : real(0),
            pessoa_id: conta.conta_pessoa_id,
            pessoa_nome: conta.tipo_pessoa === 'FISICA' ? (conta.nome_pessoa + (conta.sobrenome ? ` ${conta.sobrenome}`: '')) : conta.nomefantasia,
            habilita: conta.habilita,
            qtd_parcelas: parseInt(conta.qtd_parcelas),
            aberto,
            nfse_id: conta.nfse_id,
            numero_nota: conta.numero_nota,
            pedido_id: conta.pedido_id,
            liberaNFSE: configuracao.habilitar_emissao,
            data_inicio_nfse: configuracao.data_inicio_nfse,
            contfin_id: conta.contfin_id,
            parceiro_bancario_id: conta.parceiro_bancario_id
          })
        }
      }

      setState(prevState => ({...prevState,
        contas: {
          list: contas.sort((a, b) => (a.datavencimento_sem_format > b.datavencimento_sem_format) ? 1 : ((b.datavencimento_sem_format > a.datavencimento_sem_format) ? -1 : 0))
        },
        rows: data.rows,
        loadingTable: false,
        totais: data.totais,
        primeiraParcelaSelecionada: {
          formapagto_slug: '',
          habilita: '',
          unidade_id: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      return
    }

  }

  const handleChangePage = async (event, newPage) => {
    const {filtro} = state

    filtro.offset = newPage
    
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarContas(filtro)
  }

  const handleChangePageMobile = async (event, newPage) => {
    const {filtro} = state

    filtro.offset = newPage - 1
    
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarContas(filtro)
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarContas(filtro)
  }

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
  
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'success',
        message: 'Copiado!'
      }
    }))
    return
    
  }

  const imprimir = async () => {
    const {filtro, rows} = state

    setState(prevState => ({...prevState,
      loadingImprimir: true
    }))

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      let filtro_aux = {
        ...filtro
      }

      filtro.offset = 0
      filtro_aux.limit = rows

      const { data : parcelas } = await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/listPrincipal`, filtro_aux, getToken())

      let contas = []

      parcelas.list.forEach(conta => {
        if(conta.descricao){
          
          let aberto = false

          if(conta.situacao === 'A VENCER' || conta.situacao === 'VENCIDO'){
            aberto = true
          }

          contas.push({
            _id: conta.parcela_id,
            unidade_id: conta.unidade_id,
            unidade: `${conta.numero_unidade} - ${conta.unidade}`,
            contpr_descricao: conta.descricao + (conta.numparcela > 1 ? ` - Parc: ${conta.num}/${conta.numparcela}` : ''),
            num: conta.num,
            boleto_digitable_line: conta.boleto_digitable_line,
            boleto_transaction_id: conta.boleto_transaction_id,
            boleto_link: conta.boleto_link,
            tipo_transaction: conta.tipo_transaction,
            documento: conta.documento,
            tipo: conta.valorparcela < 0 ? 'PAGAR' : 'RECEBER',
            datavencimento: moment(conta.datavencimento).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(conta.datavencimento).format('YYYYMMDD'),
            datapagamento:conta.datapagamento ? moment(conta.datapagamento).format('DD/MM/YYYY') : '',
            datapagamentoOrder: conta.datapagamento ? moment(conta.datapagamento).format('YYYYMMDD') : '',
            datavencimento_sem_format: conta.datavencimento,
            data_documento: conta.data_documento ? moment(conta.data_documento).format('DD/MM/YYYY') : '',
            data_documentoOrder: conta.data_documento ? moment(conta.data_documento).format('YYYYMMDD') : '',
            situacao: conta.situacao,
            valorparcela: real(conta.valorparcela),
            valor_pagamento: real(conta.valor_pagamento),
            formapagto_descricao: conta.forma_pagto,
            formapagto_slug: conta.formapagto_slug,
            multajuros: conta.multajuros ? real(conta.multajuros) : real(0),
            pessoa_id: conta.conta_pessoa_id,
            pessoa_nome: conta.tipo_pessoa === 'FISICA' ? (conta.nome_pessoa + (conta.sobrenome ? ` ${conta.sobrenome}` : '')) : conta.nomefantasia,
            habilita: conta.habilita,
            qtd_parcelas: parseInt(conta.qtd_parcelas),
            aberto,
            parceiro_bancario_id: conta.parceiro_bancario_id
          })
        }
      });

      let filtro_impressao = {
        relatorio: 'contas-pagar-receber',
        data_inicial: state.filtro.data_inicial,
        data_final: state.filtro.data_final,
        filtroTela: state.filtro,
        unidade_id: "",
        unidadesnegocio: dados.unidadesnegocio,
        list: contas,
        totais: state.totais
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro_impressao, getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingImprimir: false
      }))

      saveAs(pdfBlob, 'Contas-Pagar-Receber.pdf')


    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingImprimir: false
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const imprimirBoleto = async (row) => {

    let {contas} = state

    let index = contas.list.findIndex(param => param._id === row._id)

    if(row.tipo_transaction === 'PIX'){
      try {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Carregando PIX'
          }
        }))

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pix/atualiza/${row._id}`,{}, getToken())

        contas.list[index].boleto_digitable_line = data.pix_code.emv
        contas.list[index].boleto_transaction_id = data.transaction_id
        contas.list[index].boleto_link = data.pix_code.qrcode_image_url
        contas.list[index].tipo_transaction = 'PIX'

        setState(prevState => ({...prevState,
          boletoSelecionado:{
            parceiro_bancario_id: row.parceiro_bancario_id,
            tipo_transaction: 'PIX',
            bank_slip:{
              url_slip_pdf: data.pix_code.qrcode_image_url,
              boleto_digitable_line: data.pix_code.emv
            }
          },
          contas,
          alerta: {
            open: false,
            severity: 'warning',
            message: ''
          },
          openModalExibeBoleto: true
        }))
      
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
          }
        }))
      }
    }else if(row.tipo_transaction === 'BOLETO'){
      try {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Atualizando o Boleto. Por favor Aguarde...'
          }
        }))

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/atualiza/${row._id}`,{}, getToken())

        contas.list[index].boleto_transaction_id = data.transaction_id
        contas.list[index].boleto_digitable_line = data.bank_slip.digitable_line
        contas.list[index].boleto_link = data.bank_slip.url_slip_pdf
        contas.list[index].tipo_transaction = 'BOLETO'

        setState(prevState => ({...prevState,
          boletoSelecionado:{
            parceiro_bancario_id: row.parceiro_bancario_id,
            tipo_transaction: 'BOLETO',
            bank_slip:{
              url_slip_pdf: data.bank_slip.url_slip_pdf,
              boleto_digitable_line: data.bank_slip.digitable_line
            }
          },
          contas,
          alerta: {
            open: false,
            severity: 'warning',
            message: ''
          },
          openModalExibeBoleto: true
        }))
        
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
          }
        }))
      }
    }

    // if(row.situacao === "VENCIDO"){
    //   let index = contas.list.findIndex(param => param._id === row._id)
    //   if(row.tipo_transaction === 'PIX'){
    //     try {
    //       setState(prevState => ({...prevState,
    //         alerta: {
    //           open: true,
    //           severity: 'warning',
    //           message: 'Carregando PIX'
    //         }
    //       }))
  
    //       const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pix/atualiza/${row._id}`,{}, getToken())
  
    //       contas.list[index].boleto_digitable_line = data.pix_code.emv
    //       contas.list[index].boleto_transaction_id = data.transaction_id
    //       contas.list[index].boleto_link = data.pix_code.qrcode_image_url
    //       contas.list[index].tipo_transaction = 'PIX'
  
    //       setState(prevState => ({...prevState,
    //         boletoSelecionado:{
    //           parceiro_bancario_id: row.parceiro_bancario_id,
    //           tipo_transaction: 'PIX',
    //           bank_slip:{
    //             url_slip_pdf: data.pix_code.qrcode_image_url,
    //             boleto_digitable_line: data.pix_code.emv
    //           }
    //         },
    //         contas,
    //         alerta: {
    //           open: false,
    //           severity: 'warning',
    //           message: ''
    //         },
    //         openModalExibeBoleto: true
    //       }))
        
    //     } catch (error) {
    //       console.log(error)
    //       setState(prevState => ({...prevState,
    //         alerta: {
    //           open: true,
    //           severity: 'error',
    //           message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
    //         }
    //       }))
    //     }
    //   }

    //   if(row.tipo_transaction === 'BOLETO'){
    //     try {
    //       setState(prevState => ({...prevState,
    //         alerta: {
    //           open: true,
    //           severity: 'warning',
    //           message: 'Atualizando o Boleto. Por favor Aguarde...'
    //         }
    //       }))
  
    //       const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/atualiza/${row._id}`,{}, getToken())
  
    //       contas.list[index].boleto_transaction_id = data.transaction_id
    //       contas.list[index].boleto_digitable_line = data.bank_slip.digitable_line
    //       contas.list[index].boleto_link = data.bank_slip.url_slip_pdf
    //       contas.list[index].tipo_transaction = 'BOLETO'
  
    //       setState(prevState => ({...prevState,
    //         boletoSelecionado:{
    //           parceiro_bancario_id: row.parceiro_bancario_id,
    //           tipo_transaction: 'BOLETO',
    //           bank_slip:{
    //             url_slip_pdf: data.bank_slip.url_slip_pdf,
    //             boleto_digitable_line: data.bank_slip.digitable_line
    //           }
    //         },
    //         contas,
    //         alerta: {
    //           open: false,
    //           severity: 'warning',
    //           message: ''
    //         },
    //         openModalExibeBoleto: true
    //       }))
          
    //     } catch (error) {
    //       console.log(error)
    //       setState(prevState => ({...prevState,
    //         alerta: {
    //           open: true,
    //           severity: 'error',
    //           message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
    //         }
    //       }))
    //     }
    //   }
    // } else{
    //   try {
    //     let index = contas.list.findIndex(param => param._id === row._id)

    //     if(row.tipo_transaction === 'PIX'){
    //       setState(prevState => ({...prevState,
    //         alerta: {
    //           open: true,
    //           severity: 'warning',
    //           message: 'Carregando PIX'
    //         }
    //       }))

    //       const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pix/atualiza/${row._id}`,{}, getToken())

    //       contas.list[index].boleto_digitable_line = data.pix_code.emv
    //       contas.list[index].boleto_transaction_id = data.transaction_id
    //       contas.list[index].boleto_link = data.pix_code.qrcode_image_url
    //       contas.list[index].tipo_transaction = 'PIX'

    //       setState(prevState => ({...prevState,
    //         boletoSelecionado:{
    //           parceiro_bancario_id: row.parceiro_bancario_id,
    //           tipo_transaction: 'PIX',
    //           bank_slip:{
    //             url_slip_pdf: data.pix_code.qrcode_image_url,
    //             boleto_digitable_line: data.pix_code.emv
    //           }
    //         },
    //         contas,
    //         alerta: {
    //           open: false,
    //           severity: 'warning',
    //           message: ''
    //         },
    //         openModalExibeBoleto: true
    //       }))
    //       // if(!row.boleto_transaction_id){
    //       // }else{
    //       //   setState(prevState => ({...prevState,
    //       //     boletoSelecionado:{
    //       //       parceiro_bancario_id: row.parceiro_bancario_id,
    //       //       tipo_transaction: 'PIX',
    //       //       bank_slip:{
    //       //         url_slip_pdf: row.boleto_link,
    //       //         boleto_digitable_line: row.boleto_digitable_line
    //       //       }
    //       //     },
    //       //     contas,
    //       //     alerta: {
    //       //       open: false,
    //       //       severity: 'warning',
    //       //       message: ''
    //       //     },
    //       //     openModalExibeBoleto: true
    //       //   }))
    //       // }
    //     }else{
    //       setState(prevState => ({...prevState,
    //         alerta: {
    //           open: true,
    //           severity: 'warning',
    //           message: 'Atualizando o Boleto. Por favor Aguarde...'
    //         }
    //       }))

    //       const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/atualiza/${row._id}`,{}, getToken())

    //       contas.list[index].boleto_transaction_id = data.transaction_id
    //       contas.list[index].boleto_digitable_line = data.bank_slip.digitable_line
    //       contas.list[index].boleto_link = data.bank_slip.url_slip_pdf
    //       contas.list[index].tipo_transaction = 'BOLETO'

    //       setState(prevState => ({...prevState,
    //         boletoSelecionado:{
    //           parceiro_bancario_id: row.parceiro_bancario_id,
    //           tipo_transaction: 'BOLETO',
    //           bank_slip:{
    //             url_slip_pdf: data.bank_slip.url_slip_pdf,
    //             boleto_digitable_line: data.bank_slip.digitable_line
    //           }
    //         },
    //         contas,
    //         alerta: {
    //           open: false,
    //           severity: 'warning',
    //           message: ''
    //         },
    //         openModalExibeBoleto: true
    //       }))
    //       // if(!row.boleto_transaction_id){
    //       // }else{
    //       //   setState(prevState => ({...prevState,
    //       //     boletoSelecionado:{
    //       //       parceiro_bancario_id: row.parceiro_bancario_id,
    //       //       tipo_transaction: 'BOLETO',
    //       //       bank_slip:{
    //       //         url_slip_pdf: row.boleto_link,
    //       //         boleto_digitable_line: row.boleto_digitable_line
    //       //       }
    //       //     },
    //       //     contas,
    //       //     alerta: {
    //       //       open: false,
    //       //       severity: 'warning',
    //       //       message: ''
    //       //     },
    //       //     openModalExibeBoleto: true
    //       //   }))
    //       // }
    //     }
  
    //   } catch (error) {
    //     console.log(error)
    //     setState(prevState => ({...prevState,
    //       alerta: {
    //         open: true,
    //         severity: 'error',
    //         message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
    //       }
    //     }))
    //   }
    // }

  }

  const getConfiguracaoUnidade = async (unidade_id) => {
    try {
      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade_id}`, getToken())
        
      return configuracao
    } catch ({response}) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      }))
      return
    }
  }
  
  const imprimirNFSE = async (row) => {
    
    if(!await getConfiguracaoUnidade(row.unidade_id)){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Essa unidade não possui emissão de NFS'
        }
      }))
      return 
    }
    
    if(row.nfse_id){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Carregando NFSe'
        }
      }))
    
      try {
    
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/imprimir`, {id: row.nfse_id}, getToken())
        
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        setState(prevState => ({...prevState,
          loadingImprimir: false,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))
  
        saveAs(pdfBlob, `NFSe-${row.numero_nota}.pdf`)
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingImprimir: false
        }))
      }
    }else{
      setState(prevState => ({...prevState,
        modalConfirmacaoOpen: true,
        modal: {
          mensagem: 'Deseja realmente emitir essa NFS-e?',
          row
        }
      }))
      return
    }
    
  }

  const confirmarFecharModal = async (value) => {
    if(value){
      try {
        handleClose()

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Gerando NFSe'
          }
        }))

        let {modal} = state

        let row = modal.row

        const { data : nfse } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/emissao/${row.contapr_id}/${row._id}`, {id: row.nfse_id}, getToken())

        let {contas} = state

        let index = contas.list.findIndex(param => param._id === row._id)

        contas.list[index].nfse_id = nfse.id
        contas.list[index].numero_nota = nfse.numero

        setState(prevState => ({...prevState,
          contas,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Carregando NFSe'
          }
        }))

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/imprimir`, {id: nfse.id}, getToken())
        
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        setState(prevState => ({...prevState,
          loadingImprimir: false,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))
  
        saveAs(pdfBlob, `NFSe-${row.numero_nota}.pdf`)
      }catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingImprimir: false
        }))
      }

    }else{
      handleClose()
    }
  }
  
  const handleClose = () => {
    setState(prevState => ({...prevState,
      modalConfirmacaoOpen: false,
      modal: {
        mensagem: '',
      }
    }))
  }

  const handleModalExibeBoleto = () => {
    setState(prevState => ({...prevState,openModalExibeBoleto: false}))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const updateFieldPessoaFiltro = (event, value) => {
    const {filtro} = state

    if(!value){

      filtro.pessoa_id = ''
      filtro.nome = ''

      setState(prevState => ({...prevState,
        cliente: {
          pessoa_id: '',
          nome: '',
          cpf_cnpj: ''
        },
        filtro
      }))

      return false
    } 

    filtro.pessoa_id = value.pessoa_id
    filtro.nome = value.nome

    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const selecionarBaixa = (row) => {
    let {parcelasBaixa, parcelasBaixaValor, baixaConta} = state
    
    const index = parcelasBaixa.findIndex(param => param === row._id)
    
    if(parcelasBaixa.length === 0){
      setState(prevState => ({...prevState,
        primeiraParcelaSelecionada:{
          unidade_id: row.unidade_id,
          formapagto_slug: row.formapagto_slug,
          datavencimento: row.datavencimento_sem_format,
          contfin_id: row.contfin_id,
          habilita: row.habilita
        },
        baixaConta: {
          contfin_id: row.contfin_id,
          datapagamento: row.datavencimento_sem_format
        }
      }))
    }else{
      if(baixaConta.datapagamento !== row.datavencimento_sem_format){
        baixaConta.datapagamento = ''
      }

      if(baixaConta.contfin_id !== row.contfin_id){
        baixaConta.contfin_id = ''
      }

      setState(prevState => ({...prevState,baixaConta}))
    }

    if(index === -1){
      parcelasBaixa.push(row._id)

      parcelasBaixaValor.push({
        id: row._id,
        valor: parseFloat(row.valorparcela.replaceAll(".", "").replace(",", "."))
      })
    }else{
      parcelasBaixa.splice(index, 1)
      parcelasBaixaValor.splice(index, 1)
    }

    if(parcelasBaixa.length === 0){
      setState(prevState => ({...prevState,
        primeiraParcelaSelecionada:{
          unidade_id: '',
          formapagto_slug: '',
          habilita: ''
        }
      }))
    }

    setState(prevState => ({...prevState, parcelasBaixa, parcelasBaixaValor}))

  }

  const baixarContas = async () => {
    const {primeiraParcelaSelecionada, baixaConta} = state

    try {

      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parseInt(primeiraParcelaSelecionada.unidade_id)}`, getToken())

      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira.filter(param => param.tipo_conta === primeiraParcelaSelecionada.habilita)
        },
        modalInformarConta: true,
        baixaConta
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const handleCloseModalInformarConta = () => {
    setState(prevState => ({...prevState,
      modalInformarConta: false
    }))
  }

  const updateFieldBaixaConta = (event) => {
    const {baixaConta} = state 
    baixaConta[event.target.name] = event.target.value
    setState(prevState => ({...prevState,baixaConta}))
  }

  const validateConfirmarBaixa = () => {
    const { baixaConta } = state

    if (!baixaConta.contfin_id) return false
    if (!baixaConta.datapagamento) return false

    return true
  }

  const confirmarBaixaContas = async (value) => {
    if(value){

      if (!validateConfirmarBaixa()) {
        setState(prevState => ({...prevState,
          disabled: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Preencha os campos obrigatorios (*).'
          }
        }))
        return
      }

      const {baixaConta, parcelasBaixa} = state 
  
      setState(prevState => ({...prevState,loadingBaixaConta: true}))
  
      try {

        const dados = {
          contfin_id: baixaConta.contfin_id,
          datapagamento: baixaConta.datapagamento,
          parcelasBaixa
        }
  
        await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/baixarContas`, dados, getToken())
        
        setState(prevState => ({...prevState,
          loadingBaixaConta: false,
          modalInformarConta: false,
          parcelasBaixa: [],
          parcelasBaixaValor: [],
        }))
  
        // window.location.reload()
        await filtrarContas(state.filtro)
  
      }catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loadingBaixaConta: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleCloseModalInformarConta()
    }
  }

  const updateFieldForma = (event, value) => {
    setState(prevState => ({...prevState,
      formasSelecionadas: value
    }))
  }

  const updateFieldSituacao = (event, value) => {
    setState(prevState => ({...prevState,
      situacoesSelecionadas: value
    }))
  }

  const renderIconeNFSE = (conta) => {
    if(conta.pedido_id && conta.situacao !== 'CANCELADO' && conta.situacao !== 'ESTORNADO' && conta.liberaNFSE && 
    (moment(conta.data_inicio_nfse).isBefore(conta.datavencimento_sem_format) || conta.data_inicio_nfse === conta.datavencimento_sem_format)){
      return (
        <IconButton 
          style={{color: conta.nfse_id ? '#063e11': '#f50057'}} 
          onClick={e => imprimirNFSE(conta)} 
          aria-label="view"
        >
          <DescriptionIcon />
        </IconButton>
      )
    }else{
      return (
        <IconButton disabled aria-label="view">
          <DescriptionIcon />
        </IconButton>
      )
    }
  }

  const renderIconeBoleto = (conta) => {
    if(conta.tipo_transaction && (conta.situacao === 'A VENCER' ||  conta.situacao === 'VENCIDO') && conta.tipo === 'RECEBER'){
      return (
        <IconButton style={{marginLeft: '10px'}} onClick={e => imprimirBoleto(conta)} color="primary" aria-label="view">
          <PrintIcon />
        </IconButton>
      )
    }else{
      return (
        <IconButton style={{marginLeft: '10px'}} disabled aria-label="view">
          <PrintIcon />
        </IconButton>
      )
    }
  }

  const copiar = async (row) => {
    try {
      const { data: conta } = await axios.get(`${window._env_.REACT_APP_API_URL}/contasPagarReceber/${row.contapr_id}`, getToken())
      
      let conta_aux = {
        descricao: conta.descricao,
        tipo: conta.valortotal < 0 ? "PAGAR": "RECEBER",
        valortotal: real(conta.valortotal),
        numparcela: conta.numparcela,
        item_id: conta.itens_conta.length > 0 ? conta.itens_conta[0].item_id : '',
        tipo_item: "ITEMRECDESP",
        qtd_parcelas: conta.numparcela,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        qtdRecorrencia: 1,
        data_documento: conta.data_documento,
        unidade_id: conta.unidade_id,
        parcelas: conta.parcelas.map((parcela, index) => {
          return {
            baixado: false,
            datavencimento: parcela.datavencimento,
            datavencimento_orig: parcela.datavencimento,
            valorparcela: real(parcela.valorparcela),
            formapagto_id: parcela.valores_pago[0].formapagto_id,
            contfin_id: parcela.valores_pago[0].contfin_id,
            documento: parcela.documento || "",
            habilita: parcela.valores_pago[0].forma_pagto.habilita,
            num: parcela.num,
            parcela_editada: false,
            index: index + 1
          }
        }),
        parcelado: conta.numparcela > 1 ? true : false,
        recorrencia: conta.recorrencia_id ? true: false,
      }

      if(conta.recorrencia){
        conta_aux.qtdRecorrencia = conta.recorrencia.num_ocorrencias
        conta_aux.recorrenciaACada = conta.recorrencia.repetir_a_cada
        conta_aux.tipoRecorrencia = conta.recorrencia.intervalo
      }

      let itemSelecionado = null

      if(conta.itens_conta.length > 0){
        itemSelecionado = {
          id: conta.itens_conta[0].item_id,
          descricao: conta.itens_conta[0].item.descricao,
          ativo: conta.itens_conta[0].item.ativo,
          plano_contas: conta.itens_conta[0].item.plano_contas
        }
      }else{
        itemSelecionado = {
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: ''
        }
      }
      
      conta_aux.itemSelecionado = itemSelecionado

      conta_aux.cliente = {
        pessoa_id: conta.pessoa.id,
        tipo: conta.pessoa.tipo,
        flags: conta.pessoa.flags.map(value => value.flag),
        nome: conta.pessoa.tipo === 'FISICA' ? `${conta.pessoa.fisica.nome}${conta.pessoa.fisica.sobrenome ? ` ${conta.pessoa.fisica.sobrenome}` : ''}` : conta.pessoa.juridica.nomefantasia,
        cpf_cnpj: conta.pessoa.tipo === 'FISICA' ? conta.pessoa.fisica.cpf : conta.pessoa.juridica.cnpj,
        endereco: conta.pessoa.endereco ? `${conta.pessoa.endereco.logradouro} ${conta.pessoa.endereco.numero}, ${conta.pessoa.endereco.bairro}, ${conta.pessoa.endereco.cidade} - ${conta.pessoa.endereco.estado}` : 'N/A',
        telefone: conta.pessoa.telefone ? conta.pessoa.telefone.numero : 'N/A',
        email: conta.pessoa.email ? conta.pessoa.email.email : 'N/A',
        ativo: conta.pessoa.ativo
      }

      let centro_custos = []

      if(conta.centros_custo_conta.length > 0){
        for (const centro of conta.centros_custo_conta) {
          centro_custos.push({
            id: '',
            departamento_id: centro.centro_custo.departamento_id,
            departamento: centro.centro_custo.departamento.descricao,
            centrocusto_id: centro.centrocusto_id,
            centrocusto: centro.centro_custo.descricao,
            valor_percentual: centro.valor_percentual,
            valor_rateio: centro.valor_rateio,
            ativo: true,
            index: centro_custos.length
          })
        }
      }

      conta_aux.centro_custos = centro_custos

      localStorage.setItem('conta_copiar', JSON.stringify(conta_aux));
      navigate("/conta_pagar_receber/cadastro");

    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/Listagem.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        totais:{
          totalParcelaPagar: 0,
          totalParcelaReceber: 0,
          totalPagoPagar: 0,
          totalPagoReceber: 0
        },
        situacoesSelecionadas: [
          {
            id: 'A_VENCER',
            value: 'A VENCER',
            descricao: 'A Vencer'
          },
          {
            id: 'BAIXADO',
            value: 'BAIXADO',
            descricao: 'Baixado'
          },
          {
            id: 'VENCIDO',
            value: 'VENCIDO',
            descricao: 'Vencido'
          },
        ],
        formasSelecionadas: [],
        parcelasBaixa: [],
        parcelasBaixaValor: [],
        primeiraParcelaSelecionada: {
          formapagto_slug: '',
          habilita: '',
          unidade_id: ''
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]
      // let perfilLogado = ''

      if (permissoesCaixa) {
        // perfilLogado = 'caixa'
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        // perfilLogado = 'contas-pagar-receber'
        if(permissoesContasPR.alterar){
          setState(prevState => ({...prevState,
            cabecalhoTabela: [
              { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição Conta' },
              { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Data Venc.', orderBy: 'datavencimentoOrder' },
              { id: 'valorparcela', numeric: true, disablePadding: false, label: 'Vl. Parc.' },
              { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma Pagto.' },
              { id: 'datapagamento', numeric: false, disablePadding: false, label: 'Data Pag.', orderBy: 'datapagamentoOrder' },
              { id: 'multajuros', numeric: true, disablePadding: false, label: 'Multa/Desc.' },
              { id: 'valor_pagamento', numeric: true, disablePadding: false, label: 'Vl. Pag..' },
              { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
              { id: 'baixar', numeric: false, disablePadding: true, label: 'Baixar Conta' }
            ],
            acoesTabela: ['update', 'imprimirBoleto', 'imprimirNFSE', 'copy']
          }))
        }
        
        setState(prevState => ({...prevState,
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      if (permissoesCaixa) {
        if(permissoesCaixa.inserir){
          setState(prevState => ({...prevState,
            acoesTabela: ['update', 'imprimirBoleto', 'imprimirNFSE', 'copy'],
          }))
        }      
      }

      const ultima_tela_financeiro = {
        tela: 'contasPR'
      }

      localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro))

      const caixa = JSON.parse(localStorage.getItem('caixa'))
      const contasPR = JSON.parse(localStorage.getItem('contasPR'))
      setState(prevState => ({...prevState, logado: { caixa, contasPR } }))

      let dataAtual = new Date()
      dataAtual = moment(dataAtual).format('YYYY-MM-DD')

      let data7dias = new Date()
      data7dias.setDate(data7dias.getDate() + 7)
      data7dias = moment(data7dias).format('YYYY-MM-DD')
      
      try {
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true).map(forma => {
              return {
                id: forma.id,
                descricao: forma.descricao,
                slug: forma.slug
              }
            })
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      let filtro_contaspr =  JSON.parse(localStorage.getItem('filtro_contaspr'))
      let filtro_cliente_contaspr = JSON.parse(localStorage.getItem('filtro_cliente_contaspr'))
      let filtro = state.filtro

      filtro.data_inicial = dataAtual
      filtro.data_final = data7dias

      if(filtro_contaspr){
        filtro = filtro_contaspr
        setState(prevState => ({...prevState,
          situacoesSelecionadas: filtro_contaspr.situacoes,
          formasSelecionadas: filtro_contaspr.formas_pagto
        }))
      }

      if(filtro_cliente_contaspr){
        setState(prevState => ({...prevState,
          cliente: filtro_cliente_contaspr
        }))
      }

      if(filtro.pessoa_id !== ''){
        try {
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/consultaNome/${filtro.pessoa_id}`, getToken())
        
          filtro.nome = data.tipo === 'FISICA' ? data.fisica.nome : data.juridica.nomefantasia 
        
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }
      }

      setState(prevState => ({...prevState,
        filtro,
        cliente: {
          pessoa_id: filtro.pessoa_id,
          nome: filtro.nome
        }
      }))

      console.log(filtro)

      await filtrarContas(filtro)
    
      setState(prevState => ({...prevState,
        loading: false
      }))

    };
    
    fetchData();
  }, []);

  const { filtro, contas, logado, pessoas, cliente, parcelasBaixaValor, rows, formasPagamento, formasSelecionadas,
    situacoes, situacoesSelecionadas, permissoesContasPR, totais, loadingTable, ocultarFiltros} = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main history={props.history} openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Contas a Pagar/Receber</h1>
                </Grid>
                {permissoesContasPR.inserir &&
                  <Grid item md={3} xs={12}>
                    <Link to="/conta_pagar_receber/cadastro">
                      <Button size='small' className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Conta
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>

              {!ocultarFiltros &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Filtrar data por*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="data_por"
                        value={filtro.data_por}
                        onChangeCapture={(e) => updateFiltro(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value="VENCIMENTO">Data de Vencimento</option>
                        <option value="PAGAMENTO">Data de Pagamento</option>
                        <option value="EMISSAO">Data de Emissão</option>
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField className="input" type="date" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipo"
                        value={filtro.tipo}
                        onChangeCapture={(e) => updateFiltro(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        {logado.contasPR &&
                          <React.Fragment>
                            <option value=""></option>
                            <option value="PAGAR">Despesa</option>
                            <option value="RECEBER">Receita</option>
                          </React.Fragment>
                        }
                        {!logado.contasPR &&
                          <option value="RECEBER">Receita</option>
                        }
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12} sm={6}>
                      <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={4} xs={12} sm={6}>
                      <Autocomplete
                        onChange={(event, value) => updateFieldPessoaFiltro(event, value)}
                        freeSolo
                        options={pessoas.list}
                        value={cliente}
                        size="small"
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => (
                          <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Cliente/Fornecedor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={cliente.nome} onChange={(e) => updateFiltroPessoa(e)}/>
                        )}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sm={6} id='form-contas'>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={formasPagamento.list.filter(forma => {
                          if (formasSelecionadas.findIndex(i => i.id === forma.id) === -1){
                            return forma;
                          }else{
                            return false
                          }
                        })}
                        size="small"
                        value={formasSelecionadas}
                        onChange={(event, value) => updateFieldForma(event, value)}
                        getOptionLabel={(option) => option.descricao}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Formas de Pagto." InputLabelProps={{ shrink: true }}/>
                        )}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sm={6} id='form-contas'>
                      <Autocomplete
                        multiple
                        limitTags={3}
                        id="multiple-limit-tags"
                        options={situacoes.list.filter(value => {
                          if (situacoesSelecionadas.findIndex(i => i.id === value.id) === -1){
                            return value;
                          }else{
                            return false
                          }
                        })}
                        size="small"
                        value={situacoesSelecionadas}
                        onChange={(event, value) => updateFieldSituacao(event, value)}
                        getOptionLabel={(option) => option.descricao}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Situações." InputLabelProps={{ shrink: true }}/>
                        )}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }

              <Grid container direction="row" spacing={1} className="mg_top_10 ocultar-desktop">
                <Grid item md={12} xs={12} sm={12}>
                  <Button 
                    size="small"
                    fullWidth
                    variant="contained" 
                    color={!ocultarFiltros ? "secondary": "primary"} 
                    startIcon={<FilterListIcon />}
                    onClick={() => {setState(prevState => ({...prevState,ocultarFiltros: !ocultarFiltros}))}}
                  >
                    {!ocultarFiltros ? 'Ocutar Filtro' : 'Exibir Filtro'}
                  </Button>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={5} xs={false} sm={6}>
                  {filtro.tipo === '' &&
                    <p style={{fontSize: '20px', marginBottom: '0px'}}>Tipo das Contas: A PAGAR / A RECEBER</p>
                  }
                  {filtro.tipo !== '' &&
                    <p style={{fontSize: '20px', marginBottom: '0px'}}>Tipo das Contas: {filtro.tipo === 'PAGAR' ? 'A PAGAR' : 'A RECEBER'}</p>
                  }
                </Grid>
                {state.loadingImprimir &&
                  <Grid item md={1} xs={12} sm={3}>
                      <CircularProgress />
                  </Grid>
                }
                {!state.loadingImprimir &&
                  <Grid item md={1} xs={12} sm={3}>
                  </Grid>
                }
                {state.parcelasBaixa.length > 0 &&
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='success' size='small' fullWidth variant="contained" onClick={() => baixarContas()} disabled={state.loadingImprimir}>
                      Baixar Contas
                    </Button>
                  </Grid>
                }
                {state.parcelasBaixa.length === 0 &&
                  <Grid item md={2} xs={12} sm={3}>
                  </Grid>
                }
                <Grid item md={2} xs={12} sm={3} className='ocultar-mobile'>
                  <Button size='small' fullWidth variant="contained" color="secondary" onClick={() => imprimir()} disabled={state.loadingImprimir}>
                    Imprimir
                  </Button>
                </Grid>
                {!ocultarFiltros &&
                  <Grid item md={2} xs={12} sm={3}>
                    <Button color='warning' size='small' fullWidth variant="contained" onClick={() => filtrar()} disabled={state.loadingImprimir}>
                      Filtrar
                    </Button>
                  </Grid>
                }
              </Grid>

              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} className='ocultar-mobile'>
                  <Table
                    urlUpdate="/conta_pagar_receber/cadastro/" 
                    headCell={state.cabecalhoTabela} 
                    rows={contas.list} 
                    acoes={state.acoesTabela} 
                    tamanhoFonte={'10px'}
                    imprimirBoleto={e => imprimirBoleto(e)}
                    imprimirNFSE={e => imprimirNFSE(e)}
                    liberaBaixaMultipla={state.liberaBaixaMultipla}
                    selecionarBaixa={e => selecionarBaixa(e)}
                    parcelasBaixa={state.parcelasBaixa}
                    primeiraParcelaSelecionada={state.primeiraParcelaSelecionada}
                    count={rows}
                    handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                    filtro={filtro}
                    loadingTable={loadingTable}
                    copiarVersao={(e) => copiar(e)}
                  />
                </Grid>

                <Grid item md={12} xs={12} className='ocultar-desktop'>
                  {contas.list.map((conta, key) => {
                    return (
                      <Grid key={key} container spacing={1} direction="row" className="mg_top_10">
                        <Grid style={
                        {
                          ...renderClassFinanceiro(conta.situacao || '')
                        }
                      } item md={12} xs={12} className='card-financeiro'>
                          <p><b>Cliente: {conta.pessoa_nome}</b></p>
                          <p>{conta.contpr_descricao}</p>
                          <p><b>Vencimento:</b> {conta.datavencimento}</p>
                          <p><b>Form. Pagto:</b> {conta.formapagto_descricao}</p>
                          <p><b>Valor: <span style={{color: conta.valorparcela > 0 ? '#FF0000' : '#006400', fontSize: '16px'}}>{conta.valorparcela}</span></b> - <b>Multa/Juros:</b> <span style={{fontSize: '16px'}}>{conta.multajuros}</span></p>
                          <p><b>Situação:</b> {conta.situacao}</p>

                          <Grid key={key} container spacing={1} direction="row" className="mg_top_10">
                            <Grid item xs={4} style={{textAlign: 'center'}}>
                              <Link to={`/conta_pagar_receber/cadastro/${conta._id}`}>
                                <IconButton color="error" aria-label="view">
                                  <CreateIcon />
                                </IconButton>
                              </Link>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: 'center'}}>
                              {renderIconeBoleto(conta)}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: 'center'}}>
                              {renderIconeNFSE(conta)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>

              <Grid container spacing={1} direction="row" className="mg_top_10 ocultar-desktop">
                <Grid item md={12} xs={12}>
                  <Pagination 
                    count={parseInt((parseInt(rows)/filtro.limit).toFixed(0))} 
                    color="primary"
                    onChange={(event, newPage) => handleChangePageMobile(event, newPage)}
                    siblingCount={0}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={0} className="mg_top_10">
                <Grid item md={2} xs={12} sm={3}>
                  <p style={{fontSize: '16px', marginBottom: '5px'}}>Valor Total : R$ <span>{real(parseFloat(totais.total_parcelas))}</span></p>
                </Grid>
                <Grid item md={1} xs={false} sm={false}></Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <p style={{fontSize: '16px', marginBottom: '5px'}}>Total Multa/Juros : R$ <span>{real(parseFloat(totais.total_multa_juros))}</span></p>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <p style={{fontSize: '16px', marginBottom: '5px'}}>Total Pago/Recebido : R$ <span>{real(parseFloat(totais.total_pago))}</span></p>
                </Grid>
                {parcelasBaixaValor.length > 0 &&
                  <Grid item md={3} xs={12} sm={3}>
                    <p style={{textAlign: 'right', fontSize: '16px', marginBottom: '5px'}}>Total Selecionado : R$ <span>{real(somarValores(parcelasBaixaValor.map(value => { return value.valor})))}</span></p>
                  </Grid>
                }
              </Grid>
            </div>
          </Main>
          <Nav history={props.history}/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />

          <ModalInformarConta 
            open={state.modalInformarConta}
            handleClose={e => handleCloseModalInformarConta()} 
            updateFieldBaixaConta={e => updateFieldBaixaConta(e)}
            baixaConta={state.baixaConta}
            contas_financeiras={state.contasFinanceira.list}
            confirmar={e => confirmarBaixaContas(e)}
            loadingBaixaConta={state.loadingBaixaConta}
          />

          <ModalExibeBoleto 
            open={state.openModalExibeBoleto}
            dados={state.login}
            handleClose={e => handleModalExibeBoleto()} 
            boletoSelecionado={state.boletoSelecionado}
            copyLink={e => copyLink(e)} 
          />

          <ModalConfirmacao 
            open={state.modalConfirmacaoOpen} 
            handleClose={e => handleClose(e)} 
            dados={state.modal} 
            confirmar={e => confirmarFecharModal(e)}
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Contas A Pagar/Receber"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default ContaPagarReceber