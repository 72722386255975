import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField, FormControl, FormLabel, FormControlLabel, Switch } from '@mui/material'
import Table from '../../components/Table'
import real from './../../services/real'
import moment from 'moment';
import Color from 'color';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function contarValores(arr) {
  // Objeto para armazenar as contagens de cada valor
  const contagem = {};

  // Itera pelo array e conta as ocorrências de cada valor
  arr.forEach(valor => {
      if (contagem[valor]) {
          contagem[valor]++;
      } else {
          contagem[valor] = 1;
      }
  });

  // Transforma o objeto de contagem em um array de objetos
  const resultado = Object.keys(contagem).map(chave => {
      return { valor: chave, quantidade: contagem[chave] };
  });

  return resultado;
}

export default function SimpleModal(props) {
  const theme = useTheme();

  const {contrato, permissoesContrato, cabecalhoTabelaServico, acoesTabelaServico, updateFieldExibirInativos} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Dados do Contrato</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={2} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Código" value={contrato.codigo} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Cadastro" value={contrato.criado_em} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Inicio" value={contrato.data_ini_vig} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Data Fim" value={contrato.data_fim_vig ? moment(contrato.data_fim_vig).format('DD/MM/YYYY') : ''} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
            {/* <Grid item md={2} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Tempo Contrato" value={contrato.tempo_contrato + ' ' + contrato.unidade_tempo} disabled InputLabelProps={{ shrink: true }}/>
            </Grid> */}
            <Grid item md={2} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Situação" value={contrato.estado_contrato} disabled InputLabelProps={{ shrink: true }}/>
            </Grid>
          </Grid>
          <br />
          <hr />
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={10} sm={12} xs={12}>
              <h5 className='titulo'>Serviços Adiquiridos:</h5>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Exibir Inativados?</FormLabel>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="exibir_inativados"
                      color="primary"
                      checked={props.exibir_inativados}
                    />
                  }
                  size="small"
                  label={props.exibir_inativados ? 'Sim' : 'Não'}
                  name="exibir_inativados"
                  onChange={(e) => updateFieldExibirInativos(e)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            {contrato.contrato_produto &&
              <Grid item md={12} sm={12} xs={12}>
                <Table
                  remove={e => props.avisarRemoverServico(e)}
                  headCell={cabecalhoTabelaServico}
                  acoes={acoesTabelaServico} 
                  urlUpdate={e => props.atualizaServico(e)}
                  rows={contrato.contrato_produto.filter(param => {
                    if(!props.exibir_inativados && param.ativo){
                      return true
                    }else if(props.exibir_inativados){
                      return true
                    }
                    return false
                  }).map((value, key) => {
                    let descricao_parcelamento = ''
  
                    if(value.parcelado){
                      let contagem = contarValores(value.parcelas_abertas.map(parcela => {return parcela.valorparcela})).sort((a, b) => (a.quantidade > b.quantidade) ? 1 : ((b.quantidade > a.quantidade) ? -1 : 0))
                    
                      for (let i = 0; i < contagem.length; i++) {
                        const element = contagem[i];
                
                        if((i + 1) === contagem.length){
                          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}`
                        }else{
                          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}, `
                        }
                      }
                    }else{
                      descricao_parcelamento = `${value.parcelas_abertas.length}x de R$ ${real(value.valor)}`
                    }

                    return {
                      _id: value.id,
                      produto_id: value.produto_id,
                      descricao: value.produto.descricao,
                      formapagto_id: value.forma_pagamento.id,
                      forma_pagto: value.forma_pagamento.descricao,
                      valor_parcelas: 'R$ ' + real(value.valor),
                      num_parcelas: value.num_parcelas,
                      contfin_id: value.conta_financeira.id,
                      conta_fin: value.conta_financeira.descricao,
                      venc_prim_parcela: moment(value.venc_prim_parcela).format('DD/MM/YYYY'),
                      venc_prim_parcela_sem_form: value.venc_prim_parcela,
                      ativo: value.ativo,
                      contas_contrato: value.contas_contrato,
                      parcelas_abertas: descricao_parcelamento,
                      valor_contrato: 'R$ ' + real(value.valor_total_parcelas),
                      gerar_nfse: value.gerar_nfse,
                      parcelado: value.parcelado,
                      recorrencia: value.recorrencia,
                      index: key
                    }
                  })}
                />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row">
          {contrato.contrato_produto &&
            <Grid item md={12} sm={12} xs={12}>
              <span style={{fontSize: '18px'}}>Valor total aberto: R$ {real(somarValores(contrato.contrato_produto.map(value => {return value.valor})))}. Total de parcelas abertas: {somarValores(contrato.contrato_produto.map(value => {return value.parcelas_abertas.length}))} Parcelas.</span>
            </Grid>
          }
          </Grid>
          <br />
          <hr />
          <Grid container spacing={1} direction="row" className="mg_top_10">
            {((contrato.estado_contrato === 'Ativo' || contrato.estado_contrato === 'Aguardando Assinatura') && (permissoesContrato.alterar || permissoesContrato.deletar)) &&
              <Grid item md={3} sm={12} xs={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => props.cancelarContrato(contrato, 'CANCELADO')}
                >
                  Cancelar Contrato
                </Button>
              </Grid>
            }
            {((contrato.estado_contrato === 'Encerrado' || contrato.estado_contrato === 'Ativo') && permissoesContrato.alterar) && 
              <Grid item md={3} sm={12} xs={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => props.renovarContrato(contrato)}
                >
                  Renovar Contrato
                </Button>
              </Grid>
            }
            {(permissoesContrato.inserir || permissoesContrato.alterar) &&
              <Grid item md={3} sm={12} xs={4}>
                <Button
                  color='warning'
                  size='small'
                  fullWidth
                  variant="contained"
                  onClick={() => props.adicionarServico(contrato)}
                >
                  Adicionar Serviço
                </Button>
              </Grid>
            }
            <Grid item md={3} sm={12} xs={4}>
              <Button
                color='success'
                size='small'
                fullWidth
                variant="contained"
                onClick={() => props.imprimirContratoMatricula(contrato)}
                disabled={!contrato.midia_id}
              >
                Imprimir Contrato
              </Button>
            </Grid>
          </Grid>
       </StyledPaper>
      </Modal>
    </div>
  );
}
