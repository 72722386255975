import React, { useState, useEffect, useCallback } from 'react';
import './Home.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, Button } from '@mui/material'
import axios from 'axios'
import Chart from "react-google-charts";
import real from '../../services/real'
import moment from 'moment'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RelAno from './../../components/modals/RelAno'
import ListagemCarteira from './../../components/modals/ListagemCarteira'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import TrocarRelatorio from './../../components/modals/TrocarRelatorio'
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  filtro: {
    relatorio: "resumo-financeiro",
    unidade_id: "",
    ano: '',
    mes: ''
  },
  dataChartAReceber: [],
  dataChartAPagar: [],
  dataChartAno: [
    ["x", "Receber Realizado", "Pagar Realizado", "Receber Previsto", "Pagar Previsto"]
  ],
  listAReceberDetalhada: [],
  listAPagarDetalhada: [],
  totalEntrada: 0,
  totalSaida: 0,
  total: 0,
  loading: true,
  meses: [
    {
      num: 1,
      mes: 'JAN'
    },
    {
      num: 2,
      mes: 'FEV'
    },
    {
      num: 3,
      mes: 'MAR'
    },
    {
      num: 4,
      mes: 'ABR'
    },
    {
      num: 5,
      mes: 'MAI'
    },
    {
      num: 6,
      mes: 'JUN'
    },
    {
      num: 7,
      mes: 'JUL'
    },
    {
      num: 8,
      mes: 'AGO'
    },
    {
      num: 9,
      mes: 'SET'
    },
    {
      num: 10,
      mes: 'OUT'
    },
    {
      num: 11,
      mes: 'NOV'
    },
    {
      num: 12,
      mes: 'DEZ'
    },
  ],
  expand_receitas: false,
  expand_receitas_detalhadas: false,
  expand_despesas: false,
  expand_despesas_detalhadas: false,
  expand_atrasados_fora_do_mes: false,
  expand_atrasados_dentro_do_mes: false,
  expand_pagamentos_a_receber: false,
  expand_pagamentos_antecipados: false,
  expand_pagamentos_dentro_mes: false,
  openRelAno: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  mensagemLoading: 'Carregando Relatórios...',
  listDespesasVencidasDentroMes: [],
  listDespesasVencidasForaMes: [],
  listReceitasVencidasDentroMes: [],
  listReceitasVencidasForaMes: [],
  relatorios: [],
  relatorios_usuario: [],
  listAtrasadosForaDoMes: [],
  listAtrasadosDentroDoMes: [],
  listPagamentosAReceber: [],
  listPagamentosAntecipados: [],
  listPagamentosDentroDoMes: [],
  openModalTrocarRelatorio: false,
  loading_receitas: true,
  loading_receitas_detalhadas: true,
  loading_despesas: true,
  loading_despesas_detalhadas: true,
  loading_entradas_x_saidas: true,
  loading_mes_a_mes: true,
  loading_carteira_mensal: true,
  loading_atrasados_fora_do_mes: true,
  loading_atrasados_dentro_do_mes: true,
  loading_pagamentos_a_receber: true,
  loading_pagamentos_antecipados: true,
  loading_pagamentos_dentro_mes: true,
  dataChartCarteira: [],
  larguraReceitas: 6,
  valorLarguraReceitas: 6,
  valorLarguraReceitasDetalhadas: 6,
  larguraReceitasDetalhadas: 6,
  valorLarguraDespesas: 6,
  larguraDespesas: 6,
  valorLarguraDespesasDetalhada: 6,
  larguraDespesasDetalhada: 6,
  valorLarguraCarteiraMensal: 6,
  larguraCarteiraMensal: 6,
  valorLarguraAtrasadosForaDoMes: 6,
  larguraAtrasadosForaDoMes: 6,
  valorLarguraAtrasadosDentroDoMes: 6,
  larguraAtrasadosDentroDoMes: 6,
  valorLarguraPagamentosAReceber: 6,
  larguraPagamentosAReceber: 6,
  valorLarguraPagamentosAntecipados: 6,
  larguraPagamentosAntecipados: 6,
  valorLarguraPagamentosDentroMes: 6,
  larguraPagamentosDentroMes: 6,
  marks: [
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 11,
      label: '11',
    },
    {
      value: 12,
      label: '12',
    },
  ],
  filtro_carteira: '',
  relatoriosCarteira: [
    {
      slug: 'atrasados_fora_do_mes',
      nome: 'Atrasados Fora do Mês',
      pagamento: false
    },
    {
      slug: 'atrasados_dentro_do_mes',
      nome: 'Atrasados Dentro do Mês',
      pagamento: false
    },
    {
      slug: 'pagamentos_antecipados',
      nome: 'Pagamentos Antecipados',
      pagamento: true
    },
    {
      slug: 'pagamentos_dentro_mes',
      nome: 'Pagamentos no Dia',
      pagamento: true
    },
    {
      slug: 'pagamentos_a_receber',
      nome: 'Pagamentos a Receber',
      pagamento: true
    },
    {
      slug: 'pagamentos_com_atraso',
      nome: 'Pagamentos com Atraso',
      pagamento: true
    }
  ],
  subRelCarteira: [],
  tituloRelCarteira: '',
  valorTotalRelCarteira: 0,
  openModalRelCarteira: false,
  cabecalhoTabela: [],
  acoesTabela: [],
  openModalAjuda: false,
  markdown: '',
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const Home = () => {
  const [alerta, setAlerta] = useState({
    open: false,
    severity: 'error',
    message: ''
  });
  const [state, setState] = useState({ ...initialState });

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const buscarRelatorios = useCallback(async () => {
    try {
      const { data: relatoriosUsuarioData } = await axios.get(`${window._env_.REACT_APP_API_URL}/usuarios/relatorios`, getToken());
      const { data: relatoriosData } = await axios.get(`${window._env_.REACT_APP_API_URL}/listRelatorios`, getToken());

      const relatoriosFiltrados = relatoriosData.filter(param => {
        let index = relatoriosUsuarioData.findIndex(value => value.id === param.id);
        return index === -1;
      });

      setState(prevState => ({
        ...prevState,
        relatorios_usuario: relatoriosUsuarioData,
        relatorios: relatoriosFiltrados
      }));
 
    } catch (error) {
      setAlerta({
        open: true,
        severity: 'error',
        message: error.response ? error.response.data.message : 'Erro Interno'
      });
      console.log(error);
    }
  }, []);

  const consultarRelatorios = useCallback(async (filtro) => {
    setState(prevState => ({
      ...prevState,
      loading_receitas: true,
      loading_receitas_detalhadas: true,
      loading_despesas: true,
      loading_despesas_detalhadas: true,
      loading_entradas_x_saidas: true,
      loading_mes_a_mes: true
    }));

    const { data: relatoriosUsuarioData } = await axios.get(`${window._env_.REACT_APP_API_URL}/usuarios/relatorios`, getToken());
    const { data: relatoriosData } = await axios.get(`${window._env_.REACT_APP_API_URL}/listRelatorios`, getToken());

    const relatoriosFiltrados = relatoriosData.filter(param => {
      let index = relatoriosUsuarioData.findIndex(value => value.id === param.id);
      return index === -1;
    });

    let relatorios_usuario = relatoriosUsuarioData
    let relatorios = relatoriosFiltrados

    setState(prevState => ({
      ...prevState,
      relatorios_usuario,
      relatorios
    }));

    let {meses} = state

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo
    const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))
    
    let acesso_atual = []

    if (!localStorage.getItem('acesso_atual')) {
      if (tipo === 'MANTENEDOR') {
        acesso_atual = [
          {
            id: unidades_acesso.franqueador.id,
            descricao: unidades_acesso.franqueador.descricao,
            numero: 0,
            tipo: unidades_acesso.franqueador.tipo
          }
        ]
      } else if (tipo === 'GRUPOECONOMICO') {
        if(unidades_acesso.franquia.unidades.length === 1){
          acesso_atual = [
            {
              id: unidades_acesso.franquia.unidades[0].id,
              descricao: unidades_acesso.franquia.unidades[0].descricao,
              numero: unidades_acesso.franquia.unidades[0].numero,
              tipo: unidades_acesso.franquia.unidades[0].tipo
            }
          ]
        }else{
          acesso_atual = [
            {
              id: unidades_acesso.franquia.id,
              descricao: unidades_acesso.franquia.descricao,
              numero: unidades_acesso.franquia.numero,
              tipo: unidades_acesso.franquia.tipo
            }
          ]
        }
      } else if (tipo === 'UNIDADE') {
        acesso_atual = [
          {
            id: unidades_acesso.unidade.id,
            descricao: unidades_acesso.unidade.descricao,
            numero: unidades_acesso.unidade.numero,
            tipo: unidades_acesso.unidade.tipo
          }
        ]
      }
    } else {
      acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    }

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }
   
    filtro.unidadesnegocio = dados.unidadesnegocio

    try {
      filtro.relatorio = 'receitas'
      const { data: receitas } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken()) 
      
      filtro.relatorio = 'despesas'    
      const { data: despesas } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())     

      let totalEntrada = somarValores(receitas.listAReceber.map(value => { return value.valorparcela}))
      let totalSaida = somarValores(despesas.listAPagar.map(value => { return (value.valorparcela * -1)}))
      let total = totalEntrada + totalSaida

      setState(prevState => ({
        ...prevState,
        totalEntrada: totalEntrada ? totalEntrada : 0,
        totalSaida: totalSaida ? totalSaida : 0,
        total: total ? total : 0
      }))

      if(relatorios_usuario.findIndex(param => param.slug === 'receitas') !== -1){
        setState(prevState => ({
          ...prevState,
          dataChartAReceber: receitas.listAReceber.sort((a, b) => (a.valorparcela > b.valorparcela) ? -1 : ((b.valorparcela > a.valorparcela) ? 1 : 0)),
          loading_receitas: false,
        }))
      }

      if(relatorios_usuario.findIndex(param => param.slug === 'receitas_detalhadas') !== -1){
        filtro.relatorio = 'receitas_detalhadas'
        const { data: receitas_detalhadas } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken()) 

        setState(prevState => ({
          ...prevState,
          listAReceberDetalhada: receitas_detalhadas.listAReceberDetalhada.sort((a, b) => (a.valorparcela < b.valorparcela) ? 1 : (b.valorparcela < a.valorparcela) ? -1 : 0),
          loading_receitas_detalhadas: false
        }))
      }

      if(relatorios_usuario.findIndex(param => param.slug === 'despesas') !== -1){
        setState(prevState => ({
          ...prevState,
          dataChartAPagar: despesas.listAPagar.sort((a, b) => (a.valorparcela > b.valorparcela) ? 1 : (b.valorparcela > a.valorparcela) ? -1 : 0),
          loading_despesas: false
        }))
      }

      if(relatorios_usuario.findIndex(param => param.slug === 'despesas_detalhadas') !== -1){
        filtro.relatorio = 'despesas_detalhadas'
        const { data: despesas_detalhadas } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())     

        setState(prevState => ({
          ...prevState,
          listAPagarDetalhada: despesas_detalhadas.listAPagarDetalhada.sort((a, b) => (a.valorparcela > b.valorparcela) ? 1 : (b.valorparcela > a.valorparcela) ? -1 : 0),
          loading_despesas_detalhadas: false
        }))
      }

      if(relatorios_usuario.findIndex(param => param.slug === 'entrada_x_saida') !== -1 || relatorios_usuario.findIndex(param => param.slug === 'mes_a_mes') !== -1){
        filtro.relatorio = 'entrada_x_saida'

        const { data: entrada_x_saida } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())     

        let dataChartAno = [['x', 'Receber Realizado', 'Pagar Realizado', 'Receber Previsto', 'Pagar Previsto']]

        for (let i = parseInt(filtro.mes); i < (12 + parseInt(filtro.mes)); i++) {
          let rowReceita = {}
          let rowDespesa = {}
          let rowReceitaPrevisto = {}
          let rowDespesaPrevisto = {}

          let mes = ''
          if(i <= 12){
            rowReceita = entrada_x_saida.listReceitasAno.filter(param => param.mes === i)[0]
            rowDespesa = entrada_x_saida.listDespesasAno.filter(param => param.mes === i)[0]
            rowReceitaPrevisto = entrada_x_saida.listReceitasAnoPrevisto.filter(param => param.mes === i)[0]
            rowDespesaPrevisto = entrada_x_saida.listDespesasAnoPrevisto.filter(param => param.mes === i)[0]

            mes = `${meses.filter(param => param.num === i)[0].mes}/${filtro.ano} `
          }else{
            mes = `${meses.filter(param => param.num === (i - 12))[0].mes}/${parseInt(filtro.ano)+1}`
            if(entrada_x_saida.listReceitasAno.filter(param => param.mes === (i - 12))[0]){
              rowReceita = entrada_x_saida.listReceitasAno.filter(param => param.mes === (i - 12))[0]
            }else{
              rowReceita = {
                mes,
                sum: 0
              }
            }
            
            if(entrada_x_saida.listDespesasAno.filter(param => param.mes === (i - 12))[0]){
              rowDespesa = entrada_x_saida.listDespesasAno.filter(param => param.mes === (i - 12))[0]
            }else{
              rowDespesa = {
                mes,
                sum: 0
              }
            }

            if(entrada_x_saida.listReceitasAnoPrevisto.filter(param => param.mes === (i - 12))[0]){
              rowReceitaPrevisto = entrada_x_saida.listReceitasAnoPrevisto.filter(param => param.mes === (i - 12))[0]
            }else{
              rowReceitaPrevisto = {
                mes,
                sum: 0
              }
            }

            if(entrada_x_saida.listDespesasAnoPrevisto.filter(param => param.mes === (i - 12))[0]){
              rowDespesaPrevisto = entrada_x_saida.listDespesasAnoPrevisto.filter(param => param.mes === (i - 12))[0]
            }else{
              rowDespesaPrevisto = {
                mes,
                sum: 0
              }
            }
          }

          dataChartAno.push([
            mes, 
            parseFloat((rowReceita ? rowReceita.sum : 0).toFixed(2)),
            parseFloat((rowDespesa ? rowDespesa.sum * -1 : 0).toFixed(2)),
            parseFloat((rowReceitaPrevisto ? rowReceitaPrevisto.sum : 0).toFixed(2)),
            parseFloat((rowDespesaPrevisto ? rowDespesaPrevisto.sum * -1 : 0).toFixed(2)),
          ])
        }

        setState(prevState => ({
          ...prevState,
          dataChartAno,
          loading_entradas_x_saidas: false,
          loading_mes_a_mes: false
        }))
      }
      
      if(relatorios_usuario.findIndex(param => param.slug === 'carteira_mensal') !== -1){
        filtro.relatorio = 'carteira_mensal'

        const { data: carteira_mensal } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())     

        let dataChartCarteira = [["Situação", "Valor"]]

        let resultado = carteira_mensal.sort((a, b) => (a.situacao > b.situacao) ? 1 : (b.situacao > a.situacao) ? -1 : 0)

        for (const resp of resultado) {
          dataChartCarteira.push([resp.situacao, parseFloat(resp.valor ? resp.valor.toFixed(2) : 0)])
        }

        setState(prevState => ({
          ...prevState,
          dataChartCarteira,
          loading_carteira_mensal: false
        }))
      }
      
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      console.log(error)
    } 
  }, [state]);

  const updateFieldFiltro = async (event) => {
    const {filtro} = state
    
    let name = event.target.name
    let value = event.target.value
    
    filtro[name] = value

    if(name === 'mes'){
      await consultarRelatorios(filtro)
    }

    if(name === 'relatorio'){
      await consultarRelatorios(filtro)
    }

    setState(prevState => ({
      ...prevState,
      filtro
    }));
  }

  const renderTooltipEntradas = () => {
    const rows = []

    rows.push(<p key='1'><b >Entradas:</b></p>)
    rows.push(<p key='2'>Soma total de todas as entradas no período selecionado</p>)
  
    return rows
  }

  const renderTooltipSaidas = () => {
    const rows = []

    rows.push(<p key='1'><b >Saídas:</b></p>)
    rows.push(<p key='2'>Soma total de todas as saídas no período selecionado</p>)
  
    return rows
  }

  const renderTooltipResultado = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Resultado:</b></p>)
    rows.push(<p key='2'>Total de entradas menos o total de saídas no período selecionado</p>)
  
    return rows
  }

  const renderTooltipReceber = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Receitas:</b></p>)
    rows.push(<p key='2'>São consideradas todas as receitas no período selecionado, por plano de contas.</p>)
  
    return rows
  }

  const renderTooltipReceberDetalhado = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Receitas detalhadas:</b></p>)
    rows.push(<p key='2'>São consideradas todas as receitas no período selecionado.</p>)
  
    return rows
  }

  const renderTooltipDespesas = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Despesas:</b></p>)
    rows.push(<p key='2'>São consideradas todas as despesas no período selecionado, por plano de contas</p>)
  
    return rows
  }
  
  const renderTooltipDespesasDetalhada = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Despesas detalhadas:</b></p>)
    rows.push(<p key='2'>São consideradas todas as despesas no período selecionado.</p>)
  
    return rows
  }

  const renderTooltipEntradaXSaida = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Entrada x Saida:</b></p>)
    rows.push(<p key='2'>Porcentagem de saídas sobre o valor de entrada no período selecionado</p>)
  
    return rows
  }

  const renderTooltipResultadoAno = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Mês a mês:</b></p>)
    rows.push(<p key='2'>Mês a mês no período de um ano a partir do mês selecionado</p>)
  
    return rows
  }

  const renderTooltipFiltro = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Filtro:</b></p>)
    rows.push(<p key='2'>Para os meses <b>anteriores</b> e <b>atual</b> a consulta é feita pela data de <b>pagamento</b></p>)
    rows.push(<p key='3'>Para os meses <b>posteriores</b> a consulta é feita pela data de <b>vencimento</b></p>)
  
    return rows
  }

  const renderTooltipCarteira = () => {
    const rows = []
  
    rows.push(<p key='1'><b >Carteira Mensal:</b></p>)
    rows.push(<p key='2'><b>Atrasados Dentro do Mês:</b> Parcelas atrasadas dentro do mês selecionado.</p>)
    rows.push(<p key='3'><b>Atrasados Fora do Mês: </b> Parcelas atrasadas antes do mês selecionado.</p>)
    rows.push(<p key='4'><b>Pagamentos Antecipados: </b> Parcelas com vencimento no mês selecionado pagas antes do mês.</p>)
    rows.push(<p key='5'><b>Pagamentos Dentro do Mês: </b> Parcelas com vencimento no mês selecionado pagas dentro do mês.</p>)
    rows.push(<p key='6'><b>Pagamentos a Receber: </b> Parcelas com vencimento no mês selecionado que estão abertas.</p>)
  
    return rows
  }

  const openRelAno = () => {
    setState(prevState => ({
      ...prevState,
      openRelAno: true
    }));
  }

  const handleCloseRelAno = () => {
    setState(prevState => ({
      ...prevState,
      openRelAno: false
    }));
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: alerta.severity,
        message: ''
      }
    }));
  }

  const openTrocaRelatorio = () => {
    setState(prevState => ({
      ...prevState,
      openModalTrocarRelatorio: true
    }));
  }

  const handleCloseTrocarRelatorio = async () => {

    await buscarRelatorios()

    setState(prevState => ({
      ...prevState,
      openModalTrocarRelatorio: false
    }));
  }

  const selecionarRelatorio = (relatorio) => {
    let {relatorios, relatorios_usuario} = state

    let index = relatorios.findIndex(param => param.id === relatorio.id)

    relatorios.splice(index, 1);

    relatorios_usuario.push({
      ...relatorio
    })

    setState(prevState => ({
      ...prevState,
      relatorios_usuario,
      relatorios
    }));
  }

  const removerRelatorio = (relatorio) => {
    let {relatorios, relatorios_usuario} = state

    let index = relatorios_usuario.findIndex(param => param.id === relatorio.id)

    relatorios_usuario.splice(index, 1);

    relatorios.push({
      ...relatorio
    })

    setState(prevState => ({
      ...prevState,
      relatorios,
      relatorios_usuario
    }));
  }

  const confirmarRelatorios = async (value) => {
    if(value){

      try {
        let {relatorios_usuario, filtro} = state
        
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Salvando relatórios'
          }
        }));

        await axios.post(`${window._env_.REACT_APP_API_URL}/usuarios/trocarRelatorios`, {relatorios_usuario}, getToken())

        setState(prevState => ({
          ...prevState,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          },
          openModalTrocarRelatorio: false
        }));

        await consultarRelatorios(filtro)

      } catch (error) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
        console.log(error)
      }

    }else{
      await handleCloseTrocarRelatorio()
    }
  }

  const openRelCarteira = async (e) => {
    
    try {
      let {relatoriosCarteira, filtro, cabecalhoTabela} = state

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo
      const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))

      let acesso_atual = []

      if (!localStorage.getItem('acesso_atual')) {
        if (tipo === 'MANTENEDOR') {
          acesso_atual = [
            {
              id: unidades_acesso.franqueador.id,
              descricao: unidades_acesso.franqueador.descricao,
              numero: 0,
              tipo: unidades_acesso.franqueador.tipo
            }
          ]
        } else if (tipo === 'GRUPOECONOMICO') {
          if(unidades_acesso.franquia.unidades.length === 1){
            acesso_atual = [
              {
                id: unidades_acesso.franquia.unidades[0].id,
                descricao: unidades_acesso.franquia.unidades[0].descricao,
                numero: unidades_acesso.franquia.unidades[0].numero,
                tipo: unidades_acesso.franquia.unidades[0].tipo
              }
            ]
          }else{
            acesso_atual = [
              {
                id: unidades_acesso.franquia.id,
                descricao: unidades_acesso.franquia.descricao,
                numero: unidades_acesso.franquia.numero,
                tipo: unidades_acesso.franquia.tipo
              }
            ]
          }
        } else if (tipo === 'UNIDADE') {
          acesso_atual = [
            {
              id: unidades_acesso.unidade.id,
              descricao: unidades_acesso.unidade.descricao,
              numero: unidades_acesso.unidade.numero,
              tipo: unidades_acesso.unidade.tipo
            }
          ]
        }
      } else {
        acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      }
  
      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
     
      filtro.unidadesnegocio = dados.unidadesnegocio

      let index = relatoriosCarteira.findIndex(param => param.nome === e)

      filtro.relatorio = relatoriosCarteira[index].slug
      
      if(relatoriosCarteira[index].pagamento){
        cabecalhoTabela = [
          { id: 'responsavel', numeric: false, disablePadding: true, label: 'Responsável' },
          { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Data Vencimento' },
          { id: 'datapagamento', numeric: false, disablePadding: false, label: 'Data Pagamento' },
          { id: 'valor_form', numeric: true, disablePadding: false, label: 'Valor' },
        ]
      }else{
        cabecalhoTabela = [
          { id: 'responsavel', numeric: false, disablePadding: true, label: 'Responsável' },
          { id: 'datavencimento', numeric: false, disablePadding: false, label: 'Data Vencimento' },
          { id: 'valor_form', numeric: true, disablePadding: false, label: 'Valor' },
        ]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken()) 
      
      setState(prevState => ({
        ...prevState,
        subRelCarteira: data,
        tituloRelCarteira: relatoriosCarteira[index].nome,
        valorTotalRelCarteira: somarValores(data.map(value => { return value.valor})),
        openModalRelCarteira: true,
        cabecalhoTabela
      }));
      
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }));
      console.log(error)
    } 
  }

  const handleCloseModalRelCarteira = () => {
    let {openModalRelCarteira} = state
    setState(prevState => ({
      ...prevState,
      openModalRelCarteira: !openModalRelCarteira,
      filtro_carteira: ''
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/home/Home.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState((prevState) => ({...prevState,
            markdown: text
          }))
        })

      const { filtro } = state;

      filtro.ano = moment().format('YYYY');
      filtro.mes = moment().format('M');

      await consultarRelatorios(filtro)

      setState(prevState => ({
        ...prevState,
        filtro,
        loading: false
      }));
    
    
      localStorage.removeItem('ultima_tela_financeiro')
      localStorage.removeItem('data_filtro_agenda');
    };
    

    fetchData();
  }, []);

  const {totalEntrada, totalSaida, listAReceberDetalhada, listAPagarDetalhada, dataChartAno, filtro,
    expand_receitas, expand_receitas_detalhadas, expand_despesas, expand_despesas_detalhadas, relatorios_usuario,
    dataChartCarteira, loading, valorLarguraReceitas, loading_receitas, dataChartAReceber, valorLarguraReceitasDetalhadas,
    loading_receitas_detalhadas, valorLarguraDespesas, loading_despesas, dataChartAPagar, valorLarguraDespesasDetalhada,
    loading_despesas_detalhadas, loading_entradas_x_saidas, loading_mes_a_mes, valorLarguraCarteiraMensal, loading_carteira_mensal,
    filtro_carteira, mensagemLoading, openModalTrocarRelatorio, openModalRelCarteira, subRelCarteira, tituloRelCarteira,
    valorTotalRelCarteira, cabecalhoTabela, acoesTabela, relatorios } = state

    return (
      <React.Fragment>
        {!loading &&
          <div data-cy="tela-home" className="app-menu-closed" id="app">
            <Main tela='tela_home' openModalAjuda={() => {setState((prevState) => ({...prevState, openModalAjuda: true}))}}>
            <Grid container spacing={2} direction="row">
              <Grid item md={2} xs={12} >
                <img className='logo_mais_financeiro' src="./images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={2} direction="row">
                  <Grid item md={6} xs={12}>
                    <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Filtros:</p>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div style={{width: '100%', float: 'left', textAlign: 'right'}}>
                      <Tooltip
                        title={renderTooltipFiltro()}
                      >
                        <InfoIcon className='info'/>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField 
                      className="input" 
                      label="Ano" 
                      variant="outlined" 
                      size="small" 
                      name="ano" 
                      value={filtro.ano} 
                      onChange={(e) => updateFieldFiltro(e)} 
                      onBlur={() => consultarRelatorios(filtro)}
                      InputLabelProps={{ shrink: true }}
                      data-cy="filtro-ano"
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Mês"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="mes"
                      value={filtro.mes}
                      onChangeCapture={(e) => updateFieldFiltro(e)}
                      data-cy="filtro-mes"
                      // onBlur={() => consultarRelatorios(filtro)}
                    >
                      <option value={1}>Janeiro</option>
                      <option value={2}>Fevereiro</option>
                      <option value={3}>Março</option>
                      <option value={4}>Abril</option>
                      <option value={5}>Maio</option>
                      <option value={6}>Junho</option>
                      <option value={7}>Julho</option>
                      <option value={8}>Agosto</option>
                      <option value={9}>Setembro</option>
                      <option value={10}>Outubro</option>
                      <option value={11}>Novembro</option>
                      <option value={12}>Dezembro</option>
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    <Button data-cy="botao-trocar-relatorio" fullWidth onClick={() => openTrocaRelatorio()} size="small" variant="contained" color="primary" style={{marginTop: 2}}>
                      Trocar Relatorios
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12}>
                <Grid container spacing={2} direction="row">
                  <Grid item md={4} xs={6} sm={4} className='card_rel'>
                    <div data-cy="resultado-entradas" className='div_chart div_result'>
                      <div>
                        <div style={{width: '70%', float: 'left'}}>
                          <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Entradas</p>
                        </div>
                        <div style={{width: '30%', float: 'left', textAlign: 'right'}}>
                          <Tooltip
                            title={renderTooltipEntradas()}
                          >
                            <InfoIcon className='info'/>
                          </Tooltip>
                        </div>
                      </div>
                      <div className='valor_totais'><span style={{color: '#063e11', fontSize: 18}}>R$ {real(totalEntrada)}</span></div>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={6} sm={4} className='card_rel'>
                    <div data-cy="resultado-saidas" className='div_chart div_result'>
                      <div>
                        <div style={{width: '70%', float: 'left'}}>
                          <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Saídas</p>
                        </div>
                        <div style={{width: '30%', float: 'left', textAlign: 'right'}}>
                          <Tooltip
                            title={renderTooltipSaidas()}
                          >
                            <InfoIcon className='info'/>
                          </Tooltip>
                        </div>
                      </div>
                      <div className='valor_totais'><span style={{color: '#8B0000', fontSize: 18}}>R$ {real(totalSaida)}</span></div>
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12} sm={4} className='card_rel'>
                    <div data-cy="resultado-total" className='div_chart div_result'>
                      <div>
                        <div style={{width: '70%', float: 'left'}}>
                          <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Resultado</p>
                        </div>
                        <div style={{width: '30%', float: 'left', textAlign: 'right'}}>
                          <Tooltip
                            title={renderTooltipResultado()}
                          >
                            <InfoIcon className='info'/>
                          </Tooltip>
                        </div>
                      </div>
                      <div className='valor_totais'><span style={{color: `${(totalEntrada - totalSaida) > 0 ? '#063e11' : '#8B0000'}`, fontSize: 18}}>R$ {real(totalEntrada - totalSaida)}</span></div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} direction="row">
              {relatorios_usuario.findIndex(param => param.slug === 'receitas') !== -1 &&
                <Grid item md={valorLarguraReceitas} xs={12} className='card_rel'>
                  <div data-cy="rel-receitas" style={{minHeight: 200, height: !expand_receitas ? 200: 600, overflow: !expand_receitas_detalhadas ? 'hidden': 'none'}} className='div_chart'>
                    <div>
                      <div style={{width: '85%', float: 'left'}}>
                        <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Receitas</p>
                      </div>
                      {/* <div style={{width: '30%', float: 'left'}}>
                        <Slider 
                          value={larguraReceitas}
                          onChangeCommitted={(event, newValue) => handleChangeValorLargura(event, newValue, 'receitas')}
                          onChange={(event, newValue) => handleChangeLargura(event, newValue, 'receitas')}
                          min={3}
                          max={12}
                          marks={marks}
                        />                      
                      </div> */}
                      <div style={{width: '10%', float: 'left', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipReceber()}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                      <div style={{width: '5%', float: 'left', textAlign: 'right'}}>
                        {expand_receitas &&
                          <ExpandLessIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_receitas: !expand_receitas}))}}/>
                        }
                        {!expand_receitas &&
                          <ExpandMoreIcon className='info' onClick={() => {setState(prevState => ({...prevState,expand_receitas: !expand_receitas}))}}/>
                        }
                      </div>
                    </div> 
                    {loading_receitas &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_receitas &&
                      <div className='chart' style={{clear:'both', position: 'relative'}}>
                        {dataChartAReceber.map((value, index) => {
                          return (
                            <div key={index} className='row_chart'>
                              <Grid container spacing={2} direction="row">
                                <Grid item md={5} xs={7} sm={5} className='descricao'>
                                  {value.descricao}
                                </Grid>
                                <Grid item md={7} xs={5} sm={7} className='bar'>
                                  <div 
                                    style={{
                                      width: `${(value.valorparcela / dataChartAReceber[0].valorparcela) * 100}%`,
                                      backgroundColor: '#7cb453',
                                      height: '25px',
                                      paddingTop: '4px',
                                      paddingLeft: '10px'
                                    }}
                                  >
                                    {real(value.valorparcela)}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </Grid>
              }
              {relatorios_usuario.findIndex(param => param.slug === 'receitas_detalhadas') !== -1 &&
                <Grid item md={valorLarguraReceitasDetalhadas} xs={12} className='card_rel'>
                  <div data-cy="rel-receitas-detalhadas" style={{minHeight: 200, height: !expand_receitas_detalhadas ? 200: '', overflow: !expand_receitas_detalhadas ? 'hidden': 'none'}} className='div_chart'>
                    <div>
                      <div style={{width: '85%', float: 'left'}}>
                        <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Receitas Detalhadas</p>
                      </div>
                      {/* <div style={{width: '30%', float: 'left'}}>
                        <Slider 
                          value={larguraReceitasDetalhadas}
                          onChangeCommitted={(event, newValue) => handleChangeValorLargura(event, newValue, 'receitas_detalhadas')}
                          onChange={(event, newValue) => handleChangeLargura(event, newValue, 'receitas_detalhadas')}
                          min={3}
                          max={12}
                          marks={marks}
                        />
                      </div> */}
                      <div style={{width: '10%', float: 'left', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipReceberDetalhado()}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                      <div style={{width: '5%', float: 'left', textAlign: 'right'}}>
                        {expand_receitas_detalhadas &&
                          <ExpandLessIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_receitas_detalhadas: !expand_receitas_detalhadas}))}}/>
                        }
                        {!expand_receitas_detalhadas &&
                          <ExpandMoreIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_receitas_detalhadas: !expand_receitas_detalhadas}))}}/>
                        }
                      </div>
                    </div>
                    {loading_receitas_detalhadas &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_receitas_detalhadas &&
                      <div className='chart' style={{clear:'both'}}>
                        {listAReceberDetalhada.map((value, index) => {
                          return (
                            <div key={index} className='row_chart'>
                              <Grid container spacing={2} direction="row">
                                <Grid item md={5} xs={7} sm={5} className='descricao'>
                                  {value.descricao}
                                </Grid>
                                <Grid item md={7} xs={5} sm={7} className='bar'>
                                  <div 
                                    style={{
                                      width: `${(value.valorparcela / listAReceberDetalhada[0].valorparcela) * 100}%`,
                                      backgroundColor: '#7cb453',
                                      height: '25px',
                                      paddingTop: '4px',
                                      paddingLeft: '10px'
                                    }}
                                  >
                                    {real(value.valorparcela)}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </Grid>
              }
              {relatorios_usuario.findIndex(param => param.slug === 'despesas') !== -1 &&
                <Grid item md={valorLarguraDespesas} xs={12} className='card_rel'>
                  <div data-cy="rel-despesas" style={{minHeight: 200, height: !expand_despesas ? 200: '', overflow: !expand_despesas ? 'hidden': 'none'}} className='div_chart'>
                    <div>
                      <div style={{width: '85%', float: 'left'}}>
                        <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Despesas</p>
                      </div>
                      {/* <div style={{width: '30%', float: 'left'}}>
                        <Slider 
                          value={larguraDespesas}
                          onChangeCommitted={(event, newValue) => handleChangeValorLargura(event, newValue, 'despesas')}
                          onChange={(event, newValue) => handleChangeLargura(event, newValue, 'despesas')}
                          min={3}
                          max={12}
                          marks={marks}
                        />
                      </div> */}
                      <div style={{width: '10%', float: 'left', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipDespesas()}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                      <div style={{width: '5%', float: 'left', textAlign: 'right'}}>
                        {expand_despesas &&
                          <ExpandLessIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_despesas: !expand_despesas}))}}/>
                        }
                        {!expand_despesas &&
                          <ExpandMoreIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_despesas: !expand_despesas}))}}/>
                        }
                      </div>
                    </div>
                    {loading_despesas &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_despesas &&
                      <div className='chart' style={{clear:'both'}}>
                        {dataChartAPagar.map((value, index) => {
                          return (
                            <div key={index} className='row_chart'>
                              <Grid container spacing={2} direction="row">
                                <Grid item md={5} xs={7} sm={5} className='descricao'>
                                  {value.descricao}
                                </Grid>
                                <Grid item md={7} xs={5} sm={7} className='bar'>
                                  <div 
                                    style={{
                                      width: `${(value.valorparcela / dataChartAPagar[0].valorparcela) * 100}%`,
                                      backgroundColor: '#FF6347',
                                      color: '#000',
                                      height: '25px',
                                      paddingTop: '4px',
                                      paddingLeft: '10px'
                                    }}
                                  >
                                    {real(value.valorparcela * -1)}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </Grid>
              }
              {relatorios_usuario.findIndex(param => param.slug === 'despesas_detalhadas') !== -1 &&
                <Grid item md={valorLarguraDespesasDetalhada} xs={12} className='card_rel'>
                  <div data-cy="rel-despesas-detalhadas" style={{minHeight: 200, height: !expand_despesas_detalhadas ? 200: '', overflow: !expand_despesas_detalhadas ? 'hidden': 'none'}} className='div_chart'>
                    <div>
                      <div style={{width: '85%', float: 'left'}}>
                        <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Despesas Detalhadas</p>
                      </div>
                      {/* <div style={{width: '30%', float: 'left'}}>
                        <Slider 
                          value={larguraDespesasDetalhada}
                          onChangeCommitted={(event, newValue) => handleChangeValorLargura(event, newValue, 'despesas_detalhada')}
                          onChange={(event, newValue) => handleChangeLargura(event, newValue, 'despesas_detalhada')}
                          min={3}
                          max={12}
                          marks={marks}
                        />
                      </div> */}
                      <div style={{width: '10%', float: 'left', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipDespesasDetalhada()}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                      <div style={{width: '5%', float: 'left', textAlign: 'right'}}>
                        {expand_despesas_detalhadas &&
                          <ExpandLessIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_despesas_detalhadas: !expand_despesas_detalhadas}))}}/>
                        }
                        {!expand_despesas_detalhadas &&
                          <ExpandMoreIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_despesas_detalhadas: !expand_despesas_detalhadas}))}}/>
                        }
                      </div>
                    </div>
                    {loading_despesas_detalhadas &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_despesas_detalhadas &&
                      <div className='chart' style={{clear:'both'}}>
                        {listAPagarDetalhada.map((value, index) => {
                          return (
                            <div key={index} className='row_chart'>
                              <Grid container spacing={2} direction="row">
                                <Grid item md={5} xs={7} sm={5} className='descricao'>
                                  {value.descricao}
                                </Grid>
                                <Grid item md={7} xs={5} sm={7} className='bar'>
                                  <div 
                                    style={{
                                      width: `${(value.valorparcela / listAPagarDetalhada[0].valorparcela) * 100}%`,
                                      backgroundColor: '#FF6347',
                                      color: '#000',
                                      height: '25px',
                                      paddingTop: '4px',
                                      paddingLeft: '10px'
                                    }}
                                  >
                                    {real(value.valorparcela * -1)}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </Grid>
              }
              {relatorios_usuario.findIndex(param => param.slug === 'entrada_x_saida') !== -1 &&
                <Grid item md={3} xs={12} className='card_rel'>
                  <div data-cy="rel-entradas-saidas" style={{height:'250px'}} className='div_chart div_entrada_saida'>
                    <div>
                      <div style={{width: '70%', float: 'left'}}>
                        <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Entrada x Saida</p>
                      </div>
                      <div style={{width: '30%', float: 'left', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipEntradaXSaida()}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                    </div>
                    {loading_entradas_x_saidas &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_entradas_x_saidas &&
                      <div 
                        style={{
                          width: '80%',
                          marginLeft: '10%',
                          marginTop: '25px'
                        }}
                      >
                        <Chart
                          chartType="PieChart"
                          data={[
                            ["Tipo", "Porcentagem"],
                            ["Entrada", (100-((totalSaida/totalEntrada)*100)) < 0 ? 0 : (100-((totalSaida/totalEntrada)*100))],
                            ["Saida", ((totalSaida/totalEntrada)*100) > 100 ? 100 : (totalSaida/totalEntrada)*100],
                          ]}
                          options={{
                            backgroundColor: { fill:'transparent' },
                            is3D: true,
                            legend: 'none',
                            colors: ['green', 'red'],
                            chartArea:{
                              left: 0,
                              top: 0,
                              width: '70%',
                              height: '400',
                          }
                          }}
                          formatters={[
                            {
                              type: 'NumberFormat',
                              column: 1,
                              options: {
                                groupingSymbol: '.',
                                decimalSymbol: ','
                              },
                            },
                          ]}
                          width={"100%"}
                          height={"250px"}
                        />
                        {/* <div
                          className='bar_totais'
                          style={{
                            width: `${100-((totalSaida/totalEntrada)*100)}%`,
                            backgroundColor: '#7cb453',
                            marginTop: '20px'
                          }}
                        >
                          {(100-((totalSaida/totalEntrada)*100) || 0).toFixed(0)}%
                        </div>
                        <div
                          className='bar_totais'
                          style={{
                            width: `${((totalSaida/totalEntrada)*100)}%`,
                            backgroundColor: '#FF6347',
                            color: '#000',
                            marginTop: '20px'
                          }}
                        >
                          {((totalSaida/totalEntrada)*100 || 0).toFixed(0)}%
                        </div> */}
                      </div>
                    }
                  </div>
                </Grid>
              }
              {relatorios_usuario.findIndex(param => param.slug === 'mes_a_mes') !== -1 &&
                <Grid item md={9} xs={12} className='card_rel'>
                  <div data-cy="rel-mes-a-mes" style={{height:'250px'}} className='div_chart'>
                    <div>
                      <div style={{width: '95%', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipResultadoAno()}
                          style={{position: 'absolute', textAlign: '', zIndex: '999'}}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                      <div style={{width: '5%', textAlign: 'right'}}>
                        <Tooltip
                          title={'Ampliar Relatório'}
                          style={{position: 'absolute', right: '35px', zIndex: '999'}}
                        >
                          <VisibilityIcon className='info' color='primary' onClick={() => openRelAno()}/>
                        </Tooltip>
                      </div>
                    </div>
                    {loading_mes_a_mes &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_mes_a_mes &&
                      <Chart
                        chartType="LineChart"
                        width="100%"
                        height='100%'
                        data={dataChartAno}
                        options={{
                          curveType: "function",
                          backgroundColor: { fill:'transparent' },
                          legend: { position: 'top', alignment: 'start' },
                          chartArea: {
                            left: 80,
                            width: '90%'
                          },
                          annotations: {
                            0: {
                              style: 'point'
                            },
                            1: {
                              style: 'point'
                            },
                            2: {
                              style: 'point'
                            },
                            3: {
                              style: 'point'
                            }
                          },
                          series: {
                            2: { lineDashStyle: [4, 4] },
                            3: { lineDashStyle: [4, 4] },
                          },
                          lineWidth: 2,
                          pointSize: 4,
                          hAxis : {
                            gridlines : {
                              count : 0
                            }
                          },
                          vAxis : {
                            gridlines : {
                              count : 0
                            }
                          },
                          colors: ['green', 'red', '#00BFFF', 'orange']
                        }}
                        formatters={[
                          {
                            type: 'NumberFormat',
                            column: 1,
                            options: {
                              groupingSymbol: '.',
                              decimalSymbol: ','
                            }
                          }
                        ]}
                      />
                    }
                  </div>
                </Grid>
              }
              {relatorios_usuario.findIndex(param => param.slug === 'carteira_mensal') !== -1 &&
                <Grid data-cy="rel-carteira-mensal" item md={valorLarguraCarteiraMensal} xs={12} className='card_rel'>
                  <div style={{minHeight: '300px', height: '300px', overflow: 'none'}} className='div_chart'>
                    <div>
                      <div style={{width: '85%', float: 'left'}}>
                        <p style={{margin:'0', marginLeft: '10px'}} className="titulo">Carteira Mensal</p>
                      </div>
                      {/* <div style={{width: '30%', float: 'left'}}>
                        <Slider 
                          value={larguraCarteiraMensal}
                          onChangeCommitted={(event, newValue) => handleChangeValorLargura(event, newValue, 'despesas_detalhada')}
                          onChange={(event, newValue) => handleChangeLargura(event, newValue, 'despesas_detalhada')}
                          min={3}
                          max={12}
                          marks={marks}
                        />
                      </div> */}
                      <div style={{width: '10%', float: 'left', textAlign: 'right'}}>
                        <Tooltip
                          title={renderTooltipCarteira()}
                        >
                          <InfoIcon className='info'/>
                        </Tooltip>
                      </div>
                      <div style={{width: '5%', float: 'left', textAlign: 'right'}}>
                        {expand_despesas_detalhadas &&
                          <ExpandLessIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_despesas_detalhadas: !expand_despesas_detalhadas}))}}/>
                        }
                        {!expand_despesas_detalhadas &&
                          <ExpandMoreIcon className='info' onClick={() => {setState(prevState => ({...prevState, expand_despesas_detalhadas: !expand_despesas_detalhadas}))}}/>
                        }
                      </div>
                    </div>
                    {loading_carteira_mensal &&
                      <React.Fragment>
                        <div className="progressCircular" style={{position: 'relative', top: 35}}>
                          <CircularProgress />
                        </div>
                        <div className="progressText" style={{position: 'relative'}}>
                          <p>Carregando Relatório...</p>
                        </div>
                      </React.Fragment>
                    }
                    {!loading_carteira_mensal &&
                      <div style={{clear:'both'}}>
                        {dataChartCarteira[1] &&
                          <Chart
                            chartType="PieChart"
                            data={dataChartCarteira}
                            options={{
                              backgroundColor: { fill:'transparent' },
                              is3D: true,
                              colors: ['#FFFF00', '#FF0000', '#008000', '#A9A9A9', '#FF4500', '#0000FF'],
                              chartArea:{
                                left: 10,
                                top: 0,
                                width: '90%',
                                height: '380',
                              },
                              slices: {
                                0: {textStyle: {color: '#000'}}
                              }
                            }}
                            formatters={[
                              {
                                type: 'NumberFormat',
                                column: 1,
                                options: {
                                  groupingSymbol: '.',
                                  decimalSymbol: ','
                                },
                              },
                            ]}
                            width={"100%"}
                            height={"280px"}
                            chartEvents={[
                              {
                                eventName: "select",
                                callback: async ({ chartWrapper, google }) => {
                                  const chart = chartWrapper.getChart();
                                  google.visualization.events.addListener(chart, "click",async e => {
                                    let id = parseInt(e.targetID.replace('slice#',''))
                                    
                                    
                                    if(dataChartCarteira[id+1]){
                                      if(filtro_carteira !== dataChartCarteira[id+1][0]){
                                        setState((prevState) => ({
                                          ...prevState,
                                          filtro_carteira: dataChartCarteira[id+1][0]
                                        }))
                                        await openRelCarteira(dataChartCarteira[id+1][0])
                                      }
                                    }
                                  });
                                }
                              }
                            ]}
                          />
                        }
                      </div>
                    }
                  </div>
                </Grid>
              }
            </Grid>
            </Main> 
            <Nav/>
            <RelAno 
              open={state.openRelAno}
              dataChartAno={dataChartAno}
              handleClose={e => handleCloseRelAno()}
            />
            <Alert 
              open={alerta.open}
              handleClose={e => handleCloseAlerta()} 
              severity={alerta.severity}
              message={alerta.message} 
            />
          </div>
        }
        <Loading 
          open={loading}
          message={mensagemLoading} 
        />
        <TrocarRelatorio 
          open={openModalTrocarRelatorio}
          handleClose={() => handleCloseTrocarRelatorio()}
          relatorios_usuario={relatorios_usuario}
          relatorios={relatorios}
          selecionarRelatorio={(e) => selecionarRelatorio(e)}
          removerRelatorio={(e) => removerRelatorio(e)}
          confirmar={(e) => confirmarRelatorios(e)}
        />
        <ListagemCarteira 
          open={openModalRelCarteira}
          handleClose={() => handleCloseModalRelCarteira()}
          list={subRelCarteira}
          tituloRelCarteira={tituloRelCarteira}
          valorTotalRelCarteira={valorTotalRelCarteira}
          cabecalhoTabela={cabecalhoTabela}
          acoesTabela={acoesTabela}
        />

        <ModalAjuda 
          open={state.openModalAjuda}
          tela={"Home"}
          handleClose={() => {setState((prevState) => ({...prevState, openModalAjuda: false}))}}
          markdown={state.markdown}
        />
      </React.Fragment>
    )
}

export default Home;