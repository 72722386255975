import React, { useState, useEffect } from 'react';
import './Perfil.scss'
import './../../main/ultil.scss'
import axios from 'axios'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import FormPerfil from '../../components/forms/FormPerfil'
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  perfilOriginal: {},
  perfil: {
    id: '',
    descricao: '',
    visibilidade: 'UNIDADE',
    status: true,
    permissoes: []
  },
  telas: [],
  grupos: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  update: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  tipoUnidade: 'UNIDADE',
  permissoesAtual: [],
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  openModalAjuda: false,
  markdown: ''
}

const CadastroPerfil = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { perfilId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const buscaTelas = async (visibilidade) => {
    const res = await axios.get(`${window._env_.REACT_APP_API_URL}/telas/visibilidade/${visibilidade}`, getToken())

    let grupos = []

    for (const value of res.data) {
      let index = await grupos.findIndex(param => param.id === value.modulo.grupo.id)

      if(index === -1){
        grupos.push({
          id: value.modulo.grupo.id,
          descricao: value.modulo.grupo.descricao,
          ordem: value.modulo.grupo.ordem,
          min: true
        })
      }
    }

    setState(prevState => ({...prevState,
      grupos
    }))

    if (res.data) return res.data
    return []
  }

  const updateField = async (event) => {
    const perfil = { ...state.perfil }
    perfil[event.target.name] = event.target.value
    setState(prevState => ({...prevState, perfil }))

    if (event.target.name === 'visibilidade') {
      let busca = await buscaTelas(event.target.value.toLowerCase())
      setState(prevState => ({...prevState,
        telas: busca
      }))
    }
  }

  const updateFieldAtivo = (event) => {
    const perfil = { ...state.perfil }
    perfil.status = !perfil.status
    setState(prevState => ({...prevState, perfil }))
  }

  const updateFieldSwitch = (tela, event) => {
    updateOrNewPermissoes(
      tela,
      {
        name: event.target.name,
        value: event.target.value
      }
    )
  }

  const updateOrNewPermissoes = (tela, field) => {
    const { perfil } = state
    const { permissoes } = perfil

    if (permissoes.length === 0) {
      perfil.permissoes.push(newPermissao(tela, field))
      setState(prevState => ({...prevState, perfil }))
      return true
    }

    let edit = null

    for (const i in permissoes) {
      const perm = permissoes[i]
      
      if (perm.tela_id === tela.id) {
        edit = { tela_id: tela.id }

        edit[field.name] = !perm[field.name]

        perfil.permissoes[i] = Object.assign(perm, edit);

        setState(prevState => ({...prevState, perfil }))

        return true
      }
    }

    const nova = newPermissao(tela, field)
    perfil.permissoes.push(nova)
    setState(prevState => ({...prevState, perfil }))

    return true
  }

  const newPermissao = (tela, field) => {
    const data = {
      tela: tela,
      tela_id: tela.id,
      visualizar: false,
      inserir: false,
      alterar: false,
      deletar: false
    }

    data[`${field.name}`] = !field.value
    return data
  }

  const validateForm = () => {
    const { perfil } = state

    if (!perfil.descricao) return false
    if (!perfil.visibilidade) return false

    return true
  }

  const salvar = async () => {
    setState(prevState => ({...prevState, loadingSalvar: true }))

    if (!validateForm()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      }))
      return
    }

    const { perfil } = state
    const dados = preparaJson(perfil)

    try {
      const method = perfil.id !== "" ? 'put' : 'post'
      const url = perfil.id !== "" ? `/perfis/${perfil.id}` : `/perfis`

      setState(prevState => ({...prevState,
        perfilOriginal: {},
        perfil: {
          id: '',
          descricao: '',
          visibilidade: 'UNIDADE',
          status: true,
          permissoes: []
        },
        telas: [],
      }))

      await axios[method](`${window._env_.REACT_APP_API_URL}${url}`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      }))

      backPage()
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  const backPage = (timeout = 2000) => {
    setTimeout(() => {
      navigate("/perfil");
    }, timeout)
  }

  const preparaJson = (perfil) => {
    const retorno = []

    let permissao = {}
    for (const i in perfil.permissoes) {
      permissao = perfil.permissoes[i]

      if (perfil.visibilidade === 'REPRESENTANTE' && permissao.tela && !['REPRESENTANTE', 'GRUPOECONOMICO', 'UNIDADE'].includes(permissao.tela.modulo.visibilidade)) {
        continue
      }

      if (perfil.visibilidade === 'GRUPOECONOMICO' && permissao.tela && !['GRUPOECONOMICO', 'UNIDADE'].includes(permissao.tela.modulo.visibilidade)) {
        continue
      }

      if (perfil.visibilidade === 'UNIDADE' && permissao.tela && permissao.tela.modulo.visibilidade !== 'UNIDADE') {
        continue
      }

      delete perfil.permissoes[i].tela

      if (
        !permissao.alterar &&
        !permissao.deletar &&
        !permissao.inserir &&
        !permissao.visualizar
      ) {
        if (permissao.id) {
          perfil.permissoes[i].delete = true
          retorno.push(perfil.permissoes[i])
        }
        continue
      } else {
        if (permissao.id) {
          delete perfil.permissoes[i].delete
          retorno.push(perfil.permissoes[i])
          continue
        }
      }

      retorno.push(perfil.permissoes[i])
    }

    perfil.permissoes = retorno
    return perfil
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const maximizar = (grupo) => {
    let {grupos} = state

    let index = grupos.findIndex(param => param.id === grupo.id)

    grupos[index].min = false
    
    for (let i = 0; i < grupos.length; i++) {
      const grupo = grupos[i];
      
      if(grupos[index].id !== grupo.id){
        grupo.min = true
      }

      grupos[i] = grupo
    }

    setState(prevState => ({...prevState,
      grupos
    }))
  }

  const minimizar = (grupo) => {
    let {grupos} = state

    let index = grupos.findIndex(param => param.id === grupo.id)

    grupos[index].min = true
    
    setState(prevState => ({...prevState,
      grupos
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/perfil/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipoUnidade = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

      setState(prevState => ({...prevState,
        tipoUnidade
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoesAtual = perfil.permissoes

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'perfil')[0]

      setState(prevState => ({...prevState,
        permissoes,
        permissoesAtual
      }))

      if (perfilId) {
        if (!permissoes.alterar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      } else {
        if (!permissoes.inserir) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Você não tem permissão para acessa essa tela!'
            }
          }))

          backPage()
        }
      }

      if (!perfilId) {
        const perfilOriginal = { ...state.perfil }
        perfilOriginal.permissoes = []

        let busca = await buscaTelas('unidade')

        setState(prevState => ({...prevState,
          telas: busca,
          loading: false,
          perfil: perfilOriginal,
          update: false
        }))

      }

      if (perfilId) {
        try {
          const { data: perfil } = await axios.get(`${window._env_.REACT_APP_API_URL}/perfis/${perfilId}`, getToken())

          if (!perfil.unidade_id) {
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Esse perfil não pode ser editado!'
              }
            }))

            backPage()

            return false
          }

          setState(prevState => ({...prevState,loading: false}))

          let busca = await buscaTelas(perfil.visibilidade.toLowerCase())

          return setState(prevState => ({...prevState,
            perfil,
            telas: busca,
            update: true
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))

          backPage()
        }
      }
    };
    
    fetchData();
  }, []);

  const { perfil, telas, tipoUnidade, loading, grupos } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
      {loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Perfil...</p>
          </div>
        </React.Fragment>
      }
      {!loading && 
        <div id="perfil" className="cadastro">
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={10}>
              <h1 className="titulo">{perfil.id ? 'Editar Perfil' : 'Cadastro de Perfil'}</h1>
            </Grid>
            <Grid item md={2}>
              {state.loadingSalvar}
              {state.loadingSalvar &&
                <div>
                  <CircularProgress />
                </div>
              }
            </Grid>
          </Grid>

          <FormPerfil
            updateField={e => updateField(e)}
            updateFieldAtivo={e => updateFieldAtivo(e)}
            updateFieldSwitch={(key, e) => updateFieldSwitch(key, e)}
            dados={perfil}
            telas={telas}
            update={state.update}
            tipoUnidade={tipoUnidade}
            permissoesAtual={state.permissoesAtual}
            grupos={grupos}
            maximizar={(e) => maximizar(e)}
            minimizar={(e) => minimizar(e)}
          />

          <Grid container direction="row" alignItems="flex-end">
            <Grid item md={9}></Grid>
            <Grid item md={3}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className="btn_salvar"
                size="small"
                startIcon={<SaveIcon />}
                onClick={e => salvar(e)}
                disabled={state.loadingSalvar}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Perfil de Usuário"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default CadastroPerfil