import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

const CNPJInput = ({ value, onChange, ...otherProps }) => {
  return (
    <InputMask
      mask={'99.999.999/9999-99'}
      maskChar={''}
      value={value}
      onChange={onChange}
      alwaysShowMask={false}
    >
      {(inputProps) => <TextField 
        {...inputProps}
        {...otherProps}
        variant="outlined"
        fullWidth
        className="input"
        InputLabelProps={{ shrink: true }}
      />}
    </InputMask>
  );
};

export default CNPJInput;
