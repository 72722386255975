import React from 'react'
import { Grid, Button} from '@mui/material'
import FormPessoaJuridica from './FormPessoaJuridica'
import FormPessoaFisica from './FormPessoaFisica'
import FormEndereco from './FormEndereco'
import FormEmail from './FormEmail'
import FormContato from './FormContato'
import AddIcon from '@mui/icons-material/Add';
import Tabela from '../Tabela'
import { styled } from '@mui/system';
import { yellow } from '@mui/material/colors';
import formatarTelefone from './../../services/formatarTelefone'

const FormPessoa = ({ pessoa, informarEndereco, endereco, informarContato, contato, informarEmail, email, updateFieldPessoaFisica,
  validarCpfCnpj, updateFieldPessoaJuridica, dadosComplementaresPF, metodos_divugacoes, informarNovoEndereco, pegaEndereco,
  updateFieldEndereco, updateFieldEnderecoPrincipal, addNovoEndereco, cabecalhoTabelaEndereco, editarEndereco, removeEndereco,
  informarNovoContato, updateFieldContato, updateFieldContatoPrincipal, addNovoContato, editarContato, removeContato,
  informarNovoEmail, removeEmail, updateFieldEmail, updateFieldEmailPrincipal, addNovoEmail, editarEmail, acoesTabelaEndereco,
  cabecalhoTabelaTelefone, cabecalhoTabelaEmail, updateFieldDadosComplementar, validarCpfCnpjConjugue
}) => (
  <React.Fragment>
    {pessoa.tipoPessoa === 'FISICA' &&
      <FormPessoaFisica 
        form='cliente' 
        validarCpfCnpj={e => validarCpfCnpj(e)}
        validarCpfCnpjConjugue={e => validarCpfCnpjConjugue(e)}
        updateField={e => updateFieldPessoaFisica(e)} 
        dados={pessoa.pessoaFisica} 
        dadosComplementaresPF={dadosComplementaresPF} 
        updateFieldDadosComplementar={e => updateFieldDadosComplementar(e)} 
        metodos_divugacoes={metodos_divugacoes}
      />
    }

    {pessoa.tipoPessoa === 'JURIDICA' &&
      <FormPessoaJuridica 
        form='cliente' 
        validarCpfCnpj={e => validarCpfCnpj(e)} 
        updateField={e => updateFieldPessoaJuridica(e)} 
        dados={pessoa.pessoaJuridica} 
      />
    }

    <Grid container direction="row">
      <Grid item md={9} xs={12} sm={9}>
        <h4 className="mg_top_20">Endereços: <span style={{fontSize: 14}}><b>*Não obrigatorio*</b></span></h4> 
      </Grid>
      {!informarEndereco &&
        <Grid item md={3} xs={12} sm={3} style={{marginTop: 25}}>
          <Button color='warning' fullWidth onClick={() => informarNovoEndereco()} variant="contained" size="small" startIcon={<AddIcon />}>Informar Novo Endereço </Button>
        </Grid>
      }
    </Grid>
    
    {informarEndereco &&
      <FormEndereco 
        pegaEndereco={e => pegaEndereco(e)} 
        updateField={e => updateFieldEndereco(e)} 
        updateFieldPrincipal={e => updateFieldEnderecoPrincipal(e)} 
        addNovoEndereco={(e) => addNovoEndereco(e)}
        dados={endereco}
      />
    }

    {pessoa.enderecos.length > 0 &&
      <React.Fragment>
        <Grid container direction="row" className="mg_top_10">
          <Grid item md={12}>
            <Tabela 
              headCell={cabecalhoTabelaEndereco} 
              rows={pessoa.enderecos.map(endereco => {
                return {
                  cep: endereco.cep,
                  endereco: `${endereco.rua}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                  cidade: endereco.cidade,
                  estado: endereco.estado,
                  principal: endereco.principal,
                  tipoEndereco: endereco.tipoEndereco,
                  index: endereco.index
                }
              })} 
              acoes={acoesTabelaEndereco}
              editar={(e) => editarEndereco(e)}
              remover={(index) => removeEndereco(index)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    }

    <br />
    <hr />

    <Grid container direction="row">
      <Grid item md={9} xs={12} sm={9}>
        <h4 className="mg_top_20">Contatos: <span style={{fontSize: 14}}><b>*Não obrigatorio*</b></span></h4>
      </Grid>
      {!informarContato &&
        <Grid item md={3} xs={12} sm={3}>
          <Button color='warning' style={{marginTop: 25}} fullWidth onClick={() => informarNovoContato()} variant="contained" size="small" startIcon={<AddIcon />}>Informar Novo Contato </Button>
        </Grid>
      }
    </Grid>

    {informarContato &&
      <FormContato  
        updateField={e => updateFieldContato(e)} 
        updateFieldPrincipal={e => updateFieldContatoPrincipal(e)} 
        addNovoContato={() => addNovoContato()}
        dados={contato}
      />
    }

    {pessoa.contatos.length > 0 &&
      <React.Fragment>
        <Grid container direction="row" className="mg_top_10">
          <Grid item md={12}>
            <Tabela 
              headCell={cabecalhoTabelaTelefone} 
              rows={pessoa.contatos.map(contato => {
                return {
                  numero: formatarTelefone(contato.numero),
                  tipoContato: contato.tipoContato,
                  principal: contato.principal,
                  index: contato.index
                }
              })} 
              acoes={acoesTabelaEndereco}
              editar={(e) => editarContato(e)}
              remover={(index) => removeContato(index)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    }

    <br />
    <hr />

    <Grid container direction="row">
      <Grid item md={9} xs={12} sm={9}>
        <h4 className="mg_top_20">Emails: <span style={{fontSize: 14}}><b>*Não obrigatorio*</b></span></h4>
      </Grid>
      {!informarEmail &&
        <Grid item md={3} xs={12} sm={3}>
          <Button color='warning' style={{marginTop: 25}} fullWidth onClick={() => informarNovoEmail()} variant="contained" size="small" startIcon={<AddIcon />}>Informar Novo Email </Button>
        </Grid>
      }
    </Grid>

    {informarEmail &&
      <FormEmail 
        updateField={e => updateFieldEmail(e)} 
        updateFieldEmailPrincipal={e => updateFieldEmailPrincipal(e)}
        addNovoEmail={() => addNovoEmail()}
        dados={email} 
      />
    }

    {pessoa.emails.length > 0 &&
      <React.Fragment>
        <Grid container direction="row" className="mg_top_10">
          <Grid item md={12}>
            <Tabela 
              headCell={cabecalhoTabelaEmail} 
              rows={pessoa.emails.map(email => {
                return {
                  email: email.email,
                  principal: email.principal,
                  index: email.index
                }
              })} 
              acoes={acoesTabelaEndereco}
              editar={(e) => editarEmail(e)}
              remover={(index) => removeEmail(index)}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormPessoa