import React, { useState, useEffect } from 'react';
import './PreContasPR.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import SaveIcon from '@mui/icons-material/Save';
import Alert from './../../components/templates/Alert'
import CurrencyInput from './../../components/mask/CurrencyInput'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  conta:{
    descricao: '',
    data_vencimento: '',
    habilita: '',
    contfin_id: '',
    formapagto_id: '',
    item_id: '',
    valor: ''
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  itemSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  itens_receita_despesa: {
    list: []
  },
  token: '',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

const PreContasPR = () => {
  const [state, setState] = useState({ ...initialState });
  const { unidadeId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }
  
  const updateField = async (event) => {
    let {conta, formasPagamento} = state
    
    let name = event.target.name
    let value = event.target.value

    if(name === 'formapagto_id'){
      let index = formasPagamento.list.findIndex(param => param.id === parseInt(value))
      
      if(index !== -1){
        conta.habilita = formasPagamento.list[index].habilita
      }
    }

    conta[name] = value

    setState(prevState => ({...prevState,
      conta
    }))
  }

  const updateFieldItem = (e, value) => {
    const {conta} = state

    if(!value){
      conta.item_id = ''

      setState(prevState => ({...prevState,
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        },
        conta
      }))

      return false
    }   

    conta.item_id = value.id
      
    setState(prevState => ({...prevState,
      itemSelecionado: value,
      conta
    }))
  }

  const salvar = async () => {
    let {conta} = state

    try {
      if(conta.descricao === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a descrição da conta'
          }
        }))
        return
      }

      if(conta.data_vencimento === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data do lançamento'
          }
        }))
        return
      }

      if(conta.valor === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o valor do lançamento'
          }
        }))
        return
      }

      if(conta.formapagto_id === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a forma do pagamento.'
          }
        }))
        return
      }

      if(conta.contfin_id === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a conta financeira.'
          }
        }))
        return
      }

      if(conta.item_id === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o item de receita e despesa.'
          }
        }))
        return
      }

      let dados = {
        descricao: conta.descricao,
        data_vencimento: conta.data_vencimento,
        contfin_id: conta.contfin_id,
        formapagto_id: conta.formapagto_id,
        item_id: conta.item_id,
        valor: parseFloat(conta.valor.replaceAll(".", "").replaceAll(",", ".")),
        unidade_id: unidadeId
      }

      await axios.post(`${window._env_.REACT_APP_API_URL}/preContasPR`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso'
        },
        conta:{
          descricao: '',
          data_vencimento: '',
          habilita: '',
          contfin_id: '',
          formapagto_id: '',
          item_id: '',
          valor: ''
        },
        itemSelecionado:{
          id: '',
          codigo: '',
          descricao: '',
          ativo: '',
          slug: '',
          centro_custos: '',
          totalizador: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`)

        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true)
          }
        }))

        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${unidadeId}`, getToken())
    
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira
          }
        }))

        const dados = {
          unidadesnegocio: [parseInt(unidadeId)],
        }

        const { data: itens_receita_despesa } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados, getToken())
              
        setState(prevState => ({...prevState,
          itens_receita_despesa: {
            list: itens_receita_despesa.sort((a, b) => (a.descricao > b.descricao) ? 1 : (b.descricao > a.descricao) ? -1 : 0).filter(param => param.ativo).map(item => {
              return {
                id: item.id,
                descricao: item.descricao,
                planocontas_id: item.planocontas_id,
                plano_contas: item.plano_contas
              }
            })
          }
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
      
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, [unidadeId]);
  
  const { conta, formasPagamento, contasFinanceira, itens_receita_despesa, itemSelecionado } = state

  return (
    <div className="container-boletos" id="app">
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <React.Fragment>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h4 className='titulo'>Pré Cadastro de Contas:</h4>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} xs={12} sm={6} className="mg_top_10">
              <TextField 
                className="input" 
                label="Descrição*" 
                variant="outlined" 
                name="descricao" 
                size="small"
                value={conta.descricao} 
                onChange={(e) => updateField(e)} 
                InputLabelProps={{ shrink: true }}
                disabled={conta.tipo === '' ? true: false} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6} className="mg_top_10">
              <TextField 
                type='date'
                className="input" 
                label="Data do Lançamento" 
                variant="outlined" 
                name="data_vencimento" 
                size="small"
                value={conta.data_vencimento} 
                onChange={(e) => updateField(e)} 
                InputLabelProps={{ shrink: true }}
                disabled={conta.tipo === '' ? true: false} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6} className="mg_top_10">
              <TextField 
                type='text'
                className="input" 
                label="Valor" 
                variant="outlined" 
                name="valor" 
                size="small"
                value={conta.valor} 
                onChange={(e) => updateField(e)} 
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputComponent: CurrencyInput }}
                disabled={conta.tipo === '' ? true: false} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6} className="mg_top_10">
              <TextField
                id="standard-select-currency"
                select
                label="Forma de Pagamento" 
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="formapagto_id"
                value={conta.formapagto_id}
                onChangeCapture={(e) => updateField(e)}
                InputLabelProps={{ shrink: true }}
                disabled={conta.tipo === '' ? true: false} 
              >
                <option value="">--Escolha--</option>
                {formasPagamento.list.map(forma => {
                  return (
                    <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6} className="mg_top_10">
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                label="Conta Financeira" 
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="contfin_id"
                value={conta.contfin_id}
                onChangeCapture={(e) => updateField(e)}
                InputLabelProps={{ shrink: true }}
                disabled={conta.tipo === '' ? true: false} 
              >
                <option value="">--Escolha--</option>
                {contasFinanceira.list.filter(param => param.tipo_conta === conta.habilita).map(conta => {
                  return (
                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
          
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={3} xs={12} sm={6} className="mg_top_10">
              <Autocomplete
                onChange={(event, value) => updateFieldItem(event, value)}
                freeSolo
                options={itens_receita_despesa.list}
                value={itemSelecionado}
                getOptionLabel={option => `${option.descricao}`}
                disabled={conta.tipo === '' ? true: false} 
                renderInput={(params) => (
                  <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Item Receita/Despesa*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${itemSelecionado.descricao}`} disabled={conta.tipo === '' ? true: false} />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={4} className="mg_top_10">
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" endIcon={<SaveIcon />} onClick={e => salvar()}>Adicionar</Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={2} xs={12} >
              <img style={{width: '70%', marginLeft: '15%'}} src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
            </Grid>
          </Grid>
        </React.Fragment>
      }
      <Alert
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()}
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
    </div>
    
  )
}

export default PreContasPR