import React from 'react'
import { Grid, TextField, FormControlLabel, Checkbox, Button, Switch, FormLabel, FormControl } from '@mui/material'
import moment from 'moment'
import Table from './../Table'
import AddIcon from '@mui/icons-material/Add';
import CurrencyInput from './../mask/CurrencyInput'
import real from '../../services/real';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Serviço/Produto' },
  { id: 'valor_contrato', numeric: true, disablePadding: true, label: 'Valor de Contrato' },
  { id: 'parcelas', numeric: false, disablePadding: true, label: 'Parcelas' },
  { id: 'venc_prim_parcela', numeric: false, disablePadding: true, label: 'Venc. Prim. Parcela'},
  { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
  { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
]

const acoesTabela = ['remove', 'editar']


const FormContratoCliente = ({ dados, updateField, maiorIdade, updateFieldMaiorMenor, produtos, servico, updateServico, addServico, removerServico,
  formasPagamento, contasFinanceira, editarServico, alteraServico, produtoSelecionado, cliente, possui_emissao_nfse, updateFieldGerarNfse, updateParcelado, 
  updateRecorrencia, updateFieldConta, mudarParcelas, recalcularParcela, updateFieldParcela, travarCampos, marcaParcelas, marcaParcelasContaFin, 
  logado, conta, template_documentos, updateFieldAssinar, verificaDataParcela }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Codigo" variant="outlined" size="small" name="codigo" value={dados.codigo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField type="date"
          className="input"
          label="Data Inicio Vigência*"
          variant="outlined"
          name="data_ini_vig"
          value={dados.data_ini_vig}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          size="small"
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade Tempo Duração"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidade_tempo"
          value={dados.unidade_tempo}
          disabled={dados.data_ini_vig === ''}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=''> Selecionar Unidade Tempo </option>
          <option value='DIA'> Dia </option>
          <option value='MÊS'> Mês </option>
          <option value='ANO'> Ano </option>
          
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Tempo Contrato" 
          type="number"
          variant="outlined" 
          size="small"
          name="tempo_contrato" 
          value={dados.tempo_contrato} 
          disabled={dados.unidade_tempo === ''}
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField type="date"
          className="input"
          label="Data Fim Vigência"
          variant="outlined"
          name="data_fim_vig"
          value={dados.data_fim_vig}
          disabled
          InputLabelProps={{ shrink: true }}
          size="small"
        />
      </Grid>
    </Grid>
    {template_documentos.length > 0 &&
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={3} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Selecionar Documento"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{ native: true }}
            name="template_versao_id"
            value={dados.template_versao_id}
            onChangeCapture={(e) => updateField(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value=""> Não Emitir Documento</option>
            {template_documentos.map(documento => {
              return (
                <option key={documento.versao_id} value={documento.versao_id}> {documento.titulo} - Versão: {documento.versao} </option>
              )
            })}
          </TextField>
        </Grid>
        {dados.template_versao_id !== "" &&
          <Grid item md={2} xs={12} sm={1}>
            <FormControl component="fieldset" style={{marginLeft: 20}}>
              <FormLabel component="legend">Assinar?</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    name="Ativo"
                    color="primary"
                    checked={dados.assinar_contrato}
                  />
                }
                size="small"
                label={dados.assinar_contrato ? 'Sim' : 'Não'}
                name="assinar_contrato"
                onChange={(e) => updateFieldAssinar(e)}
              />
            </FormControl>
          </Grid>
        }
      </Grid>
    }
    <br />
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={2} sm={2}>
        <Button
          fullWidth 
          color={servico.parcelado ? 'primary' : 'inherit'} 
          variant="contained" 
          size="small" 
          endIcon={servico.parcelado ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
          onClick={e => updateParcelado()}
        >
          Parcelamento
        </Button>
      </Grid>
      <Grid item md={2} xs={2} sm={2}>
        <Button 
          fullWidth 
          color={servico.recorrencia ? 'secondary' : 'inherit'} 
          variant="contained" 
          size="small" 
          endIcon={servico.recorrencia ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
          onClick={e => updateRecorrencia()}
        >
          Recorrência
        </Button>
      </Grid>
      <Grid item md={1} xs={1} sm={1}></Grid>
    </Grid>
    {servico.recorrencia && 
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={4} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Serviço"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{ native: true }}
            name="produto_id"
            value={servico.produto_id}
            onChangeCapture={(e) => updateServico(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value=""> Selecionar Produto</option>
            {produtos.map(produto => {
              return (
                <option key={produto.id} value={produto.id}> {produto.descricao} </option>
              )
            })}
          </TextField>
        </Grid>
        <Grid item md={1} xs={12} sm={6}>
          <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={servico.valor} onChange={(e) => updateServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField 
            type='number'
            className="input" 
            label="Repetir a cada*" 
            variant="outlined" 
            name="recorrenciaACada" 
            size="small"
            value={servico.recorrenciaACada} 
            onChange={(e) => updateServico(e)} 
            InputLabelProps={{ shrink: true }}/>
        </Grid>
        <Grid item md={1} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label=""
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="tipoRecorrencia"
            value={servico.tipoRecorrencia}
            onChangeCapture={(e) => updateServico(e)}
          >
            <option value='d'> Dia </option>
            <option value='w'> Semana </option>
            <option value='M'> Mês </option>
            <option value='y'> Ano </option>
          </TextField>
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField 
            type='number'
            className="input" 
            label="Num. de Ocorrências*" 
            variant="outlined" 
            name="qtdRecorrencia" 
            size="small"
            value={servico.qtdRecorrencia} 
            onChange={(e) => updateServico(e)} 
            InputLabelProps={{ shrink: true }}/>
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField className="input" type='date' label="Venc. Prim. Recor.*" variant="outlined" size="small" name="venc_prim_parcela" value={servico.venc_prim_parcela} onChange={(e) => updateServico(e)} InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{ native: true }}
            name="formapagto_id"
            value={servico.formapagto_id}
            label="Forma de Pgto.*"
            onChangeCapture={(e) => updateServico(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value="">Selecionar Forma de Pgto.</option>
            {formasPagamento.map(forma => {
              return (
                <option key={forma.id} value={forma.id}>{forma.descricao}</option>
              )
            })}
          </TextField>
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="contfin_id"
            value={servico.contfin_id}
            label="Contas Bancárias.*"
            onChangeCapture={(e) => updateServico(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value="">--Escolha--</option>
            {contasFinanceira.map(conta => {
              return (
                <option key={conta.id} value={conta.id}>{conta.descricao}</option>
              )
            })}
          </TextField>
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField 
            className="input" 
            label="Totalizando*" 
            variant="outlined" 
            name="qtdRecorrencia" 
            size="small"
            value={servico.valor ? real(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) * servico.qtdRecorrencia) : real(0)} 
            InputLabelProps={{ shrink: true }}
            disabled/>
        </Grid>
      </Grid>
    }
    {servico.parcelado &&
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={4} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Serviço"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{ native: true }}
            name="produto_id"
            value={servico.produto_id}
            onChangeCapture={(e) => updateServico(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value=""> Selecionar Produto</option>
            {produtos.map(produto => {
              return (
                <option key={produto.id} value={produto.id}> {produto.descricao} </option>
              )
            })}
          </TextField>
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField 
            className="input" 
            label="Valor Total*" 
            variant="outlined" 
            size="small" 
            name="valor" 
            value={servico.valor} 
            onChange={(e) => updateServico(e)} 
            InputLabelProps={{ shrink: true }} 
            InputProps={{ inputComponent: CurrencyInput }}
            disabled={servico.produto_id === ''}
          />
        </Grid>
        <Grid item md={2} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Unidade Tempo Duração"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="intervalo_parcela"
            value={servico.intervalo_parcela}
            onChangeCapture={(e) => updateServico(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value=''> Selecionar Intervalo </option>
            <option value='DIA'> Dia </option>
            <option value='MÊS'> Mês </option>
            <option value='ANO'> Ano </option>
            
          </TextField>
        </Grid>
      </Grid>
    }
    <Grid container direction="row" spacing={1} className="mg_top_10">
      {produtoSelecionado.preco &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={3}>
            <p style={{marginBottom: '0px'}}>Valores Sugeridos da Tabela: </p>
          </Grid>
          <Grid item md={2} xs={12} sm={2}>
            <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="valor_minimo" value={` R$ ${real(produtoSelecionado.preco.valor_minimo)}`} InputLabelProps={{ shrink: true }} disabled/>
          </Grid>
          <Grid item md={2} xs={12} sm={2}>
            <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="valor_maximo" value={` R$ ${real(produtoSelecionado.preco.valor_maximo)}`} InputLabelProps={{ shrink: true }} disabled/>
          </Grid>
        </React.Fragment>
      }
      {!produtoSelecionado.preco &&
        <Grid item md={6} xs={12} sm={7}></Grid>
      }
      <Grid item md={3} xs={12} sm={1}></Grid>
      {possui_emissao_nfse &&
        <Grid item md={1} xs={12} sm={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gerar NFSE?</FormLabel>
            <FormControlLabel className="mg_left_10"
              control={
                <Switch
                  name="Ativo"
                  color="primary"
                  checked={servico.gerar_nfse}
                />
              }
              size="small"
              label={servico.gerar_nfse ? 'Sim' : 'Não'}
              name="gerar_nfse"
              onChange={(e) => updateFieldGerarNfse(e)}
            />
          </FormControl>
        </Grid>
      }
      <Grid item md={2} xs={12} sm={3}>
        <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addServico(e)}>{alteraServico ? 'Alterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    {(servico.parcelado && servico.valor !== '') &&
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12} sm={12}>
          <div className='scroll'>
            <table className="lista sempadding borderTable">
              <thead>
                <tr className="titulos acompanha">
                  <th>Parcelas</th>
                  <th>Valor*</th>
                  <th>Vencimento*</th>
                  <th>Forma de Pagamento*</th>
                  <th>Contas Bancárias</th>
                  <th>Documento</th>
                </tr>
              </thead>
              <tbody>
                {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                  return (
                    <tr key={parcela.index}>
                      <td>
                        <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                        <span className="separacaoParcelas">/</span>
                        <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                      </td>
                      <td>
                        <TextField 
                          className="inputValor" 
                          variant="outlined" 
                          size="small" 
                          name="valorparcela" 
                          value={parcela.valorparcela} 
                          onBlur={(e) => recalcularParcela(e, parcela.index)} 
                          InputLabelProps={{ shrink: true }} 
                          InputProps={{ inputComponent: CurrencyInput }} 
                          disabled={travarCampos}
                        />
                      </td>
                      <td>
                        <TextField 
                          type="date" 
                          className="inputVencimento" 
                          variant="outlined" 
                          size="small" 
                          name="datavencimento" 
                          value={parcela.datavencimento} 
                          onChange={(e) => updateFieldParcela(e, parcela.index)} 
                          onBlur={(e) => verificaDataParcela(e, parcela.index)}
                          InputLabelProps={{ shrink: true }}
                          disabled={travarCampos}
                        />
                      </td>
                      <td>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="inputFormPagamento"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="formapagto_id"
                          value={parcela.formapagto_id}
                          onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                          onBlur={(e) => marcaParcelas(e, parcela.index)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value="">--Escolha--</option>
                          {formasPagamento.map(forma => {
                            return (
                              <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                            )
                          })}
                        </TextField>
                      </td>
                      <td>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="inputFormPagamento"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="contfin_id"
                          value={parcela.contfin_id}
                          onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                          onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                          InputLabelProps={{ shrink: true }}
                          disabled={travarCampos}
                        >
                          <option value="">--Escolha--</option>
                          {contasFinanceira.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                            return (
                              <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                            )
                          })}
                        </TextField>
                      </td>
                      <td>
                        <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    }
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
      <Table
        remove={e => removerServico(e)}
        editar={e => editarServico(e)}
        headCell={cabecalhoTabela}
        rows={dados.produtos.map(value => {
          return {
            produto_id: value.produto_id,
            descricao: value.descricao,
            formapagto_id: value.formapagto_id,
            forma_pagto: value.forma_pagto,
            valor_contrato: 'R$ '  + (value.recorrencia ? real(parseFloat(value.valor.replaceAll(".", "").replace(",", ".")) * parseInt(value.qtdRecorrencia)) : value.valor),
            parcelas: value.descricao_parcelamento,
            venc_prim_parcela: moment(value.venc_prim_parcela).format('DD/MM/YYYY'),
            conta_fin: value.conta_fin,
            contfin_id: value.contfin_id,
            parcelado: value.parcelado,
            recorrencia: value.recorrencia,
            index: value.index
          }
        })}
        acoes={acoesTabela} />
      </Grid>
    </Grid>
    <hr />
    <div className='cardEndereco'>
      <Grid container direction="row" spacing={1}>
        <Grid item md={12} xs={12} sm={12}>
          <h4 className="titulo">Responsável financeiro</h4>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12} sm={12}>
          <FormControlLabel
            value="end"
            control={<Checkbox color="primary" checked={!maiorIdade} disabled={cliente.idade < 18 ? true: false}/>}
            onClick={e => updateFieldMaiorMenor(e)}
            label="Deseja informar o responsável financeiro? "
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    </div>
  </React.Fragment>
)

export default FormContratoCliente