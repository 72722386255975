import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, IconButton,
  FormControlLabel, Switch, FormLabel, FormControl} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import PanToolIcon from '@mui/icons-material/PanTool';
import CurrencyInput from './../../mask/CurrencyInput'
import Table from './../../Table'
import HelpIcon from '@mui/icons-material/Help';
import { withStyles } from "@mui/material/styles";
import Color from 'color';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const ColorDarkOrangeButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: Color('#FF8C00').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#FF8C00',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

let cabecalho = [
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Serviço' },
  { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
]

let acoesTabela = ['editar', 'remove']

let cabecalhoClientes = [
  { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' }
]

let acoesTabelaClientes = ['remove']

export default function SimpleModal(props) {
  const theme = useTheme();

  const {dados, loadingSalvar, unidades, configuracoes, clientes, cliente, buscarPessoa, travarUnidade,verificaCaixaInicial,
    conta, formasPagamento, contasFinanceira, produto, produtos, alterar, cliente_atendimento, buscarPessoaCliente} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Venda de Voucher</h5>
            </Grid>
          </Grid>
          {!verificaCaixaInicial().libera &&
            <Grid container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={12} xs={12} sm={12}>
                <p>{verificaCaixaInicial().mensagem}</p>
              </Grid>
            </Grid>
          }

          {verificaCaixaInicial().libera &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={3} xs={12} sm={4}>
                  <TextField
                    id="unidade_id"
                    select
                    label="Unidade*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="unidade_id"
                    value={dados.unidade_id}
                    onChangeCapture={(e) => props.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={travarUnidade}
                  >
                    <option value=""> Selecionar Unidade</option>
                    {unidades.map(unidade => {
                      return (
                        <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => props.updateFieldCliente(event, value)}
                    freeSolo
                    options={clientes}
                    value={cliente}
                    id='cliente'
                    getOptionLabel={option => `${option.nome}`}
                    renderInput={(params) => (
                      <TextField 
                        InputProps={{ ...params.InputProps, type: 'search' }} 
                        {...params} 
                        size="small" 
                        label="Comprador*" 
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }} 
                        value={`${cliente.nome}`}
                        onChange={(e) => props.updateFiltroPessoa(e)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={1} xs={12} sm={2}>
                  <Tooltip title='Buscar Comprador' aria-label="add">
                    <IconButton color="primary" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => buscarPessoa()}>
                      <PanToolIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button 
                    size="small"
                    fullWidth
                    variant="contained" 
                    color="info" 
                    onClick={e => props.openCadastroCliente()}
                  >
                    Cadastrar Cliente
                  </Button>
                </Grid>
              </Grid>
              <hr className="mg_top_10"/>
              {dados.responsavel_id !== '' &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={2} xs={12} sm={4}>
                      <TextField
                        id="habilita"
                        select
                        label="Tipo*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="habilita"
                        value={dados.habilita}
                        onChangeCapture={(e) => props.updateField(e)}
                        InputLabelProps={{ shrink: true }}
                        disabled={dados.unidade_id === ''}
                      >
                        <option value=""> Selecionar Tipo</option>
                        <option value="PRODUTO">Produto</option>
                        <option value="CREDITO">Crédito</option>
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12} sm={4}>
                      <TextField
                        id="padrao_id"
                        select
                        label="Voucher*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="padrao_id"
                        value={dados.padrao_id}
                        onChangeCapture={(e) => props.updateField(e)}
                        InputLabelProps={{ shrink: true }}
                        disabled={dados.habilita === ''}
                      >
                        <option value=""> Selecionar Voucher</option>
                        {configuracoes.filter(param => param.habilita === dados.habilita).map(configuracao => {
                          return (
                            <option key={configuracao.id} value={configuracao.id}> {configuracao.nome} - {configuracao.habilita} </option>
                          )
                        })}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField 
                        id='valor_credito'
                        className="input" 
                        label="Valor do Crédito*" 
                        variant="outlined" 
                        size="small" 
                        name="valor_credito" 
                        value={dados.valor_credito} 
                        onChange={(e) => props.updateField(e)} 
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: CurrencyInput }}
                        disabled={dados.habilita === 'PRODUTO' ? true : (dados.padrao_id === '')}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <hr />
                </React.Fragment>
              }

              {(dados.habilita === 'PRODUTO' && dados.padrao_id !== '') &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField
                        id="produto_id"
                        select
                        label="Serviço*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="id"
                        value={produto.id}
                        onChangeCapture={(e) => props.updateFieldProduto(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""> Selecionar Serviço</option>
                        {produtos.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(produto => {
                          return (
                            <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                          )
                        })}
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <TextField 
                        id='valor'
                        className="input" 
                        label="Valor do Serviço*" 
                        variant="outlined" 
                        size="small" 
                        name="valor" 
                        value={produto.valor} 
                        onChange={(e) => props.updateFieldProduto(e)} 
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: CurrencyInput }}
                        disabled={produto.id === ''}
                      />
                    </Grid>
                    <Grid item md={3} xs={12} sm={4}>
                      <Button 
                        color='warning'
                        size="small"
                        variant="contained"
                        onClick={e => props.addProduto()}
                      >
                        {alterar ? 'Alterar' : 'Adicionar'} Produto
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_20">
                    <Grid item md={6} xs={12}>
                      <Table 
                        editar={e => props.editarItem(e)}
                        remove={e => props.removerItem(e)}
                        headCell={cabecalho} 
                        rows={dados.servicos} 
                        acoes={acoesTabela} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }

              {dados.valor_credito !== '' &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Presenteados:</h4>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={4} xs={12} sm={6}>
                      <Autocomplete
                        onChange={(event, value) => props.updateFieldClienteAtendido(event, value)}
                        freeSolo
                        options={clientes}
                        value={cliente_atendimento}
                        id='cliente'
                        getOptionLabel={option => `${option.nome}`}
                        renderInput={(params) => (
                          <TextField 
                            InputProps={{ ...params.InputProps, type: 'search' }} 
                            {...params} 
                            size="small" 
                            label="Cliente*" 
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }} 
                            value={`${cliente_atendimento.nome}`}
                            onChange={(e) => props.updateFiltroPessoa(e)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={1} xs={12} sm={2}>
                      <Tooltip title='Buscar Comprador' aria-label="add">
                        <IconButton color="primary" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => buscarPessoaCliente()}>
                          <PanToolIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button 
                        size="small"
                        fullWidth
                        variant="contained" 
                        color="info" 
                        onClick={e => props.openCadastroClienteVoucher()}
                      >
                        Cadastrar Cliente
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button 
                        size="small"
                        fullWidth
                        variant="contained" 
                        color="warning" 
                        onClick={e => props.addCliente()}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={4} xs={12} sm={8}>
                      <Table 
                        headCell={cabecalhoClientes} 
                        rows={dados.clientes} 
                        acoes={acoesTabelaClientes}
                        remove={(e) => props.removeCliente(e)}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                </React.Fragment>
              }

              {(dados.valor_credito !== '' && props.configuracao_unidade.perm_voucher_sem_fin) &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={3} xs={12} sm={6}>
                    <FormControl style={{marginLeft: '20px'}} component="fieldset">
                      <FormLabel component="legend">Informar Lançamento Financeiro?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="informar_financeiro"
                            color="primary"
                            checked={props.informar_financeiro}
                          />
                        }
                        size="small"
                        label={props.informar_financeiro ? 'Sim' : 'Não'}
                        name="informar_financeiro"
                        onChange={(e) => props.updateFieldInformarFinanceiro(e)}
                      />
                    </FormControl>
                    </Grid>
                </Grid>
              }
              {dados.valor_credito !== '' &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Observação"
                        className="input"
                        multiline
                        rows={5}
                        size="small"
                        variant="outlined"
                        name="observacao"
                        value={dados.observacao}
                        onChange={(e) => props.updateField(e)}
                        InputLabelProps={{ shrink: true }} 
                      />
                    </Grid>
                  </Grid>
                  <hr className="mg_top_10"/>
                </React.Fragment>
              }

              {dados.valor_credito !== '' &&
                <React.Fragment>
                  {props.informar_financeiro &&
                    <React.Fragment>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={3} xs={12} sm={8}>
                          <h4 className="titulo">Forma de Pagamento:</h4>
                        </Grid>
                        <Grid item md={2} xs={12} sm={4}>
                          <TextField 
                            id='valor_credito'
                            className="input" 
                            label="Valor da Conta*" 
                            variant="outlined" 
                            size="small" 
                            name="valor_credito" 
                            value={dados.valor_credito} 
                            onChange={(e) => props.updateField(e)} 
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: CurrencyInput }}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <div className='scroll'>
                            <table className="lista sempadding borderTable">
                              <thead>
                                <tr className="titulos acompanha">
                                  <th>Formas de Pagto.</th>
                                  <th>Valor*</th>
                                  <th>Desconto</th>
                                  <th>Data Pagto.*</th>
                                  <th>Forma de Pagamento*</th>
                                  <th>Contas Bancárias / Nº Vouchers</th>
                                </tr>
                              </thead>
                              <tbody>
                                {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                                  return (
                                    <tr key={parcela.index}>
                                      <td>
                                        <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.num} InputLabelProps={{ shrink: true }} disabled />
                                        <span className="separacaoParcelas">/</span>
                                        <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) =>props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                                      </td>
                                      <td>
                                        <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                                      </td>
                                      <td>
                                        <TextField className="inputValor" variant="outlined" size="small" name="multajuros" value={parcela.multajuros} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                                      </td>
                                      <td>
                                        <TextField 
                                          type="date" 
                                          className="inputVencimento" 
                                          variant="outlined" 
                                          size="small" 
                                          name="datavencimento" 
                                          value={parcela.datavencimento}  
                                          onChange={(e) => props.updateFieldParcela(e, parcela.index)} 
                                          onBlur={(e) => props.verificaDataParcela(e, parcela.index)}
                                          InputLabelProps={{ shrink: true }} 
                                          // InputProps={{ inputProps: { max: dataHojeFormatado } }}
                                        />
                                      </td>
                                      <td>
                                        <TextField
                                          id="standard-select-currency"
                                          select
                                          variant="outlined"
                                          className="inputFormPagamento"
                                          fullWidth
                                          size="small"
                                          SelectProps={{
                                            native: true,
                                          }}
                                          name="formapagto_id"
                                          value={parcela.formapagto_id}
                                          onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                          // onBlur={(e) => props.marcaParcelas(e, parcela.index)}
                                          InputLabelProps={{ shrink: true }}
                                          
                                        >
                                          <option value="">--Escolha--</option>
                                          {formasPagamento.map(forma => {
                                            return (
                                              <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                            )
                                          })}
                                        </TextField>
                                      </td>
                                      <td>
                                        {parseInt(parcela.formapagto_id) === 9 &&
                                          <div 
                                            style={{
                                              cursor: 'pointer',
                                              width: '300px',
                                              border: '1px solid',
                                              borderColor: 'rgba(0, 0, 0, 0.23)',
                                              height: '38px',
                                              borderRadius: '5px',
                                              padding: '10px 10px',
                                              marginLeft: '37px'
                                            }} 
                                            onClick={() => props.openVouchers(parcela.index)}
                                          >
                                            <span>{parcela.vouchersCreditoSelecionadosString}</span>
                                          </div>
                                        }
                                        {parseInt(parcela.formapagto_id) !== 9 &&
                                          <TextField
                                            id="standard-select-currency"
                                            select
                                            variant="outlined"
                                            className="inputFormPagamento"
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                              native: true,
                                            }}
                                            name="contfin_id"
                                            value={parcela.contfin_id}
                                            onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                            // onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                            InputLabelProps={{ shrink: true }}
                                          >
                                            <option value="">--Escolha--</option>
                                            {contasFinanceira.filter(param =>  (props.logado.caixa && parseInt(parcela.formapagto_id) === 1 && param.tipo_conta === "CAIXATURNO") || (param.tipo_conta === parcela.habilita)).map(conta => {
                                              return (
                                                <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                              )
                                            })}
                                          </TextField>
                                        }
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }             
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="secondary" 
                startIcon={<ArrowBackIcon />}
                onClick={e => props.confirmar(false)}
                disabled={loadingSalvar}
              >
                Voltar
              </Button>
            </Grid>
            {verificaCaixaInicial().libera &&
              <Grid item md={8} xs={12} sm={4}></Grid>
            }
            {!verificaCaixaInicial().libera &&
              <Grid item md={10} xs={12} sm={8}></Grid>
            }
            {verificaCaixaInicial().libera &&
              <Grid item md={2} xs={12} sm={4}>
                <Button 
                  size="small"
                  className="btnCadastrar" 
                  variant="contained" 
                  color="primary" 
                  startIcon={<AddIcon />}
                  onClick={e => props.confirmar(true)}
                  disabled={loadingSalvar}
                >
                  Salvar
                </Button>
              </Grid>
            }
          </Grid>
          <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon className='botao_ajuda' onClick={() => props.openModalAjudaCadastro()}/>
          </Tooltip>
        </StyledPaper>
      </Modal>
    </div>
  );
}
