import React, { useState, useEffect } from 'react';
import './Cliente.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/TableV2'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Button, TextField } from '@mui/material'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import { saveAs } from 'file-saver';
import qs from 'qs'
import ModalAjuda from './../../components/modals/Ajuda'
import { useNavigate } from 'react-router-dom';
import initialState from './initialState/cliente';
import formatarTelefone from './../../services/formatarTelefone'
import getToken from './../../services/getToken'

const Cliente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const updateFiltro = (event) => {
    const {filtro, filtrocliente} = state
    
    if (event.target.name === 'filtrar_por') {    
      setState(prevState => ({...prevState,
        filtrocliente: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      }))

      filtro[event.target.name] = event.target.value
      filtro.nome = ''
      filtro.cpf_cnpj = ''
      filtro.telefone = ''

      setState(prevState => ({...prevState,
        filtro
      }))
    } else if (event.target.name === 'flag' || event.target.name === 'ativo'){
      filtro[event.target.name] = event.target.value
      
      setState(prevState => ({...prevState,
        filtro
      }))
    }else{
      filtro[filtrocliente.name] = event.target.value
      filtro[event.target.name] = event.target.value
      
      setState(prevState => ({...prevState,
        filtro
      }))
    }
  }

  const filtrar = async () => {
    const {filtro} = state
    filtro.limit = 10
    filtro.offset = 0
    setState(prevState => ({...prevState,filtro}))

    criaUrl(filtro)
    localStorage.setItem('filtro_pessoas', JSON.stringify(filtro));

    await filtrarCliente(filtro)
  }

  const criaUrl = (filtro) => {
    let url = `/cliente?limit=${filtro.limit}&offset=${filtro.offset}`

    if(filtro.filtrar_por !== ''){
      url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
    }

    if(filtro.nome !== ''){
      url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
    }

    if(filtro.cpf_cnpj !== ''){
      url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
    }

    if(filtro.telefone !== ''){
      url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
    }

    if(filtro.ativo !== ''){
      url += `&ativo=${filtro.ativo.replaceAll(' ', '')}`
    }

    if(filtro.flag !== ''){
      url += `&flag=${filtro.flag.replaceAll(' ', '')}`
    }

    navigate(url);
  }

  const filtrarCliente = async (filtro) => {
    try {
      setState(prevState => ({...prevState,
        loading: true
      }))

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      let dadosFiltro = {
        unidadesnegocio: dados.unidadesnegocio,
        limit: filtro.limit,
        offset: filtro.offset * filtro.limit,
        nome: filtro.nome,
        cpf_cnpj: filtro.cpf_cnpj,
        telefone: filtro.telefone,
        flag: filtro.flag,
        ativo: filtro.ativo
      }
      
      localStorage.setItem('filtro_pessoas', JSON.stringify(filtro));

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, dadosFiltro, getToken())
      
      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          _id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.tipo === 'FISICA' ? `${pessoa.nome} ${pessoa.sobrenome ? pessoa.sobrenome : ''}` : pessoa.nome,
          sobrenome: pessoa.sobrenome,
          cpf_cnpj: pessoa.cpf_cnpj,
          numero: formatarTelefone(pessoa.numero),
          email: pessoa.email,
          flags: pessoa.flags.map(flag => {return flag.flag}),
          ativo: pessoa.ativo
        })
      })
      
      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas
        },
        pessoasFiltrado: {
          list: pessoas
        },
        rows: data.rows,
        loading: false
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleChangePage = async (event, newPage) => {
    const {filtro} = state
    filtro.offset = newPage
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarCliente(filtro)
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarCliente(filtro)
  }

  const imprimirFichaCliente = async (row) => {
    try {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Imprimindo Ficha Cadastral...'
        }
      }))

      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/imprimirFichaCadastral/${row._id}`, getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingImprimir: false,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

      saveAs(pdfBlob, 'Ficha-Cadastral.pdf')
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/Listagem.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        filtro: {
          nome: "",
          cpf_cnpj: "",
          flag: "",
          ativo: 'Sim',
          filtrar_por: "Nome - nome",
          value: '',
          telefone: '',
          limit: 10,
          offset: 0
        },
        voucher: {
          id: '',
          padrao_id: '',
          unidade_id: '',
          habilita: '',
          cliente: '',
          responsavel_id: '',
          valor_credito: '',
          servicos: [],
          clientes: [],
          observacao: ''
        }
      }))

      localStorage.removeItem('pessoa_cadastrada');
      localStorage.removeItem('ultima_tela_cliente');
      localStorage.removeItem('filtro_financeiro_cliente');
      localStorage.removeItem('aula_selecionada_agenda');

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cliente')[0]
      let permissoesFichaCliente = perfil.permissoes.filter(param => param.tela.modulo.slug === 'ficha-cadastro-cliente')[0]

      let acoesTabela = []

      if (permissoes.visualizar) {
        acoesTabela.push('viewCliente')
      }

      if (permissoes.alterar && permissoes.tela.modulo.slug !== 'nova-matricula') {
        acoesTabela.push('update')
      }

      if(permissoesFichaCliente){
        if(permissoesFichaCliente.visualizar){
          acoesTabela.push('imprimirFichaCliente')
        }
      }

      setState(prevState => ({...prevState,
        acoesTabela,
        permissoes
      }))

      let querys = qs.parse(window.location.href, { ignoreQueryPrefix: true })
      
      let filtro = {
        nome: querys.nome ? querys.nome.replaceAll('%', ' ') : '',
        cpf_cnpj: querys.cpf_cnpj ? querys.cpf_cnpj.replaceAll('%', ' ') : '',
        flag: querys.flag || '',
        ativo:  querys.ativo ? querys.ativo : 'Sim',
        filtrar_por: querys.filtrar_por ? querys.filtrar_por.replaceAll('%', ' ') : 'Nome - nome',
        value: querys.value ? querys.value.replaceAll('%', ' ') : '',
        telefone: querys.telefone ? querys.telefone.replaceAll('%', ' ') : '',
        limit: querys.limit ? parseInt(querys.limit) : 10,
        offset: querys.offset ? parseInt(querys.offset) : 0,
      }

      setState(prevState => ({...prevState,
        filtro
      }))

      await filtrarCliente(filtro)

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { filtro, pessoasFiltrado, permissoes, rows } = state
  const pessoa = JSON.parse(localStorage.getItem('pessoa'))
  const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={10} xs={12} sm={6}>
                  <h1 className="titulo">Clientes / Fornecedores</h1>
                </Grid>
                {permissoes.inserir &&
                  <React.Fragment>
                    <Grid item md={2} xs={12} sm={6}>
                      <Link to="/cliente/cadastro">
                        <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar
                      </Button>
                      </Link>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar por"
                    variant="outlined"
                    // className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="filtrar_por"
                    value={filtro.filtrar_por}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="Nome - nome"> Nome / Nome Fantasia </option>
                    <option value="CPF - cpf_cnpj"> CPF / CNPJ </option>
                    <option value="Telefone - telefone"> Telefone </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label={state.filtrocliente.label} variant="outlined" size="small" name='value' value={filtro.value} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Flag"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="flag"
                    value={filtro.flag}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> </option>
                    <option value="CLIENTE">Cliente</option>
                    <option value="FORNECEDOR">Fornecedor(a)</option>
                    <option value="REVENDEDOR">Revendedor(a)</option>
                    <option value="RESPONSÁVEL">Responsável</option>
                    {tipo === 'MANTENEDOR' &&
                      <React.Fragment>
                        <option value="REPRESENTANTE">Representante</option>
                        <option value="GRUPOECONOMICO">Grupo Economico</option>
                        <option value="UNIDADE">Unidade</option>
                      </React.Fragment>
                    }
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Ativo"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="ativo"
                    value={filtro.ativo}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value='Sim'> Ativo</option>
                    <option value='Não'> Inativo</option>
                  </TextField>
                </Grid>
                <Grid item md={1} xs={12} sm={1}>
                  <Button color='warning' className='btn_search_func' fullWidth variant="contained" endIcon={<SearchIcon />} onClick={e => filtrar(e)}></Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid className='ocultar-mobile' item md={12} xs={12}>
                  <Table 
                    urlUpdate="/cliente/cadastro/" 
                    urlView="/cliente/view/" 
                    headCell={state.cabecalhoTabela} 
                    rows={pessoasFiltrado.list} 
                    acoes={state.acoesTabela}
                    filtro={filtro}
                    count={rows}
                    handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                    imprimirFichaCliente={e => imprimirFichaCliente(e)}
                    tipo_unidade={tipo}
                  />
                </Grid>

                <Grid className='ocultar-desktop' item md={12} xs={12}>
                  <Table 
                    urlUpdate="/cliente/cadastro/" 
                    urlView="/cliente/view/" 
                    headCell={state.cabecalhoTabelaMobile} 
                    rows={pessoasFiltrado.list} 
                    acoes={state.acoesTabela}
                    filtro={filtro}
                    count={rows}
                    handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                    imprimirFichaCliente={e => imprimirFichaCliente(e)}
                  />
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Clientes e Fornecedores"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default Cliente