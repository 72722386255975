import React, { Component } from 'react'
import './Unidade.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import { Grid, Button, TextField } from '@mui/material'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  unidades: {
    list: []
  },
  unidadesFiltrado: {
    list: []
  },
  unidadesFisica: {
    list: []
  },
  unidadesJuridica: {
    list: []
  },
  loading: true,
  mensagemLoading: 'Carregando Unidades...',
  filtro: {
    num_desc: '',
    descricao: "",
    franqueado: "",
    cidade: "",
    tipo: '',
  },
  tipo: "",
  cabecalho: [
    { id: 'tipo_unidade', numeric: true, disablePadding: false, label: 'Tipo Unidade', edit: false, link: "", tooltip: "" },
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Descricao', edit: false, link: "" },
    { id: 'franqueado', numeric: false, disablePadding: true, label: 'Grupo Econômico', edit: false, link: "", tooltip: "" },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade', edit: false, link: "" },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa', edit: false, link: "" },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo', edit: false, link: "" }
  ],
  acoesTabela: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toString().toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Unidades extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'unidade')[0]

    this.setState({
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

    let acoesTabela = []

    if (permissoes.visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      tipo
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    try {
      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, dados, this.getToken())

      const unidades = []

      resp.data.sort((a, b) => (a.numero > b.numero) ? 1 : (b.numero > a.numero) ? -1 : 0).forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.principal)

        if (unidade.pessoa.tipo === "FISICA") {
          let franqueado = "N/A"
          if (unidade.superior.pessoa.tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.razaosocial
          }
          unidades.push({
            _id: unidade.id,
            numero: unidade.numero,
            tipo_unidade: unidade.tipo,
            num_desc: `${unidade.numero} - ${unidade.descricao}`,
            descricao: unidade.descricao,
            franqueado,
            cidade: enderecoPrincipal[0] ? `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}` : '',
            tipo: unidade.pessoa.tipo,
            ativo: unidade.status
          })
        } else {
          let franqueado = "N/A"
          if(unidade.superior){
            if (unidade.superior.pessoa.tipo === "FISICA") {
              franqueado = unidade.superior.pessoa.fisica.nome
            } else {
              franqueado = unidade.superior.pessoa.juridica.razaosocial
            }
          }
          unidades.push({
            _id: unidade.id,
            tipo_unidade: unidade.tipo,
            num_desc: `${unidade.numero} - ${unidade.descricao}`,
            numero: unidade.numero,
            descricao: unidade.descricao,
            franqueado,
            cidade: enderecoPrincipal[0] ? `${enderecoPrincipal[0].cidade} - ${enderecoPrincipal[0].estado}` : '',
            tipo: unidade.pessoa.tipo,
            ativo: unidade.status
          })
        }
      });

      this.setState({
        unidades: {
          list: unidades
        },
        unidadesFiltrado: {
          list: unidades
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const unidades = this.state.unidades.list
    const filtro = this.state.filtro

    const list = unidades.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      unidadesFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, unidadesFiltrado, permissoes } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={9} sm={6} xs={12}>
                    <h1 className="titulo">Unidades</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={3} sm={6} xs={12}>
                      <Link to="/unidade/cadastro">
                        <Button size='small' className="btnCadastrar" variant="contained" color="primary">
                          Cadastrar Unidade
                        </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Unidade" variant="outlined" size="small" name="num_desc" value={filtro.num_desc} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Franqueado" variant="outlined" size="small" name="franqueado" value={filtro.franqueado} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Cidade" variant="outlined" size="small" name="cidade" value={filtro.cidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Pessoa*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipo"
                      value={filtro.tipo}
                      onChangeCapture={(e) => this.updateFiltro(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">Pesquisar</option>
                      <option value="FISICA">
                        Pessoa Física
                        </option>
                      <option value="JURIDICA">
                        Pessoa Júridica
                        </option>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlView="/unidade/view/0/" 
                      urlUpdate="/unidade/update/" 
                      headCell={this.state.cabecalho} 
                      rows={unidadesFiltrado.list} 
                      acoes={this.state.acoesTabela} 
                    />
                    {/* <SimpleTabs headCellPessoaFisica={this.state.cabecalhoTabelaPessoaFisica} headCellPessoaJuridica={this.state.cabecalhoTabelaPessoaJuridica} rowsFisica={this.state.unidadesFisica.list} rowsJuridica={this.state.unidadesJuridica.list} /> */}
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} />
          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading}
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Unidades"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </React.Fragment>
    )
  }
}