import React, { useState, useEffect } from 'react';
import './Compras.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import moment from 'moment'
import real from './../../services/real'
import Table from './../../components/Table'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalCancelamentoCompra from './../../components/modals/CancelamentoCompra' 
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  compra: {
    id: '',
    numero: '',
    data_pedido: '',
    unidade_id: '',
    observacoes: '',
    pessoa_id: '',
    cliente: '',
    servicos: []
  },
  loading: true,
  mensagemLoading: 'Carregando compra...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  lancamentos: [],
  cabecalhoTabelaServico: [
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'nome', numeric: false, disablePadding: true, label: 'Serviço' },
    { id: 'quantidade', numeric: true, disablePadding: true, label: 'Quantidade' },
    { id: 'valor_unit', numeric: true, disablePadding: true, label: 'Valor Unitário' },
    { id: 'desconto', numeric: true, disablePadding: true, label: 'Desconto/Acréscimo Unit.' },
    { id: 'valor_total_pedido', numeric: true, disablePadding: true, label: 'Valor Total' },
    { id: 'documento', numeric: false, disablePadding: true, label: 'Documento' },
    { id: 'data_execucao_form', numeric: false, disablePadding: true, label: 'Data da Execução' },
  ],
  acoesTabela: [],
  headCellLancamentos: [
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: true, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: true, disablePadding: true, label: 'Desconto/Multa ou Juros' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  ],
  modal: {
    mensagem: '',
  },
  modalConfirmarOpen: false,
  dadosFinanceiro:{
    valor: 0,
    item_id: '',
    data_vencimento: moment().format('YYYY-MM-DD'),
    formapagto_id: '',
    contfin_id: '',
    habilita: ''
  },
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  modalCancelamentoCompraOpen: false,
  itens_receita_despesa: {
    list: []
  },
  itemSelecionado: {
    id: '',
    descricao: '',
    planocontas_id: '',
    plano_contas: {}
  },
  motivoCancelar: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ViewCompra = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { compraId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 2000) => {
    setTimeout(() => {
      navigate("/compras");
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState, 
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const voltar = () => {
    const self = this
    navigate("/compras");
  }

  const handleCloseCancelamento = () => {
    setState(prevState => ({...prevState, 
      modal: {
        mensagem: "",
      },
      modalConfirmarOpen: false
    }))
  }

  const verificaMovimentacao = async (value) => {
    try {
      const { compra } = state

      let {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/compras/consultaMovimentacao/${compra.id}`, getToken())

      if((data.valor_total > 0 && data.conciliado)){
        try {

          const dados = {
            unidadesnegocio: [compra.unidade_id],
          }
    
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,getToken())
                
          setState(prevState => ({...prevState, 
            itens_receita_despesa: {
              list: data.filter(param => param.ativo && param.plano_contas.tipo === 'DESPESA').map(item => {
                return {
                  id: item.id,
                  descricao: item.descricao,
                  planocontas_id: item.planocontas_id,
                  plano_contas: item.plano_contas
                }
              })
            }
          }))

          const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${compra.unidade_id}`, getToken())
  
          if(state.logado.caixa){
            const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
            
            setState(prevState => ({...prevState, 
              contasFinanceira: {
                list: contasFinanceira.filter(param => param.id === ultimo['conta.id'])
              }
            }))
    
          }else{
            setState(prevState => ({...prevState, 
              contasFinanceira: {
                list: contasFinanceira
              }
            }))
          }

          const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

          setState(prevState => ({...prevState, 
            formasPagamento: {
              list: formasPagamento.filter(param => param.ativo === true)
            }
          }))
    
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState, 
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }

        setState(prevState => ({...prevState, 
          dadosFinanceiro:{
            valor: data.valor_total,
            item_id: '',
            data_vencimento: moment().format('YYYY-MM-DD'),
            formapagto_id: '',
            contfin_id: '',
            habilita: ''
          },
          modal: {
            mensagem: "",
          },
          modalConfirmarOpen: false
        }))
      }

      setState(prevState => ({...prevState, 
        modalCancelamentoCompraOpen: true,
      }))
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState, 
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const cancelar = () => {
    setState(prevState => ({...prevState, 
      modal: {
        mensagem: "Deseja mesmo cancelar essa compra?",
      },
      modalConfirmarOpen: true
    }))
  }

  const confirmarCancelamento = async (value) => {
    if(value){
      try {
      
        let {compra, dadosFinanceiro, itemSelecionado} = state
  
        let dados = {}

        if(dadosFinanceiro.valor > 0){
          if(dadosFinanceiro.item_id === ''){
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar o item de despesa.'
              }
            }))
            return
          }

          if(dadosFinanceiro.data_vencimento === ''){
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a data de pagamento.'
              }
            }))
            return
          }

          if(dadosFinanceiro.formapagto_id === ''){
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a forma de pagamento.'
              }
            }))
            return
          }

          if(dadosFinanceiro.contfin_id === ''){
            setState(prevState => ({...prevState, 
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a conta financeira.'
              }
            }))
            return
          }

          let conta = {
            descricao: `Devolução da Compra Nº ${compra.numero}`,
            tipo: 'PAGAR',
            valortotal: (dadosFinanceiro.valor * -1),
            numparcela: 1,
            item_id: dadosFinanceiro.item_id,
            tipo_item: 'ITEMRECDESP',
            unidade_id: compra.unidade_id,
            pessoa_id: compra.pessoa_id,
            recorrencia: false,
            qtdRecorrencia: 1,
            recorrenciaACada: 1,
            tipoRecorrencia: 'M',
            data_documento: moment().format('YYYY-MM-DD'),
            parcelas: [{
              baixado: true,
              datavencimento: dadosFinanceiro.data_vencimento,
              datapagamento: dadosFinanceiro.data_vencimento,
              valorparcela: (dadosFinanceiro.valor * -1),
              num: 1,
              documento: '',
              contfin_id: parseInt(dadosFinanceiro.contfin_id),
              formas_pgto: [
                {
                  valor_pagamento: (dadosFinanceiro.valor * -1),
                  formapagto_id: parseInt(dadosFinanceiro.formapagto_id),
                }
              ]
            }],
            centros_custo: itemSelecionado.plano_contas.centro_custos.map(centro => {
              return {
                valor_rateio: ((dadosFinanceiro.valor * -1)*centro.valor_percentual)/100,
                valor_percentual: centro.valor_percentual,
                centrocusto_id: centro.centrocusto_id
              }
            })
          }

          if(conta.centros_custo.length === 0){
            delete conta.centros_custo
          }

          dados.conta = conta

        }

        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cancelando compra...'
          }
        }))

        dados.motivo = state.motivoCancelar

        await axios.put(`${window._env_.REACT_APP_API_URL}/compras/cancelar/${compra.id}`, dados, getToken())
  
        window.location.reload()
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      setState(prevState => ({...prevState, 
        modalCancelamentoCompraOpen: false,
        dadosFinanceiro:{
          valor: 0,
          item_id: '',
          data_vencimento: moment().format('YYYY-MM-DD'),
          formapagto_id: '',
          contfin_id: '',
          habilita: ''
        },
        modal: {
          mensagem: "",
        },
        modalConfirmarOpen: false
      }))
    }    
  }

  const handleCloseCancelamentoCompra = () => {
    setState(prevState => ({...prevState, 
      modalCancelamentoCompraOpen: false
    }))
  }

  const updateFieldItem = (e, value) => {
    if(!value){
      setState(prevState => ({...prevState, 
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        }
      }))

      return false
    } 

    let {dadosFinanceiro} = state

    dadosFinanceiro.item_id = value.id

    setState(prevState => ({...prevState, 
      itemSelecionado: value,
      dadosFinanceiro
    }))
  }

  const updateFieldFinanceiro = (event) => {
    let {dadosFinanceiro, formasPagamento} = state

    let value = event.target.value
    let name = event.target.name

    dadosFinanceiro[name] = value

    if(name === 'formapagto_id'){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

      dadosFinanceiro.habilita = formaPagtoSelecionada.habilita
      dadosFinanceiro.contfin_id = ""
    }
    
    setState(prevState => ({...prevState, dadosFinanceiro}))
  }

  const updateFieldMotivo =(event) => {
    setState(prevState => ({...prevState, 
      motivoCancelar: event.target.value
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'compra-servico-produto')[0]

      setState(prevState => ({...prevState, 
        permissoes
      }))

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]

      let perfilLogado = ''

      if (permissoesCaixa) {
        perfilLogado = 'caixa'
        setState(prevState => ({...prevState, 
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        perfilLogado = 'contas-pagar-receber'
        setState(prevState => ({...prevState, 
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      const caixa = JSON.parse(localStorage.getItem('caixa'))
      const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

      if(perfilLogado === 'contas-pagar-receber'){
        if(ultimoCaixa && !ultimoCaixa.fechado){
          setState(prevState => ({...prevState,  logado: { caixa } }))
        }else{
          setState(prevState => ({...prevState, 
            logado: {
              caixa: false
            }
          }))
        }

      }else{
        setState(prevState => ({...prevState,  logado: { caixa } }))
      }  

      if (!permissoes.visualizar) {
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }))
        
        backPage()
      }

      try {
        const { data: compra } = await axios.get(`${window._env_.REACT_APP_API_URL}/compras/${compraId}`, getToken())
        
        setState(prevState => ({...prevState, 
          compra: {
            id: compra.id,
            unidade_id: compra.unidade_id,
            numero: compra.numero,
            data_pedido: moment(compra.data_pedido).format("DD/MM/YYYY"),
            observacoes: compra.observacao,
            pessoa_id: compra.pessoa.id,
            cadastrado_por: compra.usuario.pessoa.fisica.nome + (compra.usuario.pessoa.fisica.sobrenome ? ` ${compra.usuario.pessoa.fisica.sobrenome}` : '') + ` Em: ${moment(compra.criado_em).format('DD/MM/YYYY HH:mm')}`,
            cliente: compra.pessoa.tipo === 'FISICA' ? (compra.pessoa.fisica.nome + (compra.pessoa.fisica.sobrenome ? ` ${compra.pessoa.fisica.sobrenome}`: '')) : compra.pessoa.juridica.nomefantasia,
            valor_total: real((somarValores(compra.itens_compra.map(value => {return ((value.valor_produto + value.desconto) * value.quantidade)})))*-1),
            servicos: compra.itens_compra.map(value => {
              return {
                id: value.id,
                produto_id: value.produto_id,
                quantidade: value.quantidade,
                data_execucao: value.data_execucao ? moment(value.data_execucao).format('DD/MM/YYYY') : '',
                valor_unit:'R$ ' + real(value.valor_produto * -1),
                valor_total_pedido:'R$ ' + real(((value.valor_produto + value.desconto) * value.quantidade) * -1),
                desconto:'R$ ' + real(value.desconto * -1),
                nome: value.produto.descricao,
                tipo: value.produto.tipo,
                documento: value.documento
              }
            }),
            cancelamento: compra.cancelamento
          },
          lancamentos: compra.conta.parcelas.sort((a, b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0)).map((parcela, key) => {
            let formapagto_descricao = ''

            for (let i = 0; i < parcela.valores_pago.length; i++) {
              if((i +1) === parcela.valores_pago.length){
                formapagto_descricao += `${parcela.valores_pago[i].forma_pagto.descricao}`
              }else{
                formapagto_descricao += `${parcela.valores_pago[i].forma_pagto.descricao} - `
              }
            }
            
            return {
              num_parcela: parcela.num + '/' + compra.conta.numparcela,
              datavencimento: moment(parcela.datavencimento).format('DD/MM/YYYY'),
              datapagamento: parcela.datapagamento ? moment(parcela.datapagamento).format('DD/MM/YYYY') : '',
              valorparcela: real(parcela.valorparcela * -1),
              multajuros: parcela.multajuros ? real(parcela.multajuros * -1) : real(0),
              tipo: 'SAQUE',
              formapagto_descricao,
              situacao: parcela.situacao,
              index: key
            }
          }),
        }))

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState, 
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
        return false
      }

      setState(prevState => ({...prevState, 
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const {compra, lancamentos} = state
  
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState, openModalAjuda: true}))}}>
            <div id='view'>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h1 className="titulo">
                    Compra Nº {compra.numero}
                    {compra.cancelamento &&
                      <span style={{color:'red'}}> (Cancelado) </span>
                    }
                  </h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={4}>
                  <TextField 
                    className="input"
                    label="Cadatrado por:" 
                    variant="outlined" 
                    size="small" 
                    name="cadastrado_por" 
                    value={compra.cadastrado_por} 
                    disabled
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField 
                    className="input"
                    label="Cliente:" 
                    variant="outlined" 
                    size="small" 
                    name="cliente" 
                    value={compra.cliente} 
                    disabled
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField 
                    className="input"
                    label="Data da Compra" 
                    variant="outlined" 
                    size="small" 
                    name="data_pedido" 
                    value={compra.data_pedido} 
                    disabled
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField 
                    className="input"
                    label="Valor da Compra" 
                    variant="outlined" 
                    size="small" 
                    name="valor_total" 
                    value={'R$ ' + compra.valor_total} 
                    disabled
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className='titulo'>Itens da Compra:</h4>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <Table
                    headCell={state.cabecalhoTabelaServico}
                    rows={compra.servicos.map(value => {
                      return {
                        ...value,
                        data_execucao_form:value.data_execucao
                      }
                    })}
                    acoes={state.acoesTabela}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Observações"
                    className="input"
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    name="observacoes"
                    value={compra.observacoes}
                    disabled
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
              </Grid>
              {compra.cancelamento && 
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Motivo do Cancelamento"
                      className="input"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      name="motivo"
                      value={'Cancelado por: ' + compra.cancelamento.usuario.pessoa.fisica.nome + ` Em: ${moment(compra.cancelamento.criado_em).format('DD/MM/YYYY HH:mm')} - Motivo: ` + compra.cancelamento.motivo}
                      disabled
                      InputLabelProps={{ shrink: true }} 
                    />
                  </Grid>
                </Grid>
              }
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className='titulo'>Lançamentos Financeiros:</h4>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <Table 
                    headCell={state.headCellLancamentos} 
                    rows={lancamentos} 
                    acoes={[]} 
                    ordem='desc' 
                    heightRows={15} 
                  />
                </Grid>
              </Grid>
              <hr />
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <Button 
                    fullWidth 
                    color="primary"
                    variant="contained"
                    size="small" 
                    onClick={e => voltar()}>Voltar</Button>
                </Grid>
                <Grid item md={8} xs={false} sm={4}></Grid>
                {!compra.cancelamento &&
                  <Grid item md={2} xs={12} sm={4}>
                    <Button 
                      fullWidth 
                      color="secondary"
                      variant="contained"
                      size="small" 
                      onClick={e => verificaMovimentacao()}>
                        Cancelar Compra
                    </Button>
                  </Grid>
                }
              </Grid>
            </div>
          </Main>
          <Nav/>
          
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
          
          <ModalConfirmacao 
            open={state.modalConfirmarOpen} 
            handleClose={e => handleCloseCancelamento(e)} 
            dados={state.modal} 
            confirmar={e => verificaMovimentacao(e)}
          />

          <ModalCancelamentoCompra 
            open={state.modalCancelamentoCompraOpen} 
            handleClose={e => handleCloseCancelamentoCompra(e)} 
            itemSelecionado={state.itemSelecionado}
            itens_receita_despesa={state.itens_receita_despesa.list}
            updateFieldItem={(e, value) => updateFieldItem(e, value)}
            dados={state.dadosFinanceiro}
            updateField={e => updateFieldFinanceiro(e)}
            formasPagamento={state.formasPagamento.list}
            contasFinanceira={state.contasFinanceira.list}
            logado={state.logado}
            confirmar={e => confirmarCancelamento(e)}
            motivoCancelar={state.motivoCancelar}
            updateFieldMotivo={(e) => updateFieldMotivo(e)}
          />
        </div>
      }
      <Loading
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default ViewCompra