import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios'
import './Voucher.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField } from '@mui/material'
import Table from './../../components/TableV2'
import ModalVoucher from './../../components/modals/Cadastro/Voucher'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalConfirmacaoCredito from './../../components/modals/Confirmacao'
import moment from 'moment'
import ModalSelecionaPessoa from './../../components/modals/SelecionaPessoa'
import ModalSelecionaPessoaCliente from './../../components/modals/SelecionaPessoa'
import Autocomplete from '@mui/material/Autocomplete';
import real from './../../services/real'
import moeda from './../../services/moeda'
import SelecionarVoucherCredito from './../../components/modals/SelecionarVoucherCredito'
import { format } from 'cnpj'
import SearchIcon from '@mui/icons-material/Search';
import ModalAjuda from './../../components/modals/Ajuda'
import ModalAjudaCadastro from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

const initialState = {
  loading: true,
  mensagemLoading: 'Carregando Vouchers...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  vouchers:{
    list: []
  },
  vouchersFiltrado: {
    list: []
  },
  cabecalho: [
    { id: 'padrao', numeric: false, disablePadding: false, label: 'Voucher' },
    { id: 'numero', numeric: false, disablePadding: false, label: 'Numero' },
    { id: 'habilita', numeric: false, disablePadding: false, label: 'Habilita' },
    { id: 'valor_credito', numeric: true, disablePadding: false, label: 'Valor Credito' },
    { id: 'saldo', numeric: true, disablePadding: false, label: 'Saldo' },
    { id: 'dependentes', numeric: false, disablePadding: false, label: 'Presenteados' },
    { id: 'data_compra', numeric: false, disablePadding: false, label: 'Data da Compra' },
  ],
  acoesTabela: [],
  openModalVoucher: false,
  modal: {
    mensagem: "",
  },
  modalOpen: false,
  voucher: {
    id: '',
    padrao_id: '',
    unidade_id: '',
    habilita: '',
    cliente: '',
    responsavel_id: '',
    valor_credito: '',
    servicos: [],
    clientes: [],
    observacao: ''
  },
  unidades: {
    list: []
  },
  configuracoes: {
    list: []
  },
  clientes:{
    list:[]
  },
  clientesFiltrado: {
    list: []
  },
  cliente: {
    id: '',
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  cliente_atendimento: {
    id: '',
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  ultimoCaixa: {},
  cliente_comprador: {
    id: '',
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  filtro: {
    padrao_id: '',
    responsavel_id: '',
    numero: '',
    data_inicio: '',
    data_fim: '',
    limit: 10,
    offset: 0
  },
  filtrocliente: {
    label: 'Nome',
    name:'nome'
  },
  loadingSalvar: false,
  travarUnidade: false,
  conta: {
    id: '',
    valor_total: '0,00',
    valor_total_minimo: 0,
    valor_total_maximo: 0,
    qtd_parcelas: 1,
    parcelas: [
      {
        id: '',
        baixado: "",
        multajuros: real(0),
        datavencimento: moment().format('YYYY-MM-DD'),
        datavencimento_orig: moment().format('YYYY-MM-DD'),
        valorparcela: "",
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: 1,
        parcela_editada: false,
        index: 0,
        trava: false,
        vouchersCreditoSelecionados: [],
        vouchersCreditoSelecionadosString: ''
      }
    ]
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  qtdParcelasEditado: 0,
  produto:{
    id: '',
    valor: '',
    index: ''
  },
  produtoSelecionado:{
    id: '',
    descricao: '',
    valor_maximo: '',
    valor_minimo: ''
  },
  produtos: {
    list: []
  },
  update: false,
  modalSelecionaPessoaCliente: false,
  filtro_search: {
    padrao: '',
    numero: '',
    responsavel: ''
  },
  filtro_pessoa: {
    nome: "",
    cpf_cnpj: "",
    flag: "",
    ativo: 'Sim',
    filtrar_por: "Nome - nome",
    value: '',
    limit: 10,
    offset: 0
  },
  rows: 0,
  rows_pessoa: 0,
  permissoesContasPR: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCaixa: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  perfilLogado: '',
  logado: {
    caixa: false
  },
  vouchersCreditoCliente: [],
  vouchersCreditoSelecionados: [],
  dadosTelaVoucherCredito: {
    totalConta: real(0),
    totalVouchers: real(0),
    diferenca: real(0),
    parcela_index: 0
  },
  vouchersCreditoSelecionadosString: '',
  vouchersCliente: [],
  openModalSelecionarVoucherCredito: false,
  modalConfirmacaoCreditoOpen: false,
  configuracao_unidade: {
    unidade_id: '',
    fuso: '',
    nome_fuso: '',
    intervalo_agenda: 0,
    inicio_atendimento: '',
    fim_atendimento: '',
    momento_comissao: '',
    item_despesa_troco_id: '',
    midia_log_id: '',
    exige_solic_abertura_caixa: false,
    item_recdesp_cortesia_id: '',
    item_recdesp_desc_concedido_id: '',
    item_recdesp_desc_obitido_id: '',
    item_recdesp_multa_recebida_id: '',
    item_recdesp_multa_paga_id: '',
    mensagem_boas_vindas: '',
    perm_voucher_sem_fin: false
  },
  informar_financeiro: true,
  openModalAjuda: false,
  markdown: '',
  openModalAjudaCadastro: false,
  markdownCadastro: ''
}

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const Voucher = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { tabelaPrecoProdutoId } = useParams();
  const inputRef = createRef();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const configuracaoUnidade = async (unidade_id) => {
    try {
      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade_id}`, getToken())
    
      setState(prevState => ({...prevState,
        configuracao_unidade: {
          id: configuracao.id || '',
          unidade_id: configuracao.unidade_id || '',
          fuso: configuracao.fuso || '',
          nome_fuso: configuracao.nome_fuso || '',
          intervalo_agenda: configuracao.intervalo_agenda || '',
          inicio_atendimento: configuracao.inicio_atendimento || '',
          fim_atendimento: configuracao.fim_atendimento || '',
          momento_comissao: configuracao.momento_comissao || '',
          item_despesa_troco_id: configuracao.item_despesa_troco_id || '',
          midia_logo_id: configuracao.midia_logo_id || '',
          exige_solic_abertura_caixa: configuracao.exige_solic_abertura_caixa || false,
          item_recdesp_cortesia_id: configuracao.item_recdesp_cortesia_id || '',
          item_recdesp_desc_concedido_id: configuracao.item_recdesp_desc_concedido_id || '',
          item_recdesp_desc_obitido_id: configuracao.item_recdesp_desc_obitido_id || '',
          item_recdesp_multa_recebida_id: configuracao.item_recdesp_multa_recebida_id || '',
          item_recdesp_multa_paga_id: configuracao.item_recdesp_multa_paga_id || '',
          mensagem_boas_vindas: configuracao.mensagem_boas_vindas || '',
          perm_voucher_sem_fin: configuracao.perm_voucher_sem_fin
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const filtrar = async () => {
    const {filtro} = state
    filtro.limit = 10
    filtro.offset = 0
    setState(prevState => ({...prevState,filtro}))

    await consultaVoucher()
  }

  const consultaVoucher = async () => {
    const {filtro} = state

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        ...filtro,
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      dados.offset = dados.offset * dados.limit

      if(filtro.data_inicio !== '' && filtro.data_fim === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data final'
          }
        }))
        return false
      }

      if(filtro.data_inicio === '' && filtro.data_fim !== ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data inicial'
          }
        }))
        return false
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/voucher/listPrincipal`, dados, getToken())

      const vouchers = []
    
      data.list.forEach(voucher => {
        let dependentes = ''

        if(voucher.dependentes){
          if(voucher.dependentes.length > 1){
            dependentes = voucher.dependentes[0] + ` +${voucher.dependentes.length - 1}`
          }else if(voucher.dependentes.length === 0){
            dependentes = 'Não Informado'
          }else{
            dependentes = voucher.dependentes[0]
          }
        }else{
          dependentes = 'Não Informado'
        }

        vouchers.push({
          _id: voucher.id,
          numero: voucher.numero,
          valor_credito: 'R$ ' + real(voucher.valor_credito),
          saldo: 'R$ ' + real(voucher.saldo),
          habilita: voucher.habilita === 'PRODUTO' ? 'Produto' : 'Crédito',
          padrao: voucher.padrao,
          responsavel: voucher.tipo_pessoa === 'FISICA' ? voucher.nome : voucher.nomefantasia,
          data_compra: moment(voucher.data_compra).format('DD/MM/YYYY'),
          cancelamento_voucher_id: voucher.cancelamento_voucher_id,
          observacao: voucher.observacao,
          dependentes,
          listDependentes: voucher.dependentes
        })
      })

      setState(prevState => ({...prevState,
        vouchers: {
          list: vouchers
        },
        rows: data.rows,
        filtro
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const openCadastro = async () => {
    setState(prevState => ({...prevState,
      openModalVoucher: true,
      clientes:{
        list:[]
      },
      clientesFiltrado: {
        list: []
      },
      cliente: {
        id: '',
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      },
      cliente_atendimento: {
        id: '',
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      },
      voucher: {
        id: '',
        padrao_id: '',
        unidade_id: state.voucher.unidade_id,
        habilita: '',
        cliente: '',
        responsavel_id: '',
        valor_credito: '',
        servicos: [],
        clientes: [],
        observacao: ''
      }
    }))

    await consultarFormaPagto()
    await consultarContasFinanceira()
  }

  const consultarFormaPagto = async () => {
    try {
      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

      setState(prevState => ({...prevState,
        formasPagamento: {
          list: formasPagamento.filter(param => param.ativo === true)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarContasFinanceira = async () => {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${acesso_atual[0].id}`, getToken())

      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira
        }
      }))    

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseVoucher = () =>{
    setState(prevState => ({...prevState,
      openModalVoucher: false,
      voucher: {
        id: '',
        padrao_id: '',
        unidade_id: state.voucher.unidade_id,
        habilita: '',
        cliente: '',
        responsavel_id: '',
        valor_credito: '',
        produtos: [],
        clientes: [],
        observacao: ''
      }
    }))
  }

  const confirmarFecharModal = (value) => {
    if(value){
      handleCloseVoucher()
      handleClose()
    }else{
      handleClose()
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const verificaFecharModal = () => {
    setState(prevState => ({...prevState,
      modal: {
        mensagem: "Deseja mesmo fechar o cadastro?",
      },
      modalOpen: true,
    }))
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modalOpen: false
    }))
  }

  const consultarConfiguracoes = async (unidade_id) => {
    
    try {
      const dados = {
        unidadesnegocio: [unidade_id],
        ativo: true,
        tipo: "",
        nome: ""
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/voucherPadrao/list`, dados, getToken())

      const configuracoes = []

      data.filter(param => param.ativo).sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0).forEach(configuracao => {
        configuracoes.push({
          id: configuracao.id,
          nome: configuracao.nome,
          descricao: configuracao.descricao || '',
          unidade: configuracao.unidade.numero + ' - ' + configuracao.unidade.descricao,
          unidade_id: configuracao.unidade_id,
          habilita: configuracao.habilita,
          tab_prom: configuracao.tab_prom.descricao,
          tab_normal: configuracao.tab_normal.descricao,
          dias_val_tab_prom: configuracao.dias_val_tab_prom,
          dias_val_tab_normal: configuracao.dias_val_tab_normal,
          dias_val_tab_prom_form: configuracao.dias_val_tab_prom + ' Dias',
          dias_val_tab_normal_form: configuracao.dias_val_tab_normal + ' Dias',
          ativo: configuracao.ativo,
          tab_prom_id: configuracao.tab_prom_id,
          tab_normal_id: configuracao.tab_normal_id,
          produtos: configuracao.tab_prom.preco_produtos.map(value => {
            return {
              id: value.produto.id,
              descricao: value.produto.descricao,
              valor_maximo: value.valor_maximo,
              valor_minimo: value.valor_minimo
            }
          })
        })
      })

      setState(prevState => ({...prevState,
        configuracoes: {
          list: configuracoes
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateField = async (event) => {
    const {voucher, configuracoes} = state
    
    let name = event.target.name
    let value = event.target.value
    
    voucher[name] = value

    if(name === 'unidade_id'){
      await consultarConfiguracoes(parseInt(value))
      await configuracaoUnidade(parseInt(value))
      
      setState(prevState => ({...prevState,
        clientes:{
          list:[]
        },
        clientesFiltrado: {
          list: []
        },
        cliente: {
          id: '',
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        },
      }))
      
      voucher.padrao_id = ''
      voucher.cliente = ''
      voucher.responsavel_id = ''

    }

    if(name === 'habilita'){
      voucher.padrao_id = ''
    }

    if(name === 'valor_credito'){
      setState(prevState => ({...prevState,
        conta: {
          id: '',
          valor_total: value,
          valor_total_minimo: 0,
          valor_total_maximo: 0,
          qtd_parcelas: 1,
          parcelas: [
            {
              id: '',
              baixado: "",
              multajuros: real(0),
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: value,
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: ''
            }
          ]
        },
      }))
    }

    if(name === 'padrao_id'){
      let configuracao = configuracoes.list.filter(param => param.id === parseInt(value))

      setState(prevState => ({...prevState,
        produtos: {
          list: configuracao[0].produtos
        }
      }))
    }

    setState(prevState => ({...prevState,voucher}))
  }

  const updateFieldCliente = (event, value) =>{
    const {voucher} = state
    if(!value){
      setState(prevState => ({...prevState,
        cliente: {
          id: '',
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    voucher.cliente = value.nome
    voucher.responsavel_id = value.pessoa_id

    setState(prevState => ({...prevState,
      voucher,
      cliente: value
    }))
  }

  const updateFieldClienteAtendido = async (event, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        cliente_atendimento: {
          id: '',
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    setState(prevState => ({...prevState,
      cliente_atendimento: value
    }))
  }

  const updateFiltroVoucherComprador = async (event, value) => {
    const {filtro} = state
    
    if(!value){
      
      filtro.responsavel_id = ''

      setState(prevState => ({...prevState,
        cliente_comprador: {
          id: '',
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        },
        filtro
      }))

      return false
    }

    filtro.responsavel_id = value.pessoa_id

    setState(prevState => ({...prevState,
      cliente_comprador: value,
      filtro
    }))
  }

  const filtrarCliente = async () => {
    setState(prevState => ({...prevState,
      loadingPessoas: true,
    }))

    try {
    
      let {filtro_pessoa, filtrocliente} = state

      filtro_pessoa = {
        flag: '',
        ativo: filtro_pessoa.ativo,
        filtrar_por: filtro_pessoa.filtrar_por,
        value: filtro_pessoa.value,
        nome: "",
        cpf_cnpj: "",
        nomefantasia: "",
        limit: filtro_pessoa.limit,
        offset: filtro_pessoa.offset * filtro_pessoa.limit
      }
      
      filtro_pessoa[filtrocliente.name] = filtro_pessoa.value ? filtro_pessoa.value : ''
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      filtro_pessoa.unidadesnegocio = [acesso_atual[0].id]

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtro_pessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.nome,
          sobrenome: pessoa.sobrenome,
          numero: formataTelefone(pessoa.numero),
          cpf_cnpj: pessoa.cpf_cnpj,
          flags: pessoa.flags.map(flag => {return flag.flag}),
          ativo: pessoa.ativo
        })
      })

      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: pessoas
        },
        loadingPessoas: false,
        rows_pessoa: data.rows,
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        loadingPessoas: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const filtrarClienteA = async (unidade_id) => {
    setState(prevState => ({...prevState,
      loadingPessoas: true,
    }))

    try {
    
      let {filtro_pessoa, filtrocliente} = state

      filtro_pessoa = {
        flag: '',
        ativo: filtro_pessoa.ativo,
        filtrar_por: filtro_pessoa.filtrar_por,
        value: filtro_pessoa.value,
        nome: "",
        cpf_cnpj:"",
        nomefantasia: "",
        limit: filtro_pessoa.limit,
        offset: filtro_pessoa.offset
      }
      
      filtro_pessoa[filtrocliente.name] = filtro_pessoa.value ? filtro_pessoa.value : ''

      filtro_pessoa.unidadesnegocio = [unidade_id]
      filtro_pessoa.nome = "A"
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/consultaPessoa`, filtro_pessoa, getToken())

      const pessoas = []

      data.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.tipo === 'FISICA' ? pessoa.fisica.nome + (pessoa.fisica.sobrenome ? ' ' + pessoa.fisica.sobrenome : '') : `${pessoa.juridica.nomefantasia} - ${pessoa.juridica.razaosocial}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? pessoa.fisica.cpf : pessoa.juridica.cnpj,
          flags: pessoa.flags.map(value => value.flag),
          ativo: pessoa.ativo
        })
      })

      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: pessoas
        },
        loadingPessoas: false,
        rows_pessoa: pessoas.length
      }))

    } catch (error) {
      setState(prevState => ({...prevState,
        loadingPessoas: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const buscarPessoa = async () => {
    await filtrarCliente()
    setState(prevState => ({...prevState,
      modalSelecionaPessoa: true
    }))
  }

  const buscarPessoaCliente = async () =>{
    await filtrarCliente()
    setState(prevState => ({...prevState,
      modalSelecionaPessoaCliente: true
    }))
  }

  const handleCloseModalPessoa = () =>{
    setState(prevState => ({...prevState,
      modalSelecionaPessoa: false,
      clientesFiltrado:{
        list: []
      },
      clientes:{
        list:[]
      }
    }))
  }

  const handleCloseModalPessoaCliente = () =>{
    setState(prevState => ({...prevState,
      modalSelecionaPessoaCliente: false,
      clientesFiltrado:{
        list: []
      },
      clientes:{
        list:[]
      }
    }))
  }

  const openCadastroCliente = () =>{
    localStorage.setItem('ultima_tela_cliente', '/venda_voucher');
    localStorage.setItem('origem_cadastro', 'comprador');
    localStorage.setItem('voucher', JSON.stringify(state.voucher))
    localStorage.setItem('conta_voucher', JSON.stringify(state.conta))
    localStorage.setItem('cliente_atendimento', JSON.stringify(state.cliente_atendimento))

    navigate("/cliente/cadastro");
    return;
  }

  const openCadastroClienteVoucher = () =>{
    localStorage.setItem('ultima_tela_cliente', '/venda_voucher');
    localStorage.setItem('origem_cadastro', 'cliente');
    localStorage.setItem('voucher', JSON.stringify(state.voucher))
    localStorage.setItem('conta_voucher', JSON.stringify(state.conta))
    localStorage.setItem('cliente_voucher', JSON.stringify(state.cliente))

    navigate("/cliente/cadastro");
    return;
  }

  const updateFiltroPessoaVoucher = (event) => {
    const filtro_pessoa = state.filtro_pessoa

    if (event.target.name === 'filtrar_por') {    
      setState(prevState => ({...prevState,
        filtrocliente: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      }))

      filtro_pessoa[event.target.name] = event.target.value

      setState(prevState => ({...prevState,
        filtro_pessoa
      }))
    }else{
      filtro_pessoa[event.target.name] = event.target.value
      
      setState(prevState => ({...prevState,
        filtro_pessoa
      }))
    }
  }

  const selecionarPessoa = async (row) => {
    const { voucher } = state

    voucher.cliente = row.nome
    voucher.responsavel_id = row.pessoa_id

    setState(prevState => ({...prevState,
      cliente: {
        pessoa_id: row.pessoa_id,
        tipo: row.tipo,
        flags: row.flags,
        nome: row.nome,
        cpf_cnpj: row.cpf_cnpj,
        ativo: row.ativo
      },
      modalSelecionaPessoa: false,
      voucher
    }))
  }

  const selecionarPessoaCliente = async (row) => {
    setState(prevState => ({...prevState,
      cliente_atendimento: {
        pessoa_id: row.pessoa_id,
        tipo: row.tipo,
        flags: row.flags,
        nome: row.nome,
        cpf_cnpj: row.cpf_cnpj,
        ativo: row.ativo
      },
      modalSelecionaPessoaCliente: false
    }))
  }

  const updateFieldConta = (event) => {
    const {conta} = state
    conta[event.target.name] = event.target.value
    setState(prevState => ({...prevState,conta}))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) }))
      
      const diferenca = valorTotal - somaParcelasEditada
      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }
  
  const verificaDataParcela = (event, index) => {
    const {conta} = state
        
    for (let i = (index + 1); i < conta.parcelas.length; i++) {
      let count = (i - index)

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      conta.parcelas[i].datavencimento = novaData
      conta.parcelas[i].datavencimento_orig = novaData
    }

    setState(prevState => ({...prevState, conta }))
  }

  const mudarParcelas = async (event) => {
    const {conta, voucher} = state
    
    let array = []
    const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    const dataHoje = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))

    if(!conta.parcelas[0].trava){
      let valorDividido = valorTotal / qtdParcelasInfo
      valorDividido = parseFloat(valorDividido.toFixed(2))
  
      let sobra = valorTotal - (valorDividido * qtdParcelasInfo)
  
      sobra = parseFloat(sobra.toFixed(2))
  
      for (let i = 0; i < qtdParcelasInfo; i++) {
        let mesAcrecentado = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
        mesAcrecentado.setMonth(dataHoje.getMonth() + (i))
  
        let parcela = {
          id: conta.parcelas[i] ? conta.parcelas[i].id : '',
          baixado: "",
          datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
          valorparcela: real(valorDividido),
          multajuros: real(0),
          formapagto_id: "",
          documento: "",
          num: i + 1,
          parcela_editada: false,
          index: i,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        }

        if(parcela.id === '' || !parcela.id){
          delete parcela.id
        }

        array.push({
          ...parcela
        })
      }
  
      if (sobra !== 0) {
        array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
      }

      conta.numparcela = qtdParcelasInfo
      conta.qtd_parcelas = qtdParcelasInfo
    }

    if(conta.parcelas[0].trava){
      array.push({...conta.parcelas[0]})

      let valor_restante = valorTotal -  parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
      qtdParcelasInfo -= 1

      let valorDividido = valor_restante / qtdParcelasInfo
      valorDividido = parseFloat(valorDividido.toFixed(2))
      
      let sobra = valor_restante - (valorDividido * qtdParcelasInfo)

      for (let i = 0; i < qtdParcelasInfo; i++) {
        // let mesAcrecentado = new Date(moment(conta.parcelas[0].datavencimento).format('YYYY-MM-DD HH:mm:ss'))
        // mesAcrecentado.setMonth(dataHoje.getMonth() + (i))
  
        array.push({
          baixado: "",
          datavencimento: moment().format('YYYY-MM-DD'),
          valorparcela: real(valorDividido),
          multajuros: real(0),
          formapagto_id: "",
          documento: "",
          num: i + 2,
          parcela_editada: false,
          index: i + 1,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: ''
        })

        if (sobra !== 0) {
          array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
        }
      }

      conta.numparcela = qtdParcelasInfo + 1
      conta.qtd_parcelas = qtdParcelasInfo + 1

    }
    
    conta.parcelas = array

    await buscaVouchersCredito(voucher.responsavel_id, 'CREDITO')

    setState(prevState => ({...prevState, 
      conta,
      dadosTelaVoucherCredito: {
        totalConta: real(0),
        totalVouchers: real(0),
        diferenca: real(0),
        parcela_index: 0
      }
    }))
  }

  const marcaParcelas = (event, index) =>{
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = (index + 1); i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const updateFieldParcela = async (event, index) => {
    const {conta, formasPagamento, voucher, dadosTelaVoucherCredito, vouchersCreditoCliente} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index][name] = value
    }else{
      conta.parcelas[index][name] = value
    }

    if(name === 'formapagto_id'){
      if(value !== ''){
        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
  
        if(formaPagtoSelecionada.slug === "voucher"){
        
          let valorparcela = parseFloat(conta.parcelas[index].valorparcela.replaceAll(".", "").replace(",", "."))
          let multajuros = parseFloat(conta.parcelas[index].multajuros.replaceAll(".", "").replace(",", "."))

          let totalConta = valorparcela + multajuros

          dadosTelaVoucherCredito.totalConta = real(totalConta)
          dadosTelaVoucherCredito.totalVouchers = real(0) 
          dadosTelaVoucherCredito.diferenca = real(totalConta)
          dadosTelaVoucherCredito.parcela_index = index

          if(vouchersCreditoCliente.length === 0){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'info',
                message: 'Buscando Vouchers'
              }
            }))
            let vouchers = await buscaVouchersCredito(voucher.responsavel_id, 'CREDITO')

            if(vouchers.length > 0){
              setState(prevState => ({...prevState,
                openModalSelecionarVoucherCredito: true,
                vouchersCreditoSelecionados: [],
                alerta: {
                  open: false,
                  severity: 'info',
                  message: ''
                }
              }))
            }else{
              let {conta} = state
      
              conta.parcelas = [
                {
                  id: '',
                  baixado: false,
                  datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                  datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                  valorparcela: conta.valortotal,
                  multajuros: real(0),
                  formapagto_id: "",
                  contfin_id: "",
                  documento: "",
                  habilita: "",
                  num: 1,
                  parcela_editada: false,
                  index: 0,
                  trava: false,
                  vouchersCreditoSelecionados: [],
                  vouchersCreditoSelecionadosString: '',
                }
              ]
              
              setState(prevState => ({...prevState,
                conta,
                alerta: {
                  open: true,
                  severity: 'info',
                  message: 'Cliente não possui voucher'
                }
              }))
            }
          }else{
            setState(prevState => ({...prevState,
              openModalSelecionarVoucherCredito: true,
            }))
          }
          
        }else{
          if(index === 0){
            setState(prevState => ({...prevState,
              vouchersCreditoSelecionados: [],
              dadosTelaVoucherCredito: {
                totalConta: real(0),
                totalVouchers: real(0),
                diferenca: real(0),
                parcela_index: 0
              },
              vouchersCreditoSelecionadosString: '',
              vouchersCliente: []
            }))
          }
        }

        conta.parcelas[index].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[index].contfin_id = ""
      }else{
        conta.parcelas[index].habilita = ""
        conta.parcelas[index].contfin_id = ""
      }
    }

    
    setState(prevState => ({...prevState, conta, dadosTelaVoucherCredito }))
  }

  const buscaVouchersCredito = async (pessoa_id, tipo) => {
    try {
      
      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscarVoucherPessoa/${pessoa_id}/${tipo}`, getToken())

      if(data.length > 0){
        setState(prevState => ({...prevState,
          vouchersCreditoCliente: data.map(value => {
            return {
              ...value,
              saldo_form: 'R$ ' + real(value.saldo),
              novo_saldo: value.saldo,
              novo_saldo_form: 'R$ ' + real(value.saldo),
            }
          }),
          vouchersCreditoSelecionados: []
        }))
      }

      return data
    
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const selecionarVoucherCredito = async (row) => {
    let {conta, dadosTelaVoucherCredito, vouchersCreditoCliente} = state

    let vouchersCreditoSelecionados = conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados
      
    let index = vouchersCreditoSelecionados.findIndex(param => param.voucher_id === row.id)
        
    let valor_total = parseFloat(dadosTelaVoucherCredito.totalConta.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))
    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    
    let index_voucher = await vouchersCreditoCliente.findIndex(param => param.id === row.id)
    
    if(index === -1){
      let valor_a_ultilizar = 0

      if(row.novo_saldo > diferenca){
        totalVouchers += diferenca
        valor_a_ultilizar = diferenca
        vouchersCreditoCliente[index_voucher].novo_saldo = row.novo_saldo - diferenca
        vouchersCreditoCliente[index_voucher].novo_saldo_form = 'R$ ' + real(vouchersCreditoCliente[index_voucher].novo_saldo)
      }else{
        totalVouchers += row.novo_saldo
        valor_a_ultilizar = row.novo_saldo
        vouchersCreditoCliente[index_voucher].novo_saldo = 0
        vouchersCreditoCliente[index_voucher].novo_saldo_form = 'R$ ' + real(0)
      }

      vouchersCreditoSelecionados.push({
        voucher_id: row.id,
        numero: row.numero,
        saldo: row.saldo,
        valor_a_ultilizar
      })

      dadosTelaVoucherCredito.diferenca = real(valor_total - totalVouchers)
      dadosTelaVoucherCredito.totalVouchers = real(totalVouchers)
    }else{     

      vouchersCreditoCliente[index_voucher].novo_saldo = vouchersCreditoCliente[index_voucher].novo_saldo + vouchersCreditoSelecionados[index].valor_a_ultilizar
      vouchersCreditoCliente[index_voucher].novo_saldo_form = "R$ " + real(vouchersCreditoCliente[index_voucher].novo_saldo)
      
      dadosTelaVoucherCredito.totalVouchers = real(parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")) - vouchersCreditoSelecionados[index].valor_a_ultilizar)
      dadosTelaVoucherCredito.diferenca = real(valor_total - parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")))
      
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados.splice(index,1)
      vouchersCreditoSelecionados.splice(index,1)
    }

    let vouchersCreditoSelecionadosString = ''

    for (let i = 0; i < vouchersCreditoSelecionados.length; i++) {
      if((i + 1) === vouchersCreditoSelecionados.length){
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero}`
      }else{
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero} - `
      }
      
    }

    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados = vouchersCreditoSelecionados
    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionadosString = vouchersCreditoSelecionadosString 

    setState(prevState => ({...prevState,
      dadosTelaVoucherCredito,
      vouchersCreditoCliente,
      vouchersCreditoSelecionados,
      conta
    }))
  }

  const finalizarSelecaoVoucher = () => {
    let {dadosTelaVoucherCredito, conta} = state

    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))

    if(totalVouchers > 0){
      if(diferenca > 0){
        let formas_vouchers = conta.parcelas.filter(param => parseInt(param.formapagto_id) === 9)

        if(conta.parcelas.length === formas_vouchers.length){
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = dadosTelaVoucherCredito.totalVouchers
    
          conta.qtd_parcelas = 2
    
          conta.parcelas.push({
            id: '',
            baixado: false,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(diferenca),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 2,
            parcela_editada: false,
            index: 1,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: ''
          })
        }else{
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = real(totalVouchers)

          for (let i = 0; i < conta.parcelas.length; i++) {
            if(parseInt(conta.parcelas[i].formapagto_id) !== 9){
              let valorparcela = parseFloat(conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
              let diferenca_div = diferenca / (conta.parcelas.length - formas_vouchers.length)

              conta.parcelas[i].valorparcela = real(parseFloat((valorparcela + diferenca_div).toFixed(2)))
            }
          }
        }

        setState(prevState => ({...prevState,
          dadosTelaVoucherCredito: {
            totalConta: real(totalVouchers),
            totalVouchers: real(totalVouchers),
            diferenca: real(0),
            parcela_index: 0
          }
        }))
      }
    }else{
      conta.parcelas[0].formapagto_id = ''
    }
  
    setState(prevState => ({...prevState,
      openModalSelecionarVoucherCredito: false,
      conta
    }))
  }

  const updateFieldProduto = (event) => {
    const {produto, produtos} = state
    
    let name = event.target.name
    let value = event.target.value
    
    produto[name] = value
    
    if(name === 'id'){
      let produtoSelecionado = produtos.list.filter(param => param.id === parseInt(value))[0]
      
      produto.valor = real(produtoSelecionado.valor_maximo)

      setState(prevState => ({...prevState,
        produtoSelecionado
      }))
    }

    produto.id = parseInt(produto.id)

    setState(prevState => ({...prevState,
      produto,
    }))
  }

  const addProduto = () => {
    const { produto, voucher, produtoSelecionado, produtos } = state
  
    let itens = voucher.servicos

    let preco_produto = produtos.list.filter(param => param.id === produto.id)[0]

    if(parseFloat(produto.valor.replaceAll(".", "").replace(",", ".")) < preco_produto.valor_minimo){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: `O valor informado é inferior ao valor mínimo de R$ ${real(preco_produto.valor_minimo)}!`
        }
      }))
      return
    }

    if(parseFloat(produto.valor.replaceAll(".", "").replace(",", ".")) > preco_produto.valor_maximo){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: `O valor informado é superior ao valor máximo de R$ ${real(preco_produto.valor_maximo)}!`
        }
      }))
      return
    }

    if(produto.index !== ''){
      itens[produto.index] = {
        id: produto.id,
        descricao: produtoSelecionado.descricao,
        valor: produto.valor,
        index: produto.index
      }
    }else{
      itens.push({
        id: produto.id,
        descricao: produtoSelecionado.descricao,
        valor: produto.valor,
        index: itens.length
      })
    }
  
    voucher.servicos = itens

    let valor_total = somarValores(voucher.servicos.map(value => {return parseFloat(value.valor.replaceAll(".", "").replace(",", "."))}))

    voucher.valor_credito = real(valor_total)

    setState(prevState => ({...prevState,
      voucher,
      produto:{
        id: '',
        valor: '',
        index: ''
      },
      produtoSelecionado:{
        id: '',
        descricao: '',
        valor_maximo: '',
        valor_minimo: ''
      },
      conta: {
        id: '',
        valor_total: real(valor_total),
        valor_total_minimo: 0,
        valor_total_maximo: 0,
        qtd_parcelas: 1,
        parcelas: [
          {
            id: '',
            baixado: "",
            multajuros: real(0),
            datavencimento: moment().format('YYYY-MM-DD'),
            datavencimento_orig:moment().format('YYYY-MM-DD'),
            valorparcela: real(valor_total),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: false,
            index: 0,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: ''
          }
        ]
      },
      update: false
    }))
  }

  const editarProduto = (index) =>{
    const {voucher} = state

    setState(prevState => ({...prevState,
      produto: {...voucher.servicos[index]},
      produtoSelecionado: {...voucher.servicos[index]},
      update: true
    }))
  }

  const removerProduto = (index) =>{
    const {voucher} = state

    const itens = voucher.servicos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id: itens[i].id,
        descricao: itens[i].descricao,
        valor: itens[i].valor,
        index: i
      })
    }

    voucher.servicos = aux

    if(voucher.servicos.length === 0){
      setState(prevState => ({...prevState,
        conta: {
          id: '',
          valor_total: '',
          valor_total_minimo: 0,
          valor_total_maximo: 0,
          qtd_parcelas: 1,
          parcelas: [
            {
              id: '',
              baixado: "",
              multajuros: real(0),
              datavencimento: moment().format('YYYY-MM-DD'),
              datavencimento_orig: moment().format('YYYY-MM-DD'),
              valorparcela: '',
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: ''
            }
          ]
        },
      }))

      voucher.valor_credito = ''
    }else{
      let valor_total = somarValores(voucher.servicos.map(value => {return parseFloat(value.valor.replaceAll(".", "").replace(",", "."))}))

      voucher.valor_credito = real(valor_total)

      setState(prevState => ({...prevState,
        conta: {
          id: '',
          valor_total: real(valor_total),
          valor_total_minimo: 0,
          valor_total_maximo: 0,
          qtd_parcelas: 1,
          parcelas: [
            {
              id: '',
              baixado: "",
              multajuros: real(0),
              datavencimento: moment().format('YYYY-MM-DD'),
              datavencimento_orig: moment().format('YYYY-MM-DD'),
              valorparcela: real(valor_total),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: ''
            }
          ]
        },
      }))
    }

    setState(prevState => ({...prevState, voucher }))
  }

  const addCliente = () =>{
    const {cliente_atendimento, voucher} = state

    if(cliente_atendimento.pessoa_id === ''){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor selecionar o cliente beneficiado'
        }
      }))
      return false
    }
        
    voucher.clientes.push({
      pessoa_id: cliente_atendimento.pessoa_id,
      nome: cliente_atendimento.nome,
      cpf_cnpj: cliente_atendimento.cpf_cnpj,
      index: voucher.clientes.length
    })

    setState(prevState => ({...prevState,
      cliente_atendimento: {
        id: '',
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      },
      voucher
    }))
  }

  const removeCliente = (index) => {
    let {voucher} = state

    let clientes = voucher.clientes

    clientes.splice(index, 1)

    voucher.clientes = clientes

    setState(prevState => ({...prevState,
      voucher
    }))
  }

  const salvar = async (value) => {
    if(value){
      const {voucher, conta, informar_financeiro} = state

      setState(prevState => ({...prevState,loadingSalvar: true}))

      if(voucher.responsavel_id === '' || !voucher.responsavel_id){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o Comprador!'
          },
          loadingSalvar: false
        }))

        return
      }

      if(voucher.habilita === '' || !voucher.habilita){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o Tipo!'
          },
          loadingSalvar: false
        }))
  
        document.getElementById('habilita').focus();
        return
      }

      if(voucher.padrao_id === '' || !voucher.padrao_id){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a Configuração!'
          },
          loadingSalvar: false
        }))
  
        document.getElementById('padrao_id').focus();
        return
      }

      if(voucher.habilita === 'PRODUTO' && voucher.servicos.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar ao menos um produto!'
          },
          loadingSalvar: false
        }))
  
        return
      }

      if(voucher.valor_credito === '' || !voucher.valor_credito){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o valor do Credito!'
          },
          loadingSalvar: false
        }))
  
        document.getElementById('valor_credito').focus();
        return
      }

      if(informar_financeiro){
        for (const parcela of conta.parcelas) {
          if(parcela.datavencimento === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar a data de vemcimento da parcela ${parcela.index}!`
              },
              loadingSalvar: false
            }))
            return
          }
  
          if(parcela.valorparcela === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar o valor da parcela ${parcela.index}!`
              },
              loadingSalvar: false
            }))
            return
          }
  
          if(parcela.formapagto_id === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar a forma de pagamento da parcela ${parcela.index}!`
              },
              loadingSalvar: false
            }))
            return
          }
  
          if(parcela.contfin_id === "" && parseInt(parcela.formapagto_id) !== 9){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar a conta da parcela ${parcela.index}!`
              },
              loadingSalvar: false
            }))
            return
          }
  
          let valor_parcela = parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))
          let valor_desconto = parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", "."))
  
          if(valor_desconto > 0){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Não é possivel lançar um acrescimo na parcela.'
              },
              loadingSalvar: false
            }))
            return
          }
  
          valor_desconto = valor_desconto * -1 
  
          if(valor_desconto > valor_parcela){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'O valor do desconto não pode ser superior ao valor da parcela.'
              },
              loadingSalvar: false
            }))
            return
          }
        }
        if(voucher.habilita === 'CREDITO'){
          try {
            let {data: vouchers} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscaVoucherCreditoCompra/${voucher.responsavel_id}/${voucher.padrao_id}`, getToken())
            
            let vouchers_iguais = []

            for (const voucher_antigo of vouchers) {
              let ids_voucher_novo = voucher.clientes.map(value => { return parseInt(value.pessoa_id)})
              let ids_cliente = voucher_antigo.clientes.map(value => { return parseInt(value.cliente.pessoa_id)})
            
              if(JSON.stringify(ids_cliente) === JSON.stringify(ids_voucher_novo)){
                vouchers_iguais.push({...voucher_antigo})
              }
            }
            
            if(vouchers_iguais.length > 0){
              setState(prevState => ({...prevState,
                modalConfirmacaoCreditoOpen: true,
                modal: {
                  mensagem: "Esse cliente possui outros vouchers de crédito com saldo, o saldo e os dependentes deles serão adicionados nesse voucher",
                }
              }))

              return false
            }
  
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,loadingSalvar: false}))
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
            return false
          }
        }
      }
      
      await continuarSalvar(true)
      
    }else{
      verificaFecharModal()
    }
  }

  const continuarSalvar = async (value) => {
    if(value){
      const {voucher, conta, informar_financeiro} = state

      const dados = {
        padrao_id: parseInt(voucher.padrao_id),
        responsavel_id: parseInt(voucher.responsavel_id),
        valor_credito: parseFloat(voucher.valor_credito.replaceAll(".", "").replace(",", ".")),
        unidade_id: parseInt(voucher.unidade_id),
        habilita: voucher.habilita,
        clientes: voucher.clientes.map(value => {
          return {
            pessoa_id: parseInt(value.pessoa_id)
          }
        }),
        conta: {
          valortotal: somarValores(conta.parcelas.map(parcela => {return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))})),
          parcelas: conta.parcelas.map(parcela => {
            return {
              id: parcela.id,
              datavencimento: parcela.datavencimento,
              valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
              num: parcela.index,
              contfin_id: parseInt(parcela.contfin_id),
              formapagto_id: parseInt(parcela.formapagto_id),
              multajuros: parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", ".")),
              vouchers_credito: parcela.vouchersCreditoSelecionados
            }
          })
        },
        observacao: voucher.observacao
      }
  
      if(state.logado.caixa){
        const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
        
        dados.conta.caixa = ultimo.id
      }
  
      if(voucher.habilita === 'PRODUTO'){
        dados.produtos = voucher.servicos.map(value => {
          return {
            id: value.id,
            valor: parseFloat(value.valor.replaceAll(".", "").replace(",", ".")),
          }
        })
      }
  
      for (let i = 0; i < dados.conta.parcelas.length; i++) {
        if(dados.conta.parcelas[i].formapagto_id === 9){
          delete dados.conta.parcelas[i].contfin_id
        }
        if(dados.conta.parcelas[i].vouchers_credito.length === 0){
          delete dados.conta.parcelas[i].vouchers_credito
        }
      }

      if(!informar_financeiro){
        delete dados.conta
      }
  
      try {
        await axios.post(`${window._env_.REACT_APP_API_URL}/voucher`, dados, getToken())
      
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: `Voucher cadastrado!`
          },
          loadingSalvar: false
        }))
      
        window.location.reload()
  
      }  catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,loadingSalvar: false}))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      setState(prevState => ({...prevState,
        modalConfirmacaoCreditoOpen: false,
        modal: {
          mensagem: "",
        },
        loadingSalvar: false
      }))
    }
  }

  const verificaCaixaInicial = (state) => {
    if (!state.ultimoCaixa || !state.logado)  return {
      mensagem: '',
      libera: true
    }
    
    const { ultimoCaixa, perfilLogado } = state

    if (
      perfilLogado === 'caixa' &&
      (!Object.keys(ultimoCaixa).length || (ultimoCaixa && ultimoCaixa.fechado))
    ) {
      return {
        mensagem: 'É necessário abrir um caixa para realizar a venda do voucher',
        libera: false
      }
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.fechado
    ) {

      const dataCaixa = moment(ultimoCaixa.dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        return {
          mensagem: 'O caixa não é de hoje, é necessario fechar o anterior e abrir um novo',
          libera: false
        }
      }
    }

    return {
      mensagem: '',
      libera: true
    }
  }

  const updateFiltroVoucher = (event) => {
    const filtro = state.filtro

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))
  }

  const handleChangePage = async (event, newPage) => {
    const {filtro_pessoa} = state
    filtro_pessoa.offset = newPage

    setState(prevState => ({...prevState,
      filtro_pessoa
    }))

    await filtrarCliente()
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro_pessoa} = state
    filtro_pessoa.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro_pessoa
    }))

    await filtrarCliente()
  }

  const handleChangePageVoucher = async (event, newPage) => {
    const {filtro} = state
    filtro.offset = newPage
    setState(prevState => ({...prevState,
      filtro
    }))

    await consultaVoucher()
  }

  const handleChangeRowsPerPageVoucher = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))

    await consultaVoucher()
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
      let filtroPessoa = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        }),
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: `${pessoa.nome} ${pessoa.sobrenome || ''}`,
          numero: formataTelefone(pessoa.numero),
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldInformarFinanceiro = () => {
    setState(prevState => ({...prevState,
      informar_financeiro: !state.informar_financeiro
    }))
  }

  const openVouchers = () => {
  
    setState(prevState => ({...prevState,
      openModalSelecionarVoucherCredito: true,
    }))

  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/Listagem.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      const helpPathCadastro = require("./../../help/voucher/Voucher.md")

      fetch(helpPathCadastro)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdownCadastro: text
          }))
        })

      setState(prevState => ({...prevState,
        filtro: {
          padrao_id: '',
          responsavel_id: '',
          numero: '',
          data_inicio: '',
          data_fim: '',
          limit: 10,
          offset: 0
        },
        voucher: {
          id: '',
          padrao_id: '',
          unidade_id: '',
          habilita: '',
          cliente: '',
          responsavel_id: '',
          valor_credito: '',
          servicos: [],
          clientes: [],
          observacao: ''
        }
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))
      
      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'venda-voucher')[0]
      
      let acoesTabela = []

      if (permissoes.visualizar) {
        acoesTabela.push('view')
      }

      let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
      let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]

      let perfilLogado = ''

      if (permissoesCaixa) {
        perfilLogado = 'caixa'
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      if (permissoesContasPR) {
        perfilLogado = 'contas-pagar-receber'
        setState(prevState => ({...prevState,
          permissoesContasPR,
          perfilLogado: 'contas-pagar-receber'
        }))
      }

      setState(prevState => ({...prevState,
        acoesTabela,
        permissoes
      }))

      const caixa = JSON.parse(localStorage.getItem('caixa'))
      const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

      if(perfilLogado === 'contas-pagar-receber'){
        if(ultimoCaixa && !ultimoCaixa.fechado){
          setState(prevState => ({...prevState, logado: { caixa } }))
        }else{
          setState(prevState => ({...prevState,
            logado: {
              caixa: false
            }
          }))
        }

      }else{
        setState(prevState => ({...prevState, logado: { caixa } }))
      }

      if(ultimoCaixa && ultimoCaixa['status_atual.status'] === 'ABERTO'){
        setState(prevState => ({...prevState,
          permissoesCaixa,
          perfilLogado: 'caixa'
        }))
      }

      setState(prevState => ({...prevState,
        ultimoCaixa: ultimoCaixa || {}
      }))
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      await consultaVoucher()

      try {

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.filter(param => param.tipo === 'UNIDADE').sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              return {
                id: unidade.id,
                descricao: `${unidade.numero} - ${unidade.descricao}`,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {voucher} = state
          voucher.unidade_id = unidades[0].id
          await consultarConfiguracoes(unidades[0].id)
          await configuracaoUnidade(unidades[0].id)
          // await filtrarClienteA(unidades[0].id)
          setState(prevState => ({...prevState,voucher, travarUnidade: true}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      let pessoa_cadastrada = JSON.parse(localStorage.getItem('pessoa_cadastrada'))
      let origem_cadastro = localStorage.getItem('origem_cadastro')

      if(pessoa_cadastrada){
        let voucher = JSON.parse(localStorage.getItem('voucher'))
        let conta = JSON.parse(localStorage.getItem('conta_voucher'))
        

        if(origem_cadastro === 'comprador'){
          voucher.cliente = pessoa_cadastrada.cpf ? pessoa_cadastrada.nome : pessoa_cadastrada.nomefantasia
          voucher.responsavel_id = pessoa_cadastrada.pessoa_id

          let cliente_atendimento = JSON.parse(localStorage.getItem('cliente_atendimento'))

          setState(prevState => ({...prevState,
            cliente: {
              id: pessoa_cadastrada.pessoa_id,
              nome: pessoa_cadastrada.cpf ? (pessoa_cadastrada.nome + (pessoa_cadastrada.sobrenome ? ` ${pessoa_cadastrada.sobrenome}` : '')) : pessoa_cadastrada.nomefantasia,
              cpf_cnpj: pessoa_cadastrada.cpf ? pessoa_cadastrada.cpf : pessoa_cadastrada.cnpj,
              pessoa_id: pessoa_cadastrada.pessoa_id
            },
            cliente_atendimento
          }))
        }else{
          let cliente_voucher = JSON.parse(localStorage.getItem('cliente_voucher'))
          setState(prevState => ({...prevState,
            cliente_atendimento: {
              id: pessoa_cadastrada.pessoa_id,
              nome: pessoa_cadastrada.cpf ? (pessoa_cadastrada.nome + (pessoa_cadastrada.sobrenome ? ` ${pessoa_cadastrada.sobrenome}` : '')) : pessoa_cadastrada.nomefantasia,
              cpf_cnpj: pessoa_cadastrada.cpf ? pessoa_cadastrada.cpf : pessoa_cadastrada.cnpj,
              pessoa_id: pessoa_cadastrada.pessoa_id
            },
            cliente: cliente_voucher
          }))
        }

        setState(prevState => ({...prevState,
          openModalVoucher: true,
          conta,
          voucher
        }))
        
        await consultarFormaPagto()
        await consultarContasFinanceira()

        localStorage.removeItem('pessoa_cadastrada')
        localStorage.removeItem('ultima_tela_cliente')
        localStorage.removeItem('cliente_voucher')
        localStorage.removeItem('conta_voucher')
        localStorage.removeItem('cliente_atendimento')
        
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { permissoes, filtro, rows, configuracoes } = state 

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={10} xs={12} sm={8}>
                  <h1 className="titulo">Voucher's</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={2} xs={12} sm={4}>
                    <Button onClick={() => openCadastro()} size="small" className="btnCadastrar" variant="contained" color="primary">
                      Vender Voucher
                    </Button>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Voucher"
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="padrao_id"
                    value={filtro.padrao_id}
                    onChangeCapture={(e) => updateFiltroVoucher(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecioar Padrão </option>
                    {configuracoes.list.map((value, key) => {
                      return (
                        <option key={key} value={value.id}> {value.nome} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Número" variant="outlined" size="small" name="numero" value={filtro.numero} onChange={(e) => updateFiltroVoucher(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => updateFiltroVoucherComprador(event, value)}
                    freeSolo
                    options={state.clientesFiltrado.list}
                    value={state.cliente_comprador}
                    id='cliente'
                    getOptionLabel={option => `${option.nome}`}
                    renderInput={(params) => (
                      <TextField 
                        InputProps={{ ...params.InputProps, type: 'search' }} 
                        {...params} 
                        size="small" 
                        label="Cliente*" 
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }} 
                        value={`${state.cliente_comprador.nome}`}
                        onChange={(e) => updateFiltroPessoa(e)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type='date' className="input" label="Data Inicio" variant="outlined" size="small" name="data_inicio" value={filtro.data_inicio} onChange={(e) => updateFiltroVoucher(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type='date' className="input" label="Data Fim" variant="outlined" size="small" name="data_fim" value={filtro.data_fim} onChange={(e) => updateFiltroVoucher(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={1} xs={12} sm={4}>
                  <Button color='warning' className='btn_search_func' fullWidth variant="contained" endIcon={<SearchIcon />} onClick={e => filtrar(e)}></Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    headCell={state.cabecalho} 
                    rows={state.vouchers.list} 
                    acoes={state.acoesTabela} 
                    urlView="/venda_voucher/view/"
                    filtro={filtro}
                    count={rows}
                    handleChangePage={(event, newPage) => handleChangePageVoucher(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPageVoucher(e)}
                  />
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav />
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />

          <ModalConfirmacao 
            open={state.modalOpen} 
            dados={state.modal} 
            handleClose={e => handleClose(e)} 
            confirmar={e => confirmarFecharModal(e)}
          />

          <ModalVoucher 
            open={state.openModalVoucher}
            handleClose={e => verificaFecharModal()} 
            confirmar={(e) => salvar(e)}
            updateField={e => updateField(e)}
            dados={state.voucher}
            loadingSalvar={state.loadingSalvar}
            unidades={state.unidades.list}
            configuracoes={state.configuracoes.list}
            clientes={state.clientesFiltrado.list}
            cliente={state.cliente}
            cliente_atendimento={state.cliente_atendimento}
            buscarPessoa={(e) => buscarPessoa(e)}
            buscarPessoaCliente={(e) => buscarPessoaCliente(e)}
            openCadastroCliente= {e => openCadastroCliente()}
            travarUnidade={state.travarUnidade}
            conta={state.conta}
            formasPagamento={state.formasPagamento.list}
            contasFinanceira={state.contasFinanceira.list}
            updateFieldCliente={(e, value) => updateFieldCliente(e, value)}
            updateFieldClienteAtendido={(e, value) => updateFieldClienteAtendido(e, value)}
            updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
            marcaParcelas={(e, index) => marcaParcelas(e, index)}
            updateFieldConta={(e) => updateFieldConta(e)}
            recalcularParcela={(e, value) => recalcularParcela(e, value)}
            mudarParcelas={e => mudarParcelas(e)}
            verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
            produto={state.produto}
            produtos={state.produtos.list}
            updateFieldProduto={e => updateFieldProduto(e)}
            addProduto={e => addProduto()}
            editarItem={e => editarProduto(e)}
            removerItem={e => removerProduto(e) }
            alterar={state.update}
            addCliente={e => addCliente()}
            openCadastroClienteVoucher={e => openCadastroClienteVoucher()}
            updateFiltroPessoa={e => updateFiltroPessoa(e)}
            logado={state.logado}
            verificaCaixaInicial={() => verificaCaixaInicial(state)}
            removeCliente={(e) => removeCliente(e)}
            vouchersCreditoSelecionadosString={state.vouchersCreditoSelecionadosString}
            openVouchers={() => openVouchers()}
            configuracao_unidade={state.configuracao_unidade}
            informar_financeiro={state.informar_financeiro}
            updateFieldInformarFinanceiro={(e) => updateFieldInformarFinanceiro(e)}
            openModalAjudaCadastro={() => {setState(prevState => ({...prevState,openModalAjudaCadastro: true}))}}
          />

          <ModalSelecionaPessoa 
            open={state.modalSelecionaPessoa} 
            handleClose={e => handleCloseModalPessoa()} 
            updateFiltro={e => updateFiltroPessoaVoucher(e)}
            filtro={state.filtro_pessoa}
            filtrocliente={state.filtrocliente}
            filtrarCliente={e => filtrarCliente()}
            loadingPessoas={state.loadingPessoas}
            pessoasFiltrado={state.clientesFiltrado.list}
            selecionarPessoa={e => selecionarPessoa(e)}
            desabilitaFlag={true}
            rows={state.rows_pessoa}
            handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
            handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
          />

          <ModalSelecionaPessoaCliente
            open={state.modalSelecionaPessoaCliente} 
            handleClose={e => handleCloseModalPessoaCliente()}
            filtro={state.filtro}
            filtrocliente={state.filtrocliente}
            filtrarCliente={e => filtrarCliente()}
            loadingPessoas={state.loadingPessoas}
            pessoasFiltrado={state.clientesFiltrado.list}
            selecionarPessoa={e => selecionarPessoaCliente(e)}
            desabilitaFlag={true}
            rows={state.rows}
            handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
            handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
          />

          <SelecionarVoucherCredito
            open={state.openModalSelecionarVoucherCredito} 
            handleClose={e => finalizarSelecaoVoucher(e)} 
            vouchersCliente={state.vouchersCreditoCliente}
            selecionarVoucherCredito={e => selecionarVoucherCredito(e)}
            voltar={e => finalizarSelecaoVoucher(e)}
            vouchersCreditoSelecionados={state.vouchersCreditoSelecionados}
            dados={state.dadosTelaVoucherCredito}
          />

          <ModalConfirmacaoCredito 
            open={state.modalConfirmacaoCreditoOpen} 
            dados={state.modal} 
            handleClose={e => handleClose(e)} 
            confirmar={e => continuarSalvar(e)}
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Vouchers"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
      <ModalAjudaCadastro 
        open={state.openModalAjudaCadastro}
        tela={"Venda de Vouchers"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjudaCadastro: false}))}}
        markdown={state.markdownCadastro}
      />
    </React.Fragment>
  )

}

export default Voucher