import React, { useState, useEffect } from 'react';
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import moment from 'moment'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from './../../components/templates/Alert'
import ModalExibeNFSE from './../../components/modals/ExibeBoleto'
import ModalCancelarNFSE from './../../components/modals/CancelarNFSE'
import NFSE from './template/NFSE'
import qs from 'qs'
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { format } from 'cnpj'
import { saveAs } from 'file-saver';
import real from './../../services/real'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');
const initialState = {
  filtro: {
    relatorio: "nfse",
    data_inicial: '',
    data_final: '',
    unidade_id: '',
    situacao: '',
    numero: '',
    pessoa_id: '',
    nome: '',
    limit: 10,
    offset: 0
  },
  cabecalhoTabela: [
    { id: 'numero', numeric: false, disablePadding: true, label: 'Nº da NFSE' },
    { id: 'data_emissao', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'tomador', numeric: false, disablePadding: true, label: 'Tomador' },
    { id: 'valor_form', numeric: true, disablePadding: true, label: 'Valor' },
    { id: 'cod_verificacao', numeric: false, disablePadding: false, label: 'Cod. Verificação' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'motivo_status', numeric: false, disablePadding: false, label: 'Motivo' }
  ],
  acoesTabela: ['imprimirNFSERel', 'viewNFSE'],
  nfse: {
    list: []
  },
  loading: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  status:[
    {value: 'APROVADO', name: 'Aprovado'},
    {value: 'REPROVADO', name: 'Reprovado'},
    {value: 'CANCELADO', name: 'Cancelado'},
    {value: 'PROCESSANDO', name: 'Em Processamento'},
    {value: 'CONTINGENCIA', name: 'Em Contigencia'},
  ],
  tab:0,
  openModalExibeNfse: false,
  boletoSelecionado:{
    tipo_transaction: 'BOLETO',
    bank_slip:{
      url_slip_pdf: ''
    }
  },
  openModalCancelarNFSE: false,
  dadosCancelar: {
    id:'',
    motivoCancelar: '',
  },
  motivosCancelamento: {
    list: []
  },
  rows: 0,
  pessoas: {
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  total_notas_geradas: 0,
  total_vendas_sem_nfse: 0,
  qtd_notas_emitidas: 0,
  openModalAjuda: false,
  markdown: ''
}

const RelatorioNFSE = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: pessoa.nome,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const criaUrl = (filtro) => {
    let url = `/rel_nfse?limit=${filtro.limit}&offset=${filtro.offset}&data_inicial=${filtro.data_inicial}&data_final=${filtro.data_final}`

    if(filtro.numero !== ''){
      url += `$numero=${filtro.numero}`
    }

    navigate(url);
  }

  const updateFieldFiltro = (event) => {
    const filtro = state.filtro

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState, filtro }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const updateFieldPessoaFiltro = (event, value) =>{
    const {filtro} = state

    if(!value){

      filtro.pessoa_id = ''
      filtro.nome = ''

      setState(prevState => ({...prevState,
        cliente: {
          pessoa_id: '',
          nome: '',
          cpf_cnpj: ''
        },
        filtro
      }))

      return false
    } 

    filtro.pessoa_id = value.pessoa_id
    filtro.nome = value.nome

    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const filtrar = async () => {
    const {filtro} = state

    filtro.limit = 10
    filtro.offset = 0

    criaUrl(filtro)
    localStorage.setItem('filtro_nfse', JSON.stringify(filtro));

    await filtrarNFSE()
  }

  const filtrarNFSE = async () => {
    const filtro = state.filtro

    setState(prevState => ({...prevState,
      loading: true
    }))

    if (filtro.unidade_id === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a unidade'
        },
        loading: false
      }))
      return
    }

    if (filtro.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        },
        loading: false
      }))
      return
    }

    if (filtro.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a data final'
        },
        loading: false
      }))
      return
    }

    if(moment(filtro.data_final).isBefore(filtro.data_inicial)){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'A data inicial não pode ser maior que a data final.'
        },
        loading: false
      }))
      return
    }

    try {

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/list`, filtro, getToken())
    
      setState(prevState => ({...prevState,
        nfse: {
          list: data.list.map(value => {
            return {
              id: value.id,
              numero: value.numero,
              status: state.status.filter(param => param.value === value.status)[0].name,
              tomador: value.tipo_pessoa === 'FISICA' ? `${value.nome} ${value.sobrenome || ''}` : value.nomefantasia,
              data_emissao: moment(value.data_emissao).format('DD/MM/YYYY'),
              motivo_status: value.motivo_status,
              cod_verificacao: value.cod_verificacao,
              parcela_id: value.parcela_id,
              url_pdf: value.url_pdf,
              valor: value.valor,
              valor_form: 'R$ ' + real(value.valor)
            }
          })
        },
        rows: data.rows,
        total_vendas_sem_nfse: data.total_vendas_sem_nfse,
        total_notas_geradas: data.total_notas_geradas,
        qtd_notas_emitidas: data.qtd_notas_emitidas,
        loading: false
      })  )  

    } catch (error) {
      setState(prevState => ({...prevState,
        loading: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      console.log(error)
    }
  }

  const imprimirNFSE = async (row) => {
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Carregando NFSe'
      }
    }))

    try {
    
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/imprimir`, {id: row.id}, getToken())
      
      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingImprimir: false,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

      saveAs(pdfBlob, `NFSe-${row.numero}.pdf`)
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingImprimir: false
      }))
    }
  }

  const handleModalExibeNFSE = () => {
    setState(prevState => ({...prevState,openModalExibeNfse: false}))
  }

  const openModalCancelarNFSE = async (row) => {
    try {

      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/consultarMotivoCancelamento`, getToken())
      
      setState(prevState => ({...prevState,
        openModalCancelarNFSE: true,
        dadosCancelar: {
          id: row.id,
          motivo: ''
        },
        motivosCancelamento: {
          list: data
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const cancelarNFSE = async (value) => {
    if(value){
      try {
        let {dadosCancelar} = state

        dadosCancelar.motivo = parseInt(dadosCancelar.motivo)
  
        await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/cancelar`, dadosCancelar, getToken())
      
        window.location.reload()
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleModalCancelarNFSE()
    }

  }

  const handleModalCancelarNFSE = () => {
    setState(prevState => ({...prevState,
      openModalCancelarNFSE: false,
      dadosCancelar: {
        id: ''
      }
    }))
  }

  const updateFieldCancelamento = (event) => {
    let {dadosCancelar} = state
    dadosCancelar[event.target.name] = event.target.value
    setState(prevState => ({...prevState,dadosCancelar}))
  }

  const handleChangePage = async (event, newPage) => {
    const {filtro} = state
    filtro.offset = newPage
    
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarNFSE()
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarNFSE()
  }

  const imprimir = async () => {
    const { filtro, nfse } = state

    setState(prevState => ({...prevState,
      loadingDownload: true
    }))

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      filtro.list = nfse.list
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingDownload: false
      }))

      saveAs(pdfBlob, 'NFS-e.pdf')
      
    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingDownload: false
      }))
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/relatorios/NFSE.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      let querys = qs.parse(window.location.href, { ignoreQueryPrefix: true })

      const data_inicial = moment().startOf('month').format('YYYY-MM-DD');
      const data_final   = moment().endOf('month').format('YYYY-MM-DD');

      let filtro = {
        relatorio: "nfse",
        data_inicial: querys.data_inicial || data_inicial,
        data_final: querys.data_final || data_final,
        numero: parseInt(querys.numero) || '',
        unidade_id: '',
        situacao: '',
        pessoa_id: querys.pessoa_id ? parseInt(querys.pessoa_id) : '',
        nome: "",
        limit: querys.limit ? parseInt(querys.limit) : 10,
        offset: querys.offset ? parseInt(querys.offset) : 0,
      }

      if(filtro.pessoa_id !== ''){
        try {
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/consultaNome/${filtro.pessoa_id}`, getToken())
        
          filtro.nome = data.tipo === 'FISICA' ? data.fisica.nome : data.juridica.nomefantasia 
        
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }
      }

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
        
          filtro.unidade_id = unidades[0].id
          setState(prevState => ({...prevState,filtro}))
          criaUrl(filtro)
          await filtrarNFSE()
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState,filtro}))
    };
    
    fetchData();
  }, []);

  const { filtro, cabecalhoTabela, acoesTabela, nfse, loadingDownload, unidades, tab, rows, pessoas, cliente,
    total_vendas_sem_nfse, total_notas_geradas, qtd_notas_emitidas} = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        <Grid container spacing={2} direction="row" className="borderBottom">
          <Grid item md={11} xs={10}>
            <h1 className="titulo">Relatório de NFSEs Emitidas</h1>
          </Grid>
          {loadingDownload &&
            <Grid item md={1} xs={2}>
              <CircularProgress />
            </Grid>
          }
        </Grid>
        <Grid container spacing={2} direction="row" className="mg_top_20">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Unidade"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="unidade_id"
              value={filtro.unidade_id}
              onChangeCapture={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> Selecionar Unidade</option>
              {unidades.list.map(unidade => {
                return (
                  <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='date'
              className="input" 
              label="Data Incial" 
              variant="outlined" 
              size="small" 
              name="data_inicial" 
              value={filtro.data_inicial} 
              onChange={(e) => updateFieldFiltro(e)} 
              InputProps={{ inputProps: { max: filtro.data_final !== '' ? filtro.data_final : '' } }}
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='date'
              className="input" 
              label="Data Final" 
              variant="outlined" 
              size="small" 
              name="data_final" 
              value={filtro.data_final} 
              onChange={(e) => updateFieldFiltro(e)} 
              InputProps={{ inputProps: { 
                min: filtro.data_inicial !== '' ? filtro.data_inicial : ''
              } }}
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Situação"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="situacao"
              value={filtro.situacao}
              onChangeCapture={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> Todas as Situações </option>
              <option value='APROVADO'> Aprovado </option>
              <option value='REPROVADO'> Reprovado </option>
              <option value='CANCELADO'> Cancelado </option>
              <option value='PROCESSAMENTO'>Em Processamento </option>
              <option value='CONTINGENCIA'>Em Contingencia </option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='number'
              className="input" 
              label="Nº NFSE" 
              variant="outlined" 
              size="small" 
              name="numero" 
              value={filtro.numero} 
              onChange={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" className="mg_top_10">
          <Grid item md={4} xs={12} sm={5}>
            <Autocomplete
              onChange={(event, value) => updateFieldPessoaFiltro(event, value)}
              freeSolo
              options={pessoas.list}
              value={cliente}       
              getOptionLabel={option => option.nome}
              renderInput={(params) => (
                <TextField 
                  InputProps={{ ...params.InputProps, type: 'search' }} 
                  {...params} 
                  size="small" 
                  label="Tomador*" 
                  margin="normal" 
                  variant="outlined" 
                  InputLabelProps={{ shrink: true }} 
                  value={cliente.nome} 
                  onChange={(e) => updateFiltroPessoa(e)}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={1}></Grid>
          <Grid item md={2} xs={12} sm={3}>
            {nfse.list.length > 0 &&
              <Button fullWidth variant="contained" color="secondary" onClick={() => imprimir()}>
                Imprimir
              </Button>
            }
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <Button fullWidth variant="contained" color="primary" onClick={() => filtrar()}>
              Filtrar
            </Button>
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Relatório...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <React.Fragment>
            <NFSE 
              tab={tab}
              handleChange={handleChange}
              cabecalhoTabela={cabecalhoTabela}
              acoesTabela={acoesTabela}
              list_nfse={nfse.list}
              imprimirNFSE={(e) => imprimirNFSE(e)}
              count={rows}
              handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
              handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
              filtro={filtro}
              total_notas_geradas={total_notas_geradas}
              total_vendas_sem_nfse={total_vendas_sem_nfse}
              qtd_notas_emitidas={qtd_notas_emitidas}
            />
          </React.Fragment>
        }
      </Main>
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} />
      
      <ModalExibeNFSE 
        open={state.openModalExibeNfse}
        dados={state.login}
        handleClose={e => handleModalExibeNFSE()} 
        boletoSelecionado={state.boletoSelecionado}
      />

      <ModalCancelarNFSE 
        handleClose={e => handleModalCancelarNFSE()} 
        open={state.openModalCancelarNFSE}
        dadosCancelar={state.dadosCancelar}
        motivosCancelamento={state.motivosCancelamento.list}
        updateFieldCancelamento={(e) => updateFieldCancelamento(e)}
        confirmar={(e) => cancelarNFSE(e)}
      />

      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Relatório NFS-e"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default RelatorioNFSE