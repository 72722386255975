import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import Table from '../../components/Table'
import real from './../../services/real'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '60%',
  marginLeft: '20%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'produto', numeric: false, disablePadding: false, label: 'Serviço' },
  { id: 'duracao', numeric: false, disablePadding: false, label: 'Duracão' },
  { id: 'valor_form', numeric: true, disablePadding: false, label: 'Valor' }
]

const acoesTabela = ['selecionarServico']

export default function SimpleModal(props) {
  const theme = useTheme();
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Selecionar o Produtos do Voucher para Utilizar</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={cabecalhoTabela} 
                rows={props.produtos_voucher.map(value => {
                  return {
                    produto_id: value.produto.id,
                    produto: value.produto.descricao,
                    valor: value.valor,
                    valor_form: 'R$ ' + real(value.valor),
                    unidademedida: value.produto.unidademedida,
                    duracao: value.produto.servico.duracao,
                    itemCombo: value.produto.itemCombo
                  }
                })} 
                acoes={acoesTabela} 
                selecionarPessoa={ e => props.selecionarVoucher(e)}
                servicos_selecionados={props.servicos_selecionados}
                updateFieldSelectServico={(e) => props.updateFieldSelectServico(e)}
                qtdLinhas={props.produtos_voucher.length}
                desabilitaRodape={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={4} xs={false}></Grid>
            <Grid item md={4} xs={12}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false)}>Voltar</Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)}>Continuar</Button>
            </Grid>
          </Grid>
       </StyledPaper>
      </Modal>
    </div>
  );
}
