import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './../Cliente.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import Alert from './../../../components/templates/Alert'
import moment from 'moment'
import ModalMigrarDados from './../../../components/modals/MigrarDados'
import axios from 'axios'
import { format } from 'cnpj'
import CreateIcon from '@mui/icons-material/Create';
import initialState from './../initialState/clienteView';
import getToken from './../../../services/getToken'
import Tabela from './../../../components/Tabela'
import formatarTelefone from './../../../services/formatarTelefone'
const cpf = require('node-cpf-cnpj');

const Cliente = (props) => {
  const [state, setState] = useState({ ...initialState });

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
  
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'success',
        message: 'Link copiado'
      }
    }))
    return
    
  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const migrarDados = () =>{
    setState(prevState => ({...prevState,
      openModalMigrarDados: true
    }))
  }

  const handleModalMigrarDados = () =>{
    setState(prevState => ({...prevState,
      openModalMigrarDados: false,
      clienteSelecionado: {
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      }
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome,
        cpf: '',
        nomefantasia: '',
        cnpj: '',
        flag: ''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/list`, filtroPessoa, getToken())

      const pessoas = []

      data.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: pessoa.tipo === 'FISICA' ? `${pessoa.fisica.nome} ${pessoa.fisica.sobrenome ? pessoa.fisica.sobrenome : '' }` : pessoa.juridica.nomefantasia,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.fisica.cpf ? cpf.format(pessoa.fisica.cpf) : 'N/A') : (pessoa.juridica.cnpj ? format(pessoa.juridica.cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldCliente = async (event, value) => {

    if(!value){
      setState(prevState => ({...prevState,
        clienteSelecionado: {
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    setState(prevState => ({...prevState,
      clienteSelecionado: value
    }))
  }

  const confirmarMigracao = async (value, excluir) => {
    if(value){
      try {
        let dados = {
          de: state.clienteSelecionado.pessoa_id,
          para: state.cliente._id,
          excluir
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Migrando dados...'
          }
        }))

        await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/migracao`,dados, getToken())
        
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Migração Realizada com Sucesso.'
          }
        }))

        handleModalMigrarDados()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleModalMigrarDados()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cliente')[0]
    
      if (permissoes) {
        let acoesTabela = []
  
        if (permissoes.visualizar) {
          acoesTabela.push('viewcliente')
        }
  
        if (permissoes.alterar) {
          acoesTabela.push('update')
        }
        setState(prevState => ({...prevState,
          permissoes,
          acoesTabela
        }))
      }
  
      const cliente = props.cliente
  
      setState(prevState => ({...prevState,
        cliente: cliente,
        dadosComplementaresPF: props.dadosComplementaresPF
      }))
    };
    
    fetchData();
  }, []);

  const { cliente, permissoes, cabecalhoTabelaEndereco, acoesTabelaEndereco, cabecalhoTabelaTelefone, cabecalhoTabelaEmail } = state

  console.log(cliente)

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Dados do cliente...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          {cliente.tipoPessoa === 'FISICA' &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={cliente.pessoaFisica.nome} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Sobrenome" value={cliente.pessoaFisica.sobrenome} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cliente.pessoaFisica.cpf} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={cliente.pessoaFisica.dataNascimento} InputLabelProps={{ shrink: true }}  disabled />
                </Grid>
              </Grid>
            </React.Fragment>
          }
          {cliente.tipoPessoa === 'JURIDICA' &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={cliente.pessoaJuridica.razaoSocial} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantansia" value={cliente.pessoaJuridica.nomeFantasia} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cliente.pessoaJuridica.cnpj || ''} disabled InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={cliente.pessoaJuridica.inscricaoEstadual || ''} disabled InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data Fundação" value={cliente.pessoaJuridica.dataFundacao || ''} disabled InputLabelProps={{ shrink: true }}/>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          {cliente.dadosComplementaresPF &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Dados Complementares: </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                {state.dadosComplementaresPF.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                  if(value.column_name === 'como_conheceu_id'){
                    return (
                      <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                        <TextField 
                          fullWidth 
                          size="small" 
                          variant="outlined" 
                          className="input" 
                          label={value.column_comment} 
                          value={
                            cliente.dadosComplementaresPF.como_conheceu ? cliente.dadosComplementaresPF.como_conheceu.descricao : 'Não Informado'
                          } 
                          disabled 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )
                  }else{
                    return (
                      <Grid key={value.dtd_identifier} item md={3} sm={6} xs={12}>
                        <TextField 
                          fullWidth 
                          size="small" 
                          variant="outlined" 
                          className="input" 
                          label={value.column_comment} 
                          value={
                            cliente.dadosComplementaresPF[value.column_name] 
                            ? (value.data_type === 'date' ? moment(cliente.dadosComplementaresPF[value.column_name]).format('DD/MM/YYYY') : cliente.dadosComplementaresPF[value.column_name]) 
                            : 'Não informado'
                          } 
                          disabled 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )  
                  }
                  
                })}
              </Grid>
            </React.Fragment>
          }
          {cliente.enderecos.length > 0 &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Endereços do cliente: </h4>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <Tabela 
                    headCell={cabecalhoTabelaEndereco} 
                    rows={cliente.enderecos.map(endereco => {
                      return {
                        cep: endereco.cep,
                        endereco: `${endereco.rua}, Nº ${endereco.numero}${endereco.complemento ? ` ${endereco.complemento}` : ''}, ${endereco.bairro}`,
                        cidade: endereco.cidade,
                        estado: endereco.estado,
                        principal: endereco.principal,
                        tipoEndereco: endereco.tipoEndereco,
                        index: endereco.index
                      }
                    })} 
                    acoes={acoesTabelaEndereco}
                  />
                </Grid>
              </Grid>
              {/* {cliente.enderecos.map(endereco => {
                return (
                  <div className="cardEndereco" key={endereco.id}>
                    {endereco.principal &&
                      <h5 className="titulo">Endereço Principal:</h5>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.cep} disabled />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.rua} disabled />
                      </Grid>
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.numero} disabled />
                      </Grid>
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.complemento} disabled InputLabelProps={{ shrink: true }}/>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.bairro} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.cidade} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.estado} disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.tipoEndereco} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })} */}
            </React.Fragment>
          }
          {cliente.contatos.length > 0 &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Telefones do cliente: </h4>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <Tabela 
                    headCell={cabecalhoTabelaTelefone} 
                    rows={cliente.contatos.map(contato => {
                      return {
                        numero: formatarTelefone(contato.numero),
                        tipoContato: contato.tipoContato,
                        principal: contato.principal,
                        index: contato.index
                      }
                    })} 
                    acoes={acoesTabelaEndereco}
                  />
                </Grid>
              </Grid>
              {/* {cliente.contatos.map(telefone => {
                return (
                  <div className="cardEndereco" key={telefone.id}>
                    {telefone.principal &&
                      <h5 className="titulo">Telefone Principal:</h5>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled/>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipoContato} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })} */}
            </React.Fragment>
          }
          {cliente.emails.length > 0 &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">E-mails do cliente: </h4>
                </Grid>
              </Grid>
              <Grid container direction="row" className="mg_top_10">
                <Grid item md={12}>
                  <Tabela 
                    headCell={cabecalhoTabelaEmail} 
                    rows={cliente.emails.map(email => {
                      return {
                        email: email.email,
                        principal: email.principal,
                        index: email.index
                      }
                    })} 
                    acoes={acoesTabelaEndereco}
                  />
                </Grid>
              </Grid>
              {/* {cliente.emails.map(email => {
                return (
                  <div className="cardEndereco" key={email.ema_id}>
                    {email.principal &&
                      <h5 className="titulo">E-mail Principal:</h5>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.email} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })} */}
            </React.Fragment>
          }

          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className="titulo">Acesso do cliente: </h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={4} xs={12} sm={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Link" value={`${window._env_.REACT_APP_URL}/boletos/cliente/${cliente.contrato ? (cliente.contrato.resp_fin.length > 0 ? cliente.contrato.resp_fin[0].pessoa_id : cliente._id) : cliente._id}`} InputLabelProps={{ shrink: true }} disabled />
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              <Tooltip title='Copiar Link' aria-label="add">
                <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => copyLink(`${window._env_.REACT_APP_URL}/boletos/cliente/${cliente.contrato ? (cliente.contrato.resp_fin.length > 0 ? cliente.contrato.resp_fin[0].pessoa_id : cliente._id) : cliente._id}`)}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            {permissoes.alterar &&
              <Grid item md={2} sm={12} xs={3}>
                <Link to={`/cliente/cadastro/${cliente._id}`}>
                  <Button fullWidth size='small' variant="contained" color="primary" startIcon={<CreateIcon />}>
                    Editar cliente
                  </Button>
                </Link>
              </Grid>
            }
            {permissoes.inserir &&
              <Grid item md={3} xs={6} sm={6}>
                <Button size='small' fullWidth variant="contained" color="secondary" onClick={() => migrarDados()}>
                  Importar Dados
                </Button>
              </Grid>
            }
          </Grid>
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalMigrarDados 
        open={state.openModalMigrarDados}
        handleClose={e => handleModalMigrarDados()}
        clientes={state.clientesFiltrado.list}
        cliente={state.clienteSelecionado}
        updateFiltroPessoa={e => updateFiltroPessoa(e)}
        updateFieldCliente={(e, value) => updateFieldCliente(e, value)}
        pessoa={state.cliente}
        confirmar={(e, excluir) => confirmarMigracao(e, excluir)}
      />
    </React.Fragment>
  )
}

export default Cliente
