import React, { useState, useEffect } from 'react';
import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import './Unidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import FormPessoa from '../../components/forms/FormPessoa'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'
import FormUsuario from '../../components/forms/FormUsuario'
import FormContratoFuncionario from '../../components/forms/FormContratoFuncionario'
import FormContaBancaria from '../../components/forms/FormContaBancaria'
import Alert from './../../components/templates/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios'
import { format } from 'cnpj';
import ModalInformarLogoUnidade from './../../components/modals/InformarLogoUnidade'
import {filesize} from "filesize";
import { uniqueId } from 'lodash'
import verificaForcaDaSenha from './../../services/verificaForcaSenha'
import Loading from './../../components/templates/Loading'
import { Link } from 'react-router-dom'
import { styled } from '@mui/system';
import Color from 'color';
import { useNavigate } from 'react-router-dom';
import ModalAjuda from './../../components/modals/Ajuda'

const cpf = require('node-cpf-cnpj');
const cnpj = require('node-cpf-cnpj')

const initialState = {
  unidade: {
    id: "",
    descricao: "",
    ativo: true,
    tipoPessoa: "FISICA",
    franqueado_id: "",
    unidadePrincipal: true,
    importarDadosFuncionario: true,
    num_unidade: '',
    importar_planos: false,
    unidade_plano: '',
    representante_id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: ""
    },
    pessoaJuridica: {
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: ""
    },
    enderecos: [],
    emails: [],
    contatos: []
  },
  unidades: {
    list: []
  },
  franqueados: {
    list: []
  },
  funcionario: {
    id: "",
    dataAdmissao: "",
    dataDemissao: "",
    tipoPessoa: "FISICA",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: ""
    },
    enderecos: [],
    emails: [],
    contatos: [],
    contas: [{
      agencia: "",
      numero: "",
      gerente: "",
      chave_pix: "",
      banco_id: "",
      index: 1,
      banco: {
        id: "",
        nome: "",
        numero: "",
        padraocosif: ""
      }
    }],
    usuario: {
      email_login: "",
      senha_usuario: "",
      repetirSenha: "",
      perfil_id: ""
    },
    ativo: "",
  },
  bancos: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  perfil: {
    id: "",
    descricao: ""
  },
  perfils: {
    list: []
  },
  tipo: 'UNIDADE',
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  disableEnderecoFuncionario: true,
  enableDadosPessoaFuncionario: true,
  enableDadosEnderecoFuncionario: false,
  enableDadosEmailFuncionario: false,
  enableDadosContatoFuncionario: false,
  enableDadosFuncionarioFuncionario: false,
  enableDadosContratoFuncionario: false,
  enableDadosUsuarioFuncionario: false,
  enableDadosContaBancariaFuncionario: false,
  enableUpdate: false,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  showPassword: false,
  showPasswordRepetir: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidadePlano:{
    id: '',
    descricao: '',
    numero: ''
  },
  representantes: {
    list: []
  },
  dadosComplementaresPF: {
    list: []
  },
  metodos_divugacoes: {
    list: []
  },
  openModalInformarLogoUnidade: false,
  deleteLogo: false,
  uploadedFiles: [],
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: ['update', 'remover'],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
  informarEndereco: false,
  informarContato: false,
  informarEmail: false,
  endereco: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contato: {
    numero: "55",
    tipoContato: "RESIDENCIAL",
    principal: true,
    index: ""
  },
  email: {
    email: "",
    principal: true,
    index: ""
  },
  perfis: {
    list: []
  },
  informarEnderecoFuncionario: false,
  informarContatoFuncionario: false,
  informarEmailFuncionario: false,
  enderecoFuncionario: {
    rua: "",
    complemento: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    principal: true,
    tipoEndereco: "RESIDENCIAL",
    index: ""
  },
  contatoFuncionario: {
    numero: "55",
    tipoContato: "RESIDENCIAL",
    principal: true,
    index: ""
  },
  emailFuncionario: {
    email: "",
    principal: true,
    index: ""
  },
  openModalAjuda: false,
  markdown: ''
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function formatarTelefone(str) {
  if(str){
    let texto = str.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

let Cadastro = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/unidade");
    }, timeout)
  }

  const consultaFranqueados = async (representante_id) => {
    try {
      const { data: franqueadosAux } = await axios.get(`${window._env_.REACT_APP_API_URL}/franqueados`, getToken())

      const franqueadosFisica = franqueadosAux.filter(u => u.pessoa.tipo === "FISICA" && u.superior_id === representante_id)
      const franqueadosJuridica = franqueadosAux.filter(u => u.pessoa.tipo === "JURIDICA" && u.superior_id === representante_id)

      const franqueados = []

      franqueadosFisica.forEach(pessoaFisica => {
        franqueados.push({
          _id: pessoaFisica.id,
          franqueado: pessoaFisica.pessoa.fisica.nome + " " + (pessoaFisica.pessoa.fisica.sobrenome ? pessoaFisica.pessoa.fisica.sobrenome : ''),
        })
      });

      franqueadosJuridica.forEach(pessoaJuridica => {
        franqueados.push({
          _id: pessoaJuridica.id,
          franqueado: pessoaJuridica.pessoa.juridica.nomefantasia,
        })
      });

      setState(prevState => ({
        ...prevState,
        franqueados: {
          list: franqueados
        }
      }));
    } catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }));

      backPage()
    }
  }

  const updateField = async (event) => {
    let name = event.target.name
    let value = event.target.value

    if(name === 'representante_id'){
      consultaFranqueados(parseInt(event.target.value))
    }

    const unidade = { ...state.unidade }
    unidade[name] = value
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const updateFieldUnidadePrincipal = () => {
    const {unidade} = state
    unidade.unidadePrincipal = !unidade.unidadePrincipal
    unidade.num_unidade = ''
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const verificaNumUnidade = async (event) => {
    const {unidade} = state
    if(event.target.value !== '' && !unidade.unidadePrincipal){
      try {
        const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/verificaNum/${parseInt(event.target.value)}/${unidade.franqueado_id}`, getToken())
      
        if(data){
          unidade.num_unidade = ''

          setState(prevState => ({
            ...prevState,
            unidade,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O Número informado já pertence a outra unidade.'
            }
          }))
        }
      } catch ({ response }) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: response.data.message
          }
        }))
      }
    }
  }

  const updateFieldImportarPlanos = () => {
    const unidade = state.unidade
    unidade.importar_planos = !unidade.importar_planos
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const updateFieldUnidadePlano = (event, value) => {
    if (!value) return false
    const unidade = { ...state.unidade }
    unidade.unidade_plano = value.id

    setState(prevState => ({
      ...prevState,
      unidadePlano: {
        id: value.id,
        descricao: value.descricao,
        numero: value.numero
      },
      unidade
    }));
  }

  const updateFieldPessoaFisica = (event) => {
    const unidade = { ...state.unidade }
    
    unidade.pessoaFisica[event.target.name] = event.target.value
    
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const updateFieldPessoaJuridica = (event) => {
    const unidade = { ...state.unidade }
    
    unidade.pessoaJuridica[event.target.name] = event.target.value
    
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const validarCpfCnpj = async (event) => {
    let cpf_cnpj = event.target.value
    const {unidade} = state

    if (unidade.tipoPessoa === "FISICA") {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {

        unidade.pessoaFisica.cpf = ""
        setState(prevState => ({
          ...prevState,
          unidade,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Invalido! Por favor corrija o campo CPF'
          }
        }));
      } else {
        unidade.pessoaFisica.cpf = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          unidade
        }));
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        unidade.pessoaJuridica.cnpj = cpf_cnpj
        setState(prevState => ({
          ...prevState,
          unidade
        }));

      } else {
        unidade.pessoaJuridica.cnpj = ""
        setState(prevState => ({
          ...prevState,
          unidade,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CNPJ Invalido! Por favor corrija o campo CNPJ'
          }
        }));
      }
    }
  }

  const informarEndereco = () => {
    const { unidade } = state;
    let principal = !unidade.enderecos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEndereco: true,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }));
  }

  const pegaEndereco = async (event, index) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { endereco } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco.numero,
        complemento: endereco.complemento,
        cep: formatarCEP(endereco.cep),
        principal: endereco.principal,
        tipoEndereco: endereco.tipoEndereco,
        index: endereco.index
      };
  
      setState(prevState => ({
        ...prevState,
        endereco: novoEndereco
      }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: endereco.complemento,
          rua: resp.logradouro,
          numero: endereco.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({
          ...prevState,
          endereco: enderecoEncontrado,
          disableEndereco: true,
          modalErro: false
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco.numero,
          cep: "",
          complemento: endereco.complemento,
          principal: endereco.principal,
          tipoEndereco: endereco.tipoEndereco,
          index: endereco.index
        };
  
        setState(prevState => ({
          ...prevState,
          endereco: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const updateFieldEndereco = (event, index) => {
    let { endereco } = state
    endereco[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const updateFieldEnderecoPrincipal = (event, index) => {
    let { endereco, unidade } = state
    const indexAux = unidade.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== endereco.index)) {
      endereco.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui endereço principal'
        }
      }))
    }else{
      endereco.principal = !endereco.principal
    }

    setState(prevState => ({
      ...prevState,
      endereco
    }));
  }

  const addNovoEndereco = () => {
    const { endereco, unidade } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        endereco.cep !== "" &&
        endereco.rua !== "" &&
        endereco.bairro !== "" &&
        endereco.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços da unidade
    const novosEnderecos = [...unidade.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (endereco.index === "") {
      endereco.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...endereco });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[endereco.index - 1] = { ...endereco };
    }
  
    // Atualize os endereços da unidade
    unidade.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({
      ...prevState,
      unidade,
      endereco: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEndereco: false,
      alerta: { open: false }
    }));
  }

  const editarEndereco = (row) => {
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      endereco: {...unidade.enderecos[row.index - 1]},
      informarEndereco: true
    }));
  }

  const removeEnderecoUnidade = (index) => {
    const {unidade} = state
    const enderecos = unidade.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.enderecos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const informarContato = () => {
    const { unidade } = state;
    let principal = !unidade.contatos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarContato: true,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }));
  }

  const updateFieldContato = (event, index) => {
    let { contato } = state
    contato[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const updateFieldContatoPrincipal = (event, index) => {
    let { contato, unidade } = state
    const indexAux = unidade.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contato.index)) {
      contato.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui contato principal'
        }
      }));
    }else{
      contato.principal = !contato.principal
    }

    setState(prevState => ({
      ...prevState,
      contato
    }));
  }

  const addNovoContato = () => {
    let {contato, unidade} = state

    let valido = true

    if (contato.numero === "") valido = false
    if (contato.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }));
      return
    }

    const contatos = unidade.contatos

    if(contato.index === ""){
      contato.index = contatos.length + 1

      contatos.push({
        ...contato
      })
    }else{
      contatos[contato.index - 1] = {
        ...contato
      }
    }

    unidade.contatos = contatos

    setState(prevState => ({
      ...prevState,
      unidade,
      contato: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContato: false
    }));
  }

  const editarContato = (row) =>{
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      contato: {...unidade.contatos[row.index - 1]},
      informarContato: true
    }));
  }

  const removeContatoUnidade = (index) => {
    const {unidade} = state
    const contatos = unidade.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.contatos = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const informarEmail = () => {
    const { unidade } = state;
    let principal = !unidade.emails.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEmail: true,
      email: {
        email: "",
        principal,
        index: ""
      }
    }));
  }

  const updateFieldEmail = (event, index) => {
    let { email } = state
    email[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      email
    }));
  }

  const updateFieldEmailPrincipal = (event, index) => {
    let { email, unidade } = state
    const indexAux = unidade.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== email.index)) {
      email.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse unidade já possui email principal'
        }
      }));
    }else{
      email.principal = !email.principal
    }

    setState(prevState => ({
      ...prevState,
      email
    }));
  }

  const addNovoEmail = () => {
    let {email, unidade} = state

    let valido = true

    if (email.email === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }));
      return
    }

    if (!validacaoEmail(email.email)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do unidade é inválido!'
        }
      }));
      return
    }

    const emails = unidade.emails

    if(email.index === ""){
      email.index = emails.length + 1

      emails.push({
        ...email
      })
    }else{
      emails[email.index - 1] = {
        ...email
      }
    }

    unidade.emails = emails

    setState(prevState => ({
      ...prevState,
      unidade,
      email: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmail: false
    }));
  }

  const editarEmail = (row) =>{
    let {unidade} = state

    setState(prevState => ({
      ...prevState,
      email: {...unidade.emails[row.index - 1]},
      informarEmail: true
    }));
  }

  const removeEmailUnidade = (index) => {
    const unidade = { ...state.unidade }
    const emails = unidade.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.emails = aux
    setState(prevState => ({
      ...prevState,
      unidade
    }));
  }

  const validarDadosPessoa = () => {
    const {unidade} = state

    if (unidade.representante_id === "") {
      return {
        valido: false,
        mensagem: 'Por favor informar o representante da unidade.'
      }
    }

    if(!unidade.unidadePrincipal){
      if (unidade.franqueado_id === "") {
        return {
          valido: false,
          mensagem: 'Por favor informar o grupo econômico da unidade.'
        }
      }
    }
    
    if(unidade.descricao === ""){
      return {
        valido: false,
        mensagem: 'Por favor informar a descrição da unidade.'
      }
    }
    
    if (unidade.tipoPessoa === "FISICA") {
      if (
        unidade.pessoaFisica.nome === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o nome da pessoa unidade.'
        }
      }

      if (
        unidade.pessoaFisica.dataNascimento === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar a data de nascimento da unidade.'
        }
      }

      if (
        unidade.pessoaFisica.cpf === ""
      ) {
        return {
          valido: false,
          mensagem: 'Por favor informar o CPF da unidade.'
        }
      }

      let cpf_cnpj = unidade.pessoaFisica.cpf

      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {
        if(cpf_cnpj !== '000.000.000-00'){
          return {
            valido: false,
            mensagem: 'CPF Inválido! Por favor corrija o campo CPF'
          }
        }
      }

      setState(prevState => ({
        ...prevState,
        unidade
      }));
    }else{
      if (
        unidade.pessoaJuridica.razaoSocial === "" ||
        unidade.pessoaJuridica.nomeFantasia === ""
      ) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    if(unidade.enderecos.length > 0){
      let valido = true
      unidade.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
        if (endereco.numero === "") valido = false
      })
  
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }
    
    if(unidade.contatos.length > 0){
      unidade.contatos.forEach(contato => {
        if (contato.numero === "") {
          return {
            valido: false,
            mensagem: `Por favor informar o contato da unidade.`
          }
        }
      })
    }

    if(unidade.emails.length > 0){
      let valido = true
      unidade.emails.forEach(email => {
        if (email.email === "") valido = false
      })
      if (!valido) {
        return {
          valido: false,
          mensagem: `Existem campo obrigatorios vazios(*).`
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      unidade
    }));

    return {
      valido: true,
      mensagem: ''
    }
  }

  const buscaPerfis = async () => {

    let tipoUnidade = 'GRUPOECONOMICO'

    let params = `visibilidade=${tipoUnidade}`

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?${params}`, getToken())

      setState(prevState => ({
        ...prevState,
        perfis: {
          list: data
        }
      }));
    }catch (error) {
      console.log(error)
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }));

      backPage()
    }
  }

  const informarFuncionario = async () => {
    const {unidade} = state

    let validacao = await validarDadosPessoa()

    if(!validacao.valido){
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: validacao.mensagem
        }
      }));
      return
    }

    buscaPerfis()

    setState(prevState => ({
      ...prevState,
      unidade,
      enableDadosPessoa: false,
      enableDadosFuncionario: true,
    }));
  }
  
  const updateFieldPerfil = (event, value) => {
    if (!value) return false

    const funcionario = { ...state.funcionario }
    funcionario.usuario.perfil_id = value.id

    setState(prevState => ({
      ...prevState,
      funcionario,
      perfil: {
        id: value.id,
        descricao: value.descricao
      }
    }));
  }

  const updateFieldUsuario = async (event) => {
    const funcionario = { ...state.funcionario }
    funcionario.usuario[event.target.name] = event.target.value

    if (event.target.name === 'senha_usuario') {
      const resp = await verificaForcaDaSenha(event.target.value)
      setState(prevState => ({
        ...prevState,
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      }));
    }

    setState(prevState => ({
      ...prevState,
      funcionario
    }));
  }

  const handleClickShowPassword = () => {
    setState(prevState => ({
      ...prevState,
      showPassword: !state.showPassword
    }));
  }

  const handleClickShowPasswordRepetir = () => {
    setState(prevState => ({
      ...prevState,
      showPasswordRepetir: !state.showPasswordRepetir
    }));
  }

  const handleCloseModalInformarLogoUnidade = () =>{
    setState(prevState => ({
      ...prevState,
      openModalInformarLogoUnidade: false
    }));
  }

  const handleUploadImage = (files) => {

    if (state.deleteLogo) {

      const id = state.uploadedFiles[0].id

      setState(prevState => ({
        ...prevState,
        uploadedFiles: []
      }));

      const uploadedFiles = files.map(file => ({
        file,
        id,
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        titulo: "",
        link: "",
        uploaded: false,
        error: false,
        url: null,
      }))

      concatUploadedFiles(uploadedFiles)

    } else {
      setState(prevState => ({
        ...prevState,
        uploadedFiles: []
      }));

      const uploadedFiles = files.map(file => ({
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        titulo: "",
        link: "",
        uploaded: false,
        error: false,
        url: null,
      }))

      concatUploadedFiles(uploadedFiles)
    }
  }

  const handleCloseAlerta =()=>{
    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }));
  }

  const addNovaContaBancariaFuncionario = () => {
    const {funcionario} = state
    const contas = funcionario.contas

    const index = contas.length + 1

    contas.push({
      agencia: "",
      numero: "",
      gerente: "",
      chave_pix: "",
      banco_id: "",
      banco: {
        nome: "",
        numero: "",
        padraocosif: ""
      },
      index
    })

    funcionario.contas = contas
    setState(prevState => ({
      ...prevState,
      funcionario
    }));
  }

  const removerConta = (index) => {
    const {funcionario} = state
    const contas = funcionario.contas
    const aux = contas

    let array = []

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
      array = aux
      for (let i = 0; i < aux.length; i++) {
        array[i].index = i + 1
      }
    } else {
      return false
    }

    funcionario.contas = array
    setState(prevState => ({
      ...prevState,
      funcionario
    }));
  }

  const updateFieldBanco = (event, value, index) => {
    if (!value) return false

    const funcionario = { ...state.funcionario }
    funcionario.contas[index - 1].banco = value
    funcionario.contas[index - 1].banco_id = value.id
    setState(prevState => ({
      ...prevState,
      funcionario
    }));
  }

  const updateFieldContaBancariaFuncionario =(event, index) => {
    const funcionario = { ...state.funcionario }
    funcionario.contas[index - 1][event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      funcionario
    }));
  }

  const updateFieldContratoFuncionario = async (event) => {
    if (!event.target === undefined) return false
    const {funcionario} = state

    if (event.target && event.target.name === "idDepartamento") {
      if (funcionario.contrato.departamento === parseInt(event.target.value)) return false

      try {
        funcionario.contrato.idDepartamento = parseInt(event.target.value)
        funcionario.contrato.departamento = state.departamentos.list.filter(u => u.id === parseInt(event.target.value))[0].descricao

        const { data: cargos } = await axios.get(`${window._env_.REACT_APP_API_URL}/cargos/departamento/${parseInt(event.target.value)}`, getToken())

        setState(prevState => ({
          ...prevState,
          funcionario,
          cargosFiltrado: {
            list: cargos.filter(u => u.ativo === true)
          }
        }));
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
  
        backPage()
      }
    }

    if (event.target && event.target.name === "idCargo") {
      if (funcionario.contrato.cargo === parseInt(event.target.value)) return false

      try {
        funcionario.contrato.idCargo = parseInt(event.target.value)
        funcionario.contrato.cargo = state.cargosFiltrado.list.filter(u => u.id === parseInt(event.target.value))[0].descricao

        const { data: funcoes } = await axios.get(`${window._env_.REACT_APP_API_URL}/funcoes/cargo/${parseInt(event.target.value)}`, getToken())

        setState(prevState => ({
          ...prevState,
          funcionario,
          funcaosFiltrado: {
            list: funcoes.filter(u => u.ativo === true)
          }
        }));
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }));
  
        backPage()
      }
    }

    if (event.target && event.target.name === "idFuncao") {
      if (funcionario.contrato.funsao === parseInt(event.target.value)) return false

      funcionario.contrato.idFuncao = parseInt(event.target.value)
      funcionario.contrato.funcao = state.funcaosFiltrado.list.filter(u => u.id === parseInt(event.target.value))[0].descricao
      setState(prevState => ({
        ...prevState,
        funcionario
      }));
    }

    if (event.target && event.target.name === "observacao") {
      funcionario.contrato.observacao = event.target.value
      setState(prevState => ({
        ...prevState,
        funcionario
      }));
    }

    if (event.target && event.target.name === "dataInicial") {
      funcionario.contrato.dataInicial = event.target.value
      setState(prevState => ({
        ...prevState,
        funcionario
      }));
    }
  }

  const concatUploadedFiles = (uploadedFiles) => {
    setState(prevState => ({
      ...prevState,
      uploadedFiles: state.uploadedFiles.concat(uploadedFiles)
    }));
  }

  const voltar = (atual, anterior) => {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      setState(prevState => ({
        ...prevState,
        enableDadosPessoa: true,
        enableDadosEndereco: false
      }));
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      setState(prevState => ({
        ...prevState,
        enableDadosEndereco: true,
        enableDadosContato: false
      }));
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      setState(prevState => ({
        ...prevState,
        enableDadosContato: true,
        enableDadosEmail: false
      }))
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosEmail: true,
        enableDadosFuncionario: false
      }))
    }

    if (anterior === "enableDadosPessoaFuncionario" && atual === "enableDadosEnderecoFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosPessoaFuncionario: true,
        enableDadosEnderecoFuncionario: false
      }))
    } else if (anterior === "enableDadosEnderecoFuncionario" && atual === "enableDadosContatoFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosEnderecoFuncionario: true,
        enableDadosContatoFuncionario: false
      }))
    } else if (anterior === "enableDadosContatoFuncionario" && atual === "enableDadosEmailFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosContatoFuncionario: true,
        enableDadosEmailFuncionario: false
      }))
    } else if (anterior === "enableDadosEmailFuncionario" && atual === "enableDadosContratoFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosEmailFuncionario: true,
        enableDadosContratoFuncionario: false,
      }))
    } else if (anterior === "enableDadosContratoFuncionario" && atual === "enableDadosContaBancariaFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosContratoFuncionario: true,
        enableDadosContaBancariaFuncionario: false,
      }))
    } else if (anterior === "enableDadosContaBancariaFuncionario" && atual === "enableDadosUsuarioFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosContaBancariaFuncionario: true,
        enableDadosUsuarioFuncionario: false,
      }))
    }

  }

  const proximo = (atual, prox) => {
    const { unidade, funcionario } = state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      if (unidade.representante_id === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o representante da unidade.'
          }
        }))
        return false
      }

      if(!unidade.unidadePrincipal){
        if (unidade.franqueado_id === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o grupo econômico da unidade.'
            }
          }))
          return false
        }
      }

      if (unidade.num_unidade === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o número da unidade.'
          }
        }))
        return false
      }

      if (unidade.descricao === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor preencher a descrição da unidade.'
          }
        }))
        return false
      }

      if (unidade.importar_planos) {
        if (unidade.unidade_plano === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor preencher a unidade do plano de contas.'
            }
          }))
          return false
        }
      }
    
      if (unidade.tipoPessoa === "FISICA") {
        if (unidade.pessoaFisica.nome === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o nome da pessoa fisica.'
            }
          }))
          return false
        }
        if (unidade.pessoaFisica.cpf === "") {
          setState(prevState => ({
            ...prevState,
          alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o cpf da pessoa fisica.'
            }
          }))
          return false
        }
        if (unidade.pessoaFisica.dataNascimento === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento.'
            }
          }))
          return false
        }

      } else {
        if (unidade.pessoaJuridica.razaoSocial === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a razão social.'
            }
          }))
          return false
        }
        if (unidade.pessoaJuridica.nomeFantasia === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o nome fantasia.'
            }
          }))
          return false
        }
        if (unidade.pessoaJuridica.cnpj === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o CNPJ.'
            }
          }))
          return false
        }
        if (unidade.pessoaJuridica.inscricaoEstadual === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a inscrição estadual.'
            }
          }))
          return false
        }
        if (unidade.pessoaJuridica.dataFundacao === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de fundação.'
            }
          }))
          return false
        }
      }

      setState(prevState => ({
        ...prevState,
        enableDadosPessoa: false,
        enableDadosEndereco: true
      }))
    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      unidade.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o CEP.'
            }
          }))
          return false
        }
        if (endereco.numero === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o número.'
            }
          }))
          return false
        }
        if (endereco.rua === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a rua.'
            }
          }))
          return false
        }
        if (endereco.rua === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a rua.'
            }
          }))
          return false
        }
      })

      setState(prevState => ({
        ...prevState,
        enableDadosEndereco: false,
        enableDadosContato: true
      }))
    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      unidade.contatos.forEach(contato => {
        if (contato.numero === '') {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o número do contato.'
            }
          }))
          return false
        }
      });

      buscaPerfis()

      setState(prevState => ({
        ...prevState,
        enableDadosContato: false,
        enableDadosEmail: true
      }))
    } else if (atual === "enableDadosEmail" && prox === "enableDadosFuncionario") {
      unidade.emails.forEach(email => {
        if (email.email === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o email.'
            }
          }))
          return false
        }
      });      

      setState(prevState => ({
        ...prevState,
        enableDadosEmail: false,
        enableDadosFuncionario: true
      }))
    }

    if (atual === "enableDadosPessoaFuncionario" && prox === "enableDadosEnderecoFuncionario") {
      if (funcionario.pessoaFisica.nome === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o nome.'
          }
        }))
        return false
      }
      if (funcionario.pessoaFisica.cpf === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o CPF.'
          }
        }))
        return false
      }
      if (funcionario.pessoaFisica.dataNascimento === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data de nascimento.'
          }
        }))
        return false
      }

      setState(prevState => ({
        ...prevState,
        enableDadosPessoaFuncionario: false,
        enableDadosEnderecoFuncionario: true
      }))

    } else if (atual === "enableDadosEnderecoFuncionario" && prox === "enableDadosContatoFuncionario") {
      funcionario.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento.'
            }
          }))
          return false
        }
        if (endereco.numero === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento.'
            }
          }))
          return false
        }
        if (endereco.rua === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento.'
            }
          }))
          return false
        }
        if (endereco.numero === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento.'
            }
          }))
          return false
        }
        if (endereco.rua === "") {
          setState(prevState => ({
            ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data de nascimento.'
          }
        }))
        return false
        }
      });

      setState(prevState => ({
        ...prevState,
        enableDadosEnderecoFuncionario: false,
        enableDadosContatoFuncionario: true
      }))
    } else if (atual === "enableDadosContatoFuncionario" && prox === "enableDadosEmailFuncionario") {
      funcionario.contatos.forEach(contato => {
        if (!contato.numero) {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o número de contato.'
            }
          }))
          return false
        }
      });
      setState(prevState => ({
        ...prevState,
        enableDadosContatoFuncionario: false,
        enableDadosEmailFuncionario: true
      }))
    } else if (atual === "enableDadosEmailFuncionario" && prox === "enableDadosContratoFuncionario") {
      funcionario.emails.forEach(email => {
        if (email.email === "") {
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o email.'
            }
          }))
          return false
        }
      })

      setState(prevState => ({
        ...prevState,
        enableDadosEmailFuncionario: false,
        enableDadosContratoFuncionario: true
      }))
    } else if (atual === "enableDadosContratoFuncionario" && prox === "enableDadosContaBancariaFuncionario") {
      if (funcionario.funcao === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a função.'
          }
        }))
        return false
      }
      if (funcionario.dataInicial === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data inicial.'
          }
        }))
        return false
      }

      buscaPerfis()

      setState(prevState => ({
        ...prevState,
        enableDadosContratoFuncionario: false,
        enableDadosContaBancariaFuncionario: true,
      }))
      
    } else if (atual === "enableDadosContaBancariaFuncionario" && prox === "enableDadosUsuarioFuncionario") {
      setState(prevState => ({
        ...prevState,
        enableDadosContaBancariaFuncionario: false,
        enableDadosUsuarioFuncionario: true
      }))
    }
  }

  const updateFieldPessoaFisicaFuncionario = (event) => {
    const funcionario = { ...state.funcionario }
    funcionario.pessoaFisica[event.target.name] = event.target.value
    setState(prevState => ({...prevState, funcionario }))
  }

  const updateFieldEnderecoFuncionario = (event, index) => {
    let { enderecoFuncionario } = state
    enderecoFuncionario[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      enderecoFuncionario
    }));
  }

  const updateFieldEmailFuncionario = (event, index) => {
    let { emailFuncionario } = state
    emailFuncionario[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      emailFuncionario
    }));
  }

  const updateFieldContatoFuncionario = (event, index) => {
    let { contatoFuncionario } = state
    contatoFuncionario[event.target.name] = event.target.value
    setState(prevState => ({
      ...prevState,
      contatoFuncionario
    }));
  }

  const validarCpfCnpjFuncionario = async (event) => {

    let cpf_cnpj = event.target.value
    const funcionario = state.funcionario
    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {

      funcionario.pessoaFisica.cpf = ""
      setState(prevState => ({
        ...prevState,
        funcionario,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF Invalido! Por favor corrija o campo CPF'
        }
      }));
    } else {
      funcionario.pessoaFisica.cpf = cpf_cnpj
      setState(prevState => ({
        ...prevState,
        funcionario
      }));
    }
  }

  const informarEnderecoFuncionario = () => {
    const { funcionario } = state;
    let principal = !funcionario.enderecos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEnderecoFuncionario: true,
      enderecoFuncionario: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
    }));
  }

  const pegaEnderecoFuncionario = async (event, index) => {
    const valor = event.target.value.trim();
  
    if (valor === "") return false;
  
    if (event.target.name === "cep") {
      const { enderecoFuncionario } = state;
  
      const novoEndereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecoFuncionario.numero,
        complemento: enderecoFuncionario.complemento,
        cep: formatarCEP(enderecoFuncionario.cep),
        principal: enderecoFuncionario.principal,
        tipoEndereco: enderecoFuncionario.tipoEndereco,
        index: enderecoFuncionario.index
      };
  
      setState(prevState => ({
        ...prevState,
        enderecoFuncionario: novoEndereco
      }));
  
      try {
        const resp = await getEndereco(valor);
  
        const enderecoEncontrado = {
          cep_id: resp.id,
          complemento: enderecoFuncionario.complemento,
          rua: resp.logradouro,
          numero: enderecoFuncionario.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(valor),
          principal: enderecoFuncionario.principal,
          tipoEndereco: enderecoFuncionario.tipoEndereco,
          index: enderecoFuncionario.index
        };
  
        setState(prevState => ({
          ...prevState,
          enderecoFuncionario: enderecoEncontrado,
          disableEndereco: true
        }));
      } catch (error) {
        console.error(error);
  
        const enderecoNaoEncontrado = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecoFuncionario.numero,
          cep: "",
          complemento: enderecoFuncionario.complemento,
          principal: enderecoFuncionario.principal,
          tipoEndereco: enderecoFuncionario.tipoEndereco,
          index: enderecoFuncionario.index
        };
  
        setState(prevState => ({
          ...prevState,
          enderecoFuncionario: enderecoNaoEncontrado,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CEP não encontrado'
          },
          disableEndereco: false
        }));
      }
    }
  }

  const updateFieldEnderecoPrincipalFuncionario = () => {
    let { enderecoFuncionario, funcionario } = state
    const indexAux = funcionario.enderecos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== enderecoFuncionario.index)) {
      enderecoFuncionario.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui endereço principal'
        }
      }));
    }else{
      enderecoFuncionario.principal = !enderecoFuncionario.principal
    }

    setState(prevState => ({
      ...prevState,
      enderecoFuncionario
    }));
  }

  const addNovoEnderecoFuncionario = () => {
    const { enderecoFuncionario, funcionario } = state;
  
    // Função para verificar se todos os campos obrigatórios estão preenchidos
    const validarCamposObrigatorios = () => {
      return (
        enderecoFuncionario.cep !== "" &&
        enderecoFuncionario.rua !== "" &&
        enderecoFuncionario.bairro !== "" &&
        enderecoFuncionario.numero !== ""
      );
    };
  
    // Se algum campo obrigatório estiver vazio, exiba um alerta e retorne
    if (!validarCamposObrigatorios()) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campos obrigatórios vazios (*).'
        }
      }));
      return;
    }
  
    // Crie uma cópia dos endereços do funcionario
    const novosEnderecos = [...funcionario.enderecos];
  
    // Se o índice do endereço for vazio, é um novo endereço
    if (enderecoFuncionario.index === "") {
      enderecoFuncionario.index = novosEnderecos.length + 1;
      novosEnderecos.push({ ...enderecoFuncionario });
    } else {
      // Caso contrário, atualize o endereço existente
      novosEnderecos[enderecoFuncionario.index - 1] = { ...enderecoFuncionario };
    }
  
    // Atualize os endereços do funcionario
    funcionario.enderecos = novosEnderecos;
  
    // Limpe o estado do endereço e defina a informação de endereço como false
    setState(prevState => ({
      ...prevState,
      funcionario,
      enderecoFuncionario: {
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        principal: false,
        tipoEndereco: "RESIDENCIAL",
        index: ""
      },
      informarEnderecoFuncionario: false,
      alerta: {
        open: false,
        severity: 'warning',
        message: ''
      }
    }));
  }

  const addNovoContatoFuncionario = () => {
    let {contatoFuncionario, funcionario} = state

    let valido = true

    if (contatoFuncionario.numero === "") valido = false
    if (contatoFuncionario.tipoContato === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }));
      return
    }

    const contatos = funcionario.contatos

    if(contatoFuncionario.index === ""){
      contatoFuncionario.index = contatos.length + 1

      contatos.push({
        ...contatoFuncionario
      })
    }else{
      contatos[contatoFuncionario.index - 1] = {
        ...contatoFuncionario
      }
    }

    funcionario.contatos = contatos

    setState(prevState => ({
      ...prevState,
      funcionario,
      contatoFuncionario: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal: false,
        index: ""
      },
      informarContatoFuncionario: false,
      alerta: {
        open: false,
        severity: 'warning',
        message: ''
      }
    }));
  }

  const addNovoEmailFuncionario = () => {
    let {emailFuncionario, funcionario} = state

    let valido = true

    if (emailFuncionario.email === "") valido = false

    if (!valido) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Existem campo obrigatorios vazios(*).'
        }
      }))
      return
    }

    if (!validacaoEmail(emailFuncionario.email)) {
      setState(prevState => ({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Email do funcionario é inválido!'
        }
      }));
      return
    }

    const emails = funcionario.emails

    if(emailFuncionario.index === ""){
      emailFuncionario.index = emails.length + 1

      emails.push({
        ...emailFuncionario
      })
    }else{
      emails[emailFuncionario.index - 1] = {
        ...emailFuncionario
      }
    }

    funcionario.emails = emails

    setState(prevState => ({
      ...prevState,
      alerta: {
        open: false,
        severity: 'warning',
        message: ''
      },
      funcionario,
      emailFuncionario: {
        email: "",
        principal: false,
        index: ""
      },
      informarEmailFuncionario: false
    }));
  }

  const editarEnderecoFuncionario = (row) =>{
    let {funcionario} = state

    setState(prevState => ({
      ...prevState,
      enderecoFuncionario: {...funcionario.enderecos[row.index - 1]},
      informarEnderecoFuncionario: true
    }));
  }

  const removeEnderecoFuncionario = (index) => {
    const {funcionario} = state
    const enderecos = funcionario.enderecos
    let aux = enderecos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    funcionario.enderecos = aux
    setState(prevState => ({...prevState, funcionario }))
  }

  const removeContatoFuncionario = (index) => {
    const {funcionario} = state
    const contatos = funcionario.contatos
    const aux = contatos

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    funcionario.contatos = aux
    setState(prevState => ({...prevState, funcionario }))
  }

  const removeEmailFuncionario = (index) => {
    const {funcionario} = state
    const emails = funcionario.emails
    const aux = emails

    if (aux.length > 0) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    funcionario.emails = aux
    setState(prevState => ({...prevState, funcionario }))
  }

  const informarContatoFuncionario = () => {
    const { funcionario } = state;
    let principal = !funcionario.contatos.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarContatoFuncionario: true,
      contatoFuncionario: {
        numero: "55",
        tipoContato: "RESIDENCIAL",
        principal,
        index: ""
      },
    }));
  }

  const updateFieldContatoPrincipalFuncionario = (event, index) => {
    let { contatoFuncionario, funcionario } = state
    const indexAux = funcionario.contatos.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== contatoFuncionario.index)) {
      contatoFuncionario.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui contato principal'
        }
      }))
    }else{
      contatoFuncionario.principal = !contatoFuncionario.principal
    }

    setState(prevState => ({
      ...prevState,
      contatoFuncionario
    }))
  }

  const updateFieldEmailPrincipalFuncionario = (event, index) => {
    let { emailFuncionario, funcionario } = state
    const indexAux = funcionario.emails.findIndex(x => x.principal)

    if (indexAux !== -1 && (indexAux + 1 !== emailFuncionario.index)) {
      emailFuncionario.principal = false

      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          autoHideDuration: 3000,
          message: 'Esse funcionario já possui email principal'
        }
      }))
    }else{
      emailFuncionario.principal = !emailFuncionario.principal
    }

    setState(prevState => ({
      ...prevState,
      emailFuncionario
    }))
  }

  const editarContatoFuncionario = (row) => {
    let {funcionario} = state

    setState(prevState => ({
      ...prevState,
      contatoFuncionario: {...funcionario.contatos[row.index - 1]},
      informarContatoFuncionario: true
    }))
  }

  const editarEmailFuncionario = (row) => {
    let {funcionario} = state

    setState(prevState => ({
      ...prevState,
      emailFuncionario: {...funcionario.emails[row.index - 1]},
      informarEmailFuncionario: true
    }))
  }

  const informarEmailFuncionario = () => {
    const { funcionario } = state;
    let principal = !funcionario.emails.some(param => param.principal);

    setState(prevState => ({
      ...prevState,
      informarEmailFuncionario: true,
      emailFuncionario: {
        email: "",
        principal,
        index: ""
      },
    }));
  }

  const formataTelefoneFuncionario = (event, index) => {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const {funcionario} = state 

    if (!formatarTelefone(telefoneSemFormatacao)) {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'O campo de telefone está incorreto!.'
        }
      }))
    }

    funcionario.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    setState(prevState => ({...prevState, funcionario }))
  }

  const salvar = async (validarFuncionario) => {
    const { unidade, funcionario, professor, aulasSeleciondas } = state
    let valido = true

    if (unidade.unidadePrincipal && validarFuncionario) {
      if (funcionario.usuario.email_login === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar cadastrar usuário.'
          }
        }))
        return
      }

      if (funcionario.usuario.senha_usuario !== "" && funcionario.usuario.repetirSenha === "") {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios.'
          }
        }))
        return
      }

      if (funcionario.usuario.senha_usuario !== funcionario.usuario.repetirSenha) {
        
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Senhas não conferem, por favor verificar as senhas.'
          }
        }))
        return
      }

      if (!validacaoEmail(funcionario.usuario.email_login)) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Email Invalido!'
          }
        }))
        return
      }
    }

    if (valido) {

      setState(prevState => ({
        ...prevState,
        loading: true,
        mensagemLoading: 'Cadastranda Unidade...'
      }))

      let fisica = {}
      let juridica = {}

      if (unidade.tipoPessoa === "FISICA") {
        fisica = {
          nome: unidade.pessoaFisica.nome,
          cpf: cpf.strip(unidade.pessoaFisica.cpf),
          rg: unidade.pessoaFisica.rg,
          datanascimento: unidade.pessoaFisica.dataNascimento,
          rg_orgao_emissor: unidade.pessoaFisica.rg_orgao_emissor,
          rg_uf: unidade.pessoaFisica.rg_uf,
          rg_data_emissao: unidade.pessoaFisica.rg_data_emissao,
        }
      } else {
        juridica = {
          razaosocial: unidade.pessoaJuridica.razaoSocial,
          nomefantasia: unidade.pessoaJuridica.nomeFantasia,
          inscricaoestadual: unidade.pessoaJuridica.inscricaoEstadual,
          datafundacao: unidade.pessoaJuridica.dataFundacao,
          cnpj: cnpj.strip(unidade.pessoaJuridica.cnpj)
        }
      }

      let enderecos = []
      let emails = []
      let telefones = []

      unidade.enderecos.forEach(endereco => {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      });

      unidade.emails.forEach(email => {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      });

      unidade.contatos.forEach(telefone => {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      });

      let responsavel = {}

      let dados = {}
      let data = new FormData();

      if (unidade.unidadePrincipal && validarFuncionario) {
        if (unidade.tipoPessoa === "FISICA") {
          responsavel = {
            usuario: {
              perfil_id: funcionario.usuario.perfil_id,
              login: funcionario.usuario.email_login,
              senha: funcionario.usuario.senha_usuario
            }
          }
        } else {

          let auxProfessor = {}

          if (funcionario.contrato.cargo === "Professor") {
            auxProfessor = {
              ...professor,
              aulas: aulasSeleciondas.map(aula => {
                return {
                  aula_id: aula.aula_id
                }
              })
            }

            responsavel = {
              nome: funcionario.pessoaFisica.nome,
              cpf: funcionario.pessoaFisica.cpf,
              rg: funcionario.pessoaFisica.rg,
              rg_uf: funcionario.pessoaFisica.rg_uf,
              rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
              rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
              datanascimento: funcionario.pessoaFisica.dataNascimento,
              unidadetrabalho_id: funcionario.unidadetrabalho_id,
              contt_funcao_id: funcionario.contrato.idFuncao,
              data_admissao: funcionario.contrato.dataInicial,
              datainicio: funcionario.contrato.dataInicial,
              observacao: funcionario.contrato.observacao,
              professor: auxProfessor,
              enderecos,
              emails,
              telefones,
              usuario: {
                perfil_id: funcionario.usuario.perfil_id,
                login: funcionario.usuario.email_login,
                senha: funcionario.usuario.senha_usuario
              }
            }
          }else{
            responsavel = {
              nome: funcionario.pessoaFisica.nome,
              cpf: funcionario.pessoaFisica.cpf,
              rg: funcionario.pessoaFisica.rg,
              rg_uf: funcionario.pessoaFisica.rg_uf,
              rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
              rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
              datanascimento: funcionario.pessoaFisica.dataNascimento,
              unidadetrabalho_id: funcionario.unidadetrabalho_id,
              contt_funcao_id: funcionario.contrato.idFuncao,
              data_admissao: funcionario.contrato.dataInicial,
              datainicio: funcionario.contrato.dataInicial,
              observacao: funcionario.contrato.observacao,
              enderecos,
              emails,
              telefones,
              usuario: {
                perfil_id: funcionario.usuario.perfil_id,
                login: funcionario.usuario.email_login,
                senha: funcionario.usuario.senha_usuario
              }
            }
          }
          
          const contas = funcionario.contas
          let contasAux = []

          contas.forEach(conta => {
            if (conta.agencia !== "" && conta.numero !== "" && conta.banco_id !== "") {

              const aux = {
                numero: conta.numero,
                gerente: conta.gerente,
                agencia: conta.agencia,
                chave_pix: conta.chave_pix,
                banco_id: conta.banco_id
              }

              if (conta.gerente === "") {
                delete aux.gerente
              }

              if (conta.chave_pix === "") {
                delete aux.chave_pix
              }

              contasAux.push(aux)
            }
          });

          if (contasAux.length !== 0) {
            responsavel.contas = contasAux
          }
        }

        dados = {
          franqueado_id: unidade.franqueado_id,
          representante_id: unidade.representante_id,
          importar_planos: unidade.importar_planos,
          unidade_plano: unidade.unidade_plano,
          unidade: {
            tipo: unidade.tipoPessoa,
            descricao: unidade.descricao,
            num_unidade: unidade.num_unidade,
            juridica,
            fisica,
            enderecos,
            emails,
            telefones
          },
          responsavel
        }
      } else {
        dados = {
          franqueado_id: unidade.franqueado_id,
          representante_id: unidade.representante_id,
          importar_planos: unidade.importar_planos,
          unidade_plano: unidade.unidade_plano,
          unidade: {
            tipo: unidade.tipoPessoa,
            descricao: unidade.descricao,
            num_unidade: unidade.num_unidade,
            juridica,
            fisica,
            enderecos,
            emails,
            telefones
          }
        }
      }

      data.append("dados", JSON.stringify(dados));

      try {

        await axios.post(`${window._env_.REACT_APP_API_URL}/unidades`, data, getToken())
        
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Cadastro realizado com sucesso.'
          },
          unidade: {
            id: "",
            descricao: "",
            ativo: true,
            tipoPessoa: "FISICA",
            franqueado_id: "",
            unidadePrincipal: true,
            importarDadosFuncionario: true,
            importar_planos: false,
            unidade_plano: '',
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            pessoaJuridica: {
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: ""
            },
            enderecos: [],
            emails: [],
            contatos: []
          },
          funcionario: {
            id: "",
            dataAdmissao: "",
            dataDemissao: "",
            tipoPessoa: "FISICA",
            contrato: {
              departamento: "",
              idDepartamento: "",
              cargo: "",
              idCargo: "",
              funcao: "",
              idFuncao: "",
              dataInicial: "",
              dataFinal: "",
              observacao: ""
            },
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            enderecos: [],
            emails: [],
            contatos: [],
            usuario: {
              email_login: "",
              senha_usuario: "",
              repetirSenha: "",
              perfil_id: ""
            },
            ativo: "",
          },
          loading: false
        }))

        backPage()

      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loading: false
        }))
      }

    } else {
      if (funcionario.usuario.senha !== funcionario.usuario.repetirSenha) {
        
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Senhas não conferem, por favor verificar as senhas.'
          }
        }))
      } else {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Existem campo obrigatorios vazios.'
          }
        }))
      }
    }
  }

  const informarContrato = () => {
    let { funcionario } = state

    if (funcionario.pessoaFisica.nome === "") {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o nome.'
        }
      }))
      return false
    }
    if (funcionario.pessoaFisica.cpf === "") {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o CPF.'
        }
      }))
      return false
    }
    if (funcionario.pessoaFisica.dataNascimento === "") {
      setState(prevState => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data de nascimento.'
        }
      }))
      return false
    }

    setState((prevState) => ({
      ...prevState,
      enableDadosPessoaFuncionario: false,
      enableDadosContratoFuncionario: true
    }))
  }

  const voltarUnidade = () => {
    setState(prevState => ({
      ...prevState,
      enableDadosPessoa: true,
      enableDadosFuncionario: false,
    }));
  }

  const voltarFuncionario = () => {
    setState(prevState => ({
      ...prevState,
      enableDadosPessoaFuncionario: true,
      enableDadosContratoFuncionario: false
    }));
  }

  useEffect(() => {
    const fetchData = async () => {

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'
      const unidadetrabalho = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho : {}

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'unidade')[0]

      setState(prevState => ({
        ...prevState,
        permissoes
      }));

      if (!permissoes.inserir) {
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        }));

        backPage()
      }

      setState(prevState => ({
        ...prevState,
        unidade: {
          id: "",
          descricao: "",
          ativo: true,
          tipoPessoa: "FISICA",
          franqueado_id: "",
          unidadePrincipal: true,
          importarDadosFuncionario: true,
          importar_planos: false,
          unidade_plano: '',
          num_unidade: '',
          representante_id: '',
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            dataNascimento: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: ""
          },
          pessoaJuridica: {
            razaoSocial: "",
            nomeFantasia: "",
            cnpj: "",
            inscricaoEstadual: "",
            dataFundacao: ""
          },
          enderecos: [],
          emails: [],
          contatos: []
        },
        funcionario: {
          id: "",
          dataAdmissao: "",
          dataDemissao: "",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: "",
            cpf: "",
            rg: "",
            dataNascimento: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: ""
          },
          enderecos: [],
          emails: [],
          contatos: [],
          contas: [{
            agencia: "",
            numero: "",
            gerente: "",
            chave_pix: "",
            banco_id: "",
            index: 1,
            banco: {
              id: "",
              nome: "",
              numero: "",
              padraocosif: ""
            }
          }],
          usuario: {
            email_login: "",
            senha_usuario: "",
            repetirSenha: "",
            perfil_id: ""
          },
          ativo: "",
        }
      }));

      try {

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, {}, getToken())

        let unidadesAux = unidades.filter(param => param.status === true)

        unidadesAux.push({
          id: 1,
          descricao: "Click",
          numero: 0
        })

        setState(prevState => ({
          ...prevState,
          unidades: {
            list: unidadesAux.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0))
          }
        }));

        const { data: departamentos } = await axios.post(`${window._env_.REACT_APP_API_URL}/departamentos/list`, {}, getToken())

        setState(prevState => ({
          ...prevState,
          departamentos: {
            list: departamentos.filter(u => u.ativo === true)
          }
        }));

        const { data: bancos } = await axios.get(`${window._env_.REACT_APP_API_URL}/bancos`, getToken())

        setState(prevState => ({
          ...prevState,
          bancos: {
            list: bancos
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))

        backPage()
      }

      const {unidade} = state

      if(tipo === 'MANTENEDOR'){
        try {
          const { data: representantesAux } = await axios.get(`${window._env_.REACT_APP_API_URL}/representantes`, getToken())

          const representantesFisica = representantesAux.filter(u => u.pessoa.tipo === "FISICA")
          const representantesJuridica = representantesAux.filter(u => u.pessoa.tipo === "JURIDICA")

          const representantes = []

          representantesFisica.forEach(pessoaFisica => {
            representantes.push({
              _id: pessoaFisica.id,
              representante: pessoaFisica.pessoa.fisica.nome + " " + (pessoaFisica.pessoa.fisica.sobrenome ? pessoaFisica.pessoa.fisica.sobrenome : ''),
            })
          });

          representantesJuridica.forEach(pessoaJuridica => {
            representantes.push({
              _id: pessoaJuridica.id,
              representante: pessoaJuridica.pessoa.juridica.nomefantasia,
            })
          });

          setState(prevState => ({
            ...prevState,
            representantes: {
              list: representantes
            }
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
    
          backPage()
        }
      }else if(tipo === 'REPRESENTANTE'){
        unidade.representante_id = unidadetrabalho.id

        consultaFranqueados(unidade.representante_id)
      }
    
      const {funcionario} = state
      setState(prevState => ({
        ...prevState,
        unidade, 
        funcionario, 
        tipo, 
        loading: false
      }))
    };

    fetchData();    
  }, []);

  const { unidade, funcionario, franqueados, enableUpdate, representantes, tipo, uploadedFiles } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState((prevState) => ({...prevState, openModalAjuda: true}))}}>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={12}>
                <h1 className="titulo">Cadastro de Unidade</h1>
              </Grid>
            </Grid>
            {state.enableDadosPessoa &&
              <React.Fragment>
                <div className="formFranqueado">
                  <Grid container spacing={1} direction="row" className="mg_top_20">
                    {tipo === 'MANTENEDOR' &&
                      <Grid item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Representante Pertencente*"
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="representante_id"
                          value={unidade.representante_id}
                          onChangeCapture={(e) => updateField(e)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value=""> Selecionar Representante</option>
                          {representantes.list.map(representante => {
                            return (
                              <option key={representante._id} value={representante._id}>
                                {representante.representante}
                              </option>
                            )
                          })}

                        </TextField>
                      </Grid>
                    }
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Unidade Primária (Principal)*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="unidadePrincipal"
                        value={unidade.unidadePrincipal}
                        onChangeCapture={(e) => updateFieldUnidadePrincipal(e)}
                        disabled={unidade.representante_id === ''}
                      >
                        <option value={true}>
                          Sim
                          </option>
                        <option value={false}>
                          Não
                          </option>
                      </TextField>
                    </Grid>
                    {!unidade.unidadePrincipal &&
                      <Grid item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Grupo Econômico Pertencente*"
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="franqueado_id"
                          onChangeCapture={(e) => updateField(e)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value="">
                            Selecionar Grupo Econômico
                          </option>
                          {franqueados.list.map(franqueado => {
                            return (
                              <option key={franqueado._id} value={franqueado._id}>
                                {franqueado.franqueado}
                              </option>
                            )
                          })}

                        </TextField>
                      </Grid>
                    }
                  </Grid>
                  <br />
                  <hr />
                  <br />
                  <Grid container spacing={1} direction="row">
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField className="input" type='number' label="Num. Unidade" variant="outlined" size="small" name="num_unidade" value={unidade.num_unidade} onBlur={(e) => verificaNumUnidade(e)} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={unidade.unidadePrincipal ? (unidade.representante_id === '' ? true : false) : (unidade.franqueado_id === '' ? true: false) } />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Descrição Unidade" variant="outlined" size="small" name="descricao" value={unidade.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={2} xs={12} sm={6}>
                      <FormControl component="fieldset" className="mag-top--8">
                        <FormLabel component="legend">Importar Plano de Contas?</FormLabel>
                        <FormControlLabel className="mg_left_10"
                          control={
                            <Switch
                              name="ativo"
                              color="primary"
                              checked={unidade.importar_planos}
                            />
                          }
                          size="small"
                          label={unidade.importar_planos ? 'Sim' : 'Não'}
                          name="ativo"
                          onChange={(e) => updateFieldImportarPlanos(e)}
                        />
                      </FormControl>
                    </Grid>
                    {unidade.importar_planos &&
                      <Grid item md={3} xs={12} sm={6}>
                        <Autocomplete
                          onChange={(event, value) => updateFieldUnidadePlano(event, value)}
                          freeSolo
                          options={state.unidades.list}
                          value={state.unidadePlano}
                          getOptionLabel={option => `${option.numero} - ${option.descricao}`}
                          renderInput={(params) => (
                            <TextField className="input" InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Unidade Importação*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={unidade.numero + ' - ' + unidade.descricao} />
                          )}
                        />
                      </Grid>
                    }
                  </Grid>
                  <br />
                  <hr />
                  <Grid container spacing={1} direction="row" className="mg_top_20">
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo Pessoa*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipoPessoa"
                        value={unidade.tipoPessoa}
                        onChangeCapture={(e) => updateField(e)}
                      >
                        <option value="FISICA">
                          Pessoa Física
                          </option>
                        <option value="JURIDICA">
                          Pessoa Júridica
                          </option>
                      </TextField>
                    </Grid>
                  </Grid>
                  <br />
                  <FormPessoa
                    pessoa={unidade}
                    informarEndereco={state.informarEndereco}
                    endereco={state.endereco}
                    informarContato={state.informarContato}
                    contato={state.contato}
                    informarEmail={state.informarEmail}
                    email={state.email}
                    updateFieldPessoaFisica={(e) => updateFieldPessoaFisica(e)}
                    updateFieldPessoaJuridica={(e) => updateFieldPessoaJuridica(e)}
                    validarCpfCnpj={(e) => validarCpfCnpj(e)}
                    dadosComplementaresPF={state.dadosComplementaresPF}
                    metodos_divugacoes={state.metodos_divugacoes.list}
                    informarNovoEndereco={() => informarEndereco()}
                    pegaEndereco={e => pegaEndereco(e)}
                    updateFieldEndereco={e => updateFieldEndereco(e)} 
                    updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipal(e)} 
                    addNovoEndereco={(e) => addNovoEndereco(e)}
                    cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                    editarEndereco={(e) => editarEndereco(e)}
                    removeEndereco={(e) => removeEnderecoUnidade(e)}
                    informarNovoContato={() => informarContato()}
                    updateFieldContato={e => updateFieldContato(e)} 
                    updateFieldContatoPrincipal={e => updateFieldContatoPrincipal(e)} 
                    addNovoContato={() => addNovoContato()}
                    editarContato={(e) => editarContato(e)}
                    removeContato={(e) => removeContatoUnidade(e)}
                    informarNovoEmail={() => informarEmail()}
                    updateFieldEmail={e => updateFieldEmail(e)} 
                    updateFieldEmailPrincipal={e => updateFieldEmailPrincipal(e)}
                    addNovoEmail={() => addNovoEmail()}
                    editarEmail={(e) => editarEmail(e)}
                    removeEmail={(e) => removeEmailUnidade(e)}
                    acoesTabelaEndereco={state.acoesTabelaEndereco}
                    cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                    cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                  />
                </div>
                <br />
                <hr />

                <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                  <Grid item md={2} xs={12} sm={3}>
                    <Link to={'/unidade'}>
                      <Button color='warning' fullWidth size='small' variant="contained" startIcon={<ArrowBackIcon />}>
                        Voltar Listagem
                      </Button>
                    </Link>
                  </Grid>
                  {unidade.unidadePrincipal &&
                    <React.Fragment>
                      <Grid item md={5} xs={false} sm={1}></Grid>
                      <Grid item md={2} xs={12} sm={3}>
                        <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={() => salvar(false)} disabled={state.loading}>Salvar</Button>
                      </Grid>
                      <Grid item md={3} xs={12} sm={4}>
                        <Button color='success' fullWidth onClick={() => informarFuncionario()} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Informar Funcionario</Button>
                      </Grid>
                    </React.Fragment>
                  }
                  {!unidade.unidadePrincipal &&
                    <React.Fragment>
                      <Grid item md={8} xs={false} sm={1}></Grid>
                      <Grid item md={2} xs={12} sm={3}>
                        <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={() => salvar(false)} disabled={state.loading}>Salvar</Button>
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
              </React.Fragment>
            }

            {state.enableDadosFuncionario &&
              <div className="formFranqueado">
                {unidade.tipoPessoa === "FISICA" &&
                  <React.Fragment>
                    <Grid container direction="row" className='mg_top_10'>
                      <Grid item md={12}>
                        <h4 className="titulo">Cadastro de Usuário (Grupo Econômico)</h4>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_20">
                      <Grid item md={4} xs={12} sm={6}>
                        <Autocomplete
                          onChange={(event, value) => updateFieldPerfil(event, value)}
                          freeSolo
                          options={state.perfis.list}
                          value={state.perfil}
                          getOptionLabel={option => option.descricao}
                          renderInput={(params) => (
                            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={state.perfil.descricao} />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <FormUsuario 
                      updateField={e => updateFieldUsuario(e)} 
                      dados={funcionario.usuario} 
                      handleClickShowPassword={e => handleClickShowPassword(e)}
                      handleClickShowPasswordRepetir={e => handleClickShowPasswordRepetir(e)}
                      showPassword={state.showPassword}
                      showPasswordRepetir={state.showPasswordRepetir}
                      nivelSenha={state.nivelSenha}
                      corSenha={state.corSenha}/>
                    <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                      <Grid item md={6}></Grid>
                      <Grid item md={3}>
                        <Button fullWidth onClick={() => voltar("enableDadosFuncionario", "enableDadosEmail")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                      </Grid>
                      <Grid item md={3}>
                        {!unidade.importarDadosFuncionario &&
                          <Button fullWidth onClick={() => proximo("enableDadosEmail", "enableDadosFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        }
                        {unidade.importarDadosFuncionario &&
                          <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={() => salvar(true)}>Salvar</Button>
                        }
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
                {unidade.tipoPessoa === "JURIDICA" &&
                  <React.Fragment>
                    <Grid container direction="row" className="mg_top_10">
                      <Grid item md={12}>
                        <h4 className="titulo">Cadastro do Funcionário Responsável - Franqueado</h4>
                      </Grid>
                    </Grid>
                    {state.enableDadosPessoaFuncionario &&
                      <div className="formFranqueado">
                        <FormPessoa
                          pessoa={funcionario}
                          informarEndereco={state.informarEnderecoFuncionario}
                          endereco={state.enderecoFuncionario}
                          informarContato={state.informarContatoFuncionario}
                          contato={state.contatoFuncionario}
                          informarEmail={state.informarEmailFuncionario}
                          email={state.emailFuncionario}
                          updateFieldPessoaFisica={(e) => updateFieldPessoaFisicaFuncionario(e)}
                          validarCpfCnpj={(e) => validarCpfCnpjFuncionario(e)}
                          dadosComplementaresPF={state.dadosComplementaresPF}
                          metodos_divugacoes={state.metodos_divugacoes.list}
                          informarNovoEndereco={() => informarEnderecoFuncionario()}
                          pegaEndereco={e => pegaEnderecoFuncionario(e)}
                          updateFieldEndereco={e => updateFieldEnderecoFuncionario(e)} 
                          updateFieldEnderecoPrincipal={e => updateFieldEnderecoPrincipalFuncionario(e)} 
                          addNovoEndereco={(e) => addNovoEnderecoFuncionario(e)}
                          cabecalhoTabelaEndereco={state.cabecalhoTabelaEndereco}
                          editarEndereco={(e) => editarEnderecoFuncionario(e)}
                          removeEndereco={(e) => removeEnderecoFuncionario(e)}
                          informarNovoContato={() => informarContatoFuncionario()}
                          updateFieldContato={e => updateFieldContatoFuncionario(e)} 
                          updateFieldContatoPrincipal={e => updateFieldContatoPrincipalFuncionario(e)} 
                          addNovoContato={() => addNovoContatoFuncionario()}
                          editarContato={(e) => editarContatoFuncionario(e)}
                          removeContato={(e) => removeContatoFuncionario(e)}
                          informarNovoEmail={() => informarEmailFuncionario()}
                          updateFieldEmail={e => updateFieldEmailFuncionario(e)} 
                          updateFieldEmailPrincipal={e => updateFieldEmailPrincipalFuncionario(e)}
                          addNovoEmail={() => addNovoEmailFuncionario()}
                          editarEmail={(e) => editarEmailFuncionario(e)}
                          removeEmail={(e) => removeEmailFuncionario(e)}
                          acoesTabelaEndereco={state.acoesTabelaEndereco}
                          cabecalhoTabelaTelefone={state.cabecalhoTabelaTelefone}
                          cabecalhoTabelaEmail={state.cabecalhoTabelaEmail}
                        />
                        <hr className="mg_top_10"/>
                        <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                          <Grid item md={2}>
                            <Button fullWidth onClick={() => voltarUnidade()} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                          <Grid item md={8}></Grid>
                           <Grid item md={2} xs={12} sm={4}>
                              <Button color='success' fullWidth onClick={() => informarContrato()} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Informar Contrato</Button>
                            </Grid>
                        </Grid>                         
                      </div>
                    }

                    {state.enableDadosContratoFuncionario &&
                      <div className="formFranqueado">
                        <Grid container direction="row" className="mg_top_10">
                          <Grid item md={12}>
                            <h4 className="titulo">Dados do Contrato</h4>
                          </Grid>
                        </Grid>
                        <FormContratoFuncionario
                          form='funcionario'
                          enableUpdate={enableUpdate}
                          updateField={e => updateFieldContratoFuncionario(e)}
                          dados={funcionario.contrato}
                          departamentos={state.departamentos.list}
                          cargos={state.cargosFiltrado.list}
                          funcaos={state.funcaosFiltrado.list}
                        />
                        <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                          <Grid item md={2}>
                            <Button fullWidth onClick={() => voltarFuncionario()} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                          <Grid item md={6}>
                          </Grid>
                          <Grid item md={2}></Grid>
                          <Grid item md={2}>
                            <Button color='success' fullWidth onClick={() => proximo("enableDadosContratoFuncionario", "enableDadosContaBancariaFuncionario")} variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                          </Grid>
                        </Grid>
                      </div>
                    }

                    {state.enableDadosContaBancariaFuncionario &&
                      <div className="formFranqueado">
                        <Grid container direction="row">
                          <Grid item md={12}>
                            <h4 className="titulo">Dados das Contas Bancarias</h4>
                          </Grid>
                        </Grid>
                        {funcionario.contas.map(conta => {
                          return (
                            <div key={conta.index} className="cardEndereco">
                              <FormContaBancaria
                                form='funcionario'
                                updateField={e => updateFieldContaBancariaFuncionario(e, conta.index)}
                                updateFieldBanco={(event, value, index) => updateFieldBanco(event, value, conta.index)}
                                dados={conta}
                                banco={conta.banco}
                                bancos={state.bancos.list}
                                removerConta={(index) => removerConta(index)} />
                            </div>
                          )
                        })}
                        <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                          <Grid item md={4}>
                            <Button fullWidth onClick={() => addNovaContaBancariaFuncionario()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Nova Conta </Button>
                          </Grid>
                          <Grid item md={2}></Grid>
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => voltar("enableDadosContaBancariaFuncionario", "enableDadosContratoFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => proximo("enableDadosContaBancariaFuncionario", "enableDadosUsuarioFuncionario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                          </Grid>
                        </Grid>
                      </div>
                    }

                    {state.enableDadosUsuarioFuncionario &&
                      <div className="formFranqueado">
                        <Grid container direction="row">
                          <Grid item md={12}>
                            <h4 className="titulo">Dados do Usuário</h4>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1} className="mg_top_20">
                          <Grid item md={4} xs={12} sm={6}>
                            <Autocomplete
                              onChange={(event, value) => updateFieldPerfil(event, value)}
                              freeSolo
                              options={state.perfis.list}
                              value={state.perfil}
                              getOptionLabel={option => option.descricao}
                              renderInput={(params) => (
                                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={state.perfil.descricao} />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <FormUsuario 
                          form='funcionario'
                          updateField={e => updateFieldUsuario(e)} 
                          dados={funcionario.usuario}
                          handleClickShowPassword={e => handleClickShowPassword(e)}
                          handleClickShowPasswordRepetir={e => handleClickShowPasswordRepetir(e)}
                          showPassword={state.showPassword}
                          showPasswordRepetir={state.showPasswordRepetir}
                          nivelSenha={state.nivelSenha}
                          corSenha={state.corSenha} />
                        <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                          <Grid item md={6}></Grid>
                          <Grid item md={3}>
                            <Button fullWidth onClick={() => voltar("enableDadosUsuarioFuncionario", "enableDadosContaBancariaFuncionario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          </Grid>
                          <Grid item md={3}>
                            <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={() => salvar(true)}>Salvar</Button>
                          </Grid>
                        </Grid>
                      </div>
                    }
                  </React.Fragment>
                }
              </div>
            }
            <br />
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
          <ModalInformarLogoUnidade 
            open={state.openModalInformarLogoUnidade}
            handleClose={e => handleCloseModalInformarLogoUnidade()}
            handleUploadImage={e => handleUploadImage(e)}
            uploadedFiles={uploadedFiles}
            continuar={e => handleCloseModalInformarLogoUnidade()}
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Cadastro de Unidade"}
        handleClose={() => {setState(prevState => ({ ...prevState, openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </React.Fragment>
  )
}

export default Cadastro
