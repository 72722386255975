import React, { useState, useEffect } from 'react';
import './Movimentacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import axios from 'axios'
import UploadExtrato from './../../services/uploads/UploadExtrato'
import real from './../../services/real'
import moment from 'moment';
import {filesize} from "filesize";
import { styled } from '@mui/system';
import Color from 'color';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Tooltip from '@mui/material/Tooltip';
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalConfirmacaoConciliar from './../../components/modals/Confirmacao'
import ModalConfirmacaoLocalizar from './../../components/modals/Confirmacao'
import ModalLocalizarMovimentacao from './../../components/modals/LocalizarMovimentacao'
import ModalLançarContaExtrato from './../../components/modals/LançarContaExtrato'
import ModalAjustarValorConciliacao from './../../components/modals/AjustarValorConciliacao'
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'cnpj'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

const initialState = {
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  mensagemLoading: 'Carregando Dados...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  uploadedFiles: [],
  movimentacoes_extrato_filtrado: [],
  movimentacoes_extrato: [],
  conta_extrato: {
    id: '',
    descricao: '',
    data_inicial: '',
    data_final: '',
    saldo_fechamento: 0,
    exibir_conciliado: 'Não',
    logo: null
  },
  extrato_enviado: false,
  mov_selecionadas_extrato: [],
  mov_selecionadas_sistema: [],
  mov_selecionadas_sistema_aux: [],
  openModalConfirmacao: false,
  modal: {
    mensagem: ''
  },
  acao_botao: 'Selecionar',
  openModalConfirmacaoConciliar: false,
  modalConciliar: {
    mensagem: ''
  },
  perguntar: true,
  openModalLocalizarMovimentacao: false,
  filtro: {
    tipo: "",
    descricao: "",
    data_inicial: "",
    data_final: "",
    data_por: "VENCIMENTO",
    situacoes: [],
    formas_pagto: [],
    pessoa_id: "",
    nome: "",
    conciliado: "",
    limit: 10,
    offset: 0
  },
  pessoas: {
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  formasPagamento:{
    list: []
  },
  formasSelecionadas: [],
  situacoes:{
    list: [
      {
        id: 'A_VENCER',
        value: 'A VENCER',
        descricao: 'A Vencer'
      },
      {
        id: 'BAIXADO',
        value: 'BAIXADO',
        descricao: 'Baixado'
      },
      {
        id: 'VENCIDO',
        value: 'VENCIDO',
        descricao: 'Vencido'
      }
    ]
  },
  situacoesSelecionadas: [
    {
      id: 'A_VENCER',
      value: 'A VENCER',
      descricao: 'A Vencer'
    },
    {
      id: 'BAIXADO',
      value: 'BAIXADO',
      descricao: 'Baixado'
    },
    {
      id: 'VENCIDO',
      value: 'VENCIDO',
      descricao: 'Vencido'
    },
  ],
  contas: {
    list: []
  },
  rows: 0,
  loadingTable: false,
  totais:{
    totalParcelaPagar: 0,
    totalParcelaReceber: 0,
    totalPagoPagar: 0,
    totalPagoReceber: 0
  },
  movimentacao_extrato: {
    data: ''
  },
  openModalConfirmacaoLocalizar: false,
  movimentacoes_selecionadas: [],
  contas_selecionadas: [],
  tab: 0,
  contaFinanceiras: {
    list: []
  },
  filtroMovimentacao: {
    unidadesnegocio: {},
    data_inicial: '',
    data_final: '',
    conciliado: '',
    tipo: '',
    descricao: '',
    contfin_id: ''
  },
  movimentacoes: {
    list: []
  },
  openModalLançarContaExtrato: false,
  openModalAjustarValorConciliacao: false,
  banco_informado: {
    logo: '',
    nome: '',
    numero: ''
  },
  banco_extrato: {
    logo: '',
    nome: '',
    numero: ''
  }
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ImportarExtrato = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate.push("/movimentacao_financeira");
    }, timeout)
  }

  const handleCloseAlerta = () =>{
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleUploadExtrato = (files) => {
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    setState(prevState => ({...prevState,
      uploadedFiles
    }))
  }

  const enviarExtrato = async () =>{
    let { uploadedFiles, conta_extrato } = state

    const savedFile = localStorage.getItem('savedFile');

    if(!savedFile){
      if(uploadedFiles.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o arquivo OFX.'
          }
        }))
        return
      }

      if(conta_extrato.id === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a Conta Bancária.'
          }
        }))
        return
      }
    }else if (savedFile) {
      conta_extrato = JSON.parse(localStorage.getItem('conta_extrato'));
      
      if(!conta_extrato) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a Conta Bancária.'
          }
        }))
        return
      }
      
      uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles'));

      const fileObject = JSON.parse(savedFile);      
            
      const blob = new Blob([fileObject.content], { type: fileObject.type });
      const recreatedFile = new File([blob], fileObject.name, {
        type: fileObject.type,
        lastModified: fileObject.lastModified
      });

      uploadedFiles[0].file = recreatedFile
      setState(prevState => ({...prevState,
        conta_extrato
      }))
    }

    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Carregando Extrato'
      }
    }))

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let data = new FormData();

      data.append("file", uploadedFiles[0].file, uploadedFiles[0].name);
      data.append("unidade_id", acesso_atual[0].id);
      data.append("conta_id", conta_extrato.id);

      const USER_TOKEN = localStorage.getItem('token')

      const config = {
        headers: {
          'Authorization': 'Bearer ' + USER_TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }

      let {data: resp} = await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/importarExtrato`, data, config)

      setState(prevState => ({...prevState,
        movimentacoes_extrato: resp.transacoes,
        movimentacoes_extrato_filtrado: resp.transacoes.filter(param => !param.conciliado),
        conta_extrato: {
          id: resp.id,
          descricao: resp.descricao,
          data_inicial: resp.data_inicial,
          data_final: resp.data_final,
          saldo_fechamento: resp.saldo_fechamento,
          exibir_conciliado: 'Não',
          logo: resp.logo
        },
        extrato_enviado: true,
        uploadedFiles,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        },
        banco_extrato: resp.banco_extrato,
        banco_informado: resp.banco_informado
      }))

      if (savedFile){
        localStorage.removeItem('savedFile')
        // localStorage.removeItem('uploadedFiles')
      }
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldExtrato = (event) => {
    const { conta_extrato, movimentacoes_extrato } = state;
    const { name, value } = event.target;
  
    const updatedContaExtrato = { ...conta_extrato, [name]: value };
  
    let updatedMovimentacoesExtratoFiltrado = movimentacoes_extrato;
  
    if (name === 'exibir_conciliado') {
      if (value === 'Não') {
        updatedMovimentacoesExtratoFiltrado = movimentacoes_extrato.filter(param => !param.conciliado);
      }else{
        updatedMovimentacoesExtratoFiltrado = movimentacoes_extrato.filter(param => param.conciliado);
      }
    }
  
    setState(prevState => ({...prevState,
      conta_extrato: updatedContaExtrato,
      movimentacoes_extrato_filtrado: updatedMovimentacoesExtratoFiltrado
    }));
  }

  const vincularExtrato = (mov) => {
    let {mov_selecionadas_extrato, mov_selecionadas_sistema, perguntar} = state
    // Crie uma cópia do array mov_selecionadas_extrato
    const novasSelecionadas = mov_selecionadas_extrato.slice();
  
    // Adicione um novo objeto mov ao novo array usando o push
    novasSelecionadas.push({ ...mov, valor: real(mov.valor) });
  
    // Atualize o estado com o novo array
    setState(prevState => ({...prevState, mov_selecionadas_extrato: novasSelecionadas }));

    if(novasSelecionadas.length >= 1 && mov_selecionadas_sistema.length >= 1 && perguntar){
      setState(prevState => ({...prevState,
        openModalConfirmacaoConciliar: true,
        modalConciliar: {
          mensagem: 'Deseja conciliar as movimentações selecionadas?'
        }
      }))
    }
  }

  const desvincularExtrato = (mov) => {
    // Crie uma cópia do array mov_selecionadas_extrato
    const novasSelecionadas = state.mov_selecionadas_extrato.slice();
  
    // Use filter() para criar um novo array sem o elemento que você deseja remover
    const novasSelecionadasFiltradas = novasSelecionadas.filter(param => param.key !== mov.key);
  
    // Atualize o estado com o novo array
    setState(prevState => ({...prevState, mov_selecionadas_extrato: novasSelecionadasFiltradas }));
  }

  const vincularSistema = (mov) => {
    let {mov_selecionadas_extrato, mov_selecionadas_sistema, perguntar} = state

    // Crie uma cópia do array mov_selecionadas_sistema
    const novasSelecionadas = mov_selecionadas_sistema.slice();
  
    // Adicione um novo objeto mov ao novo array usando o push
    novasSelecionadas.push({ ...mov, valor: real(mov.valor) });
  
    // Atualize o estado com o novo array
    setState(prevState => ({...prevState, mov_selecionadas_sistema: novasSelecionadas }));

    if(mov_selecionadas_extrato.length >= 1 && novasSelecionadas.length >=1 && perguntar){
      setState(prevState => ({...prevState,
        openModalConfirmacaoConciliar: true,
        modalConciliar: {
          mensagem: 'Deseja conciliar as movimentações selecionadas?'
        }
      }))
    }
  }

  const desvincularSistema = (mov) => {
    // Crie uma cópia do array mov_selecionadas_sistema
    const novasSelecionadas = state.mov_selecionadas_sistema.slice();
  
    // Use filter() para criar um novo array sem o elemento que você deseja remover
    const novasSelecionadasFiltradas = novasSelecionadas.filter(param => param.key !== mov.key);
  
    // Atualize o estado com o novo array
    setState(prevState => ({...prevState, mov_selecionadas_sistema: novasSelecionadasFiltradas }));
  }

  const conciliarMovimentacao = async (value, mov_selecionadas_sistema) => {
    if(value) {
      let {mov_selecionadas_extrato} = state
      
      let soma_extrato = parseFloat((somarValores(mov_selecionadas_extrato.map(value => parseFloat(value.valor.replaceAll(".", "").replace(",", "."))))).toFixed(2))
      let soma_sistema = parseFloat((somarValores(mov_selecionadas_sistema.map(value => parseFloat(value.valor.replaceAll(".", "").replace(",", "."))))).toFixed(2))
  
      if(soma_extrato !== soma_sistema){
        if(mov_selecionadas_extrato.length === 1 && mov_selecionadas_sistema.length === 1){
          setState(prevState => ({...prevState,
            openModalConfirmacao: true,
            modal: {
              mensagem: 'Os valores selecionados não confere! Deseja continuar a conciliação? Os valores serão ajustados com base no extrato.',
            }
          }))
          return
        }else{

          setState(prevState => ({...prevState,
            openModalAjustarValorConciliacao: true,
            mov_selecionadas_sistema_aux: mov_selecionadas_sistema.map(value => {
              return {
                ...value,
                valor: value.valor.replace('-','')
              }
            })
          }))
          return
        }
      }
  
      await confirmarConciliacao(true, mov_selecionadas_sistema)
    }else{
      setState(prevState => ({...prevState,
        perguntar: false
      }))
      handleCloseConfirmacaoConciliar()
    }
  }

  const confirmarConciliacao = async (value, mov_selecionadas_sistema) => {
    if(value){
      try {
        let {mov_selecionadas_extrato} = state
  
        let dados = {
          mov_selecionadas_sistema: mov_selecionadas_sistema.map(value => {
            return {
              ...value,
              valor: parseFloat(value.valor.replaceAll(".", "").replace(",", "."))
            }
          }),
          mov_selecionadas_extrato: mov_selecionadas_extrato.map(value => {
            return {
              ...value,
              valor: parseFloat(value.valor.replaceAll(".", "").replace(",", "."))
            }
          }),
        }
  
        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/conciliarViaExtrato`, dados, getToken())
  
        await enviarExtrato()
  
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Movimentações Conciliadas.'
          },
          mov_selecionadas_sistema: [],
          mov_selecionadas_extrato: [],
          perguntar: true,
          acao_botao: 'Selecionar',
        }))

        handleCloseConfirmacao()
        handleCloseConfirmacaoConciliar()
        handleCloseModalAjustarValorConciliacao()

      } catch (error) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleCloseConfirmacaoConciliar()
      handleCloseConfirmacao()
      return
    }
  }

  const handleCloseConfirmacao = () =>{
    setState(prevState => ({...prevState,
      openModalConfirmacao: false,
      modal: {
        mensagem: '',
      }
    }))
  }

  const selecionarTodos = () =>{
    let { movimentacoes_extrato_filtrado, mov_selecionadas_extrato, mov_selecionadas_sistema } = state

    for (const movimentacao of movimentacoes_extrato_filtrado) {
      if(!movimentacao.conciliado){
        if(movimentacao.transacao_extrato){
          mov_selecionadas_extrato.push({...movimentacao.transacao_extrato})
        }

        if(movimentacao.transacao_sistema){
          mov_selecionadas_sistema.push({...movimentacao.transacao_sistema})
        }
      }
    }

    setState(prevState => ({...prevState,mov_selecionadas_extrato, mov_selecionadas_sistema, acao_botao: 'Desmarcar', perguntar: false}))
  }

  const desmarcarTodos = () =>{
    setState(prevState => ({...prevState,
      mov_selecionadas_extrato: [], 
      mov_selecionadas_sistema: [], 
      acao_botao: 'Selecionar'
    }))
  }

  const handleCloseConfirmacaoConciliar = () =>{
    setState(prevState => ({...prevState,
      openModalConfirmacaoConciliar: false,
      modalConciliar: {
        mensagem: ''
      }
    }))
  }

  const localizar = async (mov) => {

    let {filtro, filtroMovimentacao, conta_extrato} = state

    filtro.data_inicial = conta_extrato.data_inicial
    filtro.data_final = conta_extrato.data_final

    filtroMovimentacao.data_inicial = conta_extrato.data_inicial
    filtroMovimentacao.data_final = conta_extrato.data_final
    filtroMovimentacao.contfin_id = conta_extrato.id

    await filtrarContas()
    await filtrarMovimentacao()

    setState(prevState => ({...prevState,
      filtro,
      filtroMovimentacao,
      openModalLocalizarMovimentacao: true,
      movimentacao_extrato: {...mov}
    }))
  }

  const handleCloseLocalizarMovimentacao = () =>{
    setState(prevState => ({...prevState,
      openModalLocalizarMovimentacao: false
    }))
  }

  const updateFiltro = (event) => {
    const {filtro} = state

    filtro[event.target.name] = event.target.value 

    setState(prevState => ({...prevState,
      filtro
    }))

  }

  const updateFieldPessoaFiltro = (event, value) => {
    const {filtro} = state

    if(!value){

      filtro.pessoa_id = ''
      filtro.nome = ''

      setState(prevState => ({...prevState,
        cliente: {
          pessoa_id: '',
          nome: '',
          cpf_cnpj: ''
        },
        filtro
      }))

      return false
    } 

    filtro.pessoa_id = value.pessoa_id
    filtro.nome = value.nome

    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: `${pessoa.nome} ${pessoa.sobrenome || ''}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldForma = (event, value) => {
    setState(prevState => ({...prevState,
      formasSelecionadas: value
    }))
  }

  const updateFieldSituacao = (event, value) => {
    setState(prevState => ({...prevState,
      situacoesSelecionadas: value
    }))
  }

  const filtrar = async () =>{
    const {filtro, formasSelecionadas, situacoesSelecionadas, cliente} = state

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    filtro.unidadesnegocio = dados.unidadesnegocio

    filtro.situacoes = situacoesSelecionadas
    filtro.formas_pagto = formasSelecionadas
    filtro.limit = 10
    filtro.offset = 0

    setState(prevState => ({...prevState,
      filtro
    }))

    // criaUrl(filtro)
    // localStorage.setItem('filtro_contaspr', JSON.stringify(filtro));
    // localStorage.setItem('filtro_cliente_contaspr', JSON.stringify(cliente));

    await filtrarContas()
  }

  const filtrarContas = async () =>{
    const {filtro, formasSelecionadas, situacoesSelecionadas} = state

    if (filtro.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data final!'
        }
      }))
      return
    }

    if (filtro.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial!'
        }
      }))
      return
    }

    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Buscando Movimentações'
      },
      loadingTable: true
    }))

    try {

      setState(prevState => ({...prevState,
        totais:{
          totalParcelaPagar: 0,
          totalParcelaReceber: 0,
          totalPagoPagar: 0,
          totalPagoReceber: 0
        }
      }))

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      let filtro_aux = {...filtro}
  
      filtro_aux.unidadesnegocio = dados.unidadesnegocio
      filtro_aux.situacoes = situacoesSelecionadas
      filtro_aux.formas_pagto = formasSelecionadas

      if(filtro_aux.conciliado !== ''){
        filtro_aux.conciliado = filtro_aux.conciliado === 'Sim' ? true : false
      }
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/listPrincipal`, filtro_aux, getToken())

      const contas = []

      for (const conta of data.list) {
        if(conta.descricao){         
          contas.push({
            _id: conta.parcela_id,
            valor_pago_id: conta.valor_pago_id,
            contapr_id: conta.contapr_id,
            mov_id: conta.mov_id,
            contpr_descricao: conta.descricao + (conta.numparcela > 1 ? ` - Parc: ${conta.num}/${conta.numparcela}` : ''),
            datavencimento: moment(conta.datavencimento).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(conta.datavencimento).format('YYYYMMDD'),
            datapagamento:conta.datapagamento ? moment(conta.datapagamento).format('DD/MM/YYYY') : '',
            datapagamentoOrder: conta.datapagamento ? moment(conta.datapagamento).format('YYYYMMDD') : '',
            datavencimento_sem_format: conta.datavencimento,
            situacao: conta.situacao,
            tipo: conta.valorparcela < 0 ? 'PAGAR' : 'RECEBER',
            valorparcela: real(conta.valorparcela),
            valor_pagamento: conta.situacao === 'BAIXADO' ? real(conta.valor_pagamento) : real(0),
            valor_pagamento_real: real(conta.valor_pagamento),
            formapagto_descricao: conta.forma_pagto,
            multajuros: conta.multajuros ? real(conta.multajuros) : real(0),
            pessoa_id: conta.conta_pessoa_id,
            pessoa_nome: conta.tipo_pessoa === 'FISICA' ? conta.nome_pessoa : conta.nomefantasia,
            conciliado: conta.conciliado ? true : false,
            origem: 'CONTASPR'
          })
        }
      }

      setState(prevState => ({...prevState,
        contas: {
          list: contas
        },
        rows: data.rows,
        loadingTable: false,
        totais: data.totais,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      return
    }
  }

  const handleChangePage = async (event, newPage) => {
    const {filtro} = state

    filtro.offset = newPage
    
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarContas()
  }

  const handleChangeRowsPerPage = async (event) =>{
    const {filtro} = this.state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))

    await this.filtrarContas()
  }

  const selecionarMov = async (conta) => {
    let {movimentacoes_selecionadas, contas_selecionadas} = state

    if(conta.origem === 'CONTASPR'){
      let index = contas_selecionadas.findIndex(param => param._id === conta._id)
  
      if(index === -1){
        contas_selecionadas.push({...conta})
      }else{
        contas_selecionadas.splice(index, 1)
      }
    }else{
      let index = movimentacoes_selecionadas.findIndex(param => param._id === conta._id)
  
      if(index === -1){
        movimentacoes_selecionadas.push({...conta})
      }else{
        movimentacoes_selecionadas.splice(index, 1)
      }
    }

    
    setState(prevState => ({...prevState,movimentacoes_selecionadas, contas_selecionadas}))
  }

  const confirmarLocalizacao = async () =>{
    let {contas_selecionadas, movimentacoes_selecionadas, movimentacao_extrato} = state

    let valor_total = somarValores(contas_selecionadas.map(value => parseFloat(value.valorparcela.replaceAll(".", "").replace(",", "."))))
    valor_total = somarValores(movimentacoes_selecionadas.map(value => parseFloat(value.valor.replaceAll(".", "").replace(",", "."))))

    if(valor_total !== movimentacao_extrato.valor){
      setState(prevState => ({...prevState,
        openModalConfirmacaoLocalizar: true,
        modal: {
          mensagem: 'O valores da movimentação não confere, deseja continuar com o conciliamento? Os valores serão ajustados seguindo o lançamento do extrato.'
        }
      }))
      return
    }

    continuarLocalizacao(true)
  }

  const handleCloseConfirmacaoLocalizar = () =>{
    setState(prevState => ({...prevState,
      openModalConfirmacaoLocalizar: false,
      modal: {
        mensagem: ''
      }
    }))
  }

  const continuarLocalizacao = async (value) => {
    if(value){
      let {movimentacoes_selecionadas, movimentacao_extrato, contas_selecionadas} = state

      try {
      
        let dados = {
          mov_selecionadas_extrato: [{
            ...movimentacao_extrato
          }],
          mov_selecionadas_sistema: []
        }

        for (let i = 0; i < movimentacoes_selecionadas.length; i++) {
          let item = movimentacoes_selecionadas[i]  
          let valor = parseFloat(item.valor.replaceAll(".", "").replace(",", "."))
          
          dados.mov_selecionadas_sistema.push({
            key: '',
            valor: valor,
            data: item.data_hora_sem_form,
            tipo: valor <= 0 ? 'DEBIT': 'CREDIT',
            descricao: item.descricao,
            movimento_id: item._id,
            parcela_id: item.valor_pago ? item.valor_pago.parcela.id : null,
            valor_pago_id: item.valor_pago ? item.valor_pago.id : null
          })
          
        }

        for (let i = 0; i < contas_selecionadas.length; i++) {
          let item = contas_selecionadas[i]
          let valor = parseFloat(item.valorparcela.replaceAll(".", "").replace(",", "."))

          dados.mov_selecionadas_sistema.push({
            key: '',
            valor: valor,
            data: item.datavencimento_sem_format,
            tipo: valor <= 0 ? 'DEBIT': 'CREDIT' ,
            descricao: item.contpr_descricao,
            movimento_id: item.mov_id || null,
            parcela_id: item._id,
            valor_pago_id: item.valor_pago_id
          })
        }

        dados.mov_selecionadas_sistema[0].key = movimentacao_extrato.key
  
        await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/conciliarViaExtrato`, dados, getToken())
  
        await enviarExtrato()
        handleCloseConfirmacaoLocalizar()
        handleCloseLocalizarMovimentacao()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    }else{
      handleCloseConfirmacaoLocalizar()
    }
  }

  const handleChangeTab = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue,
      movimentacoes_selecionadas: []
    }))
  }

  const filtrarMovimentacao = async () => {
    let {filtroMovimentacao} = state

    if (filtroMovimentacao.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        }
      }))
      return
    }

    if (filtroMovimentacao.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data final'
        }
      }))
      return
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
      filtroMovimentacao.unidadesnegocio = acesso_atual.map(acesso => {
        return acesso.id
      })
  
      const { data: movimentacoes_resp } = await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/list`, filtroMovimentacao, getToken())
      
      const movimentacoes = movimentacoes_resp.list.map(param => {
        let descricao = param.descricao
        
        if(param.valor_pago){
          if(param.valor_pago.parcela.conta.pessoa){
            descricao = param.descricao + ` Cliente: ${param.valor_pago ? (param.valor_pago.parcela.conta.pessoa.tipo === 'FISICA' ? `${param.valor_pago.parcela.conta.pessoa.fisica.nome} ${param.valor_pago.parcela.conta.pessoa.fisica.sobrenome ? param.valor_pago.parcela.conta.pessoa.fisica.sobrenome : ''}` : param.valor_pago.parcela.conta.pessoa.juridica.razaosocial) : ''}`
          }
        }

        return {
          _id: param.id,
          descricao,
          forma_pgto: param.valor_pago ? param.valor_pago.forma_pagto.descricao : '',
          contfin_descricao: param.conta_financeira.descricao,
          valor: real(param.valor),
          tipo: param.tipo,
          conciliado: param.conciliado,
          conciliado_atual: param.conciliado,
          conciliavel: param.conciliavel,
          data_hora_mov: moment(param.data_hora_conta).format('DD/MM/YYYY'),
          data_hora_sem_form: moment(param.data_hora_conta).format('YYYY-MM-DD'),
          contfin_conciliavel: param.conta_financeira.conciliavel,
          valor_pago: param.valor_pago,
          origem: 'MOVIMENTACAO'
        }
      })

      setState(prevState => ({...prevState,
        movimentacoes: {
          list: movimentacoes
        }
      }))
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      return
    }
  }

  const updateFiltroMovimentacao = (event) => {
    const {filtroMovimentacao} = state

    filtroMovimentacao[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtroMovimentacao
    }))

  }

  const criarMovimentacao = (mov) => {
    setState(prevState => ({...prevState,
      openModalLançarContaExtrato: true,
      movimentacao_extrato: {...mov}
    }))
    
  }

  const handleCloseModalLançarContaExtrato = () =>{
    setState(prevState => ({...prevState,
      openModalLançarContaExtrato: false
    }))
  }

  const selecionarTela = (tela) => {
    let {movimentacao_extrato, conta_extrato, uploadedFiles} = state

    const selectedFile = uploadedFiles[0].file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      const fileObject = {
        path: selectedFile.path,
        lastModified: selectedFile.lastModified,
        lastModifiedDate: selectedFile.lastModifiedDate,
        name: selectedFile.name,
        size: selectedFile.size,
        type: selectedFile.type,
        webkitRelativePath: selectedFile.webkitRelativePath,
        content: content
      };
      localStorage.setItem('savedFile', JSON.stringify(fileObject));
    };

    reader.readAsText(selectedFile);

    localStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));
    localStorage.setItem('conta_extrato', JSON.stringify(conta_extrato));

    if(tela === 'MOVIMENTACAO'){
      let movimentacoes = [
        {
          data_hora_conta: movimentacao_extrato.data,
          descricao: "",
          tipo: "SAQUE",
          conciliado: true,
          valor: real(movimentacao_extrato.valor < 0 ? movimentacao_extrato.valor * -1 : movimentacao_extrato.valor),
          contfin_id: movimentacao_extrato.tipo === 'DEBIT' ? conta_extrato.id : '',
          conciliavel: true,
          saldo_atual: "",
          contfin_descricao: movimentacao_extrato.tipo === 'DEBIT' ? conta_extrato.descricao : '',
        },
        {
          data_hora_conta: movimentacao_extrato.data,
          descricao: "",
          tipo: "DEPOSITO",
          conciliado: true,
          valor: real(movimentacao_extrato.valor),
          contfin_id: movimentacao_extrato.tipo === 'CREDIT' ? conta_extrato.id : '',
          conciliavel: true,
          saldo_atual: "",
          contfin_descricao: movimentacao_extrato.tipo === 'CREDIT' ? conta_extrato.descricao : '',
        }
      ]

      localStorage.setItem('dados_movimentacao', JSON.stringify(movimentacoes));
      localStorage.setItem('movimentacao_extrato', JSON.stringify(movimentacao_extrato));

      navigate("/movimentacao_financeira/cadastro");
    }else{
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let conta = {
        descricao: "",
        tipo: movimentacao_extrato.tipo === 'CREDIT' ? 'RECEBER' : 'PAGAR',
        valortotal: real(movimentacao_extrato.valor),
        numparcela: 1,
        item_id: "",
        qtd_parcelas: 1,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        data_documento: movimentacao_extrato.data,
        unidade_id: acesso_atual[0].id,
        parcelas: [
          {
            baixado: "",
            datavencimento: movimentacao_extrato.data,
            datavencimento_orig: movimentacao_extrato.data,
            valorparcela: real(movimentacao_extrato.valor),
            formapagto_id: 5,
            contfin_id: conta_extrato.id,
            documento: "",
            habilita: "BANCO",
            num:  1,
            parcela_editada: false,
            index: 1
          }
        ],
        parcelado: false,
        recorrencia: false,
        qtdRecorrencia: 1
      }

      localStorage.setItem('dados_contaspr', JSON.stringify(conta));
      localStorage.setItem('movimentacao_extrato', JSON.stringify(movimentacao_extrato));

      const ultima_tela_financeiro = {
        tela: 'extrato_conciliacao'
      }
  
      localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro))

      navigate("/conta_pagar_receber/cadastro");

    }
  }

  const editarMov = (mov) => {
    if(mov.parcela_id){
      const ultima_tela_financeiro = {
        tela: 'extrato_conciliacao',
        id: ''
      }
      
      localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro));
      
      let {uploadedFiles} = state
      const selectedFile = uploadedFiles[0].file;
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const content = e.target.result;
        const fileObject = {
          path: selectedFile.path,
          lastModified: selectedFile.lastModified,
          lastModifiedDate: selectedFile.lastModifiedDate,
          name: selectedFile.name,
          size: selectedFile.size,
          type: selectedFile.type,
          webkitRelativePath: selectedFile.webkitRelativePath,
          content: content
        };
        localStorage.setItem('savedFile', JSON.stringify(fileObject));
      };
  
      reader.readAsText(selectedFile);

      localStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));

      navigate(`/conta_pagar_receber/cadastro/${mov.parcela_id}`);
    }else if(mov.movimento_id){

    }
  }

  const updateContaFinanceira = (event) => {
    let { conta_extrato } = state

    conta_extrato.id = event.target.value

    setState((prevState) => ({
      ...prevState,
      conta_extrato
    }))
  }

  const handleCloseModalAjustarValorConciliacao = async () => {
    await enviarExtrato()

    setState((prevState) => ({
      ...prevState,
      mov_selecionadas_sistema_aux: [],
      openModalAjustarValorConciliacao: false
    }))
  }

  const updateFieldMovimentacao = (event, key) => {
    let {mov_selecionadas_sistema_aux} = state

    let index = mov_selecionadas_sistema_aux.findIndex(param => param.key === key)

    mov_selecionadas_sistema_aux[index][event.target.name] = event.target.value

    setState((prevState) => ({
      ...prevState,
      mov_selecionadas_sistema_aux
    }))
  }

  const confirmarValoresDiferente = async (value) =>{
    if(value){
      let {mov_selecionadas_sistema_aux} = state

      let mov_selecionadas_sistema = mov_selecionadas_sistema_aux.map(value => {
        return {
          ...value,
          valor: value.tipo === 'DEBIT' ? real(parseFloat(value.valor.replaceAll(".", "").replace(",", ".")) * -1) : value.valor
        }
      })

      await conciliarMovimentacao(true, mov_selecionadas_sistema)
    }else{
      handleCloseModalAjustarValorConciliacao()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'conta-financeira')[0]
      
      if(permissoes){
        if (!permissoes.visualizar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Você não tem permissão para acessa essa tela!"
            }
          }))
    
          backPage()
        }

        setState(prevState => ({...prevState,
          permissoes
        }))
      }
      
      setState(prevState => ({...prevState,
        openModalImportacaoExtrato: false,
        movimentacoes_extrato_filtrado: [],
        movimentacoes_extrato: [],
        uploadedFiles: [],
        conta_extrato: {
          id: '',
          descricao: '',
          data_inicial: '',
          data_final: '',
          saldo_fechamento: 0,
          exibir_conciliado: 'Não',
          logo: null
        },
        extrato_enviado: false,
        mov_selecionadas_extrato: [],
        mov_selecionadas_sistema: [],
      }))

      let savedFile = JSON.parse(localStorage.getItem('savedFile'))

      if(savedFile){
        await enviarExtrato()
      }else{
        setState(prevState => ({...prevState,
          uploadedFiles: []
        }))
      }

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          filtro: {
            tipo_conta: "BANCO",
            descricao: "",
            ativo: "Sim"
          },
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())

        const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
          return {
            id: param.id,
            descricao: param.descricao,
            ordem: param.ordem,
            saldo_atual: real(param.saldo_atual),
            tipo_conta: param.tipo_conta,
            ativo: param.ativo,
            conciliavel: param.conciliavel,
            unin_descricao: param.unin_descricao,
            logo: param.logo,
            parceiro_id: param.parceiro_id
          }
        })

        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contaFinanceiras
          }
        }))
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      localStorage.removeItem('dados_contaspr')
      localStorage.removeItem('dados_movimentacao')

      setState(prevState => ({...prevState,
        loading: false,
      }))
    };
    
    fetchData();
  }, []);

  const { uploadedFiles, extrato_enviado,conta_extrato, mov_selecionadas_extrato, mov_selecionadas_sistema,
    movimentacoes_extrato_filtrado, acao_botao, perguntar, banco_informado, banco_extrato } = state

  console.log(mov_selecionadas_sistema)

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h1 className="titulo">Conciliação Bancaria via Extrato</h1>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} xs={12} sm={4}>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Conta Bancária"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="id"
                        value={conta_extrato.id}
                        onChangeCapture={(e) => updateContaFinanceira(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=""></option>
                        {state.contasFinanceira.list.map(conta => {
                          return (
                            <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                          )
                        })}
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                      <UploadExtrato onUpload={e => handleUploadExtrato(e)} />
                    </Grid>
                    {uploadedFiles[0] &&
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12} sm={12}>
                          <p>{uploadedFiles[0] ? uploadedFiles[0].name : ''}</p>
                        </Grid>
                      </Grid>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12}>
                      <Button fullWidth size='small' onClick={e => enviarExtrato()} variant="contained" color="primary">
                        {extrato_enviado ? 'Atualizar' : 'Enviar Extrato'}
                      </Button>
                    </Grid>
                  </Grid>
                  </Grid>
                </Grid>
                {extrato_enviado &&
                  <React.Fragment>
                    <Grid item md={9} xs={12} sm={8} >
                      <div className='dadosExtrato'>
                        <Grid container spacing={1} direction="row">
                          <Grid item md={12} xs={12} sm={12}>
                            <h4 className='titulo'>Dados Extrato:</h4>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={4} xs={12} sm={4}>
                            <TextField className="input" label="Conta Financeira" variant="outlined" size="small" name="descricao" value={conta_extrato.descricao} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={4}>
                            <TextField className="input" label="Data Inicio" variant="outlined" size="small" name="data_inicial" value={moment(conta_extrato.data_inicial).format('DD/MM/YYYY')} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={4}>
                            <TextField className="input" label="Data Final" variant="outlined" size="small" name="data_final" value={moment(conta_extrato.data_final).format('DD/MM/YYYY')} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={4}>
                            <TextField className="input" label="Saldo Fechamento" variant="outlined" size="small" name="saldo_fechamento" value={'R$ ' + real(conta_extrato.saldo_fechamento)} disabled />
                          </Grid>
                          <Grid item md={2} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Exibir*"
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="exibir_conciliado"
                              value={conta_extrato.exibir_conciliado}
                              onChangeCapture={(e) => updateFieldExtrato(e)}
                              InputLabelProps={{ shrink: true }}
                            >
                              <option style={{color: '#000'}} value='Sim'>Conciliados</option>
                              <option style={{color: '#000'}} value='Não'>Não Conciliados</option>
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={12} xs={12} sm={12}>
                            <span><b>{movimentacoes_extrato_filtrado.filter(param => param.transacao_extrato).length}</b> Lançamentos Encontrados</span>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
              <hr className="mg_top_20"/>

              {movimentacoes_extrato_filtrado.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row" className="mg_top_10" style={{paddingBottom: 10}}>
                    <Grid item md={7} xs={12}>
                      <h5 className='titulo'>Movimentações:</h5>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Button 
                        color='warning'
                        fullWidth 
                        size='small' 
                        onClick={acao_botao === 'Selecionar' ? () => selecionarTodos() : () => desmarcarTodos()} 
                        variant="contained"
                      >
                        {acao_botao === 'Selecionar' ? 'Selecionar' : 'Desmarcar'} Todos
                      </Button>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Button
                        color='success'
                        fullWidth 
                        size='small' 
                        onClick={e => conciliarMovimentacao(true, state.mov_selecionadas_sistema)} 
                        variant="contained"
                        disabled={mov_selecionadas_extrato.length === 0 || mov_selecionadas_sistema.length === 0 || perguntar}
                      >
                        Conciliar Selecionados
                      </Button>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={5} xs={12} sm={5}>
                      <img style={{width: 40, position: 'absolute'}} src={banco_extrato.logo || 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt="Logo Banco" />
                      <h6 className='titulo' style={{marginLeft: 50}}>Banco Extrato: {banco_extrato.numero} - {banco_extrato.nome}</h6>
                      <h6 className='titulo' style={{marginLeft: 50}}>Importadas do Extrato: <b>Selecionado: R$ {real(somarValores(mov_selecionadas_extrato.map(value => parseFloat(value.valor.replaceAll(".", "").replace(",", ".")))))}</b></h6>
                    </Grid>
                    <Grid item md={2} xs={12} sm={1}></Grid>
                    <Grid item md={5} xs={12} sm={5}>
                      <img style={{width: 40, position: 'absolute'}} src={banco_informado.logo || 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt="Logo Banco" />
                      <h6 className='titulo' style={{marginLeft: 50}}>Banco Informado: {banco_informado.numero} - {banco_informado.nome}</h6>
                      <h6 className='titulo' style={{marginLeft: 50}}>Cadastradas no Sistema: <b>Selecionado: R$ {real(somarValores(mov_selecionadas_sistema.map(value => parseFloat(value.valor.replaceAll(".", "").replace(",", ".")))))}</b></h6>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_20">
                    <Grid item md={6} xs={12} sm={6} style={{paddingRight: 40}}>
                      <div className='movimentacoes'>
                        {movimentacoes_extrato_filtrado.map((mov, key) => {
                          return (
                            <React.Fragment key={key}>
                              {mov.transacao_extrato &&
                                <div className='card_extrato' style={{marginBottom: 10}}>
                                  <Grid container spacing={1} direction="row">
                                    <Grid item md={12} xs={12} sm={12}>
                                      {!mov.conciliado &&
                                        <React.Fragment>
                                          {mov_selecionadas_extrato.findIndex(param => param.key === mov.transacao_extrato.key) === -1 &&
                                            <Tooltip title='Vincular' aria-label="add">
                                              <IconButton className='button_selecionar' color="primary" aria-label="editar" onClick={() => vincularExtrato(mov.transacao_extrato)}>
                                                <RadioButtonUncheckedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                          {mov_selecionadas_extrato.findIndex(param => param.key === mov.transacao_extrato.key) !== -1 &&
                                            <Tooltip title='Desvincular' aria-label="add">
                                              <IconButton className='button_selecionar' color="primary" aria-label="editar" onClick={() => desvincularExtrato(mov.transacao_extrato)}>
                                                <CheckCircleIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                        </React.Fragment>
                                      }
                                      <p>Data Movimentação: {moment(mov.transacao_extrato.data).format('DD/MM/YYYY')}</p>
                                      <p>Valor:R$ {real(mov.transacao_extrato.valor)}</p>
                                      <p>Descricao: {mov.transacao_extrato.descricao}</p>
                                      {!mov.conciliado &&
                                        <React.Fragment>
                                          <Tooltip title='Localizar' aria-label="add">
                                            <Button
                                              color='info'
                                              className='btn_search' 
                                              variant="contained" 
                                              endIcon={<SearchIcon />} 
                                              onClick={() => localizar(mov.transacao_extrato)}
                                              style={{
                                                position: 'absolute',
                                                right: 10,
                                                bottom: 10
                                              }}
                                            ></Button>
                                          </Tooltip>
                                          <Tooltip title='Adicionar' aria-label="add">
                                            <Button 
                                              className='btn_search' 
                                              variant="contained" 
                                              endIcon={<AddIcon />} 
                                              onClick={e => criarMovimentacao(mov.transacao_extrato)}
                                              color='secondary'
                                              style={{
                                                position: 'absolute',
                                                right: 50,
                                                bottom: 10
                                              }}
                                            ></Button>
                                          </Tooltip>
                                        </React.Fragment>
                                      }
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} direction="row">
                                    <Grid item md={12} xs={12} sm={12}>
                                      {mov.conciliado &&
                                        <React.Fragment>
                                          <p><CheckCircleIcon/><span style={{fontSize: 14}}> Mov. Conciliada</span></p>
                                        </React.Fragment>
                                      }
                                    </Grid>
                                  </Grid>
                                </div>
                              }
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </Grid>
                    {/* <Grid item md={2} xs={12} sm={1}></Grid> */}
                    <Grid item md={6} xs={12} sm={6} style={{paddingLeft: 40}}>
                      <div className='movimentacoes'>
                        {movimentacoes_extrato_filtrado.map((mov, key) => {
                          return (
                            <React.Fragment key={key}>
                              {mov.transacao_sistema &&
                                <div className={mov.conciliado ? `card_extrato_green` : `card_extrato_yellow`} style={{marginBottom: 10}}>
                                  <Grid container spacing={1} direction="row">
                                    <Grid item md={12} xs={12} sm={12}>
                                      {!mov.conciliado &&
                                        <React.Fragment>
                                          {mov_selecionadas_sistema.findIndex(param => param.key === mov.transacao_sistema.key) === -1 &&
                                            <Tooltip title='Vincular' aria-label="add">
                                              <IconButton className='button_selecionar' color="primary" aria-label="editar" onClick={() => vincularSistema(mov.transacao_sistema)}>
                                                <RadioButtonUncheckedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                          {mov_selecionadas_sistema.findIndex(param => param.key === mov.transacao_sistema.key) !== -1 &&
                                            <Tooltip title='Desvincular' aria-label="add">
                                              <IconButton className='button_selecionar' color="primary" aria-label="editar" onClick={() => desvincularSistema(mov.transacao_sistema)}>
                                                <CheckCircleIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                        </React.Fragment>
                                      }
                                      <p>Data Movimentação: {moment(mov.transacao_sistema.data).format('DD/MM/YYYY')}</p>
                                      <p>Valor:R$ {real(mov.transacao_sistema.valor)}</p>
                                      <p>Descricao: {mov.transacao_sistema.descricao}</p>
                                      {!mov.conciliado &&
                                        <React.Fragment>
                                          <Tooltip title='Editar' aria-label="add">
                                            <Button 
                                              className='btn_search' 
                                              variant="contained"
                                              color="secondary"
                                              endIcon={<CreateIcon />} 
                                              onClick={() => editarMov(mov.transacao_sistema)}
                                              style={{
                                                position: 'absolute',
                                                right: 10,
                                                bottom: 10
                                              }}
                                            ></Button>
                                          </Tooltip>
                                        </React.Fragment>
                                      }
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} direction="row">
                                    <Grid item md={12} xs={12} sm={12}>
                                      {mov.conciliado &&
                                        <React.Fragment>
                                          <p><CheckCircleIcon/><span style={{fontSize: 14}}> Mov. Conciliada</span></p>
                                        </React.Fragment>
                                      }
                                    </Grid>
                                  </Grid>
                                  
                                </div>
                              }
                              {/* {!mov.transacao_sistema &&
                                <div className='card_extrato_red'>
                                  <p>Movimentação não localizada</p>
                                </div>
                              } */}
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </Grid>
                  </Grid>
                  <hr />
                </React.Fragment>
              }
              <Grid container spacing={2} direction="row" className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <Link to="/movimentacao_financeira">
                    <Button color='secondary' size='small' fullWidth variant="contained" startIcon={<ArrowBackIcon />}>
                      Voltar
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
            <br />
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
          <ModalConfirmacao 
            open={state.openModalConfirmacao} 
            handleClose={e => handleCloseConfirmacao(e)}
            dados={state.modal} 
            confirmar={e => confirmarConciliacao(e, state.mov_selecionadas_sistema)}
          />
          <ModalConfirmacaoLocalizar
            open={state.openModalConfirmacaoLocalizar} 
            handleClose={e => handleCloseConfirmacaoLocalizar(e)}
            dados={state.modal} 
            confirmar={e => continuarLocalizacao(e)}
          />
          <ModalConfirmacaoConciliar 
            open={state.openModalConfirmacaoConciliar} 
            handleClose={e => handleCloseConfirmacaoConciliar(e)}
            dados={state.modalConciliar} 
            confirmar={e => conciliarMovimentacao(e, mov_selecionadas_sistema)}
          />
          <ModalLocalizarMovimentacao 
            open={state.openModalLocalizarMovimentacao}
            handleClose={e => handleCloseLocalizarMovimentacao(e)}
            filtro={state.filtro}
            updateFiltro={(e) => updateFiltro(e)}
            pessoas={state.pessoas}
            cliente={state.cliente}
            formasPagamento={state.formasPagamento}
            formasSelecionadas={state.formasSelecionadas}
            situacoes={state.situacoes}
            situacoesSelecionadas={state.situacoesSelecionadas}
            updateFieldPessoaFiltro={(event, value) => updateFieldPessoaFiltro(event, value)}
            updateFiltroPessoa={(e) => updateFiltroPessoa(e)}
            updateFieldForma={(event, value) => updateFieldForma(event, value)}
            updateFieldSituacao={(event, value) => updateFieldSituacao(event, value)}
            filtrar={(e) => filtrar(e)}
            handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
            handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
            contas={state.contas}
            rows={state.rows}
            loadingTable={state.loadingTable}
            selecionarMov={(e) => selecionarMov(e)}
            movimentacoes_selecionadas={state.movimentacoes_selecionadas}
            confirmarSelecionados={() => confirmarLocalizacao()}
            tab={state.tab}
            handleChangeTab={(event, newValue) => handleChangeTab(event, newValue)}
            contaFinanceiras={state.contaFinanceiras}
            filtroMovimentacao={state.filtroMovimentacao}
            movimentacoes={state.movimentacoes}
            updateFiltroMovimentacao={(e) => updateFiltroMovimentacao(e)}
            filtrarMovimentacao={() => filtrarMovimentacao()}
            contas_selecionadas={state.contas_selecionadas}
          />

          <ModalLançarContaExtrato
            open={state.openModalLançarContaExtrato} 
            handleClose={e => handleCloseModalLançarContaExtrato(e)}
            movimentacao_extrato={state.movimentacao_extrato}
            selecionar={(e) => selecionarTela(e)}
          />

          <ModalAjustarValorConciliacao 
            open={state.openModalAjustarValorConciliacao } 
            handleClose={e => handleCloseModalAjustarValorConciliacao(e)}
            mov_selecionadas_extrato={state.mov_selecionadas_extrato}
            mov_selecionadas_sistema={state.mov_selecionadas_sistema_aux}
            updateField={(e, key) => updateFieldMovimentacao(e, key)}
            confirmar={(e) => confirmarValoresDiferente(e)}
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default ImportarExtrato