import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl, Checkbox } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import Alert from './../../../components/templates/Alert'
import { format } from 'cnpj'
import UploadCertificado from './../../../services/uploads/UploadCertificado'
import {filesize} from "filesize";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import Color from 'color';
import AddIcon from '@mui/icons-material/Add'
import Table from './../../../components/Table'
import InfoIcon from '@mui/icons-material/Info';
import { cnpj } from 'cpf-cnpj-validator';

const cpf = require('node-cpf-cnpj');

const ColorGreenButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  backgroundColor: Color('#008000').alpha(0.9).string(),
  '&:hover': {
    backgroundColor: '#008000',
  },
}));

const initialState = {
  unidade: {},
  loading: true,
  dados_emissao: {
    habilitar_emissao: false,
    cnpj: '',
		cpf: '',
		ie: '',
    im: '',
		nome_completo: '',
		razao_social: '',
		nome_fantasia: '',
		tipo_tributacao: '',
    senha_certificado: '',
		email: '',
    natureza_operacao: '',
    exigibilidade_iss: '',
    iss_retido:'',
    responsavel_retencao_iss: '',
    certificado_informado: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  tab: 0,
  uploadedFiles: [],
  showPassword: false,
  tab_cnae: {
    list: []
  },
  cnae: {
    id: '',
    descricao: '',
    codigo: '',
    principal_switch: false,
    principal: false
  },
  cnaes_unidade: [],
  cabecalhoTabela:  [
    { id: 'codigo', numeric: false, disablePadding: true, label: 'Codigo' },
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Descrição' },
    { id: 'principal_switch', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'ativo_switch', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  servicos: [],
  cabecalhoTabelaServicos: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Serviço' },
    { id: 'tributacao', numeric: false, disablePadding: false, label: 'Tributação' }
  ],
  servicos_receita: [],
  loadingSalvar: false,
  expiration: null
}

export default class EmissaoNFSE extends Component {

  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const unidade = this.props.unidade

    let email = unidade.pessoa.emails.filter(param => param.principal)[0]

    try {

      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade.id}`, this.getToken())

      this.setState({
        unidade,
        dados_emissao: {
          habilitar_emissao: configuracao ? configuracao.habilitar_emissao : false,
          cnpj: unidade.pessoa.tipo === 'JURIDICA' ? format(unidade.pessoa.juridica.cnpj) : '',
          cpf: unidade.pessoa.tipo === 'FISICA' ? cpf.format(unidade.pessoa.fisica.cpf) : '',
          ie: unidade.pessoa.tipo === 'JURIDICA' ? unidade.pessoa.juridica.inscricaoestadual.replace(/[^0-9.]+/g, '') : '',
          im: unidade.pessoa.inscricao_municipal ? (unidade.pessoa.inscricao_municipal.replace(/[^0-9.]+/g, '') || '') : '', 
          nome: unidade.pessoa.tipo === 'FISICA' ? unidade.pessoa.fisica.nome : '',
          sobrenome: unidade.pessoa.tipo === 'FISICA' ? unidade.pessoa.fisica.sobrenome : '',
          razao_social: unidade.pessoa.tipo === 'JURIDICA' ? unidade.pessoa.juridica.razaosocial : '',
          nome_fantasia: unidade.pessoa.tipo === 'JURIDICA' ? unidade.pessoa.juridica.nomefantasia : '',
          tipo_tributacao: configuracao ? configuracao.tipo_tributacao_nfse : '',
          natureza_operacao: configuracao ? configuracao.natureza_operacao : '',
          exigibilidade_iss: configuracao ? configuracao.exigibilidade_iss : '',
          iss_retido:configuracao ? configuracao.iss_retido : '',
          responsavel_retencao_iss: configuracao ? configuracao.responsavel_retencao_iss : '',
          email: email ? email.email : '',
          senha_certificado: '',
          certificado_informado: configuracao ? (configuracao.certificado_a1 ? true : false) : false,
          nfse_empresa_id: configuracao.nfse_empresa_id || ''
        }
      })

      const { data: tab_cnae } = await axios.get(`${window._env_.REACT_APP_API_URL}/cnae`, this.getToken())
    
      this.setState({
        tab_cnae: {
          list: tab_cnae.map((value, key) =>  {
            return {
              ...value,
              ativo_switch: value.ativo,
              principal_switch: false,
              principal: false,
              index: key
            }
          })
        }
      })

      const { data: cnaes_unidades } = await axios.get(`${window._env_.REACT_APP_API_URL}/cnae/listByUnidade/${unidade.id}`, this.getToken())

      let servicos_receita = []
      
      for (const cnae of cnaes_unidades) {
        if(cnae.ativo){
          let servicos_cnae = cnae.tab_cnae.serv_rec_cnae
          for (const servico_receita of servicos_cnae) {
            servicos_receita.push({
              ...servico_receita.serv_rec,
              cnae_id: cnae.cnae_id
            })
          }
        }
      }

      this.setState({
        cnaes_unidade: cnaes_unidades.map(value => {
          return {
            id: value.cnae_id,
            codigo: value.tab_cnae.codigo,
            descricao: value.tab_cnae.descricao,
            ativo_switch: value.ativo,
            ativo: value.ativo,
            tab_cnae: value.tab_cnae,
            principal_switch: value.principal,
            principal: value.principal
          }
        }),
        servicos_receita
      })

      const { data: servicos } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/servicosByUnidade/${unidade.id}`, this.getToken())

      this.setState({
        servicos: servicos.map((value, key) => {
          return {
            servico_id: value.servico.id,
            descricao: value.descricao,
            servico_receita_id: value.servico.tributacao_id ? value.servico.tributacao.servico_receita_id : '',
            tributacao_id: value.servico.tributacao_id,
            index_servico: value.servico.tributacao_id ? servicos_receita.findIndex(param => param.id === value.servico.tributacao.servico_receita_id) : '',
            index: key
          }
        })
      })

      if(configuracao){
        if(configuracao.nfse_empresa_id){
          const { data: validade_certificado } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/validadeCertificado/${unidade.id}`, this.getToken())
        
          this.setState({
            expiration: validade_certificado.expiration
          })
        }
      }

      
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
        
    this.setState({
      loading:false
    })
    
  }

  handleCloseAlerta() {
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFieldHabilitarEmissao(){
    const {dados_emissao} = this.state
    dados_emissao.habilitar_emissao = !dados_emissao.habilitar_emissao
    this.setState({dados_emissao})
  }

  updateFieldEmissao(event){
    const {dados_emissao} = this.state

    let name = event.target.name
    let value = event.target.value

    if(name === 'ie'){
      dados_emissao[name] = value.replace(/[^0-9.]+/g, '')
     
    }else{
      dados_emissao[name] = value
    }

    this.setState({dados_emissao})
  }

  async validarCpfCnpj(event) {
    if(!cnpj.isValid(event.target.value)){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CNPJ Invalido'
        }
      })
    }

    const {dados_emissao} = this.state
    dados_emissao[event.target.name] =cnpj.format(event.target.value)
    this.setState({dados_emissao})
  }

  handleUploadCertificado = files => {
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    this.setState({
      uploadedFiles
    })
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  updateFieldCNAE(event){
    let {tab_cnae, cnae} = this.state
    
    let item = tab_cnae.list.filter(param => {
      let codigo = param.codigo.replace(/\D/g,"")
      let value = event.target.value.replace(/\D/g,"")

      if(codigo[0] === '0'){
        codigo = codigo.substring(1)
      }

      if( codigo === value ){
        return true
      }

      return false
    })[0]

    cnae[event.target.name] = event.target.value

    this.setState({
      cnae
    })

    if(item){
      this.setState({
        cnae: {
          id: item.id,
          codigo: event.target.value,
          ativo: item.ativo,
          ativo_switch: item.ativo_switch,
          descricao: item.descricao,
          index: item.index,
          serv_rec_cnae: item.serv_rec_cnae,
          principal: false,
          principal_switch: false
        }
      })
    }else{
      this.setState({
        cnae: {
          id: '',
          descricao: '',
          codigo: event.target.value,
          principal: false,
          principal_switch: false
        },
      })
    }

    
  }

  verificaCNAE(){
    let {cnae} = this.state

    if(cnae.descricao === ''){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CNAE Não encontrado.'
        }
      })
    }
  }

  adicionarCNAE(){
    let {cnae, cnaes_unidade, servicos_receita} = this.state

    if(cnae.id !== ''){

      if(cnae.principal){
        let index_principal = cnaes_unidade.findIndex(param => param.principal)

        if(index_principal !== -1){
          cnaes_unidade[index_principal].principal = false
          cnaes_unidade[index_principal].principal_switch = false
        }
      }

      let index = cnaes_unidade.findIndex(param => param.id === cnae.id)

      if(index !== -1){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CNAE já'
          }
        })
        return
      }

      cnaes_unidade.push({...cnae})

      for (const value of cnae.serv_rec_cnae) {
        servicos_receita.push({
          ...value.serv_rec,
          cnae_id: cnae.id
        })
      }
  
      this.setState({
        cnaes_unidade,
        cnae: {
          id: '',
          descricao: '',
          codigo: '',
          principal: false,
          principal_switch: false
        },
      })
    }else{
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o CNAE.'
        }
      })
      return
    }

  }

  updateFieldCNAEPrincipal(){
    let {cnae} = this.state
    cnae.principal = !cnae.principal
    cnae.principal_switch = !cnae.principal_switch
    this.setState({cnae})
  }

  updateFieldAtivo(row){
    let {cnaes_unidade} = this.state
    
    let index  = cnaes_unidade.findIndex(param => param.id === row.id)

    cnaes_unidade[index].ativo_switch = !cnaes_unidade[index].ativo_switch
    cnaes_unidade[index].ativo = !cnaes_unidade[index].ativo
    
    if(cnaes_unidade[index].ativo){
      
    }else{

    }

    this.setState({
      cnaes_unidade
    })
  }

  updateFieldPrincipalTabela(row){
    let {cnaes_unidade} = this.state

    
    let index  = cnaes_unidade.findIndex(param => param.id === row.id)
    
    if(cnaes_unidade.length === 1 && row.principal){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor deixar ao menos um item como principal'
        }
      })
      return false
    }

    let index_principal = cnaes_unidade.findIndex(param => param.principal)

    if(index_principal !== -1){
      cnaes_unidade[index_principal].principal = false
      cnaes_unidade[index_principal].principal_switch = false
    }

    cnaes_unidade[index].principal_switch = !cnaes_unidade[index].principal_switch
    cnaes_unidade[index].principal = !cnaes_unidade[index].principal

    this.setState({
      cnaes_unidade
    })

  }

  updateFieldServicoReceita(event, row){
    const {servicos, servicos_receita} = this.state
    
    servicos[row.index].servico_receita_id = parseInt(event.target.value)
    servicos[row.index].index_servico = servicos_receita.findIndex(param => param.id === parseInt(event.target.value))

    this.setState({
      servicos
    })
  }

  updateFieldTributacao(event, row){
    const {servicos} = this.state
    
    servicos[row.index].tributacao_id = parseInt(event.target.value)

    this.setState({
      servicos
    })
  }

  async confirmarEmissao(){
    const {dados_emissao, unidade, uploadedFiles, cnaes_unidade, servicos} = this.state
    
    this.setState({
      loadingSalvar: true
    })

    if(dados_emissao.habilitar_emissao){
     
      if(unidade.pessoa.tipo === 'JURIDICA'){
        if(dados_emissao.razao_social === '' || !dados_emissao.razao_social){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a razao social da unidade.'
            },
            loadingSalvar: false
          })
          return
        }

        if(dados_emissao.nome_fantasia === '' || !dados_emissao.nome_fantasia){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o nome fantasia da unidade.'
            },
            loadingSalvar: false
          })
          return
        }

        if(dados_emissao.cnpj === '' || !dados_emissao.cnpj){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o CNPJ da unidade.'
            },
            loadingSalvar: false
          })
          return
        }

        if(!cnpj.isValid(dados_emissao.cnpj)){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'CNPJ Invalido'
            },
            loadingSalvar: false
          })
          return false
        }

        if((dados_emissao.ie === '' || dados_emissao.ie === 'ISENTO' || dados_emissao.ie === 'Isento' || dados_emissao.ie === 'isento') && dados_emissao.im === ''){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a Inscrição Estadual ou a Inscrição Municipal da unidade.'
            },
            loadingSalvar: false
          })
          return
        }
      }else{
        if(dados_emissao.nome === '' || !dados_emissao.nome){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o nome da pessoa da unidade.'
            },
            loadingSalvar: false
          })
          return
        }

        if(dados_emissao.cpf === '' || !dados_emissao.cpf){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o cpf da pessoa da unidade.'
            },
            loadingSalvar: false
          })
          return
        }
      }

      if(dados_emissao.tipo_tributacao === '' || !dados_emissao.tipo_tributacao){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o tipo de tributação da unidade.'
          },
          loadingSalvar: false
        })
        return
      }

      if(dados_emissao.natureza_operacao === '' || !dados_emissao.natureza_operacao){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a natureza da operação.'
          },
          loadingSalvar: false
        })
        return
      }

      if(dados_emissao.exigibilidade_iss === '' || !dados_emissao.exigibilidade_iss){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a exigibilidade do ISS.'
          },
          loadingSalvar: false
        })
        return
      }

      if(dados_emissao.iss_retido === '' || !dados_emissao.iss_retido){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o ISS retido.'
          },
          loadingSalvar: false
        })
        return
      }

      if(dados_emissao.iss_retido === 1){
        if(dados_emissao.responsavel_retencao_iss === '' || !dados_emissao.responsavel_retencao_iss){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o responsável da retenção do ISS.'
            },
            loadingSalvar: false
          })
          return
        }
      }

      if(!dados_emissao.certificado_informado){
        if(uploadedFiles.length === 0){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o certificado A1.'
            },
            loadingSalvar: false
          })
          return
        }

        if(dados_emissao.senha_certificado === ''){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a senha do certificado 1.'
            },
            loadingSalvar: false
          })
          return
        }
      }

      if(cnaes_unidade.length === 0){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o CNAE da unidade.'
          },
          loadingSalvar: false
        })
        return
      }

      let cnaes_ativos = cnaes_unidade.filter(param => param.ativo)
      
      if(cnaes_ativos.length === 0){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar pelo menos um CNAE ativo.'
          },
          loadingSalvar: false
        })
        return
      }

      if(servicos.length > 0){
        for (const servico of servicos) {
          if(servico.tributacao_id === '' || !servico.tributacao_id) {
            this.setState({
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor iformar os codigos de tributação de todos os serviços.'
              },
              loadingSalvar: false
            })
            return
          }
        }
      }
    }
    
    try {

      let data = new FormData();

      if(uploadedFiles[0]) data.append("file", uploadedFiles[0].file, uploadedFiles[0].name);

      data.append("unidade_id", unidade.id);
      data.append("habilitar_emissao", dados_emissao.habilitar_emissao);
      data.append("cnpj", dados_emissao.cnpj);
      data.append("cpf", dados_emissao.cpf);
      data.append("ie", dados_emissao.ie);
      data.append("im", dados_emissao.im);
      data.append("nome", dados_emissao.nome);
      data.append("sobrenome", dados_emissao.sobrenome);
      data.append("razao_social", dados_emissao.razao_social);
      data.append("nome_fantasia", dados_emissao.nome_fantasia);
      data.append("tipo_tributacao", dados_emissao.tipo_tributacao);
      data.append("email", dados_emissao.email);
      data.append("senha_certificado", dados_emissao.senha_certificado);
      data.append("natureza_operacao", dados_emissao.natureza_operacao);
      data.append("exigibilidade_iss", dados_emissao.exigibilidade_iss);
      data.append("iss_retido", dados_emissao.iss_retido);
      data.append("responsavel_retencao_iss", dados_emissao.responsavel_retencao_iss);

      if(cnaes_unidade.length > 0) {
        data.append("cnaes_unidade", JSON.stringify(cnaes_unidade));
      }

      if(servicos.length > 0) {
        data.append("servicos", JSON.stringify(servicos));
      }

      const USER_TOKEN = localStorage.getItem('token')

      const config = {
        headers: {
          'Authorization': 'Bearer ' + USER_TOKEN,
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }

      if(dados_emissao.nfse_empresa_id === ''){
        await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/criarEmpresa`, data, config)
      }else{
        await axios.put(`${window._env_.REACT_APP_API_URL}/nfse/atualizaEmpresa`, data, config)
      }

    
      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loadingSalvar: false
      })

      window.location.reload()
    
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      })
    }
  }
 

  render() {
    const { dados_emissao, unidade, uploadedFiles, showPassword, cnae, cnaes_unidade, servicos, 
      servicos_receita, loadingSalvar } = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados de Emissão de NFSE...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={11} xs={12} sm={11}>
                <h1 className="titulo">Emissão NFSE: </h1>
              </Grid>
              <Grid item md={1}>
                  {loadingSalvar &&
                    <div> <CircularProgress /> </div>
                  }
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={3} xs={4} sm={12}>
                <h5>Dados da Unidade:</h5>
              </Grid>
              <Grid item md={9} xs={8} sm={12} style={{textAlign: 'right'}}>
                <span><a href="http://servicos.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp" target='blank'><InfoIcon style={{color: '#063e11'}}/>Consultar dados do CNPJ na Receita</a></span>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={2} xs={12} sm={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Hibilitar Emissão?</FormLabel>
                  <FormControlLabel className="mg_left_10"
                    control={
                      <Switch
                        name="Ativo"
                        color="primary"
                        checked={dados_emissao.habilitar_emissao}
                      />
                    }
                    size="small"
                    label={dados_emissao.habilitar_emissao ? 'Habilitada' : 'Desabilitada'}
                    name="habilitar_emissao"
                    onChange={(e) => this.updateFieldHabilitarEmissao(e)}
                  />
                </FormControl>
              </Grid>
              {dados_emissao.habilitar_emissao &&
                <React.Fragment>
                  {unidade.pessoa.tipo === 'JURIDICA' &&
                    <React.Fragment>
                      <Grid item md={3} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="Razão Social" 
                          variant="outlined" 
                          size="small" 
                          name="razao_social" 
                          value={dados_emissao.razao_social} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item md={3} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="Nome Fantasia" 
                          variant="outlined" 
                          size="small" 
                          name="nome_fantasia" 
                          value={dados_emissao.nome_fantasia} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="CNPJ" 
                          variant="outlined" 
                          size="small" 
                          name="cnpj" 
                          value={dados_emissao.cnpj} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          onBlur={(e) => this.validarCpfCnpj(e)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="Inscrição Estadual"
                          variant="outlined" 
                          size="small" 
                          name="ie" 
                          value={dados_emissao.ie} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  {unidade.pessoa.tipo === 'FISICA' && 
                    <React.Fragment>
                      <Grid item md={3} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="Nome" 
                          variant="outlined" 
                          size="small" 
                          name="nome" 
                          value={dados_emissao.nome} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item md={3} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="Sobrenome" 
                          variant="outlined" 
                          size="small" 
                          name="sobrenome" 
                          value={dados_emissao.sobrenome} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item md={3} xs={12} sm={4} className="mg_top_10">
                        <TextField 
                          className="input" 
                          label="CPF" 
                          variant="outlined" 
                          size="small" 
                          name="cpf" 
                          value={dados_emissao.cpf} 
                          onChange={(e) => this.updateFieldEmissao(e)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </Grid>
            {dados_emissao.habilitar_emissao && 
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  {unidade.pessoa.tipo === 'JURIDICA' && 
                    <Grid item md={2} xs={12} sm={4}>
                      <TextField 
                        className="input" 
                        label="Inscrição Municipal" 
                        variant="outlined" 
                        size="small" 
                        name="im" 
                        value={dados_emissao.im} 
                        onChange={(e) => this.updateFieldEmissao(e)} 
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  }
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField 
                      className="input" 
                      label="Email" 
                      variant="outlined" 
                      size="small" 
                      name="email" 
                      value={dados_emissao.email} 
                      onChange={(e) => this.updateFieldEmissao(e)} 
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo da Tributação"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipo_tributacao"
                      value={dados_emissao.tipo_tributacao}
                      onChangeCapture={(e) => this.updateFieldEmissao(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value={''}> Selecionar Tributação </option>
                      <option value={'simples_nacional'}> Simples Nacional </option>
                      {/* <option value={'lucro_normal'}> Lucro Normal </option> */}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12} sm={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Natureza da Operação"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="natureza_operacao"
                      value={dados_emissao.natureza_operacao}
                      onChangeCapture={(e) => this.updateFieldEmissao(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value={''}> Selecionar Operação </option>
                      <option value={1}> Tributação no município </option>
                      <option value={2}> Tributação fora do município </option>
                      <option value={3}> Isenção </option>
                      <option value={4}> Imune </option>
                      <option value={5}> Exigibilidade suspensa por decisão judicial </option>
                      <option value={6}> Exigibilidade suspensa por procedimento administrativo </option>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Exigibilidade ISS"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="exigibilidade_iss"
                      value={dados_emissao.exigibilidade_iss}
                      onChangeCapture={(e) => this.updateFieldEmissao(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value={''}> Selecionar Exigibilidade </option>
                      <option value={1}> Exigível </option>
                      <option value={2}> Não incidência </option>
                      <option value={3}> Isenção </option>
                      <option value={4}> Exportação </option>
                      <option value={5}> Imunidade </option>
                      <option value={6}> Exigibilidade suspensa por decisão judicial </option>
                      <option value={7}> Exigibilidade suspensa por processo administrativo </option>
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="ISS Retido"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="iss_retido"
                      value={dados_emissao.iss_retido}
                      onChangeCapture={(e) => this.updateFieldEmissao(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value={''}> Selecionar ISS Retido </option>
                      <option value={1}> Sim </option>
                      <option value={2}> Não </option>
                    </TextField>
                  </Grid>
                  {parseInt(dados_emissao.iss_retido) === 1 &&
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Responsável Retencao ISS"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="responsavel_retencao_iss"
                        value={dados_emissao.responsavel_retencao_iss}
                        onChangeCapture={(e) => this.updateFieldEmissao(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value={''}> Selecionar Responsável </option>
                        <option value={1}> Tomador </option>
                        <option value={2}> Intermediário </option>
                      </TextField>
                    </Grid>
                  }
                </Grid>
                <br />
                <hr />
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>Certificado A1: {this.state.expiration && 
                      <span
                        style={{
                          color: this.state.expiration > 30 ? '#008000' : '#FF0000' 
                        }}
                      >Expira em {this.state.expiration} Dias</span>
                    }</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={3} xs={12} sm={4}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={12}>
                        <UploadCertificado onUpload={e => this.handleUploadCertificado(e)} />
                      </Grid>
                    </Grid>
                    {uploadedFiles[0] &&
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12} sm={12}>
                          <p>{uploadedFiles[0] ? uploadedFiles[0].name : ''}</p>
                        </Grid>
                      </Grid>
                    }
                    {dados_emissao.certificado_informado &&
                      <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={12}>
                        <p style={{margin:0}}>Certificado e senha já informado!</p>
                      </Grid>
                    </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField 
                      className="input" 
                      label="Senha Certificado"
                      type={showPassword ? "text" : "password"}
                      variant="outlined" 
                      size="small" 
                      name="senha_certificado" 
                      value={dados_emissao.senha_certificado} 
                      onChange={(e) => this.updateFieldEmissao(e)} 
                      InputLabelProps={{ shrink: true }}
                    />
                    {!showPassword &&
                      <VisibilityIcon className='visibilityPasswordCertificado' onClick={e => this.handleClickShowPassword()}/>
                    }
                    {showPassword &&
                      <VisibilityOffIcon className='visibilityPasswordCertificado' onClick={e => this.handleClickShowPassword()}/>
                    }
                  </Grid>
                </Grid>
                <br />
                <hr />
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>CNAE's Unidade:</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={2} xs={12} sm={8}>
                    <TextField 
                      className="input" 
                      label="Codigo"
                      type={"text"}
                      variant="outlined" 
                      size="small" 
                      name="codigo" 
                      value={cnae.codigo} 
                      onChange={(e) => this.updateFieldCNAE(e)}
                      onBlur={(e) => this.verificaCNAE(e)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} sm={8}>
                     <TextField 
                      className="input" 
                      label="Descrição"
                      type={"text"}
                      variant="outlined" 
                      size="small" 
                      name="descricao" 
                      value={cnae.descricao} 
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={8}>
                    <span>CNAE Principal</span>
                    {cnae.principal &&
                      <Checkbox
                        checked={true}
                        label="Principal"
                        onChange={() => this.updateFieldCNAEPrincipal()}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    {!cnae.principal &&
                      <Checkbox
                        checked={false}
                        label="Principal"
                        onChange={() => this.updateFieldCNAEPrincipal()}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button
                      color='success' 
                      fullWidth 
                      onClick={() => this.adicionarCNAE()}
                      variant="contained" 
                      size="small"
                      endIcon={<AddIcon />}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={8} xs={12} sm={12}>
                    <Table
                      headCell={this.state.cabecalhoTabela} 
                      rows={cnaes_unidade} 
                      acoes={this.state.acoesTabela}
                      updateFieldAtivo={(e) => this.updateFieldAtivo(e)}
                      updateFieldPrincipal={(e) => this.updateFieldPrincipalTabela(e)}
                    />
                  </Grid>
                </Grid>
                <br />
                <hr />
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>Serviços da Unidade:</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <Table
                      headCell={this.state.cabecalhoTabelaServicos} 
                      rows={servicos} 
                      acoes={this.state.acoesTabela}
                      servicos_receita={servicos_receita}
                      updateFieldServicoReceita={(e, row) => this.updateFieldServicoReceita(e, row)}
                      updateFieldTributacao={(e, row) => this.updateFieldTributacao(e, row)}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            <br />
            <hr />
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={10} xs={false} sm={4}></Grid>
              <Grid item md={2} xs={false} sm={4}>
                <Button fullWidth onClick={() => this.confirmarEmissao()} color="primary" variant="contained" size="small" disabled={loadingSalvar}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}