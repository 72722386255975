import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, FormControlLabel, Switch, FormControl, FormLabel } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

const FormNotificacao = ({ dados, updateField, formataTelefone, unidades, unidadesSelecionadas, updateFieldUnidade, updateFieldAtivo, openModalSelecionarLogo, logo_unidade }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Nome da Instância*" 
          variant="outlined" 
          size="small" 
          name="nome" 
          value={dados.nome} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Número*" 
          variant="outlined" 
          size="small" 
          name="numero" 
          value={dados.numero} 
          onBlur={(e) => formataTelefone(e)}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Api Token*" 
          variant="outlined" 
          size="small" 
          name="api_key" 
          value={dados.api_key} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      {dados.id !== '' &&
        <React.Fragment>
          <Grid item md={6} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Instância ID*" 
              variant="outlined" 
              size="small" 
              name="instancia_id" 
              value={dados.instancia_id} 
              disabled
              InputLabelProps={{ shrink: true}} 
            />
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Instância Token*" 
              variant="outlined" 
              size="small" 
              name="instancia_token" 
              value={dados.instancia_token}
              disabled
              InputLabelProps={{ shrink: true}} 
            />
          </Grid>
        </React.Fragment>
      }
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={10} xs={12} sm={6}>
        <Autocomplete
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={unidades.filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.id === unidade.id) === -1)
              return unidade;
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => option.descricao}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades*" InputLabelProps={{ shrink: true}} />
          )}
        />
      </Grid>
      <Grid item md={1} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    {dados.id !== '' &&
      <React.Fragment>
        <Grid container direction="row" spacing={1}>
          <Grid item md={6} xs={12} sm={6}>
            {(dados.qrCode === '' && !dados.conectado) &&
              <React.Fragment>
                <div className="progressCircular">
                  <CircularProgress />
                </div>
                <div className="progressText">
                  <p>Carregando QrCode...</p>
                </div>
              </React.Fragment>
            }

            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h4>Situação: <span style={{color: dados.conectado ? 'green' : 'red' }}> {dados.conectado ? 'CONECTADO' : 'DESCONECTADO'}</span></h4>
              </Grid>
            </Grid>
            {(dados.qrCode !== '' || !dados.conectado) &&
              <React.Fragment>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>Leia o QrCode para conectar o número</h5>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                    <img src={dados.qrCode} alt="" srcset="Qrcode" />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormNotificacao