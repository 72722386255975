export default {
  cliente: {
    _id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_data_inicio_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: "",
      resp_fin: []
    }
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }],
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesAltPessoa: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'success',
    message: ''
  },
  dadosComplementaresPF: [],
  formatos: [
    {
      type: "text",
      data_type: "character varying"
    },
    {
      type: "date",
      data_type: "date"
    },
    {
      type: "number",
      data_type: "integer"
    },
  ],
  openModalMigrarDados: false,
  clientes:{
    list:[]
  },
  clientesFiltrado: {
    list: []
  },
  clienteSelecionado: {
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  cabecalhoTabelaEndereco: [
    { id: 'cep', numeric: false, disablePadding: false, label: 'CEP' },
    { id: 'endereco', numeric: false, disablePadding: false, label: 'Endereço' },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'estado', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoEndereco', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  acoesTabelaEndereco: [],
  cabecalhoTabelaTelefone: [
    { id: 'numero', numeric: false, disablePadding: false, label: 'Número' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
    { id: 'tipoContato', numeric: false, disablePadding: false, label: 'Tipo' }
  ],
  cabecalhoTabelaEmail: [
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'Principal' },
  ],
}