import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { saveAs } from 'file-saver'
import Alert from './../../components/templates/Alert'
import Table from '../../components/Table'
import { format } from 'cnpj'
import ModalRelatorio from './../../components/modals/Relatorios'
import real from './../../services/real'
import ModalAjuda from './../../components/modals/Ajuda'

const cpf = require('node-cpf-cnpj');

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const initialState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    data_por: '',
    unidade_id: '',
    sexo: '',
    email: '',
    cep: '',
    uf_id: '',
    cidade_id: '',
    codigo_cliente: '',
    profissao: '',
    metodo_id: '',
    limit: 10,
    offset: 0
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlView2: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  planosConta: {
    list: []
  },
  ModalRelatorio: false,
  funcionarios:{
    list:[]
  },
  pessoas:{
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  tituloSubRel: '',
  valor_total_sub: 0,
  estados: {
    list: []
  },
  cidades: {
    list: []
  },
  metodosDivugacao: {
    list: []
  },
  profissoes: {
    list: []
  },
  openModalAjuda: false,
  markdown: ''
}

let watchID = ''

export default class Financeiro extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    const helpPath = require("./../../help/relatorios/Administrativos.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'rel-adm')

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            descricao: value.tela.descricao,
            slug: value.tela.slug,
            perm: {
              visualizar: value.visualizar
            }
          }
        })
      }
    })

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            let descricao = ''
            if (unidade.tipo === 'MANTENEDOR') {
              descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            }if (unidade.tipo === 'REPRESENTANTE') {
              descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            } else if (unidade.tipo === 'GRUPOECONOMICO') {
              descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            } else if (unidade.tipo === 'UNIDADE') {
              descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
            }

            return {
              id: unidade.id,
              descricao,
              tipo: unidade.tipo
            }
          })
        }
      })

      if(unidades.length === 1){
        const {filtro} = this.state
        filtro.unidade_id = unidades[0].id
        await this.dadosFiltroUnidade(unidades[0].id)
        this.setState({filtro})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    let {filtro} = this.state

    watchID = setInterval(async () => {
      if(filtro.relatorio === 'dados-cadastro-cliente'){
        await this.filtrar()
      }
    }, 1000 * 3);
  }

  componentWillUnmount() {
    clearInterval(watchID);
  }

  async dadosFiltroUnidade(unidade_id){
    try {

      const { data: estados } = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/estados/${unidade_id}`, this.getToken())     
      
      this.setState({
        estados: {
          list: estados
        }
      })
      
      const { data: metodosDivugacao } = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/metodosDivugacao/${unidade_id}`, this.getToken())     
      
      this.setState({
        metodosDivugacao: {
          list: metodosDivugacao
        }
      })

      const { data: profissoes } = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/profissoes/${unidade_id}`, this.getToken())     
      
      this.setState({
        profissoes: {
          list: profissoes
        }
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }
  }

  async dadosFiltroCidades(){
    try {

      let {filtro} = this.state

      const { data: cidades } = await axios.get(`${window._env_.REACT_APP_API_URL}/unidades/cidades/${filtro.unidade_id}/${filtro.uf_id}`, this.getToken())     
      
      this.setState({
        cidades: {
          list: cidades
        }
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    let name = event.target.name
    let value = event.target.value

    filtro[name] = value

    if(name === 'relatorio'){
      if(value === 'dados-cadastro-cliente'){
        await this.filtrar()
      }
    }

    if(name === 'unidade_id' && value !== ''){
      await this.dadosFiltroUnidade(value)
    }

    if(name === 'uf_id' && value !== ''){
      await this.dadosFiltroCidades(value)
    }

    this.setState({ filtro })
  }

  async filtrar() {
    const {filtro} = this.state

    this.setState({
      loading: true
    })

    if (filtro.relatorio === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o relatório'
        }
      })
      this.setState({
        loading: false
      })
      return
    }

    if (filtro.unidade_id === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a unidade'
        }
      })
      this.setState({
        loading: false
      })
      return
    }

    if(filtro.relatorio === 'aniversariante'){
      if (filtro.data_inicial === '') {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data inicial'
          }
        })
        this.setState({
          loading: false
        })
        return
      }
  
      if (filtro.data_final === '') {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message:'Por favor informar a data final'
          }
        })
        this.setState({
          loading: false
        })
        return
  
      }
    }

    if(filtro.data_por !== ''){
      if (filtro.data_inicial === '') {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data inicial'
          }
        })
        this.setState({
          loading: false
        })
        return
      }
  
      if (filtro.data_final === '') {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message:'Por favor informar a data final'
          }
        })
        this.setState({
          loading: false
        })
        return
  
      }
    }
    
    try {
     
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())     
              
      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        urlView: data.urlView,
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }


  }

  async imprimir() {
    const { filtro, relatorios } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      filtro.list = relatorios.list
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      if(filtro.relatorio !== 'dados-cadastro-cliente'){
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        this.setState({
          loadingDownload: false
        })
  
        if(filtro.relatorio === 'aniversariante')
          saveAs(pdfBlob, 'Aniversariantes.pdf')
        if(filtro.relatorio === 'contratos-por-servico')
          saveAs(pdfBlob, 'Contrato-por-Servico.pdf')
        if(filtro.relatorio === 'uso-telas-sistema')
          saveAs(pdfBlob, 'Uso-Telas-Sistema.pdf')
        if(filtro.relatorio === 'contrato-ativos')
          saveAs(pdfBlob, 'Contratos-Ativo.pdf')
      }else{
        this.setState({
          cabecalhoTabela: data.cabecalhoTabela,
          acoesTabela: data.acoesTabela,
          relatorios: {
            list: data.list
          },
          urlView: data.urlView,
        })

        this.setState({
          loadingDownload: false
        })

        this.setState({
          alerta: {
            open: true,
            severity: 'success',
            message: 'Solicitação realizada com sucesso'
          }
        })
      }
      
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async handleChangePage(event, newPage){
    const {filtro} = this.state
    filtro.offset = newPage
    this.setState({
      filtro
    })
  }

  async handleChangeRowsPerPage(event){
    const {filtro} = this.state
    filtro.limit = parseInt(event.target.value)
    this.setState({
      filtro
    })
  }

  updateFieldPessoaFiltro(event, value){
    const {filtro} = this.state

    if(!value){

      filtro.pessoa_id = ''
      filtro.nome = ''

      this.setState({
        cliente: {
          pessoa_id: '',
          nome: '',
          cpf_cnpj: ''
        },
        filtro
      })

      return false
    } 

    filtro.pessoa_id = value.pessoa_id
    filtro.nome = value.nome

    this.setState({
      cliente: value
    })
  }

  async updateFiltroPessoa(event){
    if(event.target.value.length >= 3){
      await this.consultarPessoa(event.target.value)
    }
  }

  async consultarPessoa(nome){
    try {

      let filtroPessoa = {
        unidadesnegocio: [this.state.filtro.unidade_id],
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, this.getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: `${pessoa.nome} ${pessoa.sobrenome || ''}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      this.setState({
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async viewRow(ids, acao, status) {

    try {

      let filtroAux = {
        relatorio: acao,
        ids
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtroAux, this.getToken())

      const filtroSub = this.state.filtroSub

      filtroSub.relatorio = acao
      filtroSub.list = data.list

      this.setState({
        cabecalhoTabelaModal: data.cabecalhoTabela,
        acoesTabelaModal: data.acoesTabela,
        relatoriosModal: {
          list: data.list
        },
        filtroSub,
        modalRelatorio: true,
        tituloSubRel: status,
        valor_total_sub: somarValores(data.list.map(value => {return value.valor_total})),
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      return
    }
  }

  handleCloseModalRelatorio() {
    this.setState({
     modalRelatorio: false,
    })
  }

  async imprimirSub(){
    const { filtro, filtroSub, relatoriosModal } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      filtroSub.unidade_id = filtro.unidade_id
      filtroSub.data_inicial = filtro.data_inicial
      filtroSub.data_final = filtro.data_final
      filtroSub.list = relatoriosModal.list
      filtroSub.data_por = filtro.data_por
      filtroSub.servico = this.state.tituloSubRel

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtroSub, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      if(filtroSub.relatorio !== 'uso-telas-usuarios')
        saveAs(pdfBlob, 'Clientes.pdf')
      if(filtroSub.relatorio === 'uso-telas-usuarios')
        saveAs(pdfBlob, 'Telas-Usuarios.pdf')


    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async imprimirRel(row){
    try {
      
      const dadosImprimir = {
        path: row.caminho
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/xlsx' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'Dados-Clientes.xlsx')

      let {data} = await axios.put(`${window._env_.REACT_APP_API_URL}/baixar/relatorio/${row._id}/${this.state.filtro.unidade_id}`, {}, this.getToken())
      
      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        urlView: data.urlView,
      })

    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async viewUsuTela(row){
    try {
      let filtro = {
        relatorio: "uso-telas-usuarios",
        data_inicial: this.state.filtro.data_inicial,
        data_final: this.state.filtro.data_final,
        unidade_id: row.unidade_id,
        tela: row.tela
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())     

      const filtroSub = this.state.filtroSub

      filtroSub.relatorio = "uso-telas-usuarios"
      filtroSub.list = data.list

      this.setState({
        cabecalhoTabelaModal: data.cabecalhoTabela,
        acoesTabelaModal: data.acoesTabela,
        relatoriosModal: {
          list: data.list
        },
        filtroSub,
        modalRelatorio: true,
        tituloSubRel: 'Tela: ' + row.tela,
        valor_total_sub: '',
      })
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  render() {
    const { filtro, cabecalhoTabela, loadingDownload,  unidades, urlView, relatorios, acoesTabela, 
      relatorios_options, estados, cidades, metodosDivugacao, profissoes } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          <Grid container spacing={2} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatórios Administrativos</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={2} direction="row" className="mg_top_10">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(value => {
                  if (value.perm) {
                    if (value.perm.visualizar) {
                      return (
                        <option value={value.slug}>{value.descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_id"
                value={filtro.unidade_id}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Unidade</option>
                {unidades.list.map(unidade => {
                  return (
                    <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            {(filtro.relatorio === 'aniversariante' || filtro.relatorio === 'uso-telas-sistema') &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            {filtro.relatorio === 'dados-cadastro-cliente' &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Sexo"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="sexo"
                    value={filtro.sexo}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    <option value="FEMININO">Feminino</option>
                    <option value="MASCULINO">Masculino</option>
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12} sm={4}>
                  <TextField className="input" label="Email" variant="outlined" size="small" name="email" value={filtro.email} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="CEP" variant="outlined" size="small" name="cep" value={filtro.cep} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Codigo Cliente" variant="outlined" size="small" name="codigo_cliente" value={filtro.codigo_cliente} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Estado"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="uf_id"
                    value={filtro.uf_id}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Estado</option>
                    {estados.list.map(estado => {
                      return (
                        <option key={estado.id} value={estado.id}> {estado.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Cidade"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cidade_id"
                    value={filtro.cidade_id}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={filtro.uf_id === ''}
                  >
                    <option value=""> {filtro.uf_id === '' ? 'Selecionar Estado' : 'Selecionar Cidade'}</option>
                    {cidades.list.map(estado => {
                      return (
                        <option key={estado.id} value={estado.id}> {estado.nome} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Como Soube?"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="metodo_id"
                    value={filtro.metodo_id}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Método</option>
                    {metodosDivugacao.list.map(metodo => {
                      return (
                        <option key={metodo.id} value={metodo.id}> {metodo.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Profissão"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="profissao"
                    value={filtro.profissao}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Profissão</option>
                    {profissoes.list.map(profissao => {
                      return (
                        <option key={profissao.profissao} value={profissao.profissao}> {profissao.profissao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
              </React.Fragment>
            }
            {filtro.relatorio === 'contrato-ativos' &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar data por"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="data_por"
                    value={filtro.data_por}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    <option value="INICIO">Data de Inicio do Contrato</option>
                    <option value="FINAL">Data de Fim do Contrato</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                {filtro.relatorio !== 'dados-cadastro-cliente' ? 'Filtrar' : 'Atualizar'}
              </Button>
            </Grid>
            {(relatorios.list.length > 0 || filtro.relatorio === 'dados-cadastro-cliente') &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          <hr className="mg_top_10"/>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {cabecalhoTabela.length > 0 &&
                <React.Fragment>
                  <Grid container spacing={2} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12}>
                      <Table
                        urlView={urlView}
                        headCell={cabecalhoTabela}
                        rows={relatorios.list}
                        acoes={acoesTabela}
                        viewRow={(e, acao, status) => this.viewRow(e, acao, status)} 
                        imprimirRel={(e) => this.imprimirRel(e)}
                        viewUsuTela={(e) => this.viewUsuTela(e)}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </Main>
        <Nav history={this.props.history}/>
          <ModalRelatorio
            open={this.state.modalRelatorio}
            handleClose={e => this.handleCloseModalRelatorio(e)}
            list={this.state.relatoriosModal.list}
            cabecalhoTabela={this.state.cabecalhoTabelaModal}
            acoesTabela={this.state.acoesTabelaModal}
            imprimir={e => this.imprimirSub(e)}
            loadingDownload={loadingDownload}
            urlView={this.state.urlViewModal}
            titulo={this.state.tituloSubRel}
            filtroSub={this.state.filtroSub}
            valor_total={real(this.state.valor_total_sub)}
          />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Relatórios Administrativos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}