import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button, IconButton, FormControlLabel, Switch, FormLabel, FormControl, Checkbox } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import Table from './../Table'
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PanToolIcon from '@mui/icons-material/PanTool';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CurrencyInput from './../mask/CurrencyInput'
import SearchIcon from '@mui/icons-material/Search';
import real from './../../services/real'
import CloseIcon from '@mui/icons-material/Close';
import Color from 'color';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

const cabecalhoTabela = [
  { id: 'servico_agendado', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'valor', numeric: false, disablePadding: true, label: 'Valor Serviço' },
  { id: 'ambiente', numeric: false, disablePadding: true, label: 'Ambiente' },
  { id: 'atendente', numeric: false, disablePadding: true, label: 'Atendente' },
  { id: 'data_inicio_form', numeric: false, disablePadding: true, label: 'Data'},
  { id: 'hora_inicio', numeric: false, disablePadding: true, label: 'Inicio' },
  { id: 'hora_fim', numeric: false, disablePadding: true, label: 'Fim' },
  { id: 'duracao_form', numeric: false, disablePadding: true, label: 'Duração' },
  { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' }
]

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

let SimpleModal = (props) => {
  const theme = useTheme();

  const {agenda, clientes, cliente, servicos, servico, salas, sala, servicoAgenda, alteraServico, 
    atendentes, atendente, buscarPessoa, tab, conta, formasPagamento, contasFinanceira, situacoes, financeiro_fechado,
    buscarVoucher, voucherSelecionado, mostrarFinanceiro, removerVoucher, verificaCaixaInicial, permissoes, intervalo,
    conta_anterior, vouchersCreditoCliente} = props

  let acoesTabela = []

  if(!permissoes.alterar){
    if(financeiro_fechado){
      acoesTabela = ['editarAgenda']
    }else{
      acoesTabela = ['removeAgenda', 'editarAgenda']
    }
  }else{
    acoesTabela = ['removeAgenda', 'editarAgenda']
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Ordem de Serviço
              {financeiro_fechado &&
                <span style={{color: 'red'}}> (Financeiro Fechado)</span>
              }
              </h5>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={3} xs={12} sm={6}>
              <Autocomplete
                onChange={(event, value) => props.updateFieldCliente(event, value)}
                freeSolo
                options={clientes.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)}
                value={cliente}
                id='cliente'
                getOptionLabel={option => `${option.nome}`}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.pessoa_id}>
                      {option.nome}
                    </li>
                  );
                }}
                disabled={!permissoes.alterar ? true : financeiro_fechado}
                renderInput={(params) => (
                  <TextField 
                    InputProps={{ ...params.InputProps, type: 'search' }} 
                    {...params} 
                    size="small" 
                    label="Cliente*" 
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} 
                    value={`${cliente.nome}`} 
                    disabled={financeiro_fechado}
                    onChange={(e) => props.updateFiltroPessoa(e)}
                  />
                )}
              />
            </Grid>
            <Grid item md={1} xs={12} sm={2}>
              <Tooltip title='Buscar Cliente' aria-label="add">
                <IconButton color="primary" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => buscarPessoa()} disabled={financeiro_fechado}>
                  <PanToolIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button
                color='info'
                size="small"
                variant="contained"
                fullWidth
                onClick={e => props.openCadastroCliente()}
                disabled={!permissoes.alterar ? true : financeiro_fechado}
              >
                Cadastrar Cliente
              </Button>
            </Grid>
            {agenda.cliente_id !== '' && 
              <React.Fragment>
                <Grid item md={2} xs={12} sm={false}></Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField 
                    id='voucher'
                    className="input" 
                    label="Voucher de Produto" 
                    variant="outlined" 
                    name="voucher" 
                    size="small"
                    value={agenda.voucher} 
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item md={1} xs={12} sm={2}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={6} xs={6} sm={6}>
                      <Tooltip title='Buscar Voucher' aria-label="add">
                        <IconButton color="primary" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => buscarVoucher()} disabled={!permissoes.alterar ? true : financeiro_fechado}>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                      {agenda.voucher_id !== '' && 
                        <Tooltip title='Remover Voucher' aria-label="add">
                          <IconButton color="error" aria-label="remove" style={{marginTop: '-5px', marginLeft: '0px'}} onClick={e => removerVoucher()} disabled={!permissoes.alterar ? true : financeiro_fechado}>
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </Grid>

          {agenda.voucher_id !== '' &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={6} xs={12} sm={1}></Grid>
              {voucherSelecionado.padrao.habilita === 'PRODUTO' && 
                <Grid item md={2} xs={12} sm={8}></Grid>
              }
              <Grid item md={voucherSelecionado.padrao.habilita === 'PRODUTO' ? 4 :  2} xs={12} sm={3}>
                <TextField 
                  id='habilita'
                  className="input" 
                  label="Habilita" 
                  variant="outlined" 
                  name="habilita" 
                  size="small"
                  value={voucherSelecionado.padrao.habilita} 
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              {voucherSelecionado.padrao.habilita === 'CREDITO' &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField 
                      id='valor_credito'
                      className="input" 
                      label="Valor Crédito" 
                      variant="outlined" 
                      name="valor_credito" 
                      size="small"
                      value={voucherSelecionado.valor_credito_form} 
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField 
                      id='saldo'
                      className="input" 
                      label="Valor Saldo" 
                      variant="outlined" 
                      name="saldo" 
                      size="small"
                      value={ 'R$ ' + real(voucherSelecionado.saldo - parseFloat(conta.valor_total.replaceAll(".", "").replace(",", ".")) < 0 ? 0 : voucherSelecionado.saldo - parseFloat(conta.valor_total.replaceAll(".", "").replace(",", ".")) )} 
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          }

          <hr className="mg_top_10"/>

          {agenda.cliente_id !== '' &&
            <React.Fragment>
              <AppBar position="static" className="mg_top_10"> 
                <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={props.handleChange} aria-label="simple tabs example">
                  <Tab label="Dados da Agenda" {...a11yProps(0)} />
                  {mostrarFinanceiro &&
                    <Tab label="Forma de Pagamento" {...a11yProps(1)} />
                  }
                </Tabs>
              </AppBar>

              <TabPanel value={tab} index={0}>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={8} xs={12} sm={6}>
                    <h4 className="titulo">Agenda:</h4>
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    {vouchersCreditoCliente.length > 0 &&
                      <div className='card_aviso_voucher'>
                        <WarningIcon style={{color: '#FFF'}}/>
                        <span>Esse cliente possui voucher de crédito</span>
                      </div>
                    }
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={1} className="mg_top_20">
                  {agenda.voucher_id !== '' && 
                    <Grid item md={6} xs={12} sm={6}>
                      <Grid container direction="row" spacing={1}> 
                        <Grid item md={7} xs={12} sm={4}>
                          <Autocomplete
                            id='servico'
                            onChange={(event, value) => props.updateFieldServico(event, value)}
                            freeSolo
                            options={servicos.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))}
                            value={servico}
                            getOptionLabel={option => `${option.nome} ${voucherSelecionado.produtos ? (voucherSelecionado.produtos.findIndex(param => param.produto_id === option.id && !param.utilizado) !== -1 ? '(Voucher)' : '') : ''}`}
                            // disabled={agenda.cliente_id === ''}
                            disabled={!permissoes.alterar ? true : (financeiro_fechado ? true : (agenda.cliente_id === ''))}
                            renderInput={(params) => (
                              <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Serviço*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${servico.nome}`} />
                            )}
                          />
                        </Grid>
                        <Grid item md={5} xs={12} sm={4} style={{marginTop: '-5px'}}>
                          <span style={{fontSize:14}}>Serviços do Voucher</span>
                          <Checkbox
                            checked={props.swapServicosValue === 'voucher' ? true : false}
                            onChange={() => props.swapServicos()}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <Tooltip
                            title='Apresenta apenas os itens do voucher'
                          >
                            <InfoIcon className='info'/>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {agenda.voucher_id === '' && 
                    <Grid item md={4} xs={12} sm={6}>
                      <Autocomplete
                        id='servico'
                        onChange={(event, value) => props.updateFieldServico(event, value)}
                        freeSolo
                        options={servicos.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))}
                        value={servico}
                        getOptionLabel={option => option.nome + (voucherSelecionado.produtos ? (voucherSelecionado.produtos.findIndex(param => param.produto_id === option.id && !param.utilizado) !== -1 ? '(Voucher)' : '') : '')}
                        disabled={!permissoes.alterar ? true : (financeiro_fechado ? true : (agenda.cliente_id === ''))}
                        renderInput={(params) => (
                          <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Serviço*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${servico.nome}`} />
                        )}
                      />
                    </Grid>
                  }
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField 
                      id='data_inicio'
                      type='date'
                      className="input" 
                      label="Data Atendimento" 
                      variant="outlined" 
                      name="data_inicio" 
                      size="small"
                      step={intervalo * 60}
                      value={servicoAgenda.data_inicio} 
                      onChange={(e) => props.updateServicoAgenda(e)}
                      onBlur={(e) => props.consultarTabela(e)}
                      InputLabelProps={{ shrink: true }}
                      disabled={!permissoes.alterar ? true : (servico.nome === '' ? true : false)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField 
                      id='hora_inicio'
                      type='time'
                      className="input" 
                      label="Hora Atendimento" 
                      variant="outlined" 
                      name="hora_inicio" 
                      size="small"
                      value={servicoAgenda.hora_inicio_tela} 
                      onChange={(e) => props.updateServicoAgenda(e)} 
                      onBlur={(e) => props.ajustaHoraFim(e)}
                      InputLabelProps={{ shrink: true }}
                      disabled={!permissoes.alterar ? true : (servicoAgenda.data_inicio === '' ? true : false)}
                    />
                  </Grid>
                  <Grid item md={agenda.voucher_id !== '' ? 1 : 2} xs={12} sm={4}>
                    <TextField 
                      id='hora_fim'
                      type='time'
                      className="input" 
                      label="Até" 
                      variant="outlined" 
                      name="hora_fim" 
                      size="small"
                      value={servicoAgenda.hora_fim_tela}
                      onBlur={(e) => props.updateDuracao(e)}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => props.updateServicoAgenda(e)} 
                      disabled={!permissoes.alterar ? true : (servicoAgenda.hora_inicio === '' ? true : false)}
                    />
                  </Grid>
                  <Grid item md={agenda.voucher_id !== '' ? 1 : 2} xs={12} sm={4}>
                    <TextField 
                      type='text'
                      className="input" 
                      label="Duração" 
                      variant="outlined" 
                      name="duracao" 
                      size="small"
                      value={`${servicoAgenda.duracao_tela} Minutos`} 
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </Grid>
                
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField 
                      className="input" 
                      label="Valor Serviço*" 
                      variant="outlined" 
                      name="valor" 
                      size="small"
                      value={servicoAgenda.valor} 
                      onChange={(e) => props.updateServicoAgenda(e)} 
                      InputLabelProps={{ shrink: true }} 
                      InputProps={{ inputComponent: CurrencyInput }} 
                      disabled={!permissoes.alterar ? true : ((agenda.voucher_id !== '' && voucherSelecionado.padrao.habilita === 'PRODUTO') ? true: (financeiro_fechado ? true : (servicoAgenda.data_inicio === '')))}
                      onBlur={(e) => props.verificaValor(e)}
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <Button 
                      size="small"
                      variant="contained" 
                      color="success" 
                      onClick={e => props.buscarSalasAtendentes()}
                      style={{width: '100%'}}
                      disabled={!permissoes.alterar ? true : ((servicoAgenda.hora_fim === '' || servicoAgenda.hora_inicio === '') ? true : false)}
                    >
                      Buscar Salas/Atend.
                    </Button>
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <Autocomplete
                      onChange={(event, value) => props.updateFieldSala(event, value)}
                      freeSolo
                      options={salas}
                      value={sala}
                      id='ambiente'
                      getOptionLabel={option => `${option.nome}`}
                      disabled={!permissoes.inserir}
                      renderInput={(params) => (
                        <TextField 
                          placeholder={ salas.length === 0 ? `Buscar Salas/Atendentes` : 'Selecionar Ambiente'} 
                          InputProps={{ ...params.InputProps, type: 'search' }} 
                          {...params} 
                          size="small" 
                          label="Local Atendimento*" 
                          margin="normal" 
                          variant="outlined" 
                          InputLabelProps={{ shrink: true }} 
                          value={`${sala.nome}`}
                          disabled={!permissoes.inserir}
                         />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <Autocomplete
                      onChange={(event, value) => props.updateFieldAtendente(event, value)}
                      freeSolo
                      options={atendentes}
                      value={atendente}
                      id='atendente'
                      getOptionLabel={option => `${option.nome}`}
                      placeholder='Buscar Salas/Atendentes'
                      disabled={!permissoes.inserir}
                      renderInput={(params) => (
                        <TextField 
                          placeholder={ atendentes.length === 0 ? `Buscar Salas/Atendentes` : 'Selecionar Atendente'} 
                          InputProps={{ ...params.InputProps, type: 'search' }} 
                          {...params} 
                          size="small" 
                          label="Atendente*" 
                          margin="normal" 
                          variant="outlined" 
                          InputLabelProps={{ shrink: true }} 
                          value={`${atendente.nome}`} 
                          disabled={!permissoes.inserir}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={1} xs={12} sm={4}>
                    <FormControl component="fieldset" className="mg_left_10">
                      <FormLabel component="legend">Obrigatoriedade?</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            name="obrigatoriedade"
                            color="primary"
                            checked={servicoAgenda.obrigatoriedade}
                          />
                        }
                        size="small"
                        label={servicoAgenda.obrigatoriedade ? 'Sim' : 'Não'}
                        name="obrigatoriedade"
                        onChange={(e) => props.updateFieldObrigatoriedade(e)}
                        disabled={!permissoes.inserir}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {props.valor_servico_dif.findIndex(param => param.servico_id === servicoAgenda.servico_id) !== -1 &&
                  <Grid container direction="row" spacing={1} style={{marginTop: '-20px', marginBottom: '10px', color: 'red'}}>
                    <Grid item md={3} xs={12} sm={3}>
                      <span>Valores Sugeridos da Tabela: </span>
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <span>Valor Mínimo: R$ {real(props.valor_servico_dif[props.valor_servico_dif.findIndex(param => param.servico_id === servicoAgenda.servico_id)].valor_tabela_minimo)}</span>
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <span>Valor Máximo: R$ {real(props.valor_servico_dif[props.valor_servico_dif.findIndex(param => param.servico_id === servicoAgenda.servico_id)].valor_tabela_maximo)}</span>
                    </Grid>
                  </Grid>
                }
                <Grid container direction="row" spacing={1} style={{marginTop: props.valor_servico_dif.findIndex(param => param.servico_id === servicoAgenda.servico_id) === -1 ? '-30px' : '10px'}}>
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField
                      id="situacao"
                      select
                      label="Situação*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="situacao"
                      value={servicoAgenda.situacao}
                      InputLabelProps={{ shrink: true }} 
                      onChangeCapture={(e) => props.updateServicoAgenda(e)}
                      disabled={!permissoes.inserir}
                    >
                      <option value=''>Selecionar Situação</option>
                      {situacoes.map((situacao, key) => {
                        return (
                          <option key={key} value={situacao}>{situacao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  {agenda.ordem_id === '' &&
                    <React.Fragment>
                      {servicoAgenda.recorrencia && 
                        <Grid item md={9} xs={12} sm={8} className='card_recorrencia mg_left_10'>
                          <Grid container direction="row" spacing={1}>
                            <Grid item md={2} xs={12} sm={4} style={{marginTop: 5}}>
                              <FormControl component="fieldset" className="mg_left_10">
                                <FormLabel component="legend">Recorrência?</FormLabel>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name="recorrencia"
                                      color="primary"
                                      checked={servicoAgenda.recorrencia}
                                    />
                                  }
                                  size="small"
                                  label={servicoAgenda.recorrencia ? 'Sim' : 'Não'}
                                  name="recorrencia"
                                  onChange={(e) => props.updateRecorrencia(e)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={2} xs={12} sm={6} style={{marginTop: 15}}>
                              <TextField 
                                type='number'
                                className="input" 
                                label="Repetir a cada*" 
                                variant="outlined" 
                                name="recorrenciaACada" 
                                size="small"
                                value={servicoAgenda.dadosRecorrencia.recorrenciaACada} 
                                onChange={(e) => props.updateFieldRecorrencia(e)} 
                                InputLabelProps={{ shrink: true }}/>
                            </Grid>
                            <Grid item md={2} xs={12} sm={6} style={{marginTop: 15}}>
                              <TextField
                                id="standard-select-currency"
                                select
                                label=""
                                variant="outlined"
                                className="input"
                                fullWidth
                                size="small"
                                SelectProps={{
                                  native: true,
                                }}
                                name="tipoRecorrencia"
                                value={servicoAgenda.dadosRecorrencia.tipoRecorrencia}
                                onChangeCapture={(e) => props.updateFieldRecorrencia(e)}
                              >
                                <option value='d'> Dia </option>
                                <option value='w'> Semana </option>
                                <option value='M'> Mês </option>
                                <option value='y'> Ano </option>
                              </TextField>
                            </Grid>
                            <Grid item md={3} xs={12} sm={6} style={{marginTop: 15}}>
                              <TextField 
                                type='number'
                                className="input" 
                                label="Num. de Ocorrências*" 
                                variant="outlined" 
                                name="qtdRecorrencia" 
                                size="small"
                                value={servicoAgenda.dadosRecorrencia.qtdRecorrencia} 
                                onChange={(e) => props.updateFieldRecorrencia(e)} 
                                InputLabelProps={{ shrink: true }}/>
                            </Grid>
                            <Grid item md={3} xs={12} sm={6} style={{marginTop: 15}}>
                              <TextField 
                                className="input" 
                                label="Última Agenda*" 
                                variant="outlined" 
                                name="ultima_agenda" 
                                size="small"
                                value={moment(servicoAgenda.data_inicio).add(servicoAgenda.dadosRecorrencia.tipoRecorrencia,((servicoAgenda.dadosRecorrencia.qtdRecorrencia * servicoAgenda.dadosRecorrencia.recorrenciaACada) - (servicoAgenda.dadosRecorrencia.recorrenciaACada))).format('DD/MM/YYYY')} 
                                disabled
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  {props.perm_notificacao_agenda &&
                    <Grid item md={3} xs={12} sm={4}>
                      <FormControl component="fieldset" className="mg_left_10">
                      <FormLabel component="legend">Enviar Notificação?</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            name="enviar_alerta"
                            color="primary"
                            checked={servicoAgenda.enviar_alerta}
                          />
                        }
                        size="small"
                        label={servicoAgenda.enviar_alerta ? 'Sim' : 'Não'}
                        name="enviar_alerta"
                        onChange={(e) => props.updateFieldEnviarAlerta(e)}
                        disabled={!permissoes.inserir}
                      />
                    </FormControl>
                    </Grid>
                  }
                </Grid>

                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observações do Atendimento"
                      className="input"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      name="observacao"
                      value={servicoAgenda.observacao}
                      onChange={(e) => props.updateServicoAgenda(e)}
                      disabled={!permissoes.alterar || !permissoes.inserir}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Agendas da Ordem:</h4>
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={10} xs={12} sm={9}>
                    <h3>Valor total:  {conta.valor_total}</h3>
                  </Grid>
                  {(permissoes.inserir || !permissoes.alterar) &&
                    <Grid item md={2} xs={12} sm={3}>
                      <Button
                        color='warning'
                        size="small"
                        className="btnCadastrar" 
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={e => props.adicionarServico()}
                        disabled={props.travarBotao}
                      >
                        {alteraServico ? 'Alterar' : 'Adicionar'}
                      </Button>
                    </Grid>
                  }
                </Grid> 
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <Table
                      remove={e => props.removerServico(e)}
                      editar={e => props.editarServico(e)}
                      headCell={cabecalhoTabela}
                      rows={agenda.servicos.map(value => {
                        return {
                          ...value,
                          servico_agendado: value.servico + (value.recorrencia ? ' (R)' : ''),
                          data_inicio_form: moment(value.data_inicio).format('DD/MM/YYYY'),
                          duracao_form: value.duracao + ' Minutos'
                        }
                      })}
                      qtdLinhas={5}
                      acoes={acoesTabela}
                      alteraServico={alteraServico}
                      financeiro_fechado={financeiro_fechado}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observações Geral"
                      className="input"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      name="observacao_geral"
                      value={agenda.observacao_geral}
                      onChange={(e) => props.updateFieldAgenda(e)}
                      disabled={!permissoes.alterar || !permissoes.inserir}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {mostrarFinanceiro && 
                <TabPanel value={tab} index={1}>
                  {agenda.servicos.findIndex(param => param.recorrencia) !== -1 &&
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <span>Um dos serviços informado é uma recorrência, para recorrências não é possivel informar o lançamento do financeiro no momento do lançamento das agendas.</span>
                      </Grid>
                    </Grid>
                  }

                  {agenda.servicos.findIndex(param => param.recorrencia) === -1 &&
                    <React.Fragment>
                      {!verificaCaixaInicial().libera &&
                        <Grid container direction="row" spacing={1} className="mg_top_20">
                          <Grid item md={12} xs={12} sm={12}>
                            <p>{verificaCaixaInicial().mensagem}</p>
                          </Grid>
                        </Grid>
                      }
                      {verificaCaixaInicial().libera &&
                        <React.Fragment>
                          <Grid container direction="row" spacing={1} className="mg_top_10">
                            {(conta.id === '' && (agenda.voucher_id === '' || agenda.servicos.findIndex(param => param.voucher) === -1)) &&
                              <Grid item md={8} xs={12} sm={6}>
                                <FormControl style={{marginLeft: '20px'}} component="fieldset">
                                  <FormLabel component="legend">Informar Lançamento Financeiro?</FormLabel>
                                  <FormControlLabel className="mg_left_10"
                                    control={
                                      <Switch
                                        name="informar_financeiro"
                                        color="primary"
                                        checked={agenda.informar_financeiro}
                                      />
                                    }
                                    size="small"
                                    label={agenda.informar_financeiro ? 'Sim' : 'Não'}
                                    name="informar_financeiro"
                                    onChange={(e) => props.updateFieldInformarFinanceiro(e)}
                                  />
                                </FormControl>
                              </Grid>
                            }
                            {(conta.id !== '' || agenda.voucher_id !== '') &&
                              <Grid item md={8} xs={12} sm={6}></Grid>
                            }
                            {vouchersCreditoCliente.length > 0 &&
                              <Grid item md={4} xs={12} sm={6} style={{marginTop: '-5px', fontWeight: 'bold'}}>
                                <Grid container direction="row" spacing={1}>
                                  <Grid item md={12} xs={12} sm={12}>
                                    <div className='card_aviso_voucher'>
                                      <WarningIcon style={{color: '#FFF'}}/>
                                      <span>Esse cliente possui voucher de crédito</span>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={1} className="mg_top_10">
                                  <Grid item md={12} xs={12} sm={12}>
                                    <h6 className='titulo'>Vouchers de Crédito Disponíveis:</h6>
                                  </Grid>
                                  <Grid item md={12} xs={12} sm={12}>
                                    {vouchersCreditoCliente.map((value, key) => {
                                      return (
                                        <span style={{fontSize: 16}}>* {value.padrao.nome} - Saldo: {value.saldo_form}</span>
                                      )
                                    })}
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          </Grid>
                          {agenda.informar_financeiro &&
                            <React.Fragment>
                              <Grid container direction="row" spacing={1} className="mg_top_10">
                                <Grid item md={12} xs={12} sm={12}>
                                  <h4 className="titulo">Forma de Pagamento:</h4>
                                </Grid>
                              </Grid>
                              <Grid container direction="row" spacing={1} className="mg_top_10">
                                <Grid item md={2} xs={12} sm={4}>
                                  <TextField 
                                    className="input" 
                                    label="Valor Total*" 
                                    variant="outlined" 
                                    name="valor_total" 
                                    size="small"
                                    value={conta.valor_total} 
                                    InputLabelProps={{ shrink: true }} 
                                    InputProps={{ inputComponent: CurrencyInput }} 
                                    disabled/>
                                </Grid>
                                <Grid item md={1} xs={12} sm={6}>
                                  {props.possui_emissao_nfse &&
                                    <FormControl component="fieldset">
                                      <FormLabel component="legend">Gerar NFSE?</FormLabel>
                                      <FormControlLabel className="mg_left_10"
                                        control={
                                          <Switch
                                            name="Ativo"
                                            color="primary"
                                            checked={props.conta.gerar_nfse}
                                          />
                                        }
                                        size="small"
                                        label={props.conta.gerar_nfse ? 'Sim' : 'Não'}
                                        name="gerar_nfse"
                                        onChange={(e) => props.updateFieldGerarNfseConta(e)}
                                      />
                                    </FormControl>
                                  }
                                </Grid> 
                              </Grid>
                              {(conta.valor_total !== conta_anterior.valor_total && conta_anterior.id !== '') &&
                                <Grid container direction="row" spacing={1} className="mg_top_10">
                                  <Grid item md={12} xs={12} sm={12}>
                                    <span className='aviso'>Houve uma alteração nos valores dos serviços, por favor informar novamente a forma de pagamento da 
                                    ordem de serviço. O lançamento anterior será estornado.</span>
                                  </Grid>
                                </Grid>
                              }
                              <Grid container direction="row" spacing={1} className="mg_top_10">
                                <Grid item md={12} xs={12} sm={12}>
                                  <div className='scroll'>
                                    <table className="lista sempadding borderTable">
                                      <thead>
                                        <tr className="titulos acompanha">
                                          <th>Formas de Pagto.</th>
                                          <th>Valor*</th>
                                          <th>Desconto</th>
                                          <th>Data Pagto*</th>
                                          <th>Forma de Pagamento*</th>
                                          <th>Contas Bancárias / Nº Vouchers</th>
                                          {/* <th>Documento</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                                          return (
                                            <tr key={parcela.index}>
                                              <td>
                                                <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.num} InputLabelProps={{ shrink: true }} disabled />
                                                <span className="separacaoParcelas">/</span>
                                                <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) =>props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} disabled={conta.id !== '' ? true : parcela.trava}/>
                                              </td>
                                              <td>
                                                <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={conta.id !== '' ? true : parcela.trava}/>
                                              </td>
                                              <td>
                                                <TextField className="inputValor" variant="outlined" size="small" name="multajuros" value={parcela.multajuros} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={conta.id !== '' ? true : parcela.trava}/>
                                              </td>
                                              <td>
                                                <TextField 
                                                  type="date" 
                                                  className="inputVencimento" 
                                                  variant="outlined" 
                                                  size="small" 
                                                  name="datavencimento" 
                                                  value={parcela.datavencimento} 
                                                  onChange={(e) => props.updateFieldParcela(e, parcela.index)} 
                                                  onBlur={(e) => props.verificaDataParcela(e, parcela.index)}
                                                  InputLabelProps={{ shrink: true }} 
                                                  disabled={conta.id !== '' ? true : parcela.trava}
                                                  // InputProps={{ inputProps: { max: dataHojeFormatado } }}
                                                />
                                              </td>
                                              <td>
                                              {(parseInt(parcela.formapagto_id) === 9 && (parcela.vouchersCreditoSelecionados.length > 0 || (agenda.voucher_id !== '' && agenda.servicos.findIndex(param => param.voucher) !== -1))) &&
                                                <TextField 
                                                  className="inputFormPagamento"
                                                  variant="outlined" 
                                                  size="small" 
                                                  name="formapagto_id" 
                                                  value={'Voucher'} 
                                                  InputLabelProps={{ shrink: true }} 
                                                  disabled={true}
                                                />
                                              }
                                              {parseInt(parcela.formapagto_id) !== 9 &&
                                                <TextField
                                                  id="standard-select-currency"
                                                  select
                                                  variant="outlined"
                                                  className="inputFormPagamento"
                                                  fullWidth
                                                  size="small"
                                                  SelectProps={{
                                                    native: true,
                                                  }}
                                                  name="formapagto_id"
                                                  value={parcela.formapagto_id}
                                                  onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                                  onBlur={(e) => props.verificaCortesia(e, parcela.index)}
                                                  InputLabelProps={{ shrink: true }}
                                                  disabled={conta.id !== '' ? true : parcela.trava}
                                                >
                                                  <option value="">--Escolha--</option>
                                                  {formasPagamento.map(forma => {
                                                    return (
                                                      <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                                    )
                                                  })}
                                                </TextField>
                                              }
                                              </td>
                                              <td>
                                                {parseInt(parcela.formapagto_id) === 9 &&
                                                  <div 
                                                    style={{
                                                      cursor: 'pointer',
                                                      width: '300px',
                                                      border: '1px solid',
                                                      borderColor: 'rgba(0, 0, 0, 0.23)',
                                                      height: '38px',
                                                      borderRadius: '5px',
                                                      padding: '10px 10px',
                                                      marginLeft: '37px'
                                                    }} 
                                                    onClick={() => props.openVouchers(parcela.index)}
                                                  >
                                                    <span>{parcela.vouchersCreditoSelecionadosString}</span>
                                                  </div>
                                                }
                                                {parseInt(parcela.formapagto_id) !== 9 &&
                                                  <TextField
                                                    id="standard-select-currency"
                                                    select
                                                    variant="outlined"
                                                    className="inputConta"
                                                    fullWidth
                                                    size="small"
                                                    SelectProps={{
                                                      native: true,
                                                    }}
                                                    name="contfin_id"
                                                    value={parcela.contfin_id}
                                                    onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={conta.id !== '' ? true : parcela.trava}
                                                  >
                                                    <option value="">--Escolha--</option>
                                                    {contasFinanceira.filter(param =>  (props.logado.caixa && parseInt(parcela.formapagto_id) === 1 && param.tipo_conta === "CAIXATURNO") || (param.tipo_conta === parcela.habilita)).map(conta => {
                                                      return (
                                                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                                      )
                                                    })}
                                                  </TextField>
                                                  
                                                  // <Select
                                                  //   fullWidth
                                                  //   className={`${parcela.contfin_id !== '' ? 'selecionado' : 'nao_selecionado'} inputConta`}
                                                  //   variant="outlined"
                                                  //   name="contfin_id"
                                                  //   value={parcela.contfin_id}
                                                  //   onChange={(e) => props.updateFieldParcela(e, parcela.index)}
                                                  //   disabled={financeiro_fechado ? true : parcela.trava}
                                                  // >
                                                  //   <MenuItem value=''>
                                                  //     <ListItemIcon>
                                                  //     </ListItemIcon>
                                                  //     <ListItemText primary={'Selecionar Conta'} />
                                                  //   </MenuItem>
                                                  //   {contasFinanceira.filter(param =>  (props.logado.caixa && param.tipo_conta === "CAIXATURNO") || (!props.logado.caixa && param.tipo_conta === parcela.habilita)).map(conta => {
                                                  //     return (
                                                  //       <MenuItem key={conta.id} value={conta.id}>
                                                  //         <ListItemIcon>
                                                  //           <img style={{width: '30px', height: '30px', marginRight: '5px'}} src={conta.conta_corrente ? conta.conta_corrente.conta.banco.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt='logo'/>
                                                  //         </ListItemIcon>
                                                  //         <ListItemText primary={conta.descricao} />
                                                  //       </MenuItem>
                                                  //     )
                                                  //   })}
                                                  // </Select>
                                                }
                                              </td>
                                              {/* <td>
                                                <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                              </td> */}
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </TabPanel>
              }
            </React.Fragment>
          }

          <Grid container direction="row" spacing={1}>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="secondary" 
                startIcon={<ArrowBackIcon />}
                onClick={props.handleClose}
                disabled={props.travarBotao}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item md={8} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                size="small"
                className="btnCadastrar" 
                variant="contained" 
                color="primary" 
                startIcon={<SaveIcon />}
                onClick={e => props.confirmar(true)}
                disabled={props.travarBotao}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
          <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon className='botao_ajuda' onClick={() => props.openModalAjudaCadastro()}/>
          </Tooltip>
        </StyledPaper>
      </Modal>
    </div>
  );
}

export default SimpleModal
