import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button } from '@mui/material'
import CPForCNPJInput from './../mask/CPForCNPJInput';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#FFF',
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" className="mg_top_20">
            <Grid item md={4} xs={12} sm={4}></Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Grid container direction="row" spacing={1} style={{marginTop: '100px'}}>
                <Grid item md={12} xs={12} sm={12}>
                  <h6 className="tituloConfirmacao">Área do Cliente</h6>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item md={12}>
                  <p style={{margin:'10px 0px'}}>Por favor informar o seu CPF/CNPJ:</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <CPForCNPJInput
                    label="CPF/CNPJ"
                    className="inputCpfCnpj"
                    name="cpf_cnpj"
                    value={dados.cpf_cnpj}
                    onChange={(e) => props.updateData(e)}
                    style={{marginTop: '-10px'}}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)} disabled={dados.cpf_cnpj === "" ? true : false}>Entrar</Button>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <img style={{width: '50%', marginLeft: '25%', marginTop: '30px'}} src="./../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} sm={4}></Grid>
          </Grid>
          <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon className='botao_ajuda' onClick={() => props.openModalAjuda()}/>
          </Tooltip>
        </StyledPaper>
      </Modal>
    </div>
  );
}
