export default {
  cliente: {
    _id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_data_inicio_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: "",
      resp_fin: []
    }
  },
  contrato: {
    _id: '',
    estado_contrato: '',
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    tempo_contrato: '',
    unidade_tempo: '',
    unidade: {
      numero: '',
      descricao: ''
    }
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    pessoaJuridica: {
      id:"",
      pessoa_id: "",
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: "",
    },
    endereco: {
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    },
    email: {
      email: "",
      principal: true,
      index: 1
    },
    contato: {
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    },
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesAtivarInativar: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCancelar: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesProrrogar:{
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesAtribuirCodigo:{
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesEstornoProrrogar:{
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesTransferirCodigo:{
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesVincularAditivo: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesContrato: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  loading: true,
  contratos: {
    list: []
  },
  enableBuscaResp: true,
  itens: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'codigo', numeric: false, disablePadding: false, label: 'Código' },
    { id: 'data_ini_vig', numeric: false, disablePadding: false, label: 'Data Inicio'},
    { id: 'data_fim_vig', numeric: false, disablePadding: false, label: 'Data Fim' },
    { id: 'estado_contrato', numeric: false, disablePadding: false, label: 'Situação' },
    { id: 'codigo_assinatura', numeric: false, disablePadding: false, label: 'Código de Assinatura' },
  ],
  acoesTabela: ['viewContrato', 'updateContrato'],
  cabecalhoTabelaProrrogacoes: [
    { id: 'hisp_data', numeric: false, disablePadding: false, label: 'Data da Prorrogação', orderBy: 'hisp_dataOrder' },
    { id: 'hisp_dias', numeric: false, disablePadding: false, label: 'Qtd Dias' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Colaborador'},
    { id: 'hisp_lanc_financ', numeric: false, disablePadding: false, label: 'Lanç. Financeiro'},
    { id: 'hisp_ativo', numeric: false, disablePadding: false, label: 'Ativo'},
  ],
  acoesTabelaProrrogacoes: [],
  inativarContrato: {
    motivoInativar: ''
  },
  cancelarServico: {
    motivoCancelamento: ''
  },
  modalProrrogarContrato: false,
  prorrogarContrato: {
    qtdDias: '',
    contm_data_fim_vigencia_orig: '',
    contm_data_fim_vigencia_nova: '',
    lanc_fin: false
  },
  modalInativarContrato: false,
  modalAtivarContrato: false,
  modalCancelarServico: false,
  modalErro: false,
  modalEstornarProrrogacao:false,
  modalVincularAditivo: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  financeiros_pendentes: {
    list: []
  },
  valores_quitacao: {
    list: []
  },
  qtdParcelasEditado: 0,
  total_sugerido: 0,
  conta: {
    descricao: "",
    tipo: "A RECEBER",
    valortotal: "",
    numparcela: 1,
    item_id: '',
    tipo_item: 'ITEMRECDESP',
    qtd_parcelas: 1,
    gerar_nfse: false,
    parcelas: [
      {
        baixado: "",
        datavencimento: "",
        valorparcela: "",
        formapagto_id: "",
        documento: "",
        num: "",
        contfin_id: "",
        parcela_editada: false,
        index: 1
      }
    ]
  },
  prorrogacoes:{
    list: []
  },
  formasPagamento: {
    list: []
  },
  planosConta: {
    list: []
  },
  codigos: {
    list: []
  },
  habilitaProrrogar: false,
  cabecalhoTabelaCodigo: [
    { id: 'codmatdid_codigo', numeric: false, disablePadding: true, label: 'Código' },
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Material' },
    { id: 'codmatdid_criado_em', numeric: false, disablePadding: false, label: 'Gerado em' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Atribuido por' },
    { id: 'hiscod_data', numeric: false, disablePadding: false, label: 'Atribuido em' },
    { id: 'hiscod_situacao', numeric: false, disablePadding: false, label: 'Situação' }
  ],
  acoesTabelaCodigo: [],
  modalVincularCodigo: false,
  materiais:{
    list: []
  },
  material:{
    prod_id: '',
    prod_descricao: ''
  },
  codigo: {
    codmatdid_id: '',
    codmatdid_codigo: ''
  },
  possuiFinanceiroCodigo: false,
  plano_contas:{
    plancontas_id: '',
    plancontas_codigo: '',
    plancontas_descricao: ''
  },
  prorrogacaoEstornar:{
    hisp_data: '',
    hisp_dataOrder: '',
    hisp_data_fim: '',
    hisp_data_fimOrder: '',
    hisp_dias: '',
    pesf_nome: '',
    hisp_lanc_financ: '',
    hisp_ativo: ''
  },
  estornoProrrogacao:{
    estp_hisp_id: '',
    estp_motivo: ''
  },
  snackbar:{
    mensagem: '',
    open: false,
    severity: ''
  },
  disabledButtonProrrogar: false,
  disabledButtonCancelar: false,
  modalTransferirCodigo: false,
  buscaPor: 'cpf',
  search: '',
  loadingPessoa: false,
  clienteTransferenciaCodigo: null,
  codigoSelecionado: null,
  loadingSalvarTransf: false,
  config_aditivos:{
    list: []
  },
  aditivoSelecionado:{
    ccad_id: '',
    ccad_descricao: '',
    ccad_gera_fin: false,
    ccad_duracao: '',
    cursos_aditivo: []
  },
  curso_aditivo:{
    cursad_id: '',
    curso_atual:{
      curso_nome: ''
    },
    curso_novo:{
      curso_nome: ''
    },
    material: {
      matcont_valor: '',
      matcont_num_parcelas: '',
      matcont_dia_vencimento: '',
      matcont_formapagto_id: '',
      matcont_tabprec_id: '',
      matcont_prod_id: '',
    }
  },
  planoContas: {
    parametros:{
      paramfin_parcelamento : 'Mensal'
    }
  },
  tabelaPrecoMaterial: null,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  cabecalhoTabelaServico: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Serviço/Produto' },
    // { id: 'parcelas', numeric: true, disablePadding: true, label: 'Parcelas/Recorrências' },
    { id: 'parcelas_abertas', numeric: true, disablePadding: true, label: 'Parcelas Abertas' },
    { id: 'valor_contrato', numeric: true, disablePadding: true, label: 'Total do Contrato' },
    { id: 'venc_prim_parcela', numeric: false, disablePadding: true, label: 'Venc. Prim. Parcela' },
    { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
    { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
    { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
    { id: 'gerar_nfse', numeric: false, disablePadding: true, label: 'NFSe Automático' }
  ],
  acoesTabelaServico: [],
  servico:{
    produto_id: '',
    descricao: '',
    formapagto_id: '',
    forma_pagto: '',
    valor: '',
    num_parcelas: '',
    contfin_id: '',
    conta_fin: '',
    venc_prim_parcela: '',
    venc_prim_parcela_sem_form: '',
    gerar_nfse: false,
    index: '',
    recorrencia: false,
    parcelado: false,
    recorrenciaACada: 1,
    tipoRecorrencia: 'M',
    qtdRecorrencia: 1,
    intervalo_parcela: ''
  },
  servicoRenovar:{
    produto_id: '',
    descricao: '',
    formapagto_id: '',
    forma_pagto: '',
    valor: '',
    num_parcelas: '',
    contfin_id: '',
    conta_fin: '',
    venc_prim_parcela: '',
    venc_prim_parcela_sem_form: '',
    gerar_nfse: false,
    index: ''
  },
  alteraServico: false,
  produtos:{
    list: []
  },
  contasFinanceira:{
    list: []
  },
  msgLoading: 'Carregando Dados do Contrato...',
  servicoSelecionado: {
    id: ''
  },
  modalAtualizaServico: false,
  produtoSelecionado:{
    preco: null
  },
  produtoSelecionadoRenovar:{
    preco: null
  },
  boletosVencidos: [],
  modalAlterarBoleto: false,
  modalAlterarBoletoCancelamento: false,
  modalCancelarContrato: false,
  estado_contrato: '',
  modalRenovarContrato: false,
  dadosRenovarContrato: {
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    produtos:[]
  },
  possui_emissao_nfse: false,
  openModalConfirmacao: false,
  modal: {
    mensagem: '',
  },
  indexServico: 0,
  openModalNovoContrato: false,
  dadosContrato: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    cliente_id: '',
    produtos: [],
    template_versao_id: "",
    assinar_contrato: false
  },
  openModalContrato: false,
  openModalAdicionarServico: false,
  tabContrato: 0,
  searchResp: {
    cpf: ''
  },
  template_documentos: {
    list: []
  },
  exibir_inativados: false,
  habilita_servico: false,
}