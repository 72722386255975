import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Autocomplete from '@mui/material/Autocomplete';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import real from './../../services/real'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
import ModalRelatorio from './../../components/modals/Relatorios'
import Alert from './../../components/templates/Alert'
import Dre from './template/Dre'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ModalAjuda from './../../components/modals/Ajuda'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const initialState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unidade_id: "",
    data_por: 'PAGAMENTO',
    item_id: '',
    contfin_id: ''
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  planosConta: {
    list: []
  },
  ModalRelatorio: false,
  cabecalhoTabelaQtdAulas: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos2', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  acoesTabelaQtdAulas: [],
  linhaSelecionada: '',
  itensFechadoDre: [],
  itens_receita_despesa: {
    list: []
  },
  itemSelecionado: {
    id: '',
    descricao: '',
    planocontas_id: '',
    plano_contas: {}
  },
  tituloSubRel: '',
  valor_total: 0,
  valor_total_sub: 0,
  contaFinanceiras: {
    list: []
  },
  tab: 0,
  por_unidade: {
    list: []
  },
  cabecalhoTabelaPorUnidade: [],
  acoesTabelaPorUnidade: [],
  openModalAjuda: false,
  markdown: ''
}

const maskData = value => {
  return value.replace(/\D/g, "").replace(/^(\d{2})(\d{4})+?$/, "$1/$2");
};

export default class Financeiro extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/relatorios/Financeiro.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'rel-financeiro')

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            descricao: value.tela.descricao,
            slug: value.tela.slug,
            perm: {
              visualizar: value.visualizar
            }
          }
        })
      }
    })

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.filter(param => (param.tipo === 'MANTENEDOR' || param.tipo === 'UNIDADE')).sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            let descricao = ''
            if (unidade.tipo === 'MANTENEDOR') {
              descricao = `${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
            }else if (unidade.tipo === 'UNIDADE') {
              descricao = `${unidade.numero} - ${unidade.descricao}`
            }

            return {
              id: unidade.id,
              descricao,
              tipo: unidade.tipo
            }
          })
        }
      })

      if(unidades.length === 1){
        const {filtro} = this.state
        filtro.unidade_id = unidades[0].id

        await this.consultarItens(filtro.unidade_id)
        await this.consultarContasFinanceira(filtro.unidade_id)

        this.setState({filtro})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })

      this.backPage()
    }
  }

  async consultarItens(unidade_id){
    try {
  
      const dados = {
        unidadesnegocio: [parseInt(unidade_id)],
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,this.getToken())
             
      this.setState({
        itens_receita_despesa: {
          list: data.filter(param => param.ativo).map(item => {
            return {
              id: item.id,
              descricao: item.descricao,
              planocontas_id: item.planocontas_id,
              plano_contas: item.plano_contas
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async consultarContasFinanceira(unidade_id){
    try {
      const dados = {
        filtro: {
          tipo_conta: "",
          descricao: "",
          ativo: "Sim"
        },
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
        return {
          id: param.id,
          descricao: param.descricao,
          ordem: param.ordem,
        }
      })

      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        }
      })
    }catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async updateFieldFiltroDRE(event) {
    const filtro = this.state.filtro

    let name = event.target.name
    let value = event.target.value

    if( name === 'data_inicial' || name === 'data_final'){
      filtro[name] = maskData(value)
    }else{
      filtro[name] = value
    }

    this.setState({ filtro })
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    let name = event.target.name
    let value = event.target.value

    if(name === 'unidade_id' && value !== ''){
      await this.consultarItens(value)
      await this.consultarContasFinanceira(value)
    }

    if(name === 'relatorio' && value === 'taxa-boleto-pix'){
      filtro.unidade_id = ''
    }

    filtro[name] = value

    this.setState({ filtro })
  }

  updateFieldItem(e, value){
    const {filtro} = this.state

    if(!value){
      filtro.item_id = ''

      this.setState({
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        },
        filtro
      })

      return false
    } 

    filtro.item_id = value.id
      
    this.setState({
      filtro
    })

    this.setState({
      itemSelecionado: value
    })
  }

  updateFieldFiltroData(event) {
    const filtro = this.state.filtro
    filtro[event.target.name] = event.target.value
    this.setState({ filtro })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })

    if (filtro.relatorio === 'demonstracao-resultado-exercicio' || 
      filtro.relatorio === 'dre-regime-caixa' || 
      filtro.relatorio === 'taxa-boleto-pix') {
      
      const auxInicial = filtro.data_inicial.split('/')

      if( auxInicial.length !== 2){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }
  
      const mesInicial = parseInt(auxInicial[0])
      const anoInicial = parseInt(auxInicial[1])
  
      if( !mesInicial || mesInicial < 1 || mesInicial > 12){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }
  
      if( !anoInicial || anoInicial < 1990){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }

      const auxFinal = filtro.data_final.split('/')

      if( auxFinal.length !== 2){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }
  
      const mesFinal = parseInt(auxFinal[0])
      const anoFinal = parseInt(auxFinal[1])
  
      if( !mesFinal || mesFinal < 1 || mesFinal > 12){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }
  
      if( !anoFinal || anoFinal < 1990){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data inicial corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }

      if (filtro.data_inicial === '') {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data inicial'
          }
        })
        this.setState({
          loading: false
        })
        return
      }

      if (filtro.data_final === '') {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message:'Por favor informar a data final'
          }
        })
        this.setState({
          loading: false
        })
        return
      }
    }

    if(filtro.relatorio !== 'taxa-boleto-pix'){
      if(filtro.unidade_id === ''){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a unidade'
          }
        })
        this.setState({
          loading: false
        })
        return
      }
    }

    if (filtro.relatorio !== 'demonstracao-resultado-exercicio') {
      if(filtro.data_inicial === ''){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data inicial'
          }
        })
        this.setState({
          loading: false
        })
        return
      }

      if(filtro.data_final === ''){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data final'
          }
        })
        this.setState({
          loading: false
        })
        return
      }
    }

    if (filtro.relatorio === 'extrato-bancario' && filtro.contfin_id === '') {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a conta financeira'
        }
      })
      this.setState({
        loading: false
      })
      return
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
      
      // filtro.unidadesnegocio = dados.unidadesnegocio
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())
      
      if (filtro.relatorio === 'demonstracao-resultado-exercicio' || filtro.relatorio === 'dre-regime-caixa') {
        try {
  
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/list`, dados,this.getToken())
    
          const planosConta = data.map(param => {
            return {
              id: param.id,
              codigo: param.codigo,
              descricao: param.descricao,
              ativo: param.ativo,
              slug: param.slug,
              centro_custos: param.centro_custos,
              totalizador: param.totalizador,
              tipo: param.tipo,
              nivel: param.nivel
            }
          })
      
          this.setState({
            planosConta: {
              list: planosConta
            }
          })
    
        } catch (error) {
          console.log(error)
          this.setState({
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          })
        }
      }

      if(filtro.relatorio === 'taxa-boleto-pix'){
        this.setState({
          por_unidade: {
            list: data.por_unidade
          },
          cabecalhoTabelaPorUnidade: data.cabecalhoTabelaPorUnidade,
          acoesTabelaPorUnidade: data.acoesTabelaPorUnidade
        })
      }
      
      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        urlView: data.urlView,
        valor_total: data.valor_total || 0
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }
  }

  async imprimir() {
    const { filtro, relatorios } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const dados = {
        unidadesnegocio: [
          filtro.unidade_id
        ]
      }

      filtro.unidadesnegocio = dados.unidadesnegocio
      filtro.list = relatorios.list

      if (filtro.relatorio === 'movimentacao-item-receita-despesa') {
        filtro.valor_total = this.state.valor_total
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      console.log(data)

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      if(filtro.relatorio === 'demonstracao-resultado-exercicio')
        saveAs(pdfBlob, 'DRE-Regime-de-Competência.pdf')

      if(filtro.relatorio === 'dre-regime-caixa')
        saveAs(pdfBlob, 'DRE-Regime-de-Caixa.pdf')

      if(filtro.relatorio === 'movimentacao-item-receita-despesa')
        saveAs(pdfBlob, 'Mov-Item-Receita-Despesa.pdf')
      

      if(filtro.relatorio === 'extrato-bancario')
        saveAs(pdfBlob, 'Extrato.pdf')

      if(filtro.relatorio === 'resultado-centro-custo')
        saveAs(pdfBlob, 'Resultado-Centro-Custo-Lucro.pdf')
      
    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async imprimirSub(){
    const { filtro, filtroSub, relatoriosModal } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const dados = {
        unidadesnegocio: [
          filtro.unidade_id
        ]
      }

      filtroSub.unidadesnegocio = dados.unidadesnegocio
      filtroSub.data_inicial = filtro.data_inicial
      filtroSub.data_final = filtro.data_final
      filtroSub.list = relatoriosModal.list
      filtroSub.data_por = filtro.data_por

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtroSub, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'Parcelas.pdf')

    } catch (error) {
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async viewRow(ids, acao, status) {

    if(acao === 'taxa-boleto-pix'){
    
      const filtroSub = this.state.filtroSub
  
      filtroSub.relatorio = acao
      filtroSub.list = ids

      this.setState({
        cabecalhoTabelaModal: this.state.cabecalhoTabela,
        acoesTabelaModal: this.state.acoesTabela,
        relatoriosModal: {
          list: ids
        },
        filtroSub,
        urlViewModal: '',
        modalRelatorio: true,
        linhaSelecionada: status,
        tituloSubRel: status,
        itensFechadoDre: []
      })
    }else{
      try {
  
        let filtroAux = {
          relatorio: acao,
          ids,
          status
        }
  
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtroAux, this.getToken())
  
        const filtroSub = this.state.filtroSub
  
        filtroSub.relatorio = acao
        filtroSub.list = data.list
  
        this.setState({
          cabecalhoTabelaModal: data.cabecalhoTabela,
          acoesTabelaModal: data.acoesTabela,
          relatoriosModal: {
            list: data.list
          },
          filtroSub,
          urlViewModal: data.urlView,
          modalRelatorio: true,
          linhaSelecionada: status,
          itensFechadoDre: []
        })
  
      } catch (error) {
        console.log(error)
        this.setState({
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
        return
      }
    }
  }

  handleCloseModalRelatorio() {
    this.setState({
     modalRelatorio: false,
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  minimizarMaximizarRows(row){
    const {itensFechadoDre} = this.state

    let codigo = row.split(' - ')[0]
    
    const index = itensFechadoDre.findIndex(param => param === codigo)
    
    if(index === -1){
      itensFechadoDre.push(codigo)
    }else{
      itensFechadoDre.splice(index, 1)
    }
    
    this.setState({
      itensFechadoDre
    })

  }

  async viewItens(row){
    try {
     
      let filtro = {
        relatorio: 'list-parcelas',
        data_por: this.state.filtro.data_por,
        parcelas: row.parcelas,
        titulo: 'Parcelas - ' + row.item,
      }
      
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())
           
      this.setState({
        cabecalhoTabelaModal: data.cabecalhoTabela,
        acoesTabelaModal: data.acoesTabela,
        relatoriosModal: {
          list: data.list
        },
        modalRelatorio: true,
        tituloSubRel: 'Parcelas - ' + row.item,
        valor_total_sub: row.valor_total,
        filtroSub: filtro
      })

    } catch (error) {
      this.setState({
        loading: false
      })

      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
      console.log(error)
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  viewContas(row){
    const filtroSub = this.state.filtroSub
  
    filtroSub.relatorio = 'parcelas-centro-custo'
    filtroSub.list = row.contas

    this.setState({
      cabecalhoTabelaModal: [
        {id: 'descricao_conta', numeric: false, disablePadding: false, label: 'Descrição da Conta'},
        {id: 'valor_parcela_form', numeric: true, disablePadding: false, label: 'Valor Parcela'},
        {id: 'valor_percentual', numeric: true, disablePadding: false, label: 'Porcentagem Rateio'},
        {id: 'valor_form', numeric: true, disablePadding: false, label: 'Valor do Rateio'},
      ],
      acoesTabelaModal: [],
      relatoriosModal: {
        list: row.contas
      },
      modalRelatorio: true,
      tituloSubRel: 'Parcelas Centro de Custo: ' + row.descricao,
      valor_total_sub: 0,
      filtroSub
    })
  }

  render() {
    const { filtro, cabecalhoTabela, acoesTabela, relatorios, urlView, loadingDownload, contaFinanceiras,
      relatorios_options, unidades, planosConta, itens_receita_despesa, itemSelecionado, tab, por_unidade,
      cabecalhoTabelaPorUnidade, acoesTabelaPorUnidade } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          <Grid container spacing={2} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatório Financeiro</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={2} direction="row" className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(value => {
                  if (value.perm) {
                    if (value.perm.visualizar) {
                      return (
                        <option value={value.slug}>{value.descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>
            {filtro.relatorio !== 'taxa-boleto-pix' &&
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Unidade"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="unidade_id"
                  value={filtro.unidade_id}
                  onChangeCapture={(e) => this.updateFieldFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""> Selecionar Unidade</option>
                  {unidades.list.map(unidade => {
                    return (
                      <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                    )
                  })}
                </TextField>
              </Grid>
            }
            {(filtro.relatorio === 'demonstracao-resultado-exercicio' ||
            filtro.relatorio === 'dre-regime-caixa' || 
            filtro.relatorio === 'taxa-boleto-pix') && 
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Data Inicial (Mês/Ano)" placeholder='xx/xxxx' variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltroDRE(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Data Final (Mês/Ano)" placeholder='xx/xxxx' variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltroDRE(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            { filtro.relatorio === 'movimentacao-item-receita-despesa' &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar data por*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="data_por"
                    value={filtro.data_por}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="VENCIMENTO">Data de Vencimento</option>
                    <option value="PAGAMENTO">Data de Pagamento</option>
                    <option value="EMISSAO">Data de Emissão</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => this.updateFieldItem(event, value)}
                    freeSolo
                    options={itens_receita_despesa.list}
                    value={itemSelecionado}
                    getOptionLabel={option => `${option.descricao}`}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Item Receita/Despesa" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${itemSelecionado.descricao}`} />
                    )}
                  />
                </Grid>
              </React.Fragment>
            }
            {filtro.relatorio === 'resultado-centro-custo' &&
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Filtrar data por*"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="data_por"
                  value={filtro.data_por}
                  onChangeCapture={(e) => this.updateFieldFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="VENCIMENTO">Data de Vencimento</option>
                  <option value="PAGAMENTO">Data de Pagamento</option>
                  <option value="EMISSAO">Data de Emissão</option>
                </TextField>
              </Grid>
            }
            { (filtro.relatorio === 'extrato-bancario' || filtro.relatorio === 'resultado-centro-custo') && 
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            { filtro.relatorio === 'extrato-bancario' && 
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Conta"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contfin_id"
                    value={filtro.contfin_id}
                    onChangeCapture={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    {contaFinanceiras.list.map(conta => {
                      return (
                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
            }
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.relatorio === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            {(relatorios.list.length > 0 && filtro.relatorio !== 'taxa-boleto-pix') &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          <hr className="mg_top_10"/>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {(filtro.relatorio !== "demonstracao-resultado-exercicio" && filtro.relatorio !== "dre-regime-caixa" && filtro.relatorio !== "taxa-boleto-pix") &&
                <React.Fragment>
                  {cabecalhoTabela.length > 0 &&
                    <React.Fragment>
                      <Grid container spacing={2} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12}>
                          <Table
                            urlView={urlView}
                            headCell={cabecalhoTabela}
                            rows={relatorios.list}
                            acoes={acoesTabela}
                            viewItens={(e) => this.viewItens(e)}
                            viewRow={(e, acao, status) => this.viewRow(e, acao, status)} 
                            viewContas={(e) => this.viewContas(e)}
                          />
                        </Grid>
                      </Grid>
                      { filtro.relatorio === 'movimentacao-item-receita-despesa' &&
                        <Grid container spacing={2} direction="row" >
                          <Grid item md={9} xs={false} sm={7}></Grid>
                          <Grid style={{textAlign: 'right'}} item md={3} xs={12} sm={5}>
                            <span style={{fontSize: 20}}>Valor Total: R$ {real(this.state.valor_total)}</span>
                          </Grid>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                </React.Fragment>
              }

              {(filtro.relatorio === "demonstracao-resultado-exercicio" || filtro.relatorio === "dre-regime-caixa") &&
                <React.Fragment>
                  {relatorios.list.length > 0 &&
                    <React.Fragment>
                      <Dre 
                        dados= {relatorios.list}
                        planosConta= {planosConta.list}
                        minimizarMaximizarRows = {e => this.minimizarMaximizarRows(e)}
                        itensFechadoDre={this.state.itensFechadoDre}
                      />
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {filtro.relatorio === "taxa-boleto-pix" &&
                <React.Fragment>
                  <AppBar position="static" className="mg_top_20">
                    <Tabs id="viewCliente" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                      <Tab label="Listagem" {...a11yProps(0)} />
                      <Tab label="Por Unidades" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tab} index={0}>
                    <Grid container spacing={2} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12}>
                        <Table
                          urlView={urlView}
                          headCell={cabecalhoTabela}
                          rows={relatorios.list}
                          acoes={acoesTabela}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Grid container spacing={2} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12}>
                        <Table
                          urlView={urlView}
                          headCell={cabecalhoTabelaPorUnidade}
                          rows={por_unidade.list}
                          acoes={acoesTabelaPorUnidade}
                          viewRow={(e, acao, status) => this.viewRow(e, acao, status)}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </React.Fragment>
              }

              {filtro.relatorio === "demonstracao-resultado-exercicio" &&
                <Grid container spacing={2} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <div style={{width: '100%', border: '1px solid #D0D0D0', borderRadius: '10px', padding: 10, color: '#000'}}>
                      <h5 style={{margin: 0, marginBottom: 10}}>DRE no Regime de Competência:</h5>
                      <p style={{fontSize: 16}}>O Regime de Competência é uma abordagem contábil onde as receitas e despesas são reconhecidas no período em que são incorridas, independentemente de quando o dinheiro é efetivamente recebido ou pago. Nesse regime, as receitas são registradas no momento em que são geradas (ou seja, quando a venda ou serviço é realizado), e as despesas são reconhecidas quando são incorridas, mesmo que o pagamento ou recebimento aconteça em períodos posteriores.</p>
                      <p style={{fontSize: 16}}>Essa abordagem oferece uma visão mais precisa do desempenho financeiro da empresa durante um determinado período, pois alinha as receitas e despesas aos eventos econômicos que ocorrem, proporcionando uma análise clara da rentabilidade operacional do negócio.</p>
                    </div>
                  </Grid>
                </Grid>
              }

              {filtro.relatorio === "dre-regime-caixa" &&
                <Grid container spacing={2} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <div style={{width: '100%', border: '1px solid #D0D0D0', borderRadius: '10px', padding: 10, color: '#000'}}>
                      <h5 style={{margin: 0, marginBottom: 10}}>DRE no Regime de Caixa:</h5>
                      <p style={{fontSize: 16}}>O Regime de Caixa é uma abordagem em que as receitas e despesas são reconhecidas apenas quando o dinheiro é efetivamente recebido ou pago. Neste regime, uma venda só é registrada como receita no DRE quando o pagamento é realizado, e uma despesa só é reconhecida quando o pagamento é feito.</p>
                      <p style={{fontSize: 16}}>O Regime de Caixa é útil para entender o fluxo de caixa real da empresa, ou seja, o dinheiro que entra e sai no período. Ele fornece uma visão clara da liquidez da empresa, mostrando como o caixa está sendo gerado e utilizado, <b>mas pode não refletir a real performance econômica do negócio em termos de geração de receitas e despesas no período em análise.</b></p>
                    </div>
                  </Grid>
                </Grid>
              }
              
            </React.Fragment>
          }
          <ModalRelatorio
            open={this.state.modalRelatorio}
            handleClose={e => this.handleCloseModalRelatorio(e)}
            list={this.state.relatoriosModal.list}
            cabecalhoTabela={this.state.cabecalhoTabelaModal}
            acoesTabela={this.state.acoesTabelaModal}
            imprimir={e => this.imprimirSub(e)}
            loadingDownload={loadingDownload}
            urlView={this.state.urlViewModal}
            titulo={this.state.tituloSubRel}
            valor_total={real(this.state.valor_total_sub)}
            filtroSub={this.state.filtroSub}
          />
        </Main>
        <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Relatórios Administrativos"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}