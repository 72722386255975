import React, { useState, useEffect } from 'react';
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import moment from 'moment'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
import Alert from './../../components/templates/Alert'
import Table from './../../components/Table'
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import ModalSubRelatorio from './../../components/modals/Relatorios'
import real from './../../services/real'
import VendaVouchers from './template/VendaVouchers'
import VendaPeriodo from './template/VendaPeriodo'
import VouchersAgendasCliente from './template/VouchersAgendasCliente'
import VoucherCliente from './template/VoucherCliente'
import ModalAjuda from './../../components/modals/Ajuda'
import { format } from 'cnpj'
import { useParams, useNavigate } from 'react-router-dom';

const cpf = require('node-cpf-cnpj');

const initialState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unidade_id: "",
    pessoa_id: "",
    nome: "",
    limit: 10,
    offset: 0,
    data_por: "COMPRA"
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosType: {
    list: []
  },
  acoesTabelaType: [],
  relatorios_options: {
    list: [{
      perm: {
        visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  planosConta: {
    list: []
  },
  ModalRelatorio: false,
  cabecalhoTabelaType: [],
  acoesTabelaQtdAulas: [],
  linhaSelecionada: '',
  itensFechadoDre: [],
  tab: 0,
  dataChart: [],
  consulta_realizada: false,
  pessoas:{
    list: []
  },
  cliente: {
    pessoa_id: '',
    nome: ''
  },
  vouchers: {
    list: []
  },
  agendas:{
    list: []
  },
  pagamentos_voucher: {
    list: []
  },
  pagamentos_agendas: {
    list: []
  },
  cabecalhoTabelaAgenda: [],
  acoesTabelaAgenda: [],
  relatoriosAgenda: {
    list: []
  },
  urlViewAgenda: '',
  consulta_realizadaAgenda: false,
  valor_total: '',
  openModalSubRelatorio: false,
  cabecalhoTabelaSubRel: [],
  acoesTabelaSubRel: [],
  urlViewSubRel: '',
  subRelatorios: {
    list: []
  },
  tituloSubRel: '',
  valor_total_sub_rel: '',
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const RelatorioVendas = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateFieldFiltro = (event) => {
    const filtro = state.filtro

    let name = event.target.name
    let value = event.target.value

    filtro[name] = value

    setState(prevState => ({...prevState, filtro }))

    if(name === 'relatorio'){
      setState(prevState => ({...prevState,
        filtro:{
          relatorio: value,
          data_inicial: '',
          data_final: '',
          unidade_id: state.filtro.unidade_id,
          pessoa_id: "",
          nome: "",
          data_por: filtro.relatorio === 'vendas-por-servico' ? "LANCAMENTO" :  "COMPRA",
          limit: 10,
          offset: 0
        },
        cabecalhoTabela: [],
        acoesTabela: [],
        relatorios: {
          list: []
        },
        cliente: {
          pessoa_id: '',
          nome: ''
        },
        pessoas:{
          list: []
        },
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const filtrar = async () => {
    const filtro = state.filtro

    setState(prevState => ({...prevState,
      loading: true
    }))

    if (filtro.unidade_id === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a unidade'
        }
      }))
      setState(prevState => ({...prevState,
        loading: false
      }))
      return
    }

    if (filtro.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        }
      }))
      setState(prevState => ({...prevState,
        loading: false
      }))
      return
    }

    if (filtro.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a data final'
        }
      }))
      setState(prevState => ({...prevState,
        loading: false
      }))
      return
    }

    if(moment(filtro.data_final).isBefore(filtro.data_inicial)){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'A data inicial não pode ser maior que a data final.'
        }
      }))
      setState(prevState => ({...prevState,
        loading: false
      }))
      return
    }

    if(filtro.relatorio === 'voucher-agendamento-cliente'){
      if (filtro.pessoa_id === '') {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:'Por favor informar o cliente'
          }
        }))
        setState(prevState => ({...prevState,
          loading: false
        }))
        return
      }
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())
      
      if(filtro.relatorio === 'voucher-agendamento-cliente'){
        setState(prevState => ({...prevState,
          vouchers: {
            list: data.vouchers
          },
          agendas:{
            list: data.agendas
          },
          pagamentos_voucher: {
            list: data.pagamentos_voucher
          },
          pagamentos_agendas: {
            list: data.pagamentos_agendas
          },
          consulta_realizada: true
        }))
      }else{
        setState(prevState => ({...prevState,
          cabecalhoTabela: data.cabecalhoTabela,
          acoesTabela: data.acoesTabela,
          relatorios: {
            list: data.list
          },
          urlView: data.urlView,
          consulta_realizada: true
        }))

        if(filtro.relatorio === 'vendas-por-periodo'){
          setState(prevState => ({...prevState,
            cabecalhoTabelaAgenda: data.cabecalhoTabelaAgenda,
            acoesTabelaAgenda: data.acoesTabelaAgenda,
            relatoriosAgenda: {
              list: data.listAgenda
            },
            urlViewAgenda: data.urlViewAgenda,
            consulta_realizadaAgenda: true,
            valor_total: data.valor_total
          }))
        }
  
        if(filtro.relatorio === 'venda-vouchers-periodo'){
  
          let dataChart = [['Descrição', 'Valor']]
  
          for (const value of data.listType) {
            dataChart.push([value.descricao, parseFloat(value.valor.replaceAll(".", "").replace(",", "."))])
          }
  
          setState(prevState => ({...prevState,
            relatoriosType: {
              list: data.listType
            },
            cabecalhoTabelaType: data.cabecalhoTabelaType,
            acoesTabelaType: data.acoesTabelaType,
            dataChart
          }))
        }
      }
      

      setState(prevState => ({...prevState,
        loading: false
      }))

    } catch (error) {
      setState(prevState => ({...prevState,
        loading: false
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      console.log(error)
    }
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tab: newValue
    }))
  };

  const imprimir = async () => {
    const { filtro, relatorios, tab, dataChart, relatoriosType, acoesTabelaType, cabecalhoTabelaType, agendas, 
      vouchers, pagamentos_voucher, pagamentos_agendas, relatoriosAgenda, subRelatorios, tituloSubRel, valor_total } = state

    setState(prevState => ({...prevState,
      loadingDownload: true
    }))

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio
      filtro.list = relatorios.list

      if(filtro.relatorio === 'voucher-agendamento-cliente'){
        filtro.agendas = agendas.list
        filtro.vouchers = vouchers.list
        filtro.pagamentos_voucher = pagamentos_voucher.list
        filtro.pagamentos_agendas = pagamentos_agendas.list
      }

      if(filtro.relatorio === 'vendas-por-periodo'){
        filtro.listAgenda = relatoriosAgenda.list
        filtro.valor_total = valor_total
      }

      if(filtro.relatorio === 'vouchers-ids' || filtro.relatorio === 'agendas-ids'){
        filtro.titulo = tituloSubRel
        filtro.list = subRelatorios.list
      }

      if(filtro.relatorio === 'venda-vouchers-periodo' && tab === 1){
        const dados = {
          dataChart,
          relatoriosType: relatoriosType.list,
          acoesTabelaType,
          cabecalhoTabelaType,
          filtro
        }
    
        localStorage.setItem('grafico_venda_vouchers_periodo', JSON.stringify(dados));

        navigate("/grafico_venda_vouchers_periodo/impressao");
        setState(prevState => ({...prevState, loading: false }))
        return;
      }else{
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, getToken())
  
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        setState(prevState => ({...prevState,
          loadingDownload: false
        }))
        
        if(filtro.relatorio === 'venda-vouchers-periodo')
          saveAs(pdfBlob, 'Venda-Voucher.pdf')
        if(filtro.relatorio === 'atendimento-por-servico')
          saveAs(pdfBlob, 'Agendamentos-por-Servico.pdf')
        if(filtro.relatorio === 'vendas-por-servico')
          saveAs(pdfBlob, 'Vendas-por-Servico.pdf')
        if(filtro.relatorio === 'voucher-agendamento-cliente')
          saveAs(pdfBlob, 'Vochers-Agendamentos-Cliente.pdf')
        if(filtro.relatorio === 'uso-voucher-cliente')
          saveAs(pdfBlob, 'Uso-Vouchers-Cliente.pdf')
        if(filtro.relatorio === 'vendas-por-periodo')
          saveAs(pdfBlob, 'Vendas-Periodo.pdf')
        if(filtro.relatorio === 'vouchers-ids' || filtro.relatorio === 'agendas-ids')
          saveAs(pdfBlob, `${tituloSubRel}.pdf`)
      }

    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      setState(prevState => ({...prevState,
        loadingDownload: false
      }))
      console.log(error)
    }
  }

  const imprimirSubRel = async (rel) => {
    const { subRelatorios, tituloSubRel, acoesTabelaSubRel } = state

    setState(prevState => ({...prevState,
      loadingDownload: true
    }))

    try {

      let filtro = {
        titulo: tituloSubRel,
        list: subRelatorios.list,
        relatorio: acoesTabelaSubRel[0] === 'viewVoucherRel' ? 'vouchers-ids' : 'agendas-ids',
        unidade_id: state.filtro.unidade_id
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingDownload: false
      }))
      
      if(filtro.relatorio === 'vouchers-ids' || filtro.relatorio === 'agendas-ids')
        saveAs(pdfBlob, `${tituloSubRel}.pdf`)

    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      setState(prevState => ({...prevState,
        loadingDownload: false
      }))
      console.log(error)
    }
  }

  const updateFieldPessoaFiltro = (event, value) => {
    const {filtro} = state

    if(!value){

      filtro.pessoa_id = ''
      filtro.nome = ''

      setState(prevState => ({...prevState,
        cliente: {
          pessoa_id: '',
          nome: '',
          cpf_cnpj: ''
        },
        filtro
      }))

      return false
    } 

    filtro.pessoa_id = value.pessoa_id
    filtro.nome = value.nome

    setState(prevState => ({...prevState,
      cliente: value
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      let filtroPessoa = {
        unidadesnegocio: [state.filtro.unidade_id],
        ativo: 'Sim',
        nome: nome,
        cpf_cnpj: '',
        tipo: '',
        flag:''
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtroPessoa, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          nome: `${pessoa.nome} ${pessoa.sobrenome || ''}`,
          cpf_cnpj: pessoa.tipo === 'FISICA' ? (pessoa.cpf_cnpj ? cpf.format(pessoa.cpf_cnpj) : 'N/A') : (pessoa.cpf_cnpj ? format(pessoa.cpf_cnpj) : 'N/A'),
        })
      })

      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const viewAgenda = (row) => {
    localStorage.setItem('agenda_voucher', JSON.stringify(row))

    navigate("/agenda_ambiente");
    return;
  }

  const handleChangePage = async (event, newPage) => {
    const {filtro} = state
    filtro.offset = newPage
    setState(prevState => ({...prevState,
      filtro
    }))
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))
  }

  const viewRow = async (ids, acao, status) => {
    try {
      let filtro = {
        relatorio: '',
        ids
      }

      if(acao === 'vouchers'){
        filtro.relatorio = 'vouchers-ids'
      }else if(acao === 'agendas'){
        filtro.relatorio = 'agendas-ids'
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())

      setState(prevState => ({...prevState,
        cabecalhoTabelaSubRel: data.cabecalhoTabela,
        acoesTabelaSubRel: data.acoesTabela,
        urlViewSubRel: data.urlView,
        subRelatorios: {
          list: data.list
        },
        openModalSubRelatorio: true,
        tituloSubRel: status,
        valor_total_sub_rel: real(somarValores(data.list.map(value => { return parseFloat(value.valor_item.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) })))
      }))

    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleModalSubRelatorio = () => {
    setState(prevState => ({...prevState,
      openModalSubRelatorio: false
    }))
  }

  const viewPedidos = (row) => {
    setState(prevState => ({...prevState,
      cabecalhoTabelaSubRel: [
        {id: 'cliente', numeric: false, disablePadding: false, label: 'Cliente'},
        {id: 'valor_servico', numeric: true, disablePadding: false, label: 'Valor Serviço'},
      ],
      acoesTabelaSubRel: [],
      urlViewSubRel: '',
      subRelatorios: {
        list: row.itens.map(value => {
          return {
            _id: value.pessoa_id,
            cliente: value.pessoa_tipo === 'FISICA' ? value.nome : value.nomefantasia,
            valor_servico: 'R$ ' + real(value.valor_servico)
          }
        })
      },
      openModalSubRelatorio: true,
      tituloSubRel: row.produto,
      valor_total_sub_rel: real(row.valor_total_servicos)
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/relatorios/Vendas.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      setState(prevState => ({...prevState,
        consulta_realizada: false,
        filtro: {
          relatorio: "",
          data_inicial: '',
          data_final: '',
          unidade_id: "",
          pessoa_id: "",
          nome: "",
          limit: 10,
          offset: 0,
          data_por: "COMPRA"
        },
        cliente: {
          pessoa_id: '',
          nome: ''
        },
      }))

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'rel-vendas')

      setState(prevState => ({...prevState,
        relatorios_options: {
          list: permissoes.map(value => {
            return {
              descricao: value.tela.descricao,
              slug: value.tela.slug,
              perm: {
                visualizar: value.visualizar
              }
            }
          })
        }
      }))

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())

        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }

              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))

        if(unidades.length === 1){
          const {filtro} = state
          filtro.unidade_id = unidades[0].id
          setState(prevState => ({...prevState,filtro}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
      
      const dados_impressao = JSON.parse(localStorage.getItem('grafico_venda_vouchers_periodo'))
      
      if(dados_impressao){
        setState(prevState => ({...prevState,
          filtro: dados_impressao.filtro,
          loading: true
        }))

        await filtrar()
      
        localStorage.removeItem('grafico_venda_vouchers_periodo');
      }
    };
    
    fetchData();
  }, []);

  const { filtro, cabecalhoTabela, acoesTabela, relatorios, cabecalhoTabelaType, loadingDownload, 
    relatorios_options, unidades, tab, relatoriosType, acoesTabelaType, dataChart, urlView, consulta_realizada,
    pessoas, cliente, vouchers, agendas, pagamentos_voucher, pagamentos_agendas } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        <Grid container spacing={2} direction="row" className="borderBottom">
          <Grid item md={11} xs={10}>
            <h1 className="titulo">Relatório de Vendas</h1>
          </Grid>
          {loadingDownload &&
            <Grid item md={1} xs={2}>
              <CircularProgress />
            </Grid>
          }
        </Grid>
        <Grid container spacing={2} direction="row" className="mg_top_20">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Relatório"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="relatorio"
              value={filtro.relatorio}
              onChangeCapture={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">--Escolha o Relatório--</option>
              {relatorios_options.list.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(value => {
                if (value.perm) {
                  if (value.perm.visualizar) {
                    return (
                      <option value={value.slug}>{value.descricao}</option>
                    )
                  }
                }
                return false
              })}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Unidade"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="unidade_id"
              value={filtro.unidade_id}
              onChangeCapture={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> Selecionar Unidade</option>
              {unidades.list.map(unidade => {
                return (
                  <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                )
              })}
            </TextField>
          </Grid>
          {filtro.relatorio === 'voucher-agendamento-cliente' &&
            <Grid item md={6} xs={12} sm={4}>
              <Autocomplete
                onChange={(event, value) => updateFieldPessoaFiltro(event, value)}
                freeSolo
                options={pessoas.list}
                value={cliente}      
                getOptionLabel={option => option.nome}
                renderInput={(params) => (
                  <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Cliente" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={cliente.nome} onChange={(e) => updateFiltroPessoa(e)}/>
                )}
              />
            </Grid>
          }
          {filtro.relatorio === 'uso-voucher-cliente' && 
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Data por"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="data_por"
                value={filtro.data_por}
                onChangeCapture={(e) => updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="COMPRA">Data da Compra</option>
                <option value="PROMOCIONAL">Validade Promocional</option>
                <option value="NORMAL">Validade Normal</option>
              </TextField>
            </Grid>
          }
          {filtro.relatorio === 'vendas-por-servico' &&
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Data por"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="data_por"
                value={filtro.data_por}
                onChangeCapture={(e) => updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="LANCAMENTO">Data do Lançamento</option>
                <option value="VENCIMENTO">Data do Vencimento</option>
                <option value="PAGAMENTO">Data de Pagamento</option>
              </TextField>
            </Grid>
          }
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='date'
              className="input" 
              label="Data Incial" 
              variant="outlined" 
              size="small" 
              name="data_inicial" 
              value={filtro.data_inicial} 
              onChange={(e) => updateFieldFiltro(e)} 
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField 
              type='date'
              className="input" 
              label="Data Final" 
              variant="outlined" 
              size="small" 
              name="data_final" 
              value={filtro.data_final} 
              onChange={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
          {(filtro.relatorio === 'uso-voucher-cliente' && filtro.unidade_id) && 
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Exibir Cancelados?"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="exibir_cancelados"
                  value={filtro.exibir_cancelados}
                  onChangeCapture={(e) => updateFieldFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value={1}>Sim</option>
                  <option value={0}>Não</option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={4}>
                <Autocomplete
                  onChange={(event, value) => updateFieldPessoaFiltro(event, value)}
                  freeSolo
                  options={pessoas.list}
                  value={cliente}            
                  getOptionLabel={option => option.nome}
                  renderInput={(params) => (
                    <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Cliente" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={cliente.nome} onChange={(e) => updateFiltroPessoa(e)}/>
                  )}
                />
              </Grid>
            </React.Fragment>
          }
          <Grid item md={2} xs={12} sm={3}>
            <Button fullWidth variant="contained" color="primary" onClick={() => filtrar()} disabled={filtro.relatorio === '' ? true : false}>
              Filtrar
            </Button>
          </Grid>
          {relatorios.list.length > 0 &&
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="secondary" onClick={() => imprimir()} disabled={loadingDownload}>
                Imprimir
              </Button>
            </Grid>
          }
          {(filtro.relatorio === "vendas-por-periodo" && relatorios.list.length > 0 ) &&
            <Grid item md={10} xs={12} sm={10}>
              <span className='error' style={{fontSize: '18px'}}>Relatório de faturamento por data do lançamento.</span>
            </Grid>
          }
        </Grid>
        <hr className="mg_top_10"/>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Relatório...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <React.Fragment>
            {(filtro.relatorio === "venda-vouchers-periodo" && relatorios.list.length > 0) &&
              <VendaVouchers 
                cabecalhoTabela = {cabecalhoTabela}
                acoesTabela = {acoesTabela}
                relatorios = {relatorios.list}
                viewRow={(e, acao, status) => viewRow(e, acao, status)}
                tab={tab}
                handleChange={handleChange}
                relatoriosType={relatoriosType.list}
                cabecalhoTabelaType={cabecalhoTabelaType}
                acoesTabelaType={acoesTabelaType}
                dataChart={dataChart}
                urlView={urlView}
              />
            }
            {(filtro.relatorio === "vendas-por-periodo" && relatorios.list.length > 0) &&
              <VendaPeriodo 
                cabecalhoTabela = {cabecalhoTabela}
                acoesTabela = {acoesTabela}
                relatorios = {relatorios.list}
                urlView={urlView}
                viewRow={(e, acao, status) => viewRow(e, acao, status)}
                tab={tab}
                handleChange={handleChange}
                relatoriosType={relatoriosType.list}
                cabecalhoTabelaType={cabecalhoTabelaType}
                acoesTabelaType={acoesTabelaType}
                dataChart={dataChart}
                cabecalhoTabelaAgenda = {state.cabecalhoTabelaAgenda}
                acoesTabelaAgenda = {state.acoesTabelaAgenda}
                relatoriosAgenda = {state.relatoriosAgenda.list}
                urlViewAgenda={state.urlViewAgenda}
                valor_total={state.valor_total}
              />
            }
            {(filtro.relatorio === "venda-vouchers-periodo" && relatorios.list.length === 0 && consulta_realizada) && 
              <p className="mg_top_20">Nenhuma venda de voucher foi encontrada nesse período</p>
            }

            {(filtro.relatorio === 'atendimento-por-servico' || filtro.relatorio === 'vendas-por-servico') &&
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table
                    headCell={cabecalhoTabela}
                    rows={relatorios.list}
                    acoes={acoesTabela}
                    viewPedidos={(e) => viewPedidos(e)}
                  />
                </Grid>
              </Grid>
            }

            {filtro.relatorio === 'voucher-agendamento-cliente' &&
              <VouchersAgendasCliente
                vouchers={vouchers}
                agendas={agendas}
                pagamentos_voucher={pagamentos_voucher}
                pagamentos_agendas={pagamentos_agendas}
                viewAgenda={e => viewAgenda(e)}
              />
            }

            {filtro.relatorio === 'uso-voucher-cliente' &&
              <React.Fragment>
                {relatorios.list.length > 0 &&
                  <VoucherCliente 
                    rows={relatorios.list.slice(((filtro.offset + 1) * filtro.limit) - filtro.limit, (filtro.offset + 1) * filtro.limit)}
                    qtd={relatorios.list.length}
                    limit={filtro.limit}
                    offset={filtro.offset}
                    handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                  />
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
      </Main>
      
      {state.openModalSubRelatorio &&
        <ModalSubRelatorio 
          open={state.openModalSubRelatorio}
          handleClose={e => handleModalSubRelatorio()}
          loadingDownload={state.loadingDownload}
          cabecalhoTabela={state.cabecalhoTabelaSubRel}
          acoesTabela={state.acoesTabelaSubRel}
          urlView={state.urlViewSubRel}
          list={state.subRelatorios.list}
          titulo={state.tituloSubRel}
          viewAgenda={e => viewAgenda(e)}
          imprimir={(e) => imprimirSubRel(e)}
          valor_total={state.valor_total_sub_rel}
          filtroSub={state.filtroSub}
        />
      }
      
      <Nav/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Relatórios Vendas"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default RelatorioVendas