import React, { Component } from 'react'
import axios from 'axios'
import './VoucherPadrao.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid, Button,TextField } from '@mui/material'
import Table from './../../components/Table'
import ModalConfiguracaoVoucher from './../../components/modals/Cadastro/ConfiguracaoVoucher'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import moment from 'moment'
import ModalAjuda from './../../components/modals/Ajuda'
import ModalAjudaCadastro from './../../components/modals/Ajuda'
import SearchIcon from '@mui/icons-material/Search';

const initialState = {
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  configuracoes:{
    list: []
  },
  cabecalho: [
    { id: 'nome', numeric: true, disablePadding: false, label: 'Nome' },
    // { id: 'descricao', numeric: false, disablePadding: false, label: 'Descricao' },
    { id: 'habilita', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'tab_prom', numeric: false, disablePadding: false, label: 'Tabela Promocional' },
    { id: 'dias_val_tab_prom_form', numeric: false, disablePadding: false, label: 'Qtd. Dias' },
    { id: 'tab_normal', numeric: false, disablePadding: false, label: 'Tabela Normal' },
    { id: 'dias_val_tab_normal_form', numeric: false, disablePadding: false, label: 'Qtd. Dias' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  openModalConfiguracao: false,
  modal: {
    mensagem: "",
  },
  modalOpen: false,
  configuracao: {
    id: '',
    unidade_id: '',
    nome: '',
    descricao: '',
    tab_prom_id: '',
    dias_val_tab_prom: '',
    data_val_tab_prom: '',
    tab_normal_id: '',
    dias_val_tab_normal: '',
    data_val_tab_normal: '',
    habilita: '',
    item_rd_id: '',
    validade_por: '',
    ativo: true
  },
  unidades: {
    list: []
  },
  tabelaPrecos: {
    list: []
  },
  mensagemLoading: 'Carregando Padrões...',
  itens: {
    list: []
  },
  openModalAjuda: false,
  markdown: '',
  openModalAjudaCadastro: false,
  markdownCadastro: '',
  filtro: {
    tipo: '',
    nome: '',
    ativo: 'Ativo'
  }
}

export default class AgendaAmbiente extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const helpPathCadastro = require("./../../help/voucherPadrao/VoucherPadrao.md")

    fetch(helpPathCadastro)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdownCadastro: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    
    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'config-voucher')[0]
    
    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })
    
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    try {
      await this.filtrarConfiguracoes()

      const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades: {
          list: unidades.filter(param => param.tipo === 'UNIDADE').sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
            return {
              id: unidade.id,
              descricao: `${unidade.numero} - ${unidade.descricao}`,
              tipo: unidade.tipo
            }
          })
        }
      })

      if(unidades.length === 1){
        const {configuracao} = this.state
        configuracao.unidade_id = unidades[0].id
        await this.consultarTabelas(unidades[0].id)
        await this.consultarItens(unidades[0].id)
        this.setState({configuracao})
      }
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  async filtrarConfiguracoes(){
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        }),
        ...this.state.filtro,
        ativo: this.state.filtro.ativo === 'Ativo' ? true: false
      }

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Buscando configurações...'
        }
      })

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/voucherPadrao/list`, dados, this.getToken())

      const configuracoes = []

      data.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0).forEach(configuracao => {
        configuracoes.push({
          id: configuracao.id,
          nome: configuracao.nome,
          descricao: configuracao.descricao || '',
          unidade: configuracao.unidade.numero + ' - ' + configuracao.unidade.descricao,
          unidade_id: configuracao.unidade_id,
          habilita: configuracao.habilita,
          tab_prom: configuracao.tab_prom.descricao,
          tab_normal: configuracao.tab_normal.descricao,
          validade_por: configuracao.dias_val_tab_prom ? 'QTD_DIAS' : 'DATA_FIXA',
          dias_val_tab_prom: configuracao.dias_val_tab_prom,
          data_val_tab_prom: configuracao.data_val_tab_prom,
          dias_val_tab_normal: configuracao.dias_val_tab_normal,
          data_val_tab_normal: configuracao.data_val_tab_normal,
          dias_val_tab_prom_form: configuracao.dias_val_tab_prom ? configuracao.dias_val_tab_prom + ' Dias' : moment(configuracao.data_val_tab_prom).format('DD/MM/YYYY'),
          dias_val_tab_normal_form: configuracao.dias_val_tab_normal ? configuracao.dias_val_tab_normal + ' Dias' : moment(configuracao.data_val_tab_normal).format('DD/MM/YYYY'),
          ativo: configuracao.ativo,
          tab_prom_id: configuracao.tab_prom_id,
          tab_normal_id: configuracao.tab_normal_id,
          item_rd_id: configuracao.item_rd_id
        })
      })

      this.setState({
        configuracoes: {
          list: configuracoes
        }
      })

      this.setState({
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async consultarTabelas(unidade_id){
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/tabelaPreco/list`, dados, this.getToken())

      const tabelaPrecos = data.map(param => {
        return {
          id: param.id,
          descricao: param.descricao,
          unidade: param.unidade.descricao ? `${param.unidade.numero} - ${param.unidade.descricao}` : (param.unidade.pessoa.tipo === 'FISICA' ? param.unidade.pessoa.fisica.nome : param.unidade.pessoa.juridica.razaosocial),
          data_inicio: moment(param.data_inicio).format('DD/MM/YYYY'),
          data_fim: moment(param.data_fim).format('DD/MM/YYYY'),
          data_inicioOrder: moment(param.data_inicio).format('YYYYMMDD'),
          data_fimOrder: moment(param.data_fim).format('YYYYMMDD')
        }
      })

      this.setState({
        tabelaPrecos: {
          list: tabelaPrecos
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  async consultarItens(unidade_id){
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados, this.getToken())

      const itens = []

      data.filter(param => param.plano_contas.tipo !== 'DESPESA').sort((a, b) => (a.plano_contas.codigo > b.plano_contas.codigo) ? 1 : (b.plano_contas.codigo > a.plano_contas.codigo) ? -1 : 0).forEach(item => {
        itens.push({
          id: item.id,
          descricao: item.descricao,
          planocontas: item.plano_contas.codigo + ' - ' + item.plano_contas.descricao,
          sequencia: item.plano_contas.sequencia,
          tipo: item.plano_contas.tipo === 'DESPESA' ? 'PAGAR' : 'RECEBER',
          planocontas_id: item.plano_contas.id,
          codigo: item.plano_contas.codigo,
          unidade_id: item.plano_contas.unidade_id,
          ativo: item.ativo
        })
      })

      this.setState({
        itens: {
          list: itens
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }
  }

  openCadastro(){
    this.setState({
      openModalConfiguracao: true
    })
  }

  handleCloseConfiguracao(){
    this.setState({
      openModalConfiguracao: false,
      configuracao: {
        id: '',
        unidade_id: '',
        nome: '',
        descricao: '',
        tab_prom_id: '',
        dias_val_tab_prom: '',
        tab_normal_id: '',
        dias_val_tab_normal: '',
        habilita: '',
        item_rd_id: '',
        ativo: true
      }
    })
  }

  confirmarFecharModal(value){
    if(value){
      this.handleCloseConfiguracao()
      this.handleClose()
    }else{
      this.handleClose()
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  verificaFecharModal(){
    this.setState({
      modal: {
        mensagem: "Deseja mesmo fechar o cadastro?",
      },
      modalOpen: true,
    })
  }

  handleClose() {
    this.setState({
      modalOpen: false
    })
  }

  async updateField(event){
    const {configuracao} = this.state

    let {name, value} = event.target

    configuracao[name] = value

    if(name === 'unidade_id'){
      await this.consultarTabelas(parseInt(value))
      await this.consultarItens(parseInt(value)) 
    }

    if(name === 'validade_por'){
      if(value === 'DATA_FIXA'){
        configuracao.dias_val_tab_prom = ''
        configuracao.dias_val_tab_normal = ''
      }
      if(value === 'QTD_DIAS'){
        configuracao.data_val_tab_prom = ''
        configuracao.data_val_tab_normal = ''
      }
    }

    this.setState({configuracao})
  }

  updateFieldAtivo(){
    const {configuracao} = this.state
    configuracao.ativo = !configuracao.ativo
    this.setState({configuracao})
  }

  editarConfiguracao(row){
    this.setState({
      configuracao: row
    })

    this.openCadastro()
  }

  async salvar(value){
    if(value){
      const {configuracao} = this.state

      this.setState({loadingSalvar: true})

      if(configuracao.unidade_id === '' || !configuracao.unidade_id){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a unidade!'
          },
          loadingSalvar: false
        })
        return
      }

      if(configuracao.nome === '' || !configuracao.nome){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o nome da configuração!'
          },
          loadingSalvar: false
        })
        return
      }

      if(configuracao.habilita === '' || !configuracao.habilita){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o Tipo!'
          },
          loadingSalvar: false
        })
        return
      }

      if(configuracao.validade_por === '' || !configuracao.validade_por){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a Validade Por!'
          },
          loadingSalvar: false
        })
        return
      }

      if(configuracao.tab_prom_id === '' || !configuracao.tab_prom_id){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a Tabela Promocional!'
          },
          loadingSalvar: false
        })
        return
      }

      if(configuracao.tab_normal_id === '' || !configuracao.tab_normal_id){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a Tabela Normal!'
          },
          loadingSalvar: false
        })
        return
      }

      if(configuracao.validade_por === 'QTD_DIAS'){
        if(configuracao.dias_val_tab_prom === '' || !configuracao.dias_val_tab_prom){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Informar a Validade da Tabela Promocional!'
            },
            loadingSalvar: false
          })
          return
        }
        if(configuracao.dias_val_tab_normal === '' || !configuracao.dias_val_tab_normal){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Informar a Validade da Tabela Normal!'
            },
            loadingSalvar: false
          })
          return
        }
      }else{
        if(configuracao.data_val_tab_prom === '' || !configuracao.data_val_tab_prom){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Informar a Validade da Tabela Promocional!'
            },
            loadingSalvar: false
          })
          return
        }
        if(configuracao.data_val_tab_normal === '' || !configuracao.data_val_tab_normal){
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Informar a Validade da Tabela Normal!'
            },
            loadingSalvar: false
          })
          return
        }
      }

      if(configuracao.item_rd_id === '' || !configuracao.item_rd_id){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o Item de Receita!'
          },
          loadingSalvar: false
        })
        return
      }

      try {

        if(configuracao.id !== ''){
          await axios.put(`${window._env_.REACT_APP_API_URL}/voucherPadrao/${configuracao.id}`, configuracao, this.getToken())
        }else{
          await axios.post(`${window._env_.REACT_APP_API_URL}/voucherPadrao`, configuracao, this.getToken())
        }
      
        this.setState({
          alerta: {
            open: true,
            severity: 'success',
            message: configuracao.id !== '' ? 'Cadastro atualizado com sucesso' : 'Cadastro realizado com sucesso.'
          },
          loadingSalvar: false
        })

        window.location.reload()
      
      } catch (error) {
        console.log(error)
        this.setState({loadingSalvar: false})
        this.setState({
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
      }

    }else{
      this.verificaFecharModal()
    }
  }

  updateFiltro(event){
    let {filtro} = this.state
    filtro[event.target.name] = event.target.value
    this.setState({filtro})
  }

  render() {
    const { permissoes, filtro } = this.state
    return (
      <React.Fragment>
        {!this.state.loading &&
          <div className="app-menu-closed" id="app">
            <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
              <div>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={9} xs={12} sm={8}>
                    <h1 className="titulo">Configurações de Voucher</h1>
                  </Grid>
                  {permissoes.inserir &&
                    <Grid item md={3} xs={12} sm={4}>
                      <Button onClick={() => this.openCadastro()} size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Configuração
                      </Button>
                    </Grid>
                  }
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField 
                      className="input" 
                      label="Nome Padrão" 
                      variant="outlined" 
                      size="small" 
                      name="nome" 
                      value={filtro.nome} 
                      onChange={(e) => this.updateFiltro(e)} 
                      InputLabelProps={{ shrink: true }} 
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField
                      select
                      label="Tipo"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipo"
                      value={filtro.tipo}
                      onChange={(e) => this.updateFiltro(e)} 
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""> Selecionar Tipo</option>
                      <option value="PRODUTO">Produto</option>
                      <option value="CREDITO">Crédito</option>
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField
                      select
                      label="Ativo"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="ativo"
                      value={filtro.ativo}
                      onChange={(e) => this.updateFiltro(e)} 
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value={'Ativo'}>Ativo</option>
                      <option value={'Inativo'}>Inativo</option>
                    </TextField>
                  </Grid>
                  <Grid item md={1} xs={12} sm={4}>
                    <Button color='warning' className='btn_search_func' fullWidth variant="contained" endIcon={<SearchIcon />} onClick={e => this.filtrarConfiguracoes(e)}></Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlUpdate={(e) => this.editarConfiguracao(e)}
                      headCell={this.state.cabecalho} 
                      rows={this.state.configuracoes.list} 
                      acoes={this.state.acoesTabela} />
                  </Grid>
                </Grid>
              </div>
            </Main>
            <Nav history={this.props.history}/>
            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} 
            />

            <ModalConfirmacao 
              open={this.state.modalOpen} 
              dados={this.state.modal} 
              handleClose={e => this.handleClose(e)} 
              confirmar={e => this.confirmarFecharModal(e)}
            />

            <ModalConfiguracaoVoucher 
              open={this.state.openModalConfiguracao}
              handleClose={e => this.verificaFecharModal()} 
              updateFieldAtivo={(e) => this.updateFieldAtivo(e)}
              confirmar={(e) => this.salvar(e)}
              dados={this.state.configuracao}
              unidades={this.state.unidades.list}
              tabelaPrecos={this.state.tabelaPrecos.list}
              updateField={e => this.updateField(e)}
              loadingSalvar={this.state.loadingSalvar}
              itens={this.state.itens.list}
              openModalAjudaCadastro={() => {this.setState({openModalAjudaCadastro: true})}}
            />

          </div>
        }
        <Loading 
          open={this.state.loading}
          message={this.state.mensagemLoading} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Configurações de Vouchers"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
        <ModalAjudaCadastro 
          open={this.state.openModalAjudaCadastro}
          tela={"Cadastro de Configurações de Vouchers"}
          handleClose={() => {this.setState({openModalAjudaCadastro: false})}}
          markdown={this.state.markdownCadastro}
        />
      </React.Fragment>
    )
  }
}