import React, { useState, useEffect } from 'react';
import './FechamentoCaixa.scss'
import './../../main/ultil.scss'
import Color from 'color';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'
import { saveAs } from 'file-saver';
import { Grid, Button, TextField } from '@mui/material'
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import real from '../../services/real'
import moment from 'moment'
import Alert from './../../components/templates/Alert'
import CorrigirLancamentoCaixa from './../../components/modals/CorrigirLancamentoCaixa'
import ModalAjuda from './../../components/modals/Ajuda'
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  loading: true,
  caixa: {},
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalOpen: false,
  modal: {
    mensagem: '',
  },
  movimentacao: {
    valor: '',
    contfin_id: 0,
    data_conciliacao: '',
    descricao: '',
    caixa: ''
  },
  toogleTransferencia: false,
  cofre: {
    list: []
  },
  transferencias: {
    list: []
  },
  recebidos: {
    listDinheiro: [],
    listVoucher: [],
    listOutros: []
  },
  lancamentos: {
    list: []
  },
  headCellTransferencia: [
    { id: 'posicao', numeric: false, disablePadding: true, label: '' },
    { id: 'data_hora', numeric: false, disablePadding: true, label: 'Data e Hora' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição da Movimentação' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo da Movimentação' },
    { id: 'valor_caixa', numeric: false, disablePadding: true, label: 'Valor' }
  ],
  headCellRecebidos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
  ],
  headCellRecebidosOutros: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
    { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Conta Fin.' },
  ],
  headCellRecebidosVoucher: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'numero_voucher', numeric: true, disablePadding: true, label: 'Núm. Voucher' },
    { id: 'valor_credito', numeric: true, disablePadding: true, label: 'Total do Voucher' },
    { id: 'valorparcela', numeric: true, disablePadding: true, label: 'Valor Utilizado' },
    { id: 'saldo', numeric: true, disablePadding: true, label: 'Saldo Atual' },
  ],
  headCellLancamentos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
  ],
  headCellValores: [
    { id: 'formapagto_descricao', numeric: false, disablePadding: false, label: 'Forma de Pagamento' },
    { id: 'valor_recebido', numeric: false, disablePadding: false, label: 'Saldo' },
    { id: 'conta_destino', numeric: false, disablePadding: false, label: 'Conta Destino' },
  ],
  contaCofre: {},
  valores_recebidos: {
    list: []
  },
  disabledButtonTransferir: false,
  contasFinanceira: {
    list: []
  },
  openModalCorrigirLancamento: false,
  dadosEditarFormaPagto: {
    valorpagamento: '',
    formapagto_descricao: '',
    formapagto_id: ''
  },
  formas_pagamento: {
    list: []
  },
  recebidosAlterados: [],
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  editarMov: {
    parcela_id: '',
    contpr_descricao: '',
    datavencimento: '',
    datapagamento: '',
    formapagto_id: '',
    formapagto_descricao: '',
    conta_fin_id: '',
    conta_fin: '',
    multajuros: '',
    valorpagamento: '',
    valorparcela: '',
    valpag_id: '',
    habilita: ''
  },
  openModalAjuda: false,
  markdown: ''
}

const FechamentoCaixa = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { caixaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const handleCloseCorrigirLancamento = () => {
    setState(prevState => ({...prevState,
      openModalCorrigirLancamento: !state.openModalCorrigirLancamento
    }))
  }

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/caixa_fechamento");
    }, timeout)
  }

  const listaParcelas = (lista) => {
    const retorno = lista.map(object => {
      return {
        ...object,
        datavencimento: moment(object.datavencimento).format('DD/MM/YYYY'),
        datapagamento: object.datapagamento ? moment(object.datapagamento).format('DD/MM/YYYY'): '',
        num_parcela: `${object.num}/${object.contpr_numparcela}`,
        valorparcela: real(object.valorparcela || 0),
        multajuros: real(object.multajuros || 0),
        valorpagamento: real(object.valorpagamento || 0),
        valor_credito: real(object.valor_credito || 0),
        saldo: real(object.saldo || 0),
      }
    })

    return retorno
  }

  const validarAprovar = () => {
    const { valores_recebidos } = state

    for (let i = 0; i < valores_recebidos.list.length; i++) {
      if(valores_recebidos.list[i].formapagto_id !== 9){
        if (!valores_recebidos.list[i].contfin_id) return false
      }
    }

    return true
  }

  const aprovarFechamento = async () => {
    const { valores_recebidos, caixa, recebidos } = state

    if (!validarAprovar()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    let recebidos_list = []
    
    for (const recebido of recebidos.listDinheiro) {
      if(recebido.formapagto_id !== ''){
        recebidos_list.push({
          formapagto_id: recebido.formapagto_id,
          valorpagamento: recebido.valorpagamento,
          contpr_descricao: recebido.contpr_descricao,
          numparcela: recebido.contpr_numparcela,
          num: recebido.num,
          datapagamento: recebido.datapagamento,
          datavencimento: recebido.datavencimento
        })
      }
    }

    for (const recebido of recebidos.listOutros) {
      recebidos_list.push({
        formapagto_id: recebido.formapagto_id,
        valorpagamento: recebido.valorpagamento,
        contpr_descricao: recebido.contpr_descricao,
        numparcela: recebido.contpr_numparcela,
        num: recebido.num,
        datapagamento: recebido.datapagamento,
        datavencimento: recebido.datavencimento
      })
    }

    const dados = {
      id: caixa.id,
      contfin_id: caixa['conta.id'],
      valores_recebidos: valores_recebidos.list.filter(param => param.formapagto_id !== 9).map(valor => {
        return {
          formapagto_id: valor.formapagto_id,
          contfin_id: valor.contfin_id,
          tipo_conta: valor.contfin_tipo_conta,
          valor_recebido: valor.valor_recebido
        }
      }),
      recebidos: recebidos_list
    }

    for (let i = 0; i < dados.recebidos.length; i++) {
      if(dados.recebidos[i].valorpagamento === 0 || !dados.recebidos[i].valorpagamento){
        dados.recebidos.splice(i, 1)
      }
    }

    if (dados.recebidos.length === 0) {
      delete dados.recebidos
    }

    if (dados.valores_recebidos.length === 0) {
      delete dados.valores_recebidos
    }

    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Fechando o caixa...'
      }
    }))

    try {
      await axios.post(`${window._env_.REACT_APP_API_URL}/caixa/aprovarFechamento`, dados, getToken())

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Caixa fechado com sucesso'
        }
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const resumo = async (event) => {
    setState(prevState => ({...prevState, loadingSalvar: true }))

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${state.caixa.id}/resumo`, getToken())

      const dados = {
        path: data.path
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `resumo-caixa-${state.caixa.numero}.pdf`);

      setState(prevState => ({...prevState, loadingSalvar: false }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  const editarMov = (mov) => {
    setState(prevState => ({...prevState,
      editarMov: mov,
      openModalCorrigirLancamento: true
    }))
  }

  const updateFieldParcela = (event) => {
    let {editarMov, formas_pagamento, contasFinanceira} = state

    let value = event.target.value
    let name = event.target.name

    editarMov[name] = value

    if(name === 'formapagto_id'){
      if(value !== ''){
        const formaPagtoSelecionada = formas_pagamento.list.filter(param => param.id === parseInt(value))[0]
  
        editarMov.habilita = formaPagtoSelecionada.habilita
        editarMov.formapagto_descricao = formaPagtoSelecionada.descricao
        editarMov.conta_fin_id = ""
        editarMov.conta_fin = ""
      }else{
        editarMov.habilita = ""
        editarMov.conta_fin_id = ""
        editarMov.conta_fin = ""
      }
    }

    if(name === 'conta_fin_id'){
      const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(value))[0]

      editarMov.conta_fin = contaFinanceiraSelecionada.descricao
    }

    setState(prevState => ({...prevState,editarMov}))
  }

  const confirmarAlteracao = async() => {
    let {editarMov, recebidos} = state

    try {
      let dados ={
        parcela_id: editarMov.parcela_id,
        valpag_id: editarMov.valpag_id,
        formapagto_id: editarMov.formapagto_id,
        conta_fin_id: editarMov.conta_fin_id
      }

      await axios.put(`${window._env_.REACT_APP_API_URL}/caixa/alterarLancamento/${dados.parcela_id}`, dados, getToken())

      let index = recebidos.listOutros.findIndex(param => param.parcela_id === editarMov.parcela_id)

      recebidos.listOutros[index].formapagto_descricao = editarMov.formapagto_descricao
      recebidos.listOutros[index].formapagto_id = editarMov.formapagto_id

      recebidos.listOutros[index].conta_fin_id = editarMov.conta_fin_id
      recebidos.listOutros[index].conta_fin = editarMov.conta_fin

      setState(prevState => ({...prevState,
        recebidos,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Lançamento Corrigido com Sucesso.'
        },
        openModalCorrigirLancamento: false
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({...prevState,
        loading: true
      }))

      const helpPath = require("./../../help/fechamentoCaixa/View.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })

      if (caixaId) {
        try {
          const dados = {
            filtro: {
              tipo_conta: "COFRE",
              descricao: "",
              ativo: "Sim"
            }
          }

          const { data: caixa } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${parseInt(caixaId)}`, getToken())
          const { data: cofres } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())
          const { data: transferencias } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/movimentacoes`, getToken())
          const { data: recebidos } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/recebidos`, getToken())
          // const { data: lancamentos } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/${caixa.id}/lancamentos`, getToken())
          const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${caixa.unidade_id}`, getToken())
          const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
        
          setState(prevState => ({...prevState,
            contasFinanceira: {
              list: contasFinanceira
            },
            formas_pagamento: {
              list: formasPagamento.filter(param => param.ativo === true)
            }
          }))

          let { data: valores_recebidos } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/valores-recebidos/${caixa.id}`, getToken())

          for (let i = 0; i < transferencias.length; i++) {
            const index = valores_recebidos.findIndex(param => param.formapagto_descricao === "Dinheiro")

            if (index !== -1) {
              valores_recebidos[index].valor_recebido += transferencias[i].valor
            }else{
              let valor_recebido = transferencias[i].valor

              valores_recebidos.push({
                valor_recebido,
                formapagto_id: 1,
                formapagto_descricao: "Dinheiro"
              })
            }
          }

          let listDinheiro = []

          for (const transf of transferencias) {
            listDinheiro.push({
              contpr_descricao: transf.descricao,
              contpr_numparcela: 1,
              num: 1,
              datavencimento: transf.data_hora_conta,
              datapagamento: transf.data_hora_conta,
              valorparcela:transf.valor,
              multajuros: 0,
              valorpagamento:transf.valor,
              formapagto_id: '',
              formapagto_descricao: 'Dinheiro',
              tipo: transf.valor < 0 ? 'SAQUE': 'DEPOSITO'
            })
          }

          for (const parcela of recebidos) {
            if(parcela.formapagto_slug === 'dinheiro'){
              listDinheiro.push({
                contpr_descricao: parcela.contpr_descricao,
                contpr_numparcela: parcela.contpr_numparcela,
                num: parcela.num,
                datavencimento: parcela.datavencimento,
                datapagamento: parcela.datapagamento ? parcela.datapagamento : '',
                valorparcela: parcela.valorparcela,
                multajuros: parcela.multajuros ? parcela.multajuros : '',
                valorpagamento: parcela.valpag_valor_pagamento,
                formapagto_id: parcela.formapagto_id,
                formapagto_descricao: parcela.formapagto_descricao,
                tipo: parcela.valorparcela < 0 ? 'SAQUE': 'DEPOSITO'
              })
            }
          }       

          let listVoucher = recebidos.filter(param => param.formapagto_slug === 'voucher').map(parcela => {
            return {
              contpr_descricao: parcela.contpr_descricao,
              contpr_numparcela: parcela.contpr_numparcela,
              num: parcela.num,
              datavencimento: parcela.datavencimento,
              datapagamento: parcela.datapagamento ? parcela.datapagamento : '',
              valorparcela: parcela.valorparcela,
              multajuros: parcela.multajuros ? parcela.multajuros : '',
              valorpagamento: parcela.valpag_valor_pagamento,
              formapagto_descricao: parcela.formapagto_descricao,
              tipo: parcela.valorparcela < 0 ? 'SAQUE': 'DEPOSITO',
              numero_voucher: parcela.numero_voucher,
              valor_credito: parcela.valor_credito,
              saldo: parcela.saldo
            }
          })

          let listOutros = recebidos.filter(param => param.habilita === 'BANCO').map(parcela => {
            return {
              parcela_id: parcela.id,
              contpr_descricao: parcela.contpr_descricao,
              contpr_numparcela: parcela.contpr_numparcela,
              num: parcela.num,
              datavencimento: parcela.datavencimento,
              datavencimentoSemForm: parcela.datavencimento,
              datapagamento: parcela.datapagamento ? parcela.datapagamento : '',
              datapagamentoSemForm: parcela.datapagamento ? parcela.datapagamento : '',
              valorparcela: parcela.valorparcela,
              multajuros: parcela.multajuros ? parcela.multajuros : '',
              valorpagamento: parcela.valpag_valor_pagamento,
              valpag_id: parcela.valpag_id,
              formapagto_id: parcela.formapagto_id,
              formapagto_descricao: parcela.formapagto_descricao,
              tipo: parcela.valorparcela < 0 ? 'SAQUE': 'DEPOSITO',
              conta_fin_id: parcela.conta_fin_id,
              conta_fin: parcela.conta_fin,
              habilita: parcela.habilita
            }
          })

          setState(prevState => ({...prevState,
            caixa,
            cofre: {
              list: cofres
            },
            valores_recebidos: {
              list: valores_recebidos.map((valor, key) => {
                return {
                  formapagto_id: valor.formapagto_id,
                  formapagto_descricao: valor.formapagto_descricao,
                  valor_recebido: valor.valor_recebido,
                  contfin_id: valor.formapagto_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.cofre === true)[0].id : '',
                  contfin_descricao: valor.formapagto_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.cofre === true)[0].descricao : '',
                  contfin_tipo_conta: valor.formapagto_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.cofre === true)[0].tipo_conta : '',
                  contfin_conciliavel: valor.formapagto_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.cofre === true)[0].conciliavel : '',
                  index: key
                }
              })
            },
            recebidos: {
              listDinheiro,
              listVoucher,
              listOutros: listOutros.sort((a, b) => (a.formapagto_descricao > b.formapagto_descricao) ? 1 : ((b.formapagto_descricao > a.formapagto_descricao) ? -1 : 0))
            },
            contaCofre: cofres.filter(param => param.contfin_cofre === true)[0]
          }))

        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }
      }

      setState(prevState => ({...prevState, loading: false }))
    };
    
    fetchData();
  }, [caixaId]);

  const { caixa, valores_recebidos, formas_pagamento } = state
  
  const recebidos = listaParcelas(state.recebidos.listDinheiro)
  const vouchers = listaParcelas(state.recebidos.listVoucher)
  const outros = listaParcelas(state.recebidos.listOutros)

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <div>
            <CircularProgress />
          </div>
        }
        {!state.loading &&
          <div className="view" id="caixa">
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h1 className="titulo">
                  Caixa Nº {caixa.numero}
                  {caixa['status_atual.status'] !== 'ABERTO' && <span>({caixa['status_atual.status'] === 'AGUARDANDO_FECHAMENTO' ? 'AGUARDANDO FECHAMENTO' : caixa['status_atual.status']})</span>}
                </h1>
              </Grid>
            </Grid>
            <hr />
            <Grid container direction="row" spacing={1} style={{ 'marginTop': '20px' }}>
              <Grid item md={4} xs={12} sm={4}>
                <TextField className="input" label="Operador" variant="outlined" size="small" value={caixa['user.pessoa.fisica.nome']} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <TextField className="input" label="Data/Hora Abertura" variant="outlined" size="small" value={moment(caixa.dataabertura).format('DD/MM/YYYY HH:mm')} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <TextField className="input" label="Data/Hora Fechamento" variant="outlined" size="small" value={moment(caixa.datafechamento).format('DD/MM/YYYY HH:mm')} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
              <Grid item md={12} xs={12} sm={12}>
                <h5 className="titulo">Lançamentos em Dinheiro:</h5>
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                {(() => {
                  if (recebidos.length) {
                    return (
                      <Table headCell={state.headCellRecebidos} rows={recebidos} acoes={[]} ordem='desc' heightRows={15} />
                    )
                  } else {
                    return (<p>Não há recebimentos no momento</p>)
                  }
                })()}
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
              <Grid item md={12} xs={12} sm={12}>
                <h5 className="titulo">Uso de Voucher:</h5>
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                {(() => {
                  if (recebidos.length) {
                    return (
                      <Table headCell={state.headCellRecebidosVoucher} rows={vouchers} acoes={[]} ordem='desc' heightRows={15} />
                    )
                  } else {
                    return (<p>Não há recebimentos no momento</p>)
                  }
                })()}
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
              <Grid item md={12} xs={12} sm={12}>
                <h5 className="titulo">Outros:</h5>
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                {(() => {
                  if (recebidos.length) {
                    return (
                      <Table 
                        headCell={state.headCellRecebidosOutros} 
                        rows={outros} 
                        acoes={['editarMov']} 
                        ordem='desc' 
                        heightRows={15} 
                        editarMov={(e) => editarMov(e)}
                      />
                    )
                  } else {
                    return (<p>Não há recebimentos no momento</p>)
                  }
                })()}
              </Grid>
            </Grid>


            {!caixa.fechado &&
              <React.Fragment>
                <Grid container direction="row" spacing={1} style={{ marginTop: '20px' }}>
                  <Grid item lg={4} md={4} xs={12} sm={4}>
                    <h4 className="saldo">Saldo Atual:</h4>
                    <p>Iniciado com R$ {real(caixa.fundotroco || 0)}</p>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={8} xs={12} sm={12}>
                    <Table
                      headCell={state.headCellValores}
                      rows={valores_recebidos.list}
                      acoes={[]}
                      contasFinanceira={state.contasFinanceira.list}
                      qtdLinhas={valores_recebidos.list.length}
                      desabilitaRodape={true}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>

            }

            <Grid container direction="row" spacing={1} style={{ marginTop: '20px' }}>
              <Grid item md={3} xs={12} sm={4}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  className="btn_salvar"
                  size="small"
                  onClick={e => backPage(0)}>
                  Voltar
                </Button>
              </Grid>
              {caixa['status_atual.status'] !== 'FECHADO' &&
                <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className="btn_salvar"
                    size="small"
                    onClick={e => aprovarFechamento()}>
                    Aprovar Fechamento
                  </Button>
                </Grid>
              }
              {(caixa['status_atual.status'] === "AGUARDANDO_FECHAMENTO" || caixa['status_atual.status'] === 'FECHADO') &&
                  <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    className="btn_salvar"
                    size="small"
                    onClick={e => resumo(e)}
                    disabled={state.loadingSalvar}
                    color='success'
                  >
                    Imprimir Resumo
                  </Button>
                </Grid>
              }
            </Grid>

          </div>
        }
        <CorrigirLancamentoCaixa
          open={state.openModalCorrigirLancamento}
          handleClose={e => handleCloseCorrigirLancamento(e)}
          dados={state.editarMov}
          formas_pagamento={formas_pagamento.list}
          contasFinanceira={state.contasFinanceira.list}
          updateFieldParcela={(e) => updateFieldParcela(e)}
          voltar={e => handleCloseCorrigirLancamento(e)}
          confirmar={e => confirmarAlteracao(e)}
        />

        <Alert 
          open={state.alerta.open}
          handleClose={e => handleCloseAlerta()} 
          severity={state.alerta.severity}
          message={state.alerta.message} 
        />
        <ModalAjuda 
          open={state.openModalAjuda}
          tela={"Fechamento de Caixa"}
          handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
          markdown={state.markdown}
        />
      </Main>
      <Nav />
      <Footer />
    </div >
  )
}

export default FechamentoCaixa
